import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  usersList: {
    results: [],
    count: 0
  },
  activityLogData: {
    results: [],
    count: 0
  },
  eventsCategories: {
    results: [],
    count: 0
  },
  eventsCategoriesData: [],
  eventsList: {
    results: [],
    count: 0,
    pageRange: []
  },
  archivedEventsList: {
    results: [],
    count: 0,
  },
  allEventsCategories: {
    results: [],
    count: 0,
    pageRange: []
  },
  eventsPhraseBuilderList: {
    results: [],
    groupNoCount: 0,
    connector: '',
    disableComponent: false
  },
  // callsTextList: {
  //   results: [],
  //   count: 0
  // },
  callsTextList: [],
  callsTextListPageRange: 0,
  callsTextPercentage: {},
  agentNameList: [],
  teamsList: [],
  preBuilderList: [],
  buildersCategoryList: [],
  eventDetailsObj: {}
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // action: function
    apiRequested: (state) => {
      state.isLoading = true;
    },
    apiRequestFailed: (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = action.payload;
    },
    eventGetApiRequested: (state) => {
      state.isLoading = true;
      state.eventsPhraseBuilderList.disableComponent = true;
    },
    eventGetApiFailed: (state, action) => {
      state.isLoading = false;
      state.eventsPhraseBuilderList.disableComponent = false;
      state.error = action.payload;
    },
    addNewCategoryType: (state, action) => {
      console.log(action.payload);
      console.log(state.eventsCategories);
      state.isLoading = false;
      state.allEventsCategories = {
        results: [action.payload, ...state?.allEventsCategories?.results],
        count: state?.allEventsCategories?.count + 1
      };
      // state.eventsCategories.results = [action.payload, ...state.eventsCategories.results];
      // state.eventsCategories.count = +1;
    },
    editCategoryType: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      const index = state.allEventsCategories.results.findIndex((category) => category.id === action.payload.id);
      state.allEventsCategories.results[index].name = action.payload.name;
    },
    removeCategoryType: (state, action) => {
      console.log(action.payload);
      // const newArr = state?.eventsCategories?.results?.filter(obj => obj.id !== action.payload)

      // state = { results: newArr, count: state?.count - 1 }
      // return state;
    },
    addNewEventType: (state, action) => {
      state.isLoading = false;
      state.eventsList = {
        results: [action.payload, ...state.eventsList.results],
        count: state.eventsList.count + 1
      };
    },
    ArchiveEventType: (state, action) => {
      state.isLoading = false;

      const newArr = state?.eventsList?.results?.filter((obj) => obj.id !== action.payload.id);

      state.eventsList = {
        results: newArr,
        count: state.eventsList.count - 1
      };
    },
    restoreEventType: (state, action) => {
      state.isLoading = false;

      const newArr = state?.archivedEventsList?.results?.filter((obj) => obj.id !== action.payload.id);

      state.archivedEventsList = {
        results: newArr,
        count: state.archivedEventsList.count - 1
      };
    },
    getAllEventsCategories: (state, action) => {
      console.log(action.payload.data);
      console.log(state.eventsCategories);
      state.isLoading = false;
      const data = action.payload.data;
      state.eventsCategoriesData = data;
    },
    getAllEvents: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      const { results, count, pageRange } = action.payload;
      state.eventsList = { results, count, pageRange };
    },
    getAllArchivedEvents: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      const { results, count } = action.payload;
      state.archivedEventsList = { results, count };
    },
    duplicateEventType: (state, action) => {
      console.log(action.payload.data);
      console.log(state.eventsList.results);
      state.isLoading = false;
      state.eventsList = {
        results: [action.payload.data, ...state.eventsList.results],
        count: state.eventsList.count + 1
      };
    },
    getAllEventsCategoriesDataType: (state, action) => {
      state.isLoading = false;
      const { results, count, pageRange } = action.payload;
      state.allEventsCategories = { results, count, pageRange };
    },
    addNewPhraseType: (state, action) => {
      state.isLoading = false;

      const specificBuilderIndex = state?.eventsPhraseBuilderList?.results.findIndex(
        (obj) => obj.id === action.payload.phraseBuilder
      );

      console.log(specificBuilderIndex);
      state?.eventsPhraseBuilderList?.results[specificBuilderIndex].phrases.push(action.payload);
      // specificBuilder.phrases.push(action.payload);
    },
    getEventPhraseBuildersType: (state, action) => {
      console.log(action)
      state.isLoading = false;
      const { result, groupNoCount, connector, durationType, callDurationStart, callDurationEnd, isEnabled } = action.payload;
      state.eventsPhraseBuilderList = { results: result, groupNoCount, connector,  durationType, callDurationStart, callDurationEnd, isEnabled };
      state.eventsPhraseBuilderList.disableComponent = false;
    },
    getPreviewPhraseType: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      // const { data, totalCall, callCount, totalPage } = action.payload;
      // state.callsTextList.results = data.hits.hits;
      // state.callsTextList.count = totalPage;
      const { data, totalCall, callCount, totalPage } = action.payload;
      state.callsTextList = data.hits.hits;
      state.callsTextPercentage = { totalCall, callCount };
      state.callsTextListPageRange = totalPage;
    },
    addNewPhraseBuilderType: (state, action) => {
      state.isLoading = false;

      console.log(action.payload);

      // state.eventsPhraseBuilderList = {
      //   results: [...state.eventsPhraseBuilderList.results, ...action.payload.data],
      //   count: state.eventsPhraseBuilderList.count + 1
      // };
    },
    removeBuilderType: (state, action) => {
      console.log(action.payload);
      const newArr = state?.eventsPhraseBuilderList?.results?.filter(
        (obj) => obj.id !== Number(action.payload.data.builder)
      );

      state.eventsPhraseBuilderList = { results: newArr, count: state?.count - 1 };
      // return state;
    },
    removeBuilderPhraseType: (state, action) => {
      const specificBuilderIndex = state?.eventsPhraseBuilderList?.results.findIndex(
        (obj) => obj.id === action.payload.phraseBuilder
      );

      console.log(specificBuilderIndex);
      state?.eventsPhraseBuilderList?.results[specificBuilderIndex].phrases.push(action.payload);

      console.log(action.payload);
      const newArr = state?.eventsPhraseBuilderList?.results?.filter(
        (obj) => obj.id !== Number(action.payload.data.builder)
      );

      state.eventsPhraseBuilderList = { results: newArr, count: state?.count - 1 };
    },
    updateEnableType: (state, action) => {
      console.log(action)
      state.isLoading = false;
      const index = state.eventsList.results.findIndex((event) => event.id === action.payload.data.id);
      state.eventsList.results[index].isEnabled = action.payload.data.isEnabled;
    },
    updateBuilderEnableType: (state, action) => {
      state.isLoading = false;
    },
    getAgentType: (state, action) => {
      console.log(action.payload.data);
      state.isLoading = false;
      const data = action.payload.data;
      state.agentNameList = data;
    },
    getTeamsType: (state, action) => {
      console.log(action.payload.data);
      state.isLoading = false;
      const data = action.payload.data;
      state.teamsList = data;
    },
    moveEventType: (state, action) => {
      state.isLoading = true;

      // const newArr = state?.eventsList?.results?.filter((obj) => obj.id !== action.payload.id);

      // state.eventsList = {
      //   results: newArr,
      //   count: state.eventsList.count - 1
      // };
    },
    getEventDetailsType: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      state.eventDetailsObj = action.payload;
      // const newArr = state?.eventsList?.results?.filter((obj) => obj.id !== action.payload.id);

      // state.eventsList = {
      //   results: newArr,
      //   count: state.eventsList.count - 1
      // };
    },
    updateBuilderNameType: (state, action) => {
      console.log(action.payload);
      // console.log(state.eventsCategories);
      // state.isLoading = false;
      // state.allEventsCategories = {
      //   results: [action.payload, ...state?.allEventsCategories?.results],
      //   count: state?.allEventsCategories?.count + 1
      // };
      // state.eventsCategories.results = [action.payload, ...state.eventsCategories.results];
      // state.eventsCategories.count = +1;
    },
    getPreBuildersType: (state, action) => {
      console.log(action.payload.data);
      state.isLoading = false;
      // const { result, groupNoCount } = action.payload;
      // state.preBuilderList = action.payload.data;
      state.preBuilderList = [
        {
          phrases: [],
          created_at: '2023-12-15T08:59:19.966230',
          updated_at: '2023-12-15T08:59:19.966274',
          name: 'replace 1',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'agent',
          group_style: false,
          joint: 'OR',
          event: 114,
          description: 'Direct mentions',
          preBuilder: true
        },
        {
          phrases: [],
          created_at: '2023-12-15T09:16:00.043096',
          updated_at: '2023-12-15T09:16:00.043146',
          name: 'replcae 2',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'both',
          group_style: false,
          joint: 'OR',
          event: 114,
          description: 'Direct mentions',
          preBuilder: true
        },
        {
          phrases: [],
          created_at: '2023-12-15T10:47:12.246637',
          updated_at: '2023-12-15T10:47:12.246653',
          name: 'replace 3',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'both',
          group_style: false,
          joint: 'OR',
          event: 114,
          description: 'Direct mentions of a postal address or ZIP code in the call transcript',
          preBuilder: true
        },
        {
          phrases: [],
          created_at: '2023-12-15T10:47:12.246637',
          updated_at: '2023-12-15T10:47:12.246653',
          name: 'replace 4',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'both',
          group_style: false,
          joint: 'OR',
          event: 114,
          description: 'Direct mentions of a postal address or ZIP code in the call transcript',
          preBuilder: true
        },
        {
          phrases: [],
          created_at: '2023-12-15T10:47:12.246637',
          updated_at: '2023-12-15T10:47:12.246653',
          name: 'replace 5',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'both',
          group_style: false,
          joint: 'OR',
          event: 114,
          description: 'Direct mentions of a postal address or ZIP code in the call transcript',
          preBuilder: true
        },
        {
          phrases: [],
          created_at: '2023-12-15T10:47:12.246637',
          updated_at: '2023-12-15T10:47:12.246653',
          name: 'replace 6',
          phrase_type: 'Present',
          group_name: null,
          speaker: 'both',
          group_style: false,
          joint: 'OR',
          event: 114,
          description:
            'Direct mentions of a postal address or ZIP code in the call transcript ffffffffffffffffffffffffffffffff vvvvvv',
          preBuilder: true
        }
      ];
    },
    getBuildersCategoryType: (state, action) => {
      console.log(action.payload.data);
      state.isLoading = false;
      // const { result, groupNoCount } = action.payload;
      state.buildersCategoryList = action.payload.data;
      // state.buildersCategoryList = [
      //   { id: 1, name: 'Agent Behaviour' },
      //   { id: 2, name: 'Information Identifier' },
      //   { id: 3, name: 'Agent Performance' },
      //   { id: 4, name: 'Technical Complaint' },
      //   { id: 5, name: 'Agent Speech Quality' }
      // ];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  apiRequested,
  apiRequestFailed,
  eventGetApiRequested,
  eventGetApiFailed,
  addNewCategoryType,
  updateBuilderNameType,
  editCategoryType,
  removeCategoryType,
  getAllEventsCategories,
  getAllEvents,
  getAllArchivedEvents,
  removeUser,
  addNewEventType,
  ArchiveEventType,
  getEventPhraseBuildersType,
  duplicateEventType,
  getAllEventsCategoriesDataType,
  addNewPhraseType,
  addNewPhraseBuilderType,
  getPreviewPhraseType,
  removeBuilderType,
  removeBuilderPhraseType,
  updateEnableType,
  updateBuilderEnableType,
  restoreEventType,
  getAgentType,
  getTeamsType,
  moveEventType,
  getEventDetailsType,
  getPreBuildersType,
  getBuildersCategoryType
} = slice.actions;

// Action Creators
const url = '/events_categories';

export const getAllEventsCategoriesList = (pageNo) =>
  apiCallBegan({
    url: `${url}/get_list`,
    onStart: apiRequested.type,
    onSuccess: getAllEventsCategories.type,
    onError: apiRequestFailed.type
  });

export const addNewCategory = (category) =>
  apiCallBegan({
    url,
    onStart: apiRequested.type,
    method: 'POST',
    data: category,
    onSuccess: addNewCategoryType.type,
    onError: apiRequestFailed.type
  });

export const editCategory = (category) =>
  apiCallBegan({
    url: `${url}/${category.id}`,
    onStart: apiRequested.type,
    method: 'PATCH',
    data: category,
    onSuccess: editCategoryType.type,
    onError: apiRequestFailed.type
  });

export const deleteEventsCategory = (categoryId) =>
  apiCallBegan({
    url: `${url}/${categoryId}`,
    method: 'DELETE',
    onStart: apiRequested.type,
    onSuccess: removeCategoryType.type,
    onError: apiRequestFailed.type
  });

export const addNewEvent = (events) =>
  apiCallBegan({
    url: `events`,
    onStart: apiRequested.type,
    method: 'POST',
    data: events,
    onSuccess: addNewEventType.type,
    onError: apiRequestFailed.type
  });

export const ArchiveEvent = (eventId, obj) =>
  apiCallBegan({
    url: `events/${eventId}`,
    onStart: apiRequested.type,
    method: 'PATCH',
    data: obj,
    onSuccess: ArchiveEventType.type,
    onError: apiRequestFailed.type
  });

export const restoreEvent = (eventId, obj) =>
  apiCallBegan({
    url: `events/${eventId}?archive=true`,
    onStart: apiRequested.type,
    method: 'PATCH',
    data: obj,
    onSuccess: restoreEventType.type,
    onError: apiRequestFailed.type
  });

export const getAllEventsList = (categoryId, searchValue, pageNo) =>
  apiCallBegan({
    url: `events?category=${categoryId}&search=${searchValue}&page=${pageNo}`,
    onStart: apiRequested.type,
    onSuccess: getAllEvents.type,
    onError: apiRequestFailed.type
  });

export const getAllArchivedEventsList = (searchValue) =>
  apiCallBegan({
    url: `events?archive=${true}&search=${searchValue}`,
    onStart: apiRequested.type,
    onSuccess: getAllArchivedEvents.type,
    onError: apiRequestFailed.type
  });

export const duplicateEvent = (events) =>
  apiCallBegan({
    url: `events/duplicate_event`,
    onStart: apiRequested.type,
    method: 'POST',
    data: events,
    onSuccess: duplicateEventType.type,
    onError: apiRequestFailed.type
  });

export const getAllEventsCategoriesData = (searchValue) =>
  apiCallBegan({
    url: `${url}?search=${searchValue}`,
    onStart: apiRequested.type,
    onSuccess: getAllEventsCategoriesDataType.type,
    onError: apiRequestFailed.type
  });

export const getEventPhraseBuilders = (eventId) =>
  apiCallBegan({
    url: `event_phrase_builder?event=${eventId}`,
    onStart: eventGetApiRequested.type,
    onSuccess: getEventPhraseBuildersType.type,
    onError: eventGetApiFailed.type
  });

export const addNewPhrase = (events) =>
  apiCallBegan({
    url: `event_phrase`,
    onStart: apiRequested.type,
    method: 'POST',
    data: events,
    onSuccess: addNewPhraseType.type,
    onError: apiRequestFailed.type
  });

export const getPreviewPhrase = (obj, pageNo) =>
  apiCallBegan({
    url: `analyze/search?search=${obj.phrase}&call_type=${obj.callType}&search_type=${obj.searchType}&page=${pageNo}`,
    onStart: apiRequested.type,
    onSuccess: getPreviewPhraseType.type,
    onError: apiRequestFailed.type
  });

export const addNewPhraseBuilder = (events) =>
  apiCallBegan({
    url: `event_phrase_builder`,
    onStart: eventGetApiRequested.type,
    method: 'POST',
    data: events,
    onSuccess: addNewPhraseBuilderType.type,
    onError: eventGetApiFailed.type
  });

export const deletePhraseBuilder = (builderId) =>
  apiCallBegan({
    url: `event_phrase_builder/${builderId}`,
    method: 'DELETE',
    onStart: apiRequested.type,
    onSuccess: removeBuilderType.type,
    onError: apiRequestFailed.type
  });

export const searchAllPhraseBuilder = (events, pageNo) =>
  apiCallBegan({
    url: `analyze/preview_event?page=${pageNo}`,
    onStart: apiRequested.type,
    method: 'POST',
    data: events,
    onSuccess: getPreviewPhraseType.type,
    onError: apiRequestFailed.type
  });

export const deletePhrase = (builderId) =>
  apiCallBegan({
    url: `event_phrase/${builderId}`,
    method: 'DELETE',
    onStart: apiRequested.type,
    onSuccess: removeBuilderPhraseType.type,
    onError: apiRequestFailed.type
  });

export const updateEnable = (obj) =>
  apiCallBegan({
    url: `events/event_status`,
    onStart: apiRequested.type,
    method: 'POST',
    data: obj,
    onSuccess: updateEnableType.type,
    onError: apiRequestFailed.type,
    returnPromise: true // Ensure the middleware returns a promise
  });

export const getAgentList = (obj) =>
  apiCallBegan({
    url: `calls/get_call_agent?from_date=${obj.fromDate}&to_date=${obj.toDate}`,
    onStart: apiRequested.type,
    onSuccess: getAgentType.type,
    onError: apiRequestFailed.type
  });

export const moveEvent = (eventId, obj) =>
  apiCallBegan({
    url: `events/${eventId}`,
    onStart: apiRequested.type,
    method: 'PATCH',
    data: obj,
    onSuccess: moveEventType.type,
    onError: apiRequestFailed.type
  });

export const getEventDetails = (eventId) =>
  apiCallBegan({
    url: `events/${eventId}`,
    onStart: apiRequested.type,
    // method: 'PATCH',
    // data: obj,
    onSuccess: getEventDetailsType.type,
    onError: apiRequestFailed.type
  });

export const updateEventDetails = (obj) =>
  apiCallBegan({
    url: `events/${obj.id}`,
    onStart: apiRequested.type,
    method: 'PATCH',
    data: obj,
    onSuccess: getEventDetailsType.type,
    onError: apiRequestFailed.type
  });

export const getTeamsList = (obj) =>
  apiCallBegan({
    url: `calls/call_teams?from_date=${obj.fromDate}&to_date=${obj.toDate}`,
    onStart: apiRequested.type,
    onSuccess: getTeamsType.type,
    onError: apiRequestFailed.type
  });

export const updateEnableBuilder = (obj) =>
  apiCallBegan({
    url: `events/event_status`,
    onStart: apiRequested.type,
    method: 'POST',
    data: obj,
    onSuccess: updateBuilderEnableType.type,
    onError: apiRequestFailed.type
  });

export const updateBuilderName = (builderData) =>
  apiCallBegan({
    url: `event_phrase_builder/rename`,
    onStart: apiRequested.type,
    method: 'POST',
    data: builderData,
    onSuccess: updateBuilderNameType.type,
    onError: apiRequestFailed.type
  });

export const getPreBuilders = (categoryId) =>
  apiCallBegan({
    url: `pre_builder_evets?category=${categoryId}`,
    onStart: apiRequested.type,
    onSuccess: getPreBuildersType.type,
    onError: apiRequestFailed.type
  });

export const getBuildersCategory = () =>
  apiCallBegan({
    url: `builder_category`,
    onStart: apiRequested.type,
    onSuccess: getBuildersCategoryType.type,
    onError: apiRequestFailed.type
  });

  export const triggerCallsFilter = (obj) =>
    apiCallBegan({
      url: `events/assign_on_previous_call?event=${obj.event}&from_date=${obj.fromDate}&to_date=${obj.toDate}`,
      onStart: apiRequested.type,
      onSuccess: getPreBuildersType.type,
      onError: apiRequestFailed.type
    });