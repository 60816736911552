import { useRef, useState, useContext } from 'react';
// REACT ROUTER STATES
import { Link, useNavigate, useLocation } from 'react-router-dom';
// NPM IMPORTS
import { Icon } from '@iconify/react';
// MATERIAL IMPORTS
import { Avatar, Badge, Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { PATH_EMP, PATH_DASHBOARD } from "../../routes/paths";
// import context 
import { SettingsContext } from "../../contexts/SettingsContext"

// ----------------------------------------------------------------------

export default function AccountPopover({ fromDashboard }) {

  const { setColor, themeMode, setNotificationMsgCount, notificationMsgCount } = useContext(SettingsContext)

  const navigate = useNavigate();
  // REACT REF STATES
  const anchorRef = useRef(null);
  const anchorRef1 = useRef(null);
  const { pathname } = useLocation();

  // REACT STATES
  const [open, setOpen] = useState(false);
  const [openDashboard, setOpenDashboard] = useState(false);

  // METHODS / FUNCTIONS
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenMenu = () => {
    setOpenDashboard(true);
  };
  const handleCloseMenu = () => {
    setOpenDashboard(false);
  };

  const getThemeColor = () => {
    if (themeMode === 'light') {
      return setColor?.main
    }
    return setColor?.dark
  }


  return (
    <>

      {fromDashboard && <Tooltip title="Dashboard">
        {/* <Link to={PATH_DASHBOARD.general.dashboard} style={{ textDecoration: "none" }}> */}
        <Button endIcon={<KeyboardArrowDown fontSize='small' />} ref={anchorRef1} disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }} onClick={handleOpenMenu} >
          <Icon
            sx={{
              fontSize: { lg: '16px', xl: '18px' },
              mr: 1,
              color: "inherit" // Ensures the icon color matches the text color
            }}
          >

            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/dashboard.svg" width="24" height="24" />

          </Icon>
          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Dashboard</Typography>
        </Button>

        {/* </Link> */}
      </Tooltip>}

      {!fromDashboard && <Button ref={anchorRef} endIcon={<KeyboardArrowDown fontSize='small' />} sx={{
        backgroundColor: '#F27534', color: '#FFFFFF', p: 1.5, '&:focus-visible': {
          backgroundColor: '#D65A29',  // Focused background color
          color: '#E0E0E0',             // Focused text color
        },
        '&:hover': {
          backgroundColor: '#f38f5b',  // Hover state keeps the same background
        },
        '& .MuiSvgIcon-root': {
          color: '#FFFFFF', // Default icon color
        },
        '&:focus-visible .MuiSvgIcon-root': {
          color: '#E0E0E0', // Icon color on focus
        },
        '&:focus-visible .MuiTypography-root': {
          color: '#E0E0E0', // Typography color on focus
        }
      }} size='small' onClick={handleOpen}>
        <Icon
          sx={{
            fontSize: { lg: '16px', xl: '18px' },
            mr: 1,
            color: "#FFFFFF" // Ensures the icon color matches the text color
          }}
        >

          <img alt="Custom SVG Icon" src="/static/illustrations/navbar/download.svg" width="24" height="24" />

        </Icon>
        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Speech AI Hub</Typography>
      </Button>}

      <Menu
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        PaperProps={{
          sx: { width: 200, zIndex: 1300, }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleClose(); navigate('/events') }}>

          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Events</Typography>
        </MenuItem>
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleClose(); navigate('/calls') }}>

          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calls</Typography>
        </MenuItem>
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleClose(); navigate('/analyze?tab=one') }}>

          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analyze</Typography>
        </MenuItem>
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleClose(); navigate('/auto_qa_rules') }}>

          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Auto QA</Typography>
        </MenuItem>
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleClose(); navigate('/coaching?csId=CD') }}>

          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Coaching</Typography>
        </MenuItem>
        {/* <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/assignment') }}>

            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Assignment</Typography>
          </MenuItem> */}
      </Menu>

      <Menu
        open={openDashboard}
        onClose={() => handleCloseMenu()}
        anchorEl={anchorRef1.current}
        PaperProps={{
          sx: { width: 200 }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate(PATH_DASHBOARD.general.dashboard) }}>
          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Global</Typography>
        </MenuItem>

        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate(PATH_DASHBOARD.general.evaluatorDashboard) }}>
          <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Evaluator</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}