import PropTypes from 'prop-types';
import React, { useState } from 'react';
// react router imports
import { Navigate, useSearchParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, path } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

const isScoreHome = "Score & Reports,(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isEvaluatorHome = '(Score, Reports & Analytics)';
const isCalibrateHome = "Calibrate Only"
const isReportsHome = "Reports & Analytics,Reports Only"

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const lastPath = JSON.parse(window.localStorage.getItem('lastPath'));
  const [searchParams, setSearchParams] = useSearchParams()
  React.useEffect(() => {


    switch (loggedUserData?.userLevel?.name) {
      case 'Calibrate Only':
        changeDashboard(`/calibration`);
        break;
      case 'Score, Calibrate, Report & Analytics':
        changeDashboard(`/score/selectgroup`);
        break;

      case 'Score, Reports & Analytics':
        changeDashboard(PATH_DASHBOARD.general.evaluatorDashboard);
        break;

      case 'Reports & Analytics':
        changeDashboard(`/reports`);
        break;
      case 'Reports Only':
        changeDashboard(`/reports`);
        break;
      case 'Score Only':
        changeDashboard(`/score/selectgroup`);
        break;
      case "Score & Calibrate":
        changeDashboard(`/score/selectgroup`);
        break;
      case "Score & Reports":
        changeDashboard(`/score/selectgroup`);
        break;


      default:
        if (!loggedUserData?.staff) {
          changeDashboard(`/dashboard`);
        } else {
          if (loggedUserData?.staff?.staffType === 10) {
            changeDashboard(`/employee/dashboard`);
          }
          if (loggedUserData?.staff?.staffType !== 10) {
            changeDashboard(`/supervisor/dashboard`);
          }
        }
        break;
    }
  }, [loggedUserData])

  const changeDashboard = (linkName) => PATH_DASHBOARD.general.dashboard = path('/', `${linkName}`)

  if (loggedUserData?.staff?.staffType === 10 && isAuthenticated) {
    // if (lastPath && lastPath?.pathLink !== "/auth/login" && (loggedUserData?.email === lastPath?.email)) {
    //   return <Navigate to={lastPath?.pathLink} />
    // }
    return <Navigate to='/employee/dashboard' />;
  }

  if ((loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && isAuthenticated) {
    // if (lastPath && lastPath?.pathLink !== "/auth/login" && (loggedUserData?.email === lastPath?.email)) {
    //   return <Navigate to={lastPath?.pathLink} />
    // }
    return <Navigate to='/supervisor/dashboard' />;
  }

  if (!loggedUserData?.staff && isAuthenticated) {

    // if (lastPath && lastPath?.pathLink !== "/auth/login" && (loggedUserData?.email === lastPath?.email)) {
    //   return <Navigate to={lastPath?.pathLink} />
    // }
    if (isCalibrateHome.includes(loggedUserData?.userLevel?.name)) {
      return <Navigate to='/calibration' />;
    }
    if (isReportsHome.includes(loggedUserData?.userLevel?.name)) {
      return <Navigate to='/reports' />;
    }
    if (isEvaluatorHome.includes(loggedUserData?.userLevel?.name)) {
      return <Navigate to='/evaluator_dashboard' />;
    }
    if (isScoreHome.includes(loggedUserData?.userLevel?.name)) {
      return <Navigate to='/score/selectgroup' />;
    }
    if (searchParams.get('isTicket') === "True" && (!loggedUserData?.staff || (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30))) {
      return <Navigate to={`/support/support_view?ticket_id=${searchParams.get('ticket_id')}`} />
    }
    // if(searchParams.get('isTicket') === "True" && (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ) {
    //   return <Navigate to={`/support/support_view?ticket_id=${searchParams.get('ticket_id')}`} />
    // }
    return <Navigate to='/dashboard' />;
  }

  return <>{children}</>;
}
