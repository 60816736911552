import React from 'react';
import { Stack, CircularProgress, Box, IconButton, Typography, Dialog, DialogContent, Button, DialogTitle, Divider, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { EmpContext } from '../../contexts/employeecontext';
import { accptCommentAPI } from "../../_apis_/authApi.js/empapi";
import { MIconButton } from '../../components/@material-extend';


export default function AcceptComment({ ID, setID, fn }) {
    const { acceptComment, setAcceptComment } = React.useContext(EmpContext);
    const [disBtn, setDisBtn] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: { comment: "" },
        // validationSchema: Yup.object({ comment: Yup.string().required("*required") }),
        onSubmit: values => {
            (async function () {
                setDisBtn(true)
                const obj = {
                    score: ID,
                    comments: values.comment
                }
                const { data } = await accptCommentAPI(obj);
                setDisBtn(false);
                enqueueSnackbar('Accepted Commented Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )

                });
                if (fn) {
                    fn();
                }
                setAcceptComment(false); formik.resetForm(); if (setID) {
                    setID();
                }


            })()
        }
    });




    return (
        <>
            <Dialog open={acceptComment} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack sx={{ px: 2, py: 1 }} direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">
                            Accept Comment
                        </Typography>
                        <IconButton onClick={() => { setAcceptComment(false); formik.resetForm(); if (setID) { setID(); } }}><Close /></IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ py: 1, px: 2 }}>
                        <Typography sx={{ my: 1 }} variant="body">Are you sure you want to accept this score?</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ my: 2 }}>
                                <TextField name="comment" {...formik.getFieldProps('comment')} fullWidth label="Add a comment concering this score" sx={{ my: 1, width: "100%" }} multiline rows={5} />
                                {formik.errors.comment && formik.touched.comment && <Typography variant="body2" sx={{ color: "red" }}>{formik.errors.comment}</Typography>}
                            </Box>
                            <Stack direction="row" justifyContent="flex-end" sx={{ pr: 1, mt: 3 }}>
                                <Button onClick={() => { setAcceptComment(false); formik.resetForm(); setID(); }} variant="contained" sx={{ mr: 2 }}>Cancel</Button>
                                <Button variant="contained" disabled={disBtn} type='submit'>Yes,Accept {disBtn && <CircularProgress sx={{ mx: 1 }} size={14} />}</Button>
                            </Stack>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}