import * as Yup from 'yup';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// 
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import moment from 'moment'
// material
import { Close } from '@material-ui/icons'
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Alert,
  Stack,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  ListSubheader,
  InputAdornment,
  FormControl,
  IconButton,
  Divider,
  Box,
  Typography,
  OutlinedInput,
  Autocomplete,
  Chip,
  Radio
} from '@material-ui/core';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//
// import { MIconButton } from '../../../../components/@material-extend';
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext'
import { AnalyticContext } from '../../../../contexts/AnalyticContext';
import { SettingsContext } from "../../../../contexts/SettingsContext"
import { getPointsOfFailureSupervisorApi } from "../../../../_apis_/authApi.js/analytic_api";
import { formulaType, DateRangeList, filterByList, setFilterByHeader } from '../../../../utils/filterUtilities';

// ------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle:
  {
    padding: 5
  },
  select: {
    backgroundColor: "#FFFFFF"
  },
  selectDisabled: {
    backgroundColor: "#E9ECEF"
  },
  textField: {
    backgroundColor: "#FFFFFF"
  },
  textFieldLock: {
    backgroundColor: "#E9ECEF"
  },
  selectLock: {
    backgroundColor: "#E9ECEF"
  }
}));

// ----------------------------------------------------------------------

export default function AnalyticSupervisorFilterDialog({ addUserButton, isDialogOpened }) {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const grpArr = loggedUserData?.groups?.map(obj => obj.id);
  // CONTEXT STATES
  const { getScoreLogGroups, reportsScoreLogGroups, getScoreLogScorecards, reportsScoreLogScorecards, getScoreLogEventType, reportsScoreLogEventType, getScoreLogEventSubType, reportsScoreLogEventSubType, getScoreLogTeams, reportsScoreLogTeams, getScoreLogSupervisor, reportsScoreLogSupervisor, getScoreLogEmployee, reportsScoreLogEmployee, getScoreLogCategory, reportsScoreLogCategory, getScoreLogEvaluator, reportsScoreLogEvaluator } = useContext(ScoreCardsContext);
  const { dataTagByScoreCard, setDataTagByScoreCard,
    getDataTagsByScoreCard, selAnaSupervisorFilters,
    selectedSupervisorToWeek, setSelectedSupervisorToWeek,
    selectedSupervisorFromWeek, setSelectedSupervisorFromWeek,
    selectedSupervisorEvaluatorFilter, setSelectedSupervisorEvaluatorFilter,
    selectedSupervisorEventSubTypeFilter, setselectedDataTagFilter,
    selectedSupervisorDataTagFilter, setselectedSupervisorDataTagFilter,
    selectedSupervisorDataTagOperator, setselectedSupervisorDataTagOperator,
    setSelectedSupervisorEventSubTypeFilter,
    selectedSupervisorHighlightFilter, setSelectedSupervisorHighlightFilter,
    selectedSupervisorEventTypeFilter, setSelectedSupervisorEventTypeFilter,
    setSelectedSupervisorTeamFilter, selectedSupervisorTeamFilter,
    selectedSupervisorGroupFilter, setPointOfFailureSupervisorsData,
    setPointOfFailureSupervisorsLoading, selectedSupervisorDateAnaRange,
    setSelectedSupervisorDateAnaRange, getResultAcceptanceBySupervisor,
    setSelAnaSupervisorFilters, setSelectedSupervisorFilterBy,
    getSupervisorBySectionDetails, selScDurationInd, selectedSupervisorFromDate,
    selectedSupervisorToDate, setSelectedSupervisorToDate,
    setSelectedSupervisorFromDate, selectedSupervisorFilterBy,
    subModLabel, selectedSupervisorScorecardFilter,
    setSelectedSupervisorScorecardFilter, setSelectedSupervisorGroupFilter,
    setSelectedTeamFilter, selectedSupervisorFilter, getSupervisorByQuestion
  } = useContext(AnalyticContext);

  const { dataCreatedFrom } = useContext(SettingsContext);

  // REACT ROUTER STATES
  const [searchParams, setSearchParams] = useSearchParams();

  // REACT STATES
  const [open, setOpen] = useState(false);
  const [advanceFilters, setAdvanceFilters] = useState();
  const [customFilters, setCustomFilters] = useState(false);
  const [fromWeekNo, setFromWeekNo] = useState();
  const [toWeekNo, setToWeekNo] = useState();
  const [circular, setCircular] = useState(false);

  // Data tags state
  const [isAnyOfTag, setIsAnyOfTag] = useState(true)
  const [isAllOfTag, setIsAllOfTag] = useState(false)

  // REF STATES
  const isMountedRef = useIsMountedRef();


  // FORMIK STATES
  const ReportSchema = Yup.object().shape({
    scoreCardSingle: (subModLabel === 2.1 || subModLabel === 2.3 || subModLabel === 2.2) ? Yup.number().required('This Value is required').nullable() : '',
    group: (subModLabel === 2.1 || subModLabel === 2.3 || subModLabel === 2.2) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      dateRange: '',
      filterByRange: '',
      fromDate: '',
      fromWeek: '',
      toDate: '',
      toWeek: '',
      filterBy: 'created_at',
      group: '',
      teams: '',
      scoreCard: [],
      scoreCardSingle: '',
      scoreCardSingle2: '',
      scoreCardName: [],
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: '',
      staff: '',
      scoreCardCategory: '',
      evaluator: '',
      highlightValue: '',
      reference: '',
      section: '',
      formula: 10,
      subgrp: '',
      dataTag: [],
      allOfTag: '',
      anyOfTag: '',
    },
    validationSchema: ReportSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {

      console.log("values", values)
      setCircular(true);
      setSelectedSupervisorHighlightFilter(values.highlightValue);
      try {

        if (subModLabel === 2.1) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCardSingle,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            staff: values.staff,
            dateRange: values?.dateRange,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            section: values.section,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            formula: values.formula || '',
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
          };
          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.data?.length > 0) {
            setSelAnaSupervisorFilters({
              ...filterObj, scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
            await getSupervisorBySectionDetails({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
          } else {
            setSelAnaSupervisorFilters({
              ...filterObj, scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle
            });
            await getSupervisorBySectionDetails({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle
            });
          }


          setSearchParams(filterObj)
          setCircular(false);

          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            setCircular(false);
            console.log(values);
            setOpen(false);
          }

        }
        if (subModLabel === 2.2) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            dateRange: values.dateRange,
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCardSingle,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            staff: values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            section: values.section,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            formula: values.formula || '',
            sup_grp: values.subgrp || '',
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag || ''
          };

          try {
            setPointOfFailureSupervisorsData([]);
            setPointOfFailureSupervisorsLoading(true);
            const { data } = await getPointsOfFailureSupervisorApi({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle
            });
            setPointOfFailureSupervisorsData(data?.results);
            setCircular(false);
            handleClose();
            setSearchParams({ ...filterObj })
            setSelAnaSupervisorFilters({ ...filterObj });
            setPointOfFailureSupervisorsLoading(false);

            if (values.scoreCardSingle !== '') {
              setSelectedSupervisorScorecardFilter(values.scoreCardSingle);

            }

          } catch (err) {
            setPointOfFailureSupervisorsData([]);
            setPointOfFailureSupervisorsLoading(false);
            setCircular(false);
          }


          if (useIsMountedRef.current) {
            setSubmitting(false);
            console.log(values);
            setCircular(false);
            setOpen(false);
          }

        }
        if (subModLabel === 2.3) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCardSingle,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            staff: values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            dateRange: values?.dateRange,
            highlightValue: values.highlightValue,
            section: values.section,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            formula: values.formula || '',
            sup_grp: values.subgrp || '',
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
          };
          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.data?.length > 0) {
            setSelAnaSupervisorFilters({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
            await getSupervisorByQuestion({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
          } else {
            await getSupervisorByQuestion({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,

            });
            setSelAnaSupervisorFilters({
              ...filterObj, scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle
            });
          }
          if (advanceFilters && formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {
            getScoreLogEventType(filterObj);
            getScoreLogEventSubType(filterObj);
            getScoreLogSupervisor(filterObj);
          }
          setSearchParams(filterObj)
          if (values.scoreCardSingle !== '') {
            setSelectedSupervisorScorecardFilter(values.scoreCardSingle);
          }
          setCircular(false)
          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            console.log(values);
            setCircular(false);
            setOpen(false);
          }

        } else if (subModLabel === 2.4) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            filterBy: values.filterBy,
            group: values.group,
            teams: values.teams,
            scoreCard: values.scoreCardSingle,
            scoreCardGroups: values?.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            staff: values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            section: values.section,
            supervisor: values.supervisor,
            numericValues: values.numericValues,
            dateRange: values?.dateRange,
            sup_grp: values.subgrp || '',
            formula: values.formula || '',
            dataTag: [values.dataTag] || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
          };
          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.data?.length > 0) {
            setSelAnaSupervisorFilters({
              ...filterObj, scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
            await getResultAcceptanceBySupervisor({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,
              dataTag: [values.dataTag] || '',
              dataTagsOperator: values.anyOfTag || values.allOfTag || '10'
            });
          } else {
            await getResultAcceptanceBySupervisor({
              ...filterObj,
              scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle,

            });
            setSelAnaSupervisorFilters({
              ...filterObj, scoreCard: values.scoreCardSingle,
              scoreCardSingle: values.scoreCardSingle
            });
          }

          setSearchParams(filterObj)
          if (formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {
            getScoreLogEventType(filterObj);
            getScoreLogEventSubType(filterObj);
            getScoreLogSupervisor(filterObj);
          }

          if (values.scoreCardSingle !== '') {
            setSelectedSupervisorScorecardFilter(values.scoreCardSingle);
          }

          setCircular(false);
          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            console.log(values);
            setCircular(false);
            setOpen(false);
          }

        }

      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
          setCircular(false);
        }
      }
    },
    enableReinitialize: true,
  });
  const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;

  // USEEFFECT METHODS

  useMemo(() => {
    console.log(selectedSupervisorDateAnaRange, selectedSupervisorFromDate, selectedSupervisorToDate);
    if (selectedSupervisorDateAnaRange) {
      console.log("one", selectedSupervisorDateAnaRange)
      setFieldValue("dateRange", selectedSupervisorDateAnaRange);
      setFieldValue("fromDate", selectedSupervisorFromDate);
      setFieldValue("toDate", selectedSupervisorToDate);

    } else if (subModLabel === 2.4) {
      setFieldValue("dateRange", 'Today');
      setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
      setFieldValue("toDate", moment().format('YYYY-MM-DD'));
    } else {
      setFieldValue("dateRange", 'This Month');
      setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
      setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
    }


    if (selectedSupervisorDateAnaRange === 'Custom Date Range') {
      setCustomFilters(true);
      setFieldValue("fromWeek", selectedSupervisorFromWeek);
      setFieldValue("toWeek", selectedSupervisorToWeek);
      setFieldValue("fromDate", selectedSupervisorFromDate);
      setFieldValue("toDate", selectedSupervisorToDate);
    }

    if (selectedSupervisorFilterBy) {
      setFieldValue("filterBy", selectedSupervisorFilterBy);
    } else {
      setFieldValue("filterBy", 'created_at');
    }

    if (selectedSupervisorGroupFilter) {
      // setFieldValue("group", selAnaSupervisorFilters?.group);
      setFieldValue("group", selectedSupervisorGroupFilter);
    }
    else {
      setFieldValue("group", '');
    }

    if (selectedSupervisorTeamFilter) {
      setFieldValue("teams", selectedSupervisorTeamFilter);
    }
    else {
      setFieldValue("teams", '');
    }

    console.log('selectedSupervisorScorecardFilter', selectedSupervisorScorecardFilter)
    if (selectedSupervisorScorecardFilter) {
      setFieldValue("scoreCardSingle", selectedSupervisorScorecardFilter)
      const filterScoreCardObj = {
        "fromDate": values.fromDate || searchParams.get('fromDate'),
        "toDate": values.toDate || searchParams.get('toDate'),
        "filterBy": values.filterBy || searchParams.get('filterBy'),
        "scoreCard": selectedSupervisorScorecardFilter ?? '',
      }
      getDataTagsByScoreCard(filterScoreCardObj);
    } else {
      setFieldValue("scoreCardSingle", '');
    }

    if (selectedSupervisorEventTypeFilter) {
      setFieldValue("eventType", selectedSupervisorEventTypeFilter);
    } else {
      setFieldValue("eventType", '')
    }
    console.log('selectedSupervisorEventTypeFilter', selectedSupervisorEventTypeFilter, selectedSupervisorEventSubTypeFilter)
    if (selectedSupervisorEventSubTypeFilter) {
      setFieldValue("eventTypeEventSubType", selectedSupervisorEventSubTypeFilter);
    } else {
      setFieldValue("eventTypeEventSubType", '')
    }


    if (selectedSupervisorEvaluatorFilter) {
      setFieldValue("evaluator", selectedSupervisorEvaluatorFilter);
    } else {
      setFieldValue("evaluator", '')
    }

    if (selectedSupervisorHighlightFilter) {
      setFieldValue("highlightValue", selectedSupervisorHighlightFilter)
    } else {
      setFieldValue("highlightValue", 0);
    }

    console.log('selectedSupervisorDataTagFilter', selectedSupervisorDataTagFilter)
    if (selectedSupervisorDataTagFilter?.length > 0) {
      setFieldValue('dataTag', selectedSupervisorDataTagFilter)
    } else {
      setFieldValue('dataTag', [])
    }

    console.log('selectedSupervisorDataTagOperator',
      selectedSupervisorDataTagOperator === '20',
      searchParams.get('dataTagsOperator'))
    if ((selectedSupervisorDataTagOperator || searchParams.get('dataTagsOperator')) !== '20') {
      setIsAnyOfTag(true)
      setIsAllOfTag(false)
      setFieldValue('anyOfTag', '10')
      setFieldValue('allOfTag', '');

    } else {
      setIsAnyOfTag(false)
      setIsAllOfTag(true)
      setFieldValue('allOfTag', '20');
      setFieldValue('anyOfTag', '');
    }

    // if (selectedSupervisorDataTagOperator !== '20') {
    //   setIsAnyOfTag(true)
    //   setIsAllOfTag(false)
    // } else {
    //   setIsAnyOfTag(false)
    //   setIsAllOfTag(true)
    // }

    console.log('selAnaSupervisorFilter 2,1', selAnaSupervisorFilters)
    setFieldValue("scoreCardCategory", '');

    if (searchParams.get('formula')) {
      setFieldValue('formula', searchParams.get('formula'))
    } else {
      setFieldValue('formula', '')
    }

    if (searchParams.get('highlightValue')) {
      setFieldValue('highlightValue', searchParams.get('highlightValue'))
    } else {
      setFieldValue('highlightValue', '')
    }
  }, [open]);

  useEffect(() => {

    const arrFromWeek = [];
    const arrToWeek = [];
    if (formik.values.fromWeek > moment().week()) {
      for (let i = 1; i <= formik.values.fromWeek; i) {
        arrFromWeek.push(i);
        i += 1;
      }
      setFromWeekNo(arrFromWeek);
    } else {
      for (let i = 1; i <= moment().week(); i) {
        arrFromWeek.push(i);
        i += 1;
      }
      setFromWeekNo(arrFromWeek);
    }

    if (formik.values.toWeek > moment().week()) {
      for (let i = 1; i <= formik.values.toWeek; i) {
        arrToWeek.push(i);
        i += 1;
      }
      setToWeekNo(arrToWeek);
    } else {
      for (let i = 1; i <= moment().week(); i) {
        arrToWeek.push(i);
        i += 1;
      }
      setToWeekNo(arrToWeek);
    }

    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": values.supervisor,
      "staff": values.staff,
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const groupFilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: '',
      teams: values.teams,
      scoreCard: values.scoreCard || values.scoreCardSingle,
      eventType: values.eventType,
      eventTypeEventSubType: values.eventTypeEventSubType,
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    console.log('value 2.4', values, selectedSupervisorScorecardFilter)
    const evntTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard?.length > 0 ? values.scoreCard : (values.scoreCardSingle),
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const evntSubTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard?.length > 0 ? values.scoreCard : (values.scoreCardSingle),
      "eventType": values.eventType,
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    console.log('formik values', values)
    const scoreCardfilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const teamFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const empFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard || values.scoreCardSingle,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": values.supervisor,
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const supFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard || values.scoreCardSingle,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": '',
      "staff": values.staff,
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    if (formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {
      // if (subModLabel !== 2.4) {
      getScoreLogTeams(teamFilterObj);
      getScoreLogScorecards(scoreCardfilterObj);
      getScoreLogEventType(evntTypeFilterObj);
      getScoreLogEventSubType(evntSubTypeFilterObj);
      getScoreLogSupervisor(supFilterObj);
      getScoreLogEmployee(empFilterObj);
      getScoreLogEvaluator(filterObj);
      // }
      getScoreLogGroups(groupFilterObj);
    }
  }, [formik?.values]);



  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };


  // Close user dialog modal
  const handleClose = (event, reason) => {

    if (reason !== 'backdropClick') {

      setOpen(false)
    }
  };

  // METHODS

  const handleGroupChange = (event, newValue) => {

    // Resetting remaining fields
    setFieldValue("teams", '');
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("scoreCardSingle", '');
    setFieldValue('dataTag', [])

    setFieldValue("group", newValue?.id ?? '');
    setSelectedSupervisorGroupFilter(newValue?.id ?? '');
    const filterObj =
    {
      "fromDate": values.fromDate,
      "toDate": values.toDate,
      "filterBy": values.filterBy,
      "group": (newValue?.id || selectedSupervisorGroupFilter) ?? '',
      "teams": '',
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    getScoreLogTeams(filterObj);
    getScoreLogScorecards(filterObj);
    getScoreLogEventType(filterObj);
    getScoreLogEventSubType(filterObj);

  };

  const handleTeamChange = (event) => {
    // Resetting remaining fields
    setFieldValue("scoreCardSingle", []);
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue('dataTag', [])

    setFieldValue("teams", event.target.value);
    setSelectedSupervisorTeamFilter(event.target.value);

  };

  const handleDataTags = (event) => {
    console.log('data tag', event)
    setFieldValue("dataTag", (event.target.value).flat(100));
    setselectedSupervisorDataTagFilter((event.target.value).flat(100));
    localStorage.setItem('dataTags', JSON.stringify((event.target.value).flat(100)))

    console.log('dataTag', (event.target.value).flat(100));
  }

  const handleAnyOfTag = (event) => {
    setFieldValue('allOfTag', '')
    setFieldValue('anyOfTag', '10')
    setselectedSupervisorDataTagOperator('10')
    setIsAnyOfTag(true)
    setIsAllOfTag(false)
  }

  const handleAllOfTag = () => {
    setFieldValue('dataTag', [])
    setFieldValue('anyOfTag', '')
    setFieldValue('allOfTag', '20')
    setselectedSupervisorDataTagOperator('20')
    setIsAnyOfTag(false)
    setIsAllOfTag(true)
  }

  const handleScorecardChange = (event, newValue) => {
    console.log("newValue", newValue, event)
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("dataTag", []);

    // if (newValue?.scoreCardName) {
    setFieldValue("scoreCardSingle", newValue?.scoreCard ?? '');
    setSelectedSupervisorScorecardFilter(newValue?.scoreCard ?? '');
    // }
    localStorage.setItem('dataTags', [])
    setSelectedSupervisorEventTypeFilter('')
    setSelectedSupervisorEventSubTypeFilter('')
    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
      "teams": values.teams,
      "scoreCard": (newValue?.scoreCard || selectedSupervisorScorecardFilter || values?.scoreCardSingle) ?? '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
    }
    getDataTagsByScoreCard(filterObj);
  };

  const handleDateRangeChange = (event) => {

    // Resetting remaining fields
    setFieldValue("group", '');
    setFieldValue("teams", '');
    setFieldValue("scoreCardSingle", '');
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("evaluator", '');
    setFieldValue('dataTag', [])


    const dateRange = event.target.value;
    setFieldValue("dateRange", dateRange);
    // setScorecardType(event.target.value);
    console.log("dateRange", event.target.value)
    setSelectedSupervisorGroupFilter('')
    setSelectedSupervisorScorecardFilter('')
    setSelectedSupervisorTeamFilter('')
    setSelectedSupervisorDateAnaRange('');
    setSelectedSupervisorFilterBy('');
    setSelectedSupervisorEventTypeFilter('');
    setSelectedSupervisorEventSubTypeFilter('')
    setselectedSupervisorDataTagFilter([])
    switch (dateRange) {

      case 'Today':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Today');
        break;
      case 'Yesterday':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Yesterday');
        break;
      case 'This Week':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('week').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().startOf('week').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().endOf('week').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('This Week');
        break;
      case 'Last Week':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Last Week');
        break;
      case 'This Month':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().endOf('month').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('This Month');
        break;
      case 'Last Month':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Last Month');
        break;
      case 'This Quarter':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('This Quarter');
        break;
      case 'Last Quarter':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Last Quarter');
        break;
      case 'This Year':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('year').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().startOf('year').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().endOf('year').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('This Year');
        break;
      case 'Last Year':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedSupervisorFromDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'))
        setSelectedSupervisorToDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'))
        setSelectedSupervisorDateAnaRange('Last Year');
        break;
      case 'Custom Date Range':
        setCustomFilters(true);
        formik.setFieldValue("fromDate", moment());
        formik.setFieldValue("toDate", moment());
        setFieldValue("toWeek", toWeekNo[toWeekNo?.length - 1]);
        setFieldValue("fromWeek", fromWeekNo[fromWeekNo?.length - 1]);
        setSelectedSupervisorFromWeek(fromWeekNo[fromWeekNo?.length - 1]);
        setSelectedSupervisorToWeek(toWeekNo[toWeekNo?.length - 1])
        setSelectedSupervisorDateAnaRange('Custom Date Range');
        break;
      default:
        setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().format('YYYY-MM-DD'));
        break;
    }

  };



  const handleFilterByChange = (event) => {

    // setFieldValue("filterByRange", filterBy);
    // setScorecardType(event.target.value);
    setFieldValue("group", '');
    setFieldValue("teams", '');
    setFieldValue("scoreCardSingle", '');
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("evaluator", '');
    setFieldValue('dataTag', [])

    switch (event.target.value) {

      case 'created_at':
        setFieldValue("filterBy", 'created_at');
        setSelectedSupervisorFilterBy(event.target.value);
        break;
      case 'event_date':
        setFieldValue("filterBy", 'event_date');
        setSelectedSupervisorFilterBy(event.target.value);
        break;
      case 'updated_at':
        setFieldValue("filterBy", 'updated_at');
        setSelectedSupervisorFilterBy(event.target.value);
        break;
      default:
        setFieldValue("filterBy", 'created_at');
        setSelectedSupervisorFilterBy(event.target.value);
        break;
    }

  };

  const handleEventTypeChange = (event) => {

    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", 0);
    setFieldValue("section", '');
    setFieldValue("evaluator", '');
    setFieldValue('eventType', event.target.value);
    setSelectedSupervisorEventTypeFilter(event.target.value);


    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
      "teams": values.teams,
      "scoreCard": values.scoreCard,
      "eventType": event.target.value,
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
    }


    getScoreLogEvaluator(filterObj);
    getScoreLogEventSubType(filterObj);


  };

  const handleEventSubTypeChange = (event) => {

    // setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", 0);
    setFieldValue("section", '');
    setFieldValue("evaluator", '');

    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
      "teams": values.teams,
      "scoreCard": values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": event.target.value,
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
    }

    setFieldValue('eventTypeEventSubType', event.target.value);
    getScoreLogEvaluator(filterObj);
    setSelectedSupervisorEventSubTypeFilter(event.target.value);

  };

  const handleEvalChange = (event) => {
    setFieldValue("evaluator", event.target.value)
    setSelectedSupervisorEvaluatorFilter(event.target.value);
  }

  const handleAdvancedFilters = (event) => {
    setAdvanceFilters((advanceFilters) => !advanceFilters);
  };


  const renderOptions = (props, option) => {
    return <li {...props} key={option?.scoreCard} style={{ color: option?.scoreCardIsArchive ? 'red' : '' }}>
      {option?.scoreCardName}
    </li>

  }

  return (
    <div>

      {addUserButton &&
        <Button variant="contained" onClick={handleClickOpen}>
          Filter
        </Button>
      }

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle sx={{ pb: 2 }} > <h3>Report Filter</h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 15,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: '20px' }}>
              <Stack spacing={3}>

                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 0 }}>
                  <FormControl fullWidth sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    {subModLabel !== 3.3 &&
                      <Button startIcon={advanceFilters ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        sx={{ p: 0 }} onClick={handleAdvancedFilters}>
                        {advanceFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                      </Button>}
                  </FormControl>
                </Stack>
                <Divider />


                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                      labelId="date-range-label"
                      id="demo-select-small"
                      value={values.dateRange}
                      label="Date Range"
                      name="dateRange"
                      {...getFieldProps('dateRange')}
                      onChange={(e) => { handleDateRangeChange(e) }}
                    >
                      {DateRangeList?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                          {item.dateRange}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="filter-by-label">Filter By</InputLabel>
                    <Select
                      labelId="filter-by-label"
                      id="demo-select-small"
                      value={values.filterBy}
                      label="Filter By"
                      name="filterBy"

                      {...getFieldProps('filterBy')}
                      onChange={handleFilterByChange}
                    >
                      {filterByList?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.id}>
                          {item.type}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {customFilters && <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box sx={{ width: "50%" }}>
                      <DesktopDatePicker
                        label="Date From"
                        inputFormat="yyyy/MM/dd"
                        name="fromDate"
                        minDate={new Date(dataCreatedFrom)}
                        disableFuture
                        {...getFieldProps('fromDate')}
                        onChange={(newValue) => {
                          setFieldValue("group", '');
                          if (moment(newValue).format('MM-DD') === '01-01') {
                            formik.setFieldValue("fromWeek", 1)
                            setSelectedSupervisorFromWeek(1)
                          } else {
                            formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            setSelectedSupervisorFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                          }

                          setFieldValue("fromDate", newValue);
                          setSelectedSupervisorFromDate(newValue);

                          if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                            formik.setFieldValue("toDate", newValue)
                            setSelectedSupervisorToDate(newValue);
                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("toWeek", 1)
                              setSelectedSupervisorToWeek(1)

                            } else {
                              formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedSupervisorToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}
                    </Box>

                    <Box sx={{ width: "50%" }}>
                      <DesktopDatePicker
                        label="Date To"
                        inputFormat="yyyy/MM/dd"
                        name="toDate"
                        minDate={new Date(dataCreatedFrom)}
                        disableFuture
                        {...getFieldProps('toDate')}
                        onChange={(newValue) => {
                          setFieldValue("group", '')
                          if (moment(newValue).format('MM-DD') === '01-01') {
                            formik.setFieldValue("toWeek", 1)
                            setSelectedSupervisorToWeek(1)
                          } else {
                            formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            setSelectedSupervisorToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                          }
                          setFieldValue("toDate", newValue);
                          setSelectedSupervisorToDate(newValue);

                          // Extract year from full date
                          const fullDate = moment(newValue);
                          const extractyear = fullDate.year();

                          if (moment(newValue, "YYYY-MM-DD").isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {
                            formik.setFieldValue("fromDate", newValue)
                            setSelectedSupervisorFromDate(newValue);
                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("fromWeek", 1)
                              setSelectedSupervisorFromWeek(1)

                            } else {
                              formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedSupervisorFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                    </Box>
                  </Stack>
                </LocalizationProvider>}
                {customFilters && <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      required
                      value={formik.values.fromWeek}
                      multiple={false}
                      select
                      name="fromWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("fromWeek", e.target.value);
                        setSelectedSupervisorFromWeek(e.target.value);
                        formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                        setSelectedSupervisorFromDate(moment().week(e.target.value).startOf('week'))
                      }}

                    >
                      {fromWeekNo?.map((item) => (
                        <MenuItem key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}

                    </TextField>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      multiple={false}
                      value={formik.values.toWeek}
                      required
                      select
                      name="toWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("toWeek", e.target.value);
                        setSelectedSupervisorToWeek(e.target.value);
                        formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                        setSelectedSupervisorToDate(moment().week(e.target.value).endOf('week'));
                      }}

                    >
                      {toWeekNo?.map((item) => (
                        <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}
                    </TextField>
                  </Box>

                </Stack>}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={(subModLabel === 2.4 ? [{
                        name: 'All', id: ''
                      }].concat(reportsScoreLogGroups) : reportsScoreLogGroups)?.find((option) => option?.id === values?.group)}
                      fullWidth
                      options={subModLabel === 2.4 ? [{
                        name: 'All', id: ''
                      }].concat(reportsScoreLogGroups) : reportsScoreLogGroups ?? [{}]
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, newValue) => {
                        handleGroupChange(event, newValue);
                      }}
                      id="controllable-states-demo"
                      renderInput={(params) => <TextField {...params} label="Group"
                        popoverprops={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        variant="outlined"
                        error={Boolean(touched.group && errors.group)}
                        helperText={touched.group && errors.group}
                        required
                      />}
                    />
                  </FormControl>
                  {(subModLabel === 2.4) && <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">Teams</InputLabel>
                    <Select
                      name="teams"
                      required
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.teams}
                      {...getFieldProps('teams')}
                      input={<OutlinedInput notched label="Teams" />}
                      onChange={handleTeamChange}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogTeams?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                  }
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={(subModLabel === 2.4 ? [{
                        scoreCardName: 'All', scoreCard: ''
                      }].concat(reportsScoreLogScorecards) : reportsScoreLogScorecards)?.find((option) => option?.scoreCard === values?.scoreCardSingle)}
                      fullWidth
                      options={subModLabel === 2.4 ? [{
                        scoreCardName: 'All', scoreCard: ''
                      }].concat(reportsScoreLogScorecards) : reportsScoreLogScorecards ?? [{}]}
                      getOptionLabel={(option) => option?.scoreCardName || ""}
                      renderOption={renderOptions}
                      onChange={(event, newValue) => {
                        handleScorecardChange(event, newValue);
                      }}
                      id="controllable-states-demo"
                      renderInput={(params) => <TextField {...params} label="Scorecard"
                        popoverprops={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        variant="outlined"
                        error={Boolean(touched.scoreCardSingle && errors.scoreCardSingle)}
                        helperText={touched.scoreCardSingle && errors.scoreCardSingle}
                        required
                      />}
                    />
                  </FormControl>
                  {
                    (dataTagByScoreCard?.data?.length > 0) && <> <FormControl fullWidth>
                      <InputLabel shrink id="demo-multiple-name-label">Data Tag(s)</InputLabel>
                      <Select
                        name='dataTag'
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={values.dataTag}
                        {...getFieldProps('dataTag')}
                        input={<OutlinedInput notched label="Data Tag(s)" />}
                        onChange={handleDataTags}
                      >
                        {dataTagByScoreCard?.data?.map((item, i) => (
                          <MenuItem key={i} id={item.dataTags} value={item.dataTags}>
                            {item.dataTagsName}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    </>
                  }

                </Stack>
                {dataTagByScoreCard?.data?.length > 0 && <Stack direction={{ sm: 'row' }} spacing={0} alignItems="flex-end" justifyContent="flex-end">
                  <FormControlLabel
                    name="anyOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="10"
                    control={<Radio />}
                    label="Any of the Tags"
                    checked={isAnyOfTag}
                    onChange={handleAnyOfTag}
                  />
                  {/* {...getFieldProps('anyOfTag')} */}

                  <FormControlLabel
                    name="allOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="20"
                    control={<Radio />}
                    label="All of the Tags"
                    onChange={handleAllOfTag}
                    checked={isAllOfTag}


                  />
                  {/* {...getFieldProps('allOfTag')} */}

                </Stack>

                }
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="group-label">Event Type</InputLabel>
                    <Select
                      // defaultValue='All'
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      // value={values.type}
                      // label="Event Type"
                      name="eventType"
                      input={<OutlinedInput notched label="Event Type" />}
                      {...getFieldProps('eventType')}
                      onChange={handleEventTypeChange}

                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogEventType?.map((item, index) => (
                        <MenuItem key={index} id={item.eventType} value={item.eventType}>
                          <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">Event Sub Type</InputLabel>
                    <Select
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      name="eventTypeEventSubType"
                      input={<OutlinedInput notched label="Event Sub Type" />}
                      {...getFieldProps('eventTypeEventSubType')}
                      onChange={handleEventSubTypeChange}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogEventSubType?.map((item, index) =>
                        <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                          <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventSubType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>

                </Stack>
                {
                  advanceFilters &&
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {
                      subModLabel !== 2.4 && <FormControl sx={{ width: "50%" }}>
                        <TextField
                          fullWidth
                          label="Highlight Below"
                          value={formik.values.highlightValue}
                          {...getFieldProps('highlightValue')}
                          onClick={(e) => {
                            formik.setFieldValue("highlightValue", e.target.value);
                          }}
                          error={Boolean(touched.highlightValue && errors.highlightValue)}
                          helperText={touched.highlightValue && errors.highlightValue}
                        />
                      </FormControl>
                    }

                    {(subModLabel === 2.1 || subModLabel === 2.3) &&
                      <FormControl sx={{ width: "50%" }}>
                        <InputLabel shrink id="group-label">
                          Formula
                        </InputLabel>
                        <Select
                          labelId="group-label"
                          id="demo-select-small"
                          displayEmpty
                          value={formik?.values.formula}
                          input={<OutlinedInput notched label="Formula" />}
                          {...getFieldProps('formula')}
                        >
                          {/* <MenuItem value=""> Select </MenuItem > */}

                          {formulaType?.map((item) => (
                            <MenuItem key={item.id} id={item.id} value={item.id}>
                              {item.type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </Stack>

                }
                {(advanceFilters && subModLabel === 2.4) && <FormControl sx={{ width: "50%" }}>
                  <InputLabel shrink id="scorecard-label">Evaluators</InputLabel>
                  <Select
                    defaultValue='All'
                    labelId="scorecard-label"
                    id="demo-select-small"
                    displayEmpty
                    input={<OutlinedInput notched label="evaluator" />}
                    onChange={handleEvalChange}
                    {...getFieldProps('evaluator')}
                  >
                    <MenuItem value=""> All </MenuItem >
                    {reportsScoreLogEvaluator?.map(obj => <MenuItem key={obj?.evaluator} value={obj?.evaluator}>{`${obj?.evaluatorFirstName} ${obj?.evaluatorLastName}`}</MenuItem>)}
                  </Select>
                </FormControl>
                }
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => { handleClose() }} color="inherit">
                Cancel
              </Button>
              <Button type="submit" disabled={circular} variant="contained" loading={isSubmitting?.toString()}>
                Apply{circular && <CircularProgress size={14} sx={{ ml: 1 }} />}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}