import React from 'react';
// NPM IMPORTS
import moment from 'moment';

export const CalibrationContext = React.createContext();

export default function CalibrationProviders({ children }) {
// LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // STATES
    const [openCreateList, setOpenCreateList] = React.useState(false);
    const [isListAdded, setIsListAdded] = React.useState([]);
    const [count, setCount] = React.useState(0);

    // list builder filter states
    const [listBuilderGroups, setListBuilderGroups] = React.useState('all')
    const [listBuilderFilterBy, setListBuilderFilterBy] = React.useState('created_at');
    const [listBuilderTeams, setListBuilderTeams] = React.useState([])
    const [listBuilderEventType, setListBuilderEventType] = React.useState([])
    const [DateData, setListBuilderDateData] = React.useState({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') });
    const [listBuilderCategory, setListBuilderCategory] = React.useState('all')
    const [supervisorGrp, setSupervisorGrp] = React.useState([]);
    const [countlistBuilder, setCountlistBuilder] = React.useState(0);
    const [calibrationDate, setCalibrationDate] = React.useState(JSON.parse(localStorage?.getItem('filterDate')))
    // const [calibrationToDate, setCalibrationToDate] = React.useState()

    // edit page states
    const [tableData, setTableData] = React.useState([]);

    // list filter states
    const [listDate, setListDate] = React.useState({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') });

    React.useEffect(() => {
        if (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) {
            setSupervisorGrp(loggedUserData?.groups?.map(obj => obj.id));
        }

    }, [])
    return (
        <CalibrationContext.Provider value={{
            openCreateList, setOpenCreateList,
            isListAdded, setIsListAdded,
            listBuilderGroups, setListBuilderGroups,
            listBuilderTeams, setListBuilderTeams,
            listBuilderEventType, setListBuilderEventType,
            listBuilderCategory, setListBuilderCategory,
            listBuilderFilterBy, setListBuilderFilterBy,
            DateData, setListBuilderDateData,
            listDate, setListDate,
            tableData, setTableData,
            supervisorGrp,
            count, setCount,
            countlistBuilder, setCountlistBuilder,

            // calibration list page date
            calibrationDate, setCalibrationDate
        }}>
            {children}
        </CalibrationContext.Provider>
    )
}