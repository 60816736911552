// import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, Skeleton } from '@material-ui/core';
// hooks
// import useCollapseDrawer from '../hooks/useCollapseDrawer';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

FooterLogo.propTypes = {
  sx: PropTypes.object
};

export default function FooterLogo({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const SECONDARY_MAIN = theme.palette.secondary.main;

  // const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  // context
  const { 
    // updateCompanySettings, getCompanySettings, 
    // updateProfilePicture, getProfilePicture, deleteProfilePicture, updateLogo, 
    headerLogo, 
    // setHeaderLogo, favIconLogo, setFavIconLogo 
  } = useAuth();
  // const [headerLogo, setHeaderLogo] = useState();

  // useEffect(() => {

  //   const fetchMyAPI = async () => {

  //     const response = await getCompanySettings();

  //     // console.log("scoreCardSettings", response);
  //     setHeaderLogo(response?.logo);
  //     setFavIconLogo(response?.favicon);

  //   }

  //   fetchMyAPI();
  // }, [headerLogo, favIconLogo]);

  return (
    <Box sx={{ height: 30, ...sx }}>
      {headerLogo ? <img alt="logo" src={headerLogo} height="30" /> : <Skeleton variant="rectangular" height={40} width={170} />}
    </Box>
  );
}
