import { useState, useRef } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import MainFooter from '../../main/MainFooter';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
//
import SupervisorDashboardNavbar from './sup_navbar';
import HelpSupportPopUpForm from "../../../pages/dashboard/help_support/help_support_pop_up_form";
import DashboardNavbar from '../DashboardNavbar';
import DashboardSidebar from '../DashboardSidebar';
// import MainFooter from '../main/MainFooter';
// import SupervisorDashboardNavbar from './supervisor_dashboard/sup_navbar';
// import EmployeeDashboardNavbar from "./employee_dashboard/emp_navbar";
import EmployeeDashboardSidebar from '../employee_dashboard/emp_sidenavbar';
import SupervisorDashboardSidebar from './sup_sidebar';
// import HelpSupportPopUpForm from "../../pages/dashboard/help_support/help_support_pop_up_form";



// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    // paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP - 12,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function SupervisorDashboardLayout() {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation()
    const pathnameIndexTwo = [
        'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore', 'call_details', 'eventBuilder'
    ]

    const ref = useRef(null);
    const theme = useTheme();
    const { collapseClick } = useCollapseDrawer();
    const [open, setOpen] = useState(false);
    console.log('loggedUserData', loggedUserData, 'loggedUserData?.staff', loggedUserData?.staff)
    return (
        <>
            <RootStyle>
                <SupervisorDashboardNavbar
                    onOpenSidebar={() => setOpen(true)}
                />
                {/* {loggedUserData?.staff?.staffType !== 10 &&
                    <SupervisorDashboardSidebar
                        isOpenSidebar={open}
                        onCloseSidebar={() => setOpen(!true)}
                    />} */}
                {/* {(loggedUserData?.staff?.staffType === 20 || (loggedUserData?.staff?.staffType === 30)) &&
                    <SupervisorDashboardNavbar
                        onOpenSidebar={() => setOpen(true)}
                    />} */}
                {(loggedUserData?.staff?.staffType !== 10 && searchParams.get("review")) &&
                    <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
                {/* {loggedUserData?.staff?.staffType === 10 && <EmployeeDashboardNavbar type="score" />} */}
                {(!loggedUserData?.staff && !searchParams.get("review")) &&
                    <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
                {(!loggedUserData?.staff && !searchParams.get("review") && !searchParams.get("stff_emp_id") &&
                    !pathnameIndexTwo?.includes(pathname?.split('/')[2])) &&
                    <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}

                {(loggedUserData?.staff?.staffType === 10 || searchParams.get("stff_emp_id")) && <EmployeeDashboardSidebar />}

                <MainStyle
                    sx={{
                        transition: theme.transitions.create('margin', {
                            duration: theme.transitions.duration.complex
                        }),
                        // ...(collapseClick && {
                        //     ml: '102px'
                        // })
                    }}
                >
                    <Outlet />
                </MainStyle>
                <HelpSupportPopUpForm reference={ref} />
            </RootStyle>
            <MainFooter />
        </>
    );

}
