import React from 'react';
// MATERIAL IMPORTS
import {
    IconButton, Stack, Tooltip,
    Card, CardHeader, Box, Typography, Button, Divider
} from "@material-ui/core";
import { FilterAltOutlined } from "@material-ui/icons";
// OTHER COMPONENTS IMPORTS
import Page from '../../components/Page';
import { BlockNewUI } from '../components-overview/Block_newUI';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import GlobalDatePicker from "./datepicker";
import GloabalDashboardBody from "./main";
// CONTEXT IMPORTS
import { StaffContext } from '../../contexts/staffContext';


export default function AdminDashboard() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT STATES
    // const [open, setOpen] = React.useState(false);

    // CONTEXT STATES
    const { strtDate, endDate, tester } = React.useContext(StaffContext);
    const handleGlobalDashboard = () => {
        switch (loggedUserData?.userLevel?.name) {
            case 'Global Admin':
                return 'Global Dashboard';
            case 'Employee':
                return 'Admin Employee Dashboard';
            case 'Team Admin':
                return 'Team Dashboard';
            case 'Group Admin':
                return 'Group Dashboard';
            case 'Score, Reports & Analytics':
                return 'Score, Reports & Analytics Dashboard';
            case 'Score, Calibrate, Report & Analytics':
                return 'Score, Calibrate, Report & Analytic Dashboard';
            case 'Reports & Analytics':
                return 'Reports & Analytics Dashboard';
            case 'Score & Calibrate':
                return 'Score & Calibrate';
            case 'Score Only':
                return 'Score Dashboard';
            case 'Reports Only':
                return "Reports Dashboard";
            case 'Billing Only':
                return 'Billing Dashboard';
            case 'Dashboard Only':
                return 'Dashboard';
            case 'Calibrate Only':
                return 'Calibrate Dashboard';
            case 'Score & Reports':
                return 'Score & Reports Dashboard';
            case 'Advanced Supervisor':
                return 'Advanced Supervisor Dashboard';
            case 'Supervisor':
                return 'Supervisor Dashboard';
            default:
                return 'Global Dashboard'

        }
    }
    return (
        <>
            <Page title="Dashboard | BeyondQA" sx={{ py: 1, px: 3 }}>
                
                <GloabalDashboardBody handleGlobalDashboard={handleGlobalDashboard}/>

            </Page>
        </>
    )
}