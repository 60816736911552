// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: "#000000 !important"
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.background.default,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
              color: `${theme.palette.mode === "light" ? '#ffffff' : '#000000'} !important`
            },
            '&:focus': {
              backgroundColor: theme.palette.action.selected,
              color: theme.palette.background.default,
            }
          },
        }
      }
    }
  };
}
