import React from 'react';
// REACT ROUTER IMPORTS
import { Link as RouterLink, useSearchParams } from "react-router-dom";
// MATERIAL IMPORTS
import { Box, Button, CircularProgress, Paper, Stack, Tab, TablePagination, Typography } from '@material-ui/core';
import { Comment, ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
// NPM IMPORTS
import { Interweave } from 'interweave';
import { Icon } from '@iconify/react';
import moment from 'moment';
// OTHER COMPONENTS IMPORTS
import EmpDatePicker from "./datepicker";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../routes/paths';
import AcceptComment from "./commentDialog";
import AcceptOne2One from "./one2oneDialog";
import AcceptReview from "../supervisor/reviewDialog";
import { setEmpTargetComment, setButtonBg } from "../../utils/filterUtilities";
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import { SettingsContext } from '../../contexts/SettingsContext';
// API IMPORTS
import { empScoreListApi, empCommentDetailApi } from "../../_apis_/authApi.js/empapi";


export default function EmpComment() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();

    // CONTEXT STATES
    const { setColor, themeMode } = React.useContext(SettingsContext);
    const { datesData, setAcceptReview, acceptOne2One, setAcceptOne2One } = React.useContext(EmpContext);

    // REACT STATES
    const [value, setValue] = React.useState('1');
    const [commentData, setCommentData] = React.useState([]);
    const [scoreList, setScoreList] = React.useState([]);
    const [id, setId] = React.useState();
    const [label, setLabel] = React.useState("Personal Goals");
    const [scorecrdId, setScoreCardId] = React.useState();
    const [date, setDate] = React.useState(moment(new Date()).format("YYYY, DD, MM"));
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [evaluator, setEvaluator] = React.useState();
    const [supervisor, setSupervisor] = React.useState();


    // METHODS/ FUNCTIONS

    React.useEffect(() => {
        if (!acceptOne2One) {
            setEvaluator(undefined);
            setSupervisor(undefined);
        }
    }, [acceptOne2One])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPage(0)
    };

    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    const setTargetArrow = val => {
        if (val === 10) {
            return <ArrowDownward fontSize='large' sx={{ color: setEmpTargetComment(val)?.bgColor }} />
        }
        if (val === 20) {
            return <ArrowUpward fontSize='large' sx={{ color: setEmpTargetComment(val)?.bgColor }} />
        }
        if (val === 30) {
            return <ArrowDownward fontSize='large' sx={{ color: setEmpTargetComment(val)?.bgColor }} />
        }
    }


    const tabButtons = (props) => {
        // console.log(props.direction, props)
        if (props.direction === 'left' && !props.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => props.onClick()} icon="icon-park-solid:left-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />
            </Stack>)
        }
        if (props.direction === 'right' && !props.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => props.onClick()} icon="icon-park-solid:right-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />

                </Stack>
            )
        }
        return null

    }

    async function getList() {
        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

        try {

            setIsDataLoading(true);
            loadingTimeOut();
            setCommentData([]);
            const { data } = await empScoreListApi(searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2);
            setScoreList(data.data);
            setIsDataLoading(false);
            setScoreCardId(data?.data[0]?.score_card)
            const res = await empCommentDetailApi(data?.data[0]?.score_card, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2);
            // console.log(res)
            setCommentData(res.data.results);
            setValue('1');
            setPage(0);
            setCount(res.data.count)
        } catch (err) {
            setIsDataLoading(false);
        }
    }


    async function getCommentDetails(id, pg) {
        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

        // const res = await empCommentDetailApi(data?.data[0]?.id, loggedUserData?.staff?.id, strtDate, endDate);

        // setCommentData(res.data.data)
        try {
            setCommentData([]);
            setScoreCardId(id);
            setIsDataLoading(true);
            setPage(0);
            setCount(0)
            const res = await empCommentDetailApi(id, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2, pg || 1);
            setCommentData(res.data.results)
            setCount(res.data.count);
            setIsDataLoading(false);
        } catch (err) {
            console.log(err);
        }
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        getCommentDetails(scorecrdId, newPage + 1)

    }

    // USEEFFECT STATES
    React.useEffect(() => {
        setScoreList([]);
        if (datesData?.date1) {
            getList();
        }
    }, [datesData])

    return (
        <Box sx={{ px: 1 }}>
            <Stack sx={{ p: 0, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0 }} direction='row' alignContent='center' alignItems='center' justifyContent='space-between'>

                <Box>

                    <Typography variant='h5' sx={{ p: 0, m: 0, fontSize: { lg: '20px', xl: '28px' }, color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }}>
                        Comments
                    </Typography>
                    <HeaderBreadcrumbs
                        sx={{ pr: 3, mb: 1, color: '#666666', fontWeight: 600 }}
                        links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Comments', href: PATH_EMP.comments }]}
                    />

                </Box>
                <EmpDatePicker />
            </Stack>
            {/* <HeaderBreadcrumbs
                sx={{ pl: 2, ml: 3, pt: 1, pr: 3 }}
                links={[{ name: 'Home', href: PATH_EMP.dashboard }, { name: 'Comments', href: PATH_EMP.comments }]}
            /> */}
            <Paper sx={{ width: "100%", pb: 2, pt: 1, borderRadius: '5px' }} elevation={5}>

                {/* <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ px: 4, py: 2 }}>
                    <Typography variant="h4">Comments</Typography>
                    <EmpDatePicker />
                </Box>
                <Divider /> */}
                <Box>
                    {scoreList.length > 0 &&
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                                <TabList TabIndicatorProps={{ style: { backgroundColor: themeMode === 'light' ? setColor?.main : setColor?.dark } }} ScrollButtonComponent={(props) => tabButtons(props)} variant="scrollable" sx={{ width: '100%', display: "flex", flexDirection: "row", px: 3 }} onChange={handleChange}>
                                    {scoreList.map((tab, index) => (
                                        <Tab sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} key={index} label={tab.score_card__name} onClick={() => { setPage(0); getCommentDetails(tab?.score_card) }} value={String(index + 1)} />
                                    ))}
                                </TabList>
                            </Box>

                            <Box sx={{ width: '100%', px: 3, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                                <TablePagination
                                    size='small'
                                    sx={{ border: '1px solid transparent' }}
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={count || 0}
                                    rowsPerPage={100}
                                    page={page}
                                    onPageChange={handleChangePage}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                            <Box
                                sx={{
                                    py: 1,
                                    mt: 1,
                                    px: 5,
                                    height: 'auto',
                                    width: '100%',
                                    borderRadius: 1
                                }}
                            >
                                {scoreList?.map((panel, index) => (
                                    <TabPanel key={index} value={String(index + 1)}>
                                        {scoreList?.length > 0 ? <Box>
                                            {commentData.length > 0 ?
                                                commentData?.map(row => <Box key={row.id} sx={{ borderRadius: 1, border: "1px solid grey", my: 2, overflow: "hidden" }}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ px: 2, py: 1, backgroundColor: " #dcdde1" }}>
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography sx={{ fontSize: "18px", ml: 2 }}>
                                                                {row?.score_result === 20 && <ArrowUpward fontSize='large' sx={{ color: setEmpTargetComment(row?.score_result)?.bgColor }} />}
                                                                {(row?.score_result === 10 || row?.score_result === 30) && <ArrowDownward fontSize='large' sx={{ color: setEmpTargetComment(row?.score_result)?.bgColor }} />}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: "17px", fontWeight: "bold", color: setEmpTargetComment(row?.score_result)?.bgColor }}>
                                                                {setEmpTargetComment(row?.score_result)?.targetName}
                                                            </Typography>
                                                        </Stack>

                                                        <Button disableRipple sx={{ backgroundColor: setButtonBg(row?.score_result), cursor: "default", color: "white", "&:hover": { backgroundColor: setButtonBg(row?.score_result) } }} variant="contained">{`${row?.percentage}%`}</Button>

                                                    </Box>
                                                    <Box display="flex" alignItems="center" sx={{ minHeight: "150px", width: "100%", px: 2, py: 1 }}>
                                                        <Box sx={{ width: "90%", py: 1, px: 3 }}>
                                                            <Typography variant="body">
                                                                {row?.is_score_accepted && <Stack direction="flow"><h4>Accepted-</h4> <Interweave content={row.comments} /></Stack>}
                                                                {row?.is_onetoone_req && <Stack direction="flow"><h4>1:1 Req-</h4> <Interweave content={row.comments} /></Stack>}
                                                                {row?.is_score_reviewed && <Stack direction="flow"><h4>Reviewed-</h4> <Interweave content={row.comments} /></Stack>}
                                                                {(row?.is_score_accepted === false && row?.is_score_reviewed === false && row?.is_onetoone_req === false) && <Interweave content={row.comments} />}

                                                            </Typography><br />
                                                            <Box sx={{ mt: 3 }}>
                                                                <Typography variant="body2" sx={{ fontSize: "bold" }}>
                                                                    {row?.commented_by || "user"}
                                                                </Typography>
                                                                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                                                    {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 1 && "Today"}
                                                                    {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') === 1 && "1 Day Ago"}
                                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 7 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') > 1) && `${moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days')} days ago`}
                                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 7 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 14) && "1 week ago"}
                                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 14 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 28) && "2 week ago"}
                                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 28 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 30) && "3 week ago"}
                                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 30 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 60) && "1 month ago"}
                                                                    {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 60 && moment(row?.created_at).format("LL")}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ width: "100%", pr: 2 }} display="flex" alignItems="center" justifyContent="flex-end">
                                                            <RouterLink to={searchParams.get("stff_emp_id") ? `/reports/reviewPerformance?score_card_Id=${scorecrdId}&score_Id=${row?.score_id}&review=True&sup_view=True` : `/reports/reviewPerformance?score_card_Id=${scorecrdId}&score_Id=${row?.score_id}&review=True`}><Button variant="text">View</Button></RouterLink>
                                                            {(row?.score_onetoone && (!row?.score_accepted && !row?.score_reviewed)) && <Button disabled variant="text" sx={{ mx: 1 }}>1:1 Requested</Button>}
                                                            {(((!row?.score_accepted && !row?.score_reviewed) && !row?.score_onetoone) && loggedUserData?.staff?.staffType === 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only') && <Button variant="text" sx={{ mx: 1 }} onClick={() => { setSupervisor(row?.supervisor); setEvaluator(row?.evaluator?.name); setAcceptOne2One(true); setId(row?.score_id) }}>1:1 Req</Button>}
                                                            {/* {row?.score_accepted ? <Button disabled variant="contained">Accepted</Button> : <Button variant="outlined" onClick={() => { setAcceptComment(true); setId(row?.score_id) }}>Accept</Button>} */}
                                                            {row?.score_accepted && <Button sx={{ mx: 1 }} disabled variant="contained">Accepted</Button>}
                                                            {/* {(!row?.score_accepted && loggedUserData?.staff?.staffType === 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only') && <Button variant="outlined" sx={{ mx: 1 }} onClick={() => { setAcceptComment(true); setId(row?.score_id) }}>Accept</Button>} */}
                                                            {row?.score_reviewed && <Button disabled variant="contained">Reviewed</Button>}
                                                            {(!row?.score_reviewed && loggedUserData?.staff?.staffType !== 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only') && <Button variant="outlined" sx={{ mx: 1 }} onClick={() => { setAcceptReview(true); setId(row?.score_id) }}>Review</Button>}

                                                        </Box>
                                                    </Box>
                                                </Box>) : <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                                    <Comment sx={{ fontSize: "100px" }} /><br />
                                                    {!isDataLoading && <Typography variant="body" sx={{ my: 1 }}>
                                                        No Comments found for the data range selected.
                                                    </Typography>}
                                                    {isDataLoading && <Stack direction="row" alignItems="center" justifyContent="center">
                                                        <CircularProgress />
                                                        <Typography variant="body" sx={{ my: 1 }}>
                                                            Loading Data...
                                                        </Typography>
                                                    </Stack>}
                                                </Box>}
                                        </Box> : <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                            <Comment sx={{ fontSize: "100px" }} /><br />
                                            <Typography variant="body" sx={{ my: 1 }}>
                                                No Comments found for the data range selected.
                                            </Typography>
                                        </Box>}
                                    </TabPanel>
                                ))}
                            </Box>
                        </TabContext>}

                    {scoreList?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                        <Comment sx={{ fontSize: "100px" }} /><br />
                        {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                            <CircularProgress />
                            <Typography variant="body" sx={{ my: 1 }}>
                                Loading Data...
                            </Typography>
                        </Stack> : <Typography variant="body" sx={{ my: 1 }}>
                            No Comments found for the data range selected.
                        </Typography>}
                    </Box>}
                    <AcceptComment ID={id} setID={setId} fn={getList} />
                    <AcceptReview ID={id} setID={setId} fn={getList} />
                    <AcceptOne2One supervisorName={supervisor} evaluatorName={evaluator} ID={id} setID={setId} fn={getList} />
                </Box>
            </Paper >
        </Box>
    )
}