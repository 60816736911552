import React, { useCallback } from 'react';
import update from 'immutability-helper'

// import MATERIAL UI 
import { Box } from '@material-ui/core';
// import 'react-resizable/css/';
import Card from './cards';


// CONTEXTS IMPORTS
import { CollapseDrawerContext } from "../../../../../../contexts/CollapseDrawerContext";
import { AnalyticContext } from "../../../../../../contexts/AnalyticContext";







export default function AddedCustomReports({ data }) {
    const { customData, setCustomData } = React.useContext(AnalyticContext);
    const { collapseClick, collapseHover } = React.useContext(CollapseDrawerContext);
    const ref1 = React.useRef();
    const ref2 = React.useRef();
    const [test, setTest] = React.useState([]);


    const useRefDimensions = (ref) => {
        const [dimensions, setDimensions] = React.useState({ width: 1, height: 2 })
        React.useEffect(() => {

            if (ref.current) {
                const { current } = ref
                const boundingRect = current.getBoundingClientRect()
                const { width, height } = boundingRect

                if (window.screen.width <= 2560 && window.screen.width > 1920) {
                    if (collapseClick) {
                        setDimensions({ width: 2350, height: Math.round(height) })
                    } else {
                        setDimensions({ width: 2150, height: Math.round(height) })
                    }
                }
                if (window.screen.width <= 1920 && window.screen.width > 1536) {
                    if (collapseClick) {
                        setDimensions({ width: 1650, height: Math.round(height) })
                    } else {
                        setDimensions({ width: 1485, height: Math.round(height) })
                    }
                }
                if (window.screen.width <= 1536 && window.screen.width > 1366) {
                    if (collapseClick) {
                        setDimensions({ width: 1065, height: Math.round(height) })
                    } else {
                        setDimensions({ width: 1220, height: Math.round(height) })
                    }
                }
                // if (window.screen.width <= 1536 && window.screen.width > 1366) {
                //     if (collapseClick) {
                //         setDimensions({ width: 1065, height: Math.round(height) })
                //     } else {
                //         setDimensions({ width: 1220, height: Math.round(height) })
                //     }
                // }
                if (window.screen.width <= 1366 && window.screen.width > 768) {
                     if (collapseClick) {
                        setDimensions({ width: 1300, height: Math.round(height) })
                    } else {
                        setDimensions({ width: 1050, height: Math.round(height) })
                    }
                }
                if (window.screen.width <= 768 && window.screen.width > 424) {
                    if (collapseClick) {
                       setDimensions({ width: 820, height: Math.round(height) })
                   } else {
                       setDimensions({ width: 1050, height: Math.round(height) })
                   }
               }
               if (window.screen.width <= 424) {
                if (collapseClick) {
                   setDimensions({ width: 580, height: Math.round(height) })
               } else {
                   setDimensions({ width: 1050, height: Math.round(height) })
               }
           }

                // setDimensions({ width: Math.round(width - 30), height: Math.round(height) })



            }
        }, [ref, collapseClick])
        return dimensions
    }

    const dimensions = useRefDimensions(ref1)
    // ref1.current = dimensions

    React.useEffect(() => {
        const temp = customData?.map(item => {
            return {
                id: item?.id, text: item?.report_module_name
            }
        })
        setTest(temp)
    }, [customData])

    const style = {
        width: '100%',
        // border: '1px solid black',

        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
    }
    // console.log(test)

    {
        const [cards, setCards] = React.useState([

        ])

        React.useEffect(() => {
            const temp = customData?.map((item, index) => { return { id: item?.id, text: item?.report_module_name, test: customData[index] } })

            // console.log(customData, temp)
            setCards(temp)
        }, [customData])

        const moveCard = useCallback(
            (dragIndex, hoverIndex) => {
                const dragCard = cards[dragIndex]
                // setCards(
                //     update(cards, {
                //         $splice: [
                //             [dragIndex, 1],
                //             [hoverIndex, 0, dragCard],
                //         ],
                //     }),

                // )
                const t = cards
                // console.log(t)
                const arr = [];
                for (let index = 0; index < cards.length; index += 1) {
                    if (index === dragIndex) { arr.push(cards[hoverIndex]) }
                    if (index === hoverIndex) { arr.push(cards[dragIndex]) }
                    if (index !== dragIndex && index !== hoverIndex) {
                        arr.push(cards[index])
                    }

                }
                // console.log(arr)
                setCards(arr)
            },
            [cards],
        )
        console.log('size', dimensions)
        const renderCard = (card, index, size) => {
            return (

                <Card
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={`${card?.text} ${card.id}`}
                    moveCard={moveCard}
                    width1={size?.width}
                    apiData={card.test}
                />

            )
        }
        return (
            <>
                <Box ref={ref1}
                    sx={{
                        width: "100%",
                        backgroundColor: theme => theme.palette.background.paper
                    }}>
                    <div ref={ref2} style={style}>

                        {cards.map((card, i) => renderCard(card, i, dimensions, customData[i]))}

                    </div>
                </Box>

            </>
        )
    }
}