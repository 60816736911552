import React, { useState, createContext, useEffect, useCallback } from 'react';

export const NetworkContext = createContext();

export default function NetworkProvider({ children }) {

    const [isOnline, setIsOnline] = useState(() => {
        return navigator.onLine
    });

    const setOnlineToTrue = useCallback(() => {
        setIsOnline(true)
    }, [])
    const setOnlineToFalse = useCallback(() => {
        setIsOnline(false)
    }, [])

    useEffect(() => {
        window.addEventListener('online', setOnlineToTrue)
        window.addEventListener('offline', setOnlineToFalse)
        return () => {
            window.removeEventListener('online', setOnlineToTrue)
            window.removeEventListener('offline', setOnlineToFalse)
        }
    }, [setOnlineToTrue, setOnlineToFalse])

    return (
        <NetworkContext.Provider value={{ isOnline }} >
            {children}
        </NetworkContext.Provider>
    )


}