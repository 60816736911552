import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
import moment from "moment";


// material
import {
    Box,
    Table,
    Switch,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Button,
    Avatar,
    TableContainer,
    TablePagination,
    FormControlLabel,
    Stack,
    Tooltip,
    CircularProgress,
    Typography,
    Divider,
    OutlinedInput, InputAdornment,

} from '@material-ui/core';
import { Interweave } from 'interweave';

import { CheckBox } from "@material-ui/icons";
import { useTheme, styled } from '@material-ui/core/styles';


// components../
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
// import SearchNotFound from '../../../../../../components/SearchNotFound';
import { delGroup, getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
import { getSingleInbox, getSearchInbox } from "../../../../../_apis_/authApi.js/globalDashboardApi";
//
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import { StaffContext } from "../../../../../contexts/staffContext";



// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Id'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description'
    },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location'
    },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    console.log("okay")
}

function getComparator(order, orderBy) {
    console.log("okay")
}



function applySortFilter(array, comparator, query) {
    console.log("okay")
}

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 45,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function MessageInboxTable() {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const group = useSelector(state => state.groups);
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { setSearchText } = useContext(StaffContext);
    const [toUser, setToUser] = useState("");
    const [seenBy, setSeenBy] = useState("");

    // get Groups
    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllGroups(1))
        async function getValue() {
            const { data } = await getSingleInbox(searchParams.get("id"));
            console.log(data);
            const newArr = []; const newArr2 = [];
            data?.to_user?.map(obj => newArr.push(`${obj?.first_name} ${obj?.last_name}`));
            data?.seen_by?.map(obj => newArr2.push(`${obj?.first_name} ${obj?.last_name}`));
            setSeenBy(newArr2?.join(","));
            setToUser(newArr?.join(","));
            setData(data);
        }
        getValue();

    }, [])


    // chck error connection

    const chckTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        chckTimeOut();
    }, 60000);




    // updating DATA STATE

    useEffect(() => {

        if (data) {
            setIsLoading(false);
        }
    }, [data]);
    // console.log(group)
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage === 0) {
            getAllGroups(1);
            return setPage(newPage);
        }
        getAllGroups(newPage + 1);
        return setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleFilterByName = (event) => {
        // if (event.target.value === "") {
        //     setFilterName(event.target.value);
        //     return dispatch(getAllGroups())
        // }
        // dispatch(searchAllGroups(event.target.value, 1))
        setFilterName(event.target.value)
    };

    // async function handleDelGroup(id) {

    //     await confirm({ description: 'This action is permanent! It will permanently delete the group. ' })
    //     const res = await delGroupAPI(id);

    //     dispatch(delGroup(id))
    //     enqueueSnackbar('Deleted Group Successfully', {
    //         variant: 'success',
    //         action: (key) => (

    //             <MIconButton size="small" onClick={() => closeSnackbar(key)}>
    //                 <Icon icon={closeFill} />
    //             </MIconButton>
    //         )
    //     });

    // }



    const isSelected = (name) => selected.indexOf(name) !== -1;


    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    let homeLink = PATH_DASHBOARD.root;

    if (loggedUserData?.staff?.staffType === 10) {
        homeLink = '/employee/dashboard'
    }
    if (loggedUserData?.staff?.staffType === 20) {
        homeLink = "/supervisor/dashboard"
    }

    return (
        <>

            <ScoreWrapper title="Inbox" link={[{ name: 'Home', href: homeLink }, { name: "Notifications", href: "/notification" }, { name: "Inbox" }]}>
                <Stack sx={{ width: "100%", px: 2, py: 1 }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}


                    <Tooltip title="Press Enter to Search">
                        <SearchStyle
                            value={filterName}
                            onChange={handleFilterByName}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setSearchText(filterName);
                                    navigate("/notification");
                                }
                            }}
                            placeholder="Search..."
                            startAdornment={
                                <>
                                    <InputAdornment position="start">
                                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                </>
                            }
                        />
                    </Tooltip>

                </Stack>
                <Divider />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>

                            <TableBody>
                                {/* {filteredUsers?.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return ( */}
                                <TableRow
                                // hover

                                // role="checkbox"
                                // aria-checked={isItemSelected}
                                // tabIndex={-1}
                                // key={row.id}
                                // selected={isItemSelected}
                                >
                                    <TableCell>
                                        <Box sx={{ p: 2, ml: 3 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "300px" }}>
                                                <Avatar alt="img" src="/static/illustrations/beyondQA-suspense.png" />
                                                <Box sx={{ ml: 2 }}>
                                                    {/* {allUsers?.results?.length} */}
                                                    <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>From:&nbsp;{data?.from_user}</Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>To:&nbsp;{toUser}</Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>{moment(data?.created_at).format("LL, LT")}</Typography>
                                                </Box >

                                            </Box >
                                            <Box sx={{ my: 4, ml: 5, pl: 3 }}>
                                                <Box sx={{ mb: 3 }}>
                                                    <Interweave content={data?.message} />

                                                </Box>
                                                {/* <Typography variant="body" sx={{ my: 1 }}>sravan test has just been scored.<br />
                                                    To review the score go to your Supervisor Dashboard.</Typography>
                                                <Typography variant="body2" sx={{ mt: 1, mb: 5 }}>Thanks,<br />
                                                    The Scorebuddy Team</Typography> */}

                                                {(data?.is_seen && data?.to_user?.length > 1 && data?.to_user?.length !== data?.seen_by?.length) && <Typography variant="caption" sx={{ mt: 5, mb: 1 }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px" }} /> Seen by {seenBy}</Stack></Typography>}
                                                {(data?.is_seen && data?.to_user?.length > 1 && data?.to_user?.length === data?.seen_by?.length) && <Typography variant="caption" sx={{ mt: 5, mb: 1 }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px" }} /> Seen by everyone</Stack></Typography>}
                                                {(data?.is_seen && data?.to_user?.length === 1) && <Typography variant="caption" sx={{ mt: 5, mb: 1 }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px" }} /> Seen at {moment(data?.last_seen).format("LL")},&nbsp; {moment(data?.last_seen).format("LT")}</Stack></Typography>}

                                            </Box>
                                            <Divider sx={{ my: 3 }} />
                                        </Box>

                                    </TableCell>
                                </TableRow>
                                {/* );
                                })} */}
                                {/* {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                {data?.length === 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows
                                        }}
                                    >

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Groups...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Groups Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                    }}
                >
                    <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
                </Box>
            </ScoreWrapper>
        </>
    );
}
