import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreRoundedIcon
      },

      styleOverrides: {
        root: {

          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
              color: theme.palette.mode === "light" ? theme.palette.textcolor.main : theme.palette.textcolor.dark
            },
          },
        }
      }
    }
  };
}
