import React from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';

import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button, Badge, Menu, MenuItem } from '@material-ui/core';
import { Mail, KeyboardArrowDown } from "@material-ui/icons";

// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { SettingsContext } from "../../../contexts/SettingsContext";
import { EmpContext } from "../../../contexts/employeecontext";
import Logo from "../../../components/Logo";
// components
import { MHidden } from '../../../components/@material-extend';
import { PATH_EMP, PATH_DASHBOARD } from "../../../routes/paths";
// import Searchbar from './Searchbar';
import AccountPopover from '../AccountPopover';
import LanguagePopover from '../LanguagePopover';
import { getMessageCount } from "../../../_apis_/authApi.js/globalDashboardApi";


// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 200;
const COLLAPSE_WIDTH_LG = 48;
const COLLAPSE_WIDTH_XL = 60;

// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 34;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme, t, p }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
        width: (t === "score" && p?.split("/")[1] !== "notification" && p?.split("/")[1] !== "companySettings" && p?.split("/")[2] !== "reviewPerformance" && p?.split("/")[1] !== "score" && p?.split("/")[1] !== "userProfile") && p?.split("/")[1] !== "calibration" ? "100%" : `calc(100% - ${DRAWER_WIDTH_LG}px)`
        // width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    [theme.breakpoints.up('xl')]: {
        width: (t === "score" && p?.split("/")[1] !== "notification" && p?.split("/")[1] !== "companySettings" && p?.split("/")[2] !== "reviewPerformance" && p?.split("/")[1] !== "score" && p?.split("/")[1] !== "userProfile") && p?.split("/")[1] !== "calibration" ? "100%" : `calc(100% - ${DRAWER_WIDTH_XL}px)`
        // width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

EmployeeDashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

const isScore = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isStaff = "Global Admin,Group Admin,Team Admin,Internal"
const isScorecard = "Global Admin,Group Admin,Internal"
const isAnalytics = "Global Admin,Group Admin,Team Admin,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics"
const isReports = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics,Reports Only"
const isCalibrate = "Global Admin,Group Admin,Team Admin,Score & Calibrate,Calibrate Only,(Score, Calibrate, Report & Analytics)"
export default function EmployeeDashboardNavbar({ onOpenSidebar, type }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const { pathname } = useLocation();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [messageCount, setMessageCount] = React.useState(0);
    const navigate = useNavigate();

    // console.log(loggedUserData)
    const { showHelpSupportPopUp,
        setNotificationMsgCount, notificationMsgCount,
        setShowHelpSupportPopUp,
        setColor, themeMode } = React.useContext(SettingsContext);
    const { activeMenu, setActiveMenu } = React.useContext(EmpContext);
    const { isCollapse } = useCollapseDrawer();
    React.useEffect(() => {
        async function getCount() {
            const { data } = await getMessageCount();
            setNotificationMsgCount(data?.data);
        }
        getCount();
    }, [pathname, notificationMsgCount])

    const themeColor = () => {
        if (themeMode === 'light') {
            return setColor?.main
        }
        return setColor?.dark
    }

    return (
        <RootStyle
            t={type}
            p={pathname}
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH_LG}px)`, xl: `calc(100% - ${COLLAPSE_WIDTH_XL}px)` }
                })
            }}
        >

            {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#ecfefff3", width: "100%", height: "50px" }}>
                <Link to={PATH_DASHBOARD.general.dashboard} style={{ fontSize: "12px" }}>Back To supervisor Dashboard</Link>
            </Box>}
            {searchParams.get("global_dash") === `True` && <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#ecfefff3", width: "100%", height: "50px" }}>
                <Link to={PATH_DASHBOARD.general.dashboard} style={{ fontSize: "12px" }}>Back To Global Dashboard</Link>
            </Box>}
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
                {(type === "score" && pathname.split("/")[1] !== 'auto_qa_rules' && pathname.split("/")[1] !== 'calls' && pathname.split("/")[1] !== 'events' && pathname.split("/")[1] !== 'analyze' && pathname.split("/")[1] !== 'coaching' && pathname.split("/")[1] !== "notification" && pathname.split("/")[1] !== "calibration" && pathname.split("/")[1] !== "companySettings" && pathname?.split("/")[2] !== "reviewPerformance" && pathname?.split("/")[1] !== "score" && pathname?.split("/")[1] !== "userProfile") && <Logo />}
                {/* <Searchbar /> */}
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} >
                    {(loggedUserData?.staff?.staffType === 10 && pathname?.split('/')[2] === 'dashboard') && <Typography variant='h5' sx={{ color: theme => theme.palette.text.buttonText, fontWeight: 500, mr: 4, textTransform: 'capitalize' }}>Welcome back, {`${loggedUserData?.firstName}`}</Typography>}
                    {(searchParams.get("global_dash") === 'True' || loggedUserData?.staff?.staffType === 10) && <Link to={`${PATH_DASHBOARD.general.dashboard}`} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: 2, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: (pathname.split("/")[1] === "employee" || pathname.split("/")[1] === "supervisor") ? themeColor() : "transparent" }, color: (pathname.split("/")[1] === "employee" || pathname.split("/")[1] === "supervisor") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Dashboard</Button></Link>}
                    {loggedUserData?.staff?.isSelfScore && <Link to={`/score/select_scorecard?grpId=${grpArr.join("")}&empId=${loggedUserData?.staff?.id}&sup_dash=False`} style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/score/select_scorecard" ? themeColor() : "transparent" }, color: pathname === "/score/select_scorecard" ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Score</Button>
                    </Link>}
                    {loggedUserData?.staff?.staffType === 10 && <Link to="/calibration" style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Calibration</Button>
                    </Link>}



                    {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/score/select_emp?id=${grpArr.join(",")}&sup_dash=True`} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Score</Button></Link>}
                    {(searchParams.get("global_dash") === 'True' && isScore.includes(loggedUserData?.userLevel?.name)) && <Link to={PATH_DASHBOARD?.general?.score} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Score</Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isStaff.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.Staff} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Staff</Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isScorecard.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.Scorecards} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Scorecards</Button></Link>}
                    {(searchParams.get("global_dash") === 'True' && isScorecard.includes(loggedUserData?.userLevel?.name)) && <Link to={PATH_DASHBOARD?.general?.admin} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Admin</Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isReports.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.reports} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Reports</Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isCalibrate.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to="/calibration" style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname === pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Calibration</Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isAnalytics.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to="/calibration" style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname === pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>Analytics</Button></Link>}
                    {(!loggedUserData?.staff && loggedUserData?.isAiAccess && loggedUserData?.aiEnabled) && <Button sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", color: "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }} endIcon={<KeyboardArrowDown fontSize='small' />} onClick={handleOpenMenu} disableRipple >Speech AI Hub</Button>}


                </Box>
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5, display: "none" }}>

                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover /> */}
                    {/* <ContactsPopover /> */}
                    {/* {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <IconButton onClick={() => { navigate("/notification") }}> <Badge badgeContent={messageCount} color="primary"><Mail /></Badge></IconButton>} */}
                    {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <IconButton sx={{ mr: 1 }} onClick={() => { navigate("/notification"); }}> <Badge badgeContent={notificationMsgCount} color="primary"><Icon icon='ion:mail-outline' style={{ fontSize: '32px', fontWeight: 'lighter', color: themeMode === 'light' ? setColor?.main : setColor?.dark }} /></Badge></IconButton>}

                    <AccountPopover />
                </Stack>

            </ToolbarStyle>

            <Box>
                <Menu
                    open={open}
                    onClose={() => handleCloseMenu()}
                    anchorEl={anchorEl}
                    autoFocus={false}
                    sx={{ border: '1px solid transparent', boxShadow: 0, minWidth: '150px' }}
                >
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/events') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Events</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/calls') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calls</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/analyze') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analyze</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/auto_qa_rules') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Auto QA</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/coaching/add_coaching?csId=AC') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Coaching</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </RootStyle >
    );
}
