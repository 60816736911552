import React from 'react';

// REACT ROUTER IMPORTS
import { useNavigate, useSearchParams } from "react-router-dom";

// MATERIAL UI IMPORT
import {
    Box, Button,
    Divider,
    Stack,
    Table, TableHead, TableContainer,
    TableRow, TableCell, TableBody,
    ToggleButton, ToggleButtonGroup,
    Typography, tableCellClasses
} from "@material-ui/core";

// OTHER IMPORTS
import moment from "moment";
import Scrollbar from '../../../../../../components/Scrollbar';

// Context IMPORTS
import { AnalyticContext } from "../../../../../../contexts/AnalyticContext";

// API IMPORTS
import { getEvaluationPerPeriodApi } from "../../../../../../_apis_/authApi.js/analytic_api";



export default function EvaluationPerPeriodTable({ customTableData, customDateType, wdth, hght }) {

    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // CONTEXT STATES
    const {
        setPerPeriodGraphData,
        setPerPeriodGraphCategory,
        perPeriodData, setPerPeriodData,
        setPerPeriodLoading,
        perPeriodDateType, setPerPeriodDateType,
        selAnaEvaluationFilters,
        setSubModLabel } = React.useContext(AnalyticContext);


    // STATES

    const [headers, setHeaders] = React.useState([]);
    const [score, setScore] = React.useState();
    const [tableData, setTableData] = React.useState([]);
    const [dateType, setDateType] = React.useState();
    const [searchParam, setSearchParam] = useSearchParams()

    React.useEffect(() => {
        if (typeof perPeriodData === 'object') {
            setTableData(perPeriodData)
        }
        if (customTableData !== undefined) {
            setTableData(customTableData)
        }
    }, [perPeriodData, customTableData])

    React.useEffect(() => {
        if (perPeriodDateType) {
            setDateType(perPeriodDateType)
        }
        if (customDateType) {
            setDateType(customDateType)
        }
    }, [perPeriodDateType, customDateType])


    React.useEffect(() => {

        if (dateType === "week") {
            const arr = [];
            tableData?.header?.map(val => arr.push({ name: `Week ${val?.date_num} (${val?.year})` }))

            setHeaders([{ name: "Evaluator Name" }, ...arr, { name: "Total" }]);
            const categoryName = []
            arr?.map(val => categoryName.push(val?.name));
            setPerPeriodGraphCategory(categoryName);

        }
        if (dateType === "month") {
            const arr = [];
            tableData?.header?.map(val => arr.push({ name: `${moment(val?.date_num, 'MM').format('MMMM')} (${val?.year})` }))
            setHeaders([{ name: "Evaluator Name" }, ...arr, { name: "Total" }]);
            const categoryName = []
            arr?.map(val => categoryName.push(val?.name));
            setPerPeriodGraphCategory(categoryName);

        }
        if (dateType === "quarter") {
            const arr = [];
            tableData?.header?.map(val => arr.push({ name: `Quarter ${val?.date_num} (${val?.year})` }))
            const categoryName = []
            arr?.map(val => categoryName.push(val?.name));
            setHeaders([{ name: "Evaluator Name" }, ...arr, { name: "Total" }]);
            setPerPeriodGraphCategory(categoryName);
        }

        const arr = [];
        for (let index = 0; index < tableData?.score_card?.length; index += 1) {
            const arr2 = [];
            const i = index;
            tableData?.data[i]?.map(row => arr2.push(row?.count ? row?.count : null));
            arr.push({
                name: `${tableData?.score_card[i]?.evaluator__first_name} ${tableData?.score_card[i]?.evaluator__last_name}`,
                data: arr2
            });
        }
        setPerPeriodGraphData(arr);
        setScore(tableData);
    }, [tableData, dateType])


    React.useEffect(() => {
        setSubModLabel(3.2);
    }, [])



    // REACT ROUTER STATESS
    const navigate = useNavigate();

    const fetchAcceptanceByAgentData = async (typeOfData) => {
        try {
            const { data } = await getEvaluationPerPeriodApi(selAnaEvaluationFilters, typeOfData);

            if (data?.score_card?.length > 0) {
                setPerPeriodData(data);
            }
            if (data?.score_card?.length === 0) {
                setPerPeriodData([]);
            }
            setPerPeriodLoading(false);
        } catch (err) {

            setPerPeriodData([]);
            setPerPeriodLoading(false);
        }
    }


    let mbLink = [{ name: 'Home', href: loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? "/supervisor/dashboard" : "/dashboard" }, { name: 'Analytics', href: "/analytics" }, { name: "Staff", href: "" }, { name: "1.11 Result Acceptance By Agent" }]
    mbLink = [];

    console.log('selAnaEvaluationFilters', selAnaEvaluationFilters, score)
    console.log('searchParam.get()', searchParam.get('highlightBelow'))
    return (
        <>
            <Box sx={{ mb: !customTableData ? 5 : 0 }}>
                {!customTableData && <Stack sx={{ width: "100%", px: 2 }} direction="row" alignItems="center" alignContent="center" justifyContent="flex-end">

                    <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                        <ToggleButtonGroup
                            value={dateType}
                            onChange={(e) => {
                                setPerPeriodDateType(e.target.value);
                                setPerPeriodLoading(true);
                                setPerPeriodData([]);
                                fetchAcceptanceByAgentData(e.target.value);
                                localStorage.setItem('dateType', e.target.value)

                            }}
                        >
                            <ToggleButton value="week" size='small'>
                                Week
                            </ToggleButton>
                            <ToggleButton value="month" size='small'>
                                Month
                            </ToggleButton>
                            <ToggleButton value="quarter" size='small'>
                                Year
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>


                </Stack>}
                {!customTableData && <Divider />}
                <Box>
                    <Scrollbar>
                        {(typeof score === 'object' && score?.score_card?.length > 0) && <TableContainer sx={{ minWidth: customTableData ? `${wdth}px` : "800px", maxHeight: customTableData ? `${hght - 100}px` : '100%', py: 2, cursor: 'default' }}>
                            <Table size='medium' sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    border: '1px solid #DEE2E6'
                                }
                            }}>

                                <TableHead>
                                    <TableRow>
                                        {headers?.map((row, index) => <TableCell key={index} sx={{ px: 1, margin: "0 auto", minWidth: (row?.name === "Evaluator Name" && headers?.length > 7) ? '250px' : '0px' }} align={row?.name === "Evaluator Name" ? "left" : "center"} padding='normal'>
                                            {row?.name}
                                        </TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(typeof score === 'object' && score?.score_card?.length > 0) && (score?.score_card?.map((row, index) => <TableRow key={index}>
                                        <TableCell sx={{ px: 1, margin: "0 auto", minWidth: headers?.length > 7 ? '250px' : '0px' }} align="left" padding='normal'>

                                            <Button onClick={() => {
                                                if (!customTableData) {
                                                    navigate(`/analytics/evaluation/reportResultDetails?from_date=${moment(selAnaEvaluationFilters?.fromDate).format('YYYY-MM-DD')}&to_date=${moment(selAnaEvaluationFilters?.toDate).format('YYYY-MM-DD')}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.score_card[index]?.evaluator}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow') || ""}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&dateType=${dateType}&module=${3}&sub_module=${2}`)
                                                }
                                            }}>   {`${row?.evaluator__first_name} ${row?.evaluator__last_name}`}</Button>


                                        </TableCell>


                                        {score?.header?.map((hdrRow, hedrIndx) => (typeof score === 'object' && typeof score?.data[index][hedrIndx] === 'object') ?
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal' key={hdrRow}>
                                                {(dateType === 'week' &&
                                                    selAnaEvaluationFilters?.dateRange !== 'Custom Date Range') &&
                                                    <Button variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${moment(selAnaEvaluationFilters?.fromDate || searchParam.get("fromDate")).format('YYYY-MM-DD')}&to_date=${moment(selAnaEvaluationFilters?.toDate || searchParam.get("toDate")).format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow') || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}
                                                    >
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>
                                                }
                                                {(dateType === 'month' &&
                                                    selAnaEvaluationFilters?.dateRange !== 'Custom Date Range') &&
                                                    <Button
                                                        size='small'
                                                        variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${moment(selAnaEvaluationFilters?.fromDate || searchParam.get("fromDate")).format('YYYY-MM-DD')}&to_date=${moment(selAnaEvaluationFilters?.toDate || searchParam.get("toDate")).format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow')) || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}
                                                    >
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>}
                                                {(dateType === 'quarter' &&
                                                    selAnaEvaluationFilters?.dateRange !== 'Custom Date Range') &&
                                                    <Button variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${moment(selAnaEvaluationFilters?.fromDate || searchParam.get("fromDate")).format('YYYY-MM-DD')}&to_date=${moment(selAnaEvaluationFilters?.toDate || searchParam.get("toDate")).format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow')) || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}
                                                    >
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>}
                                                {(dateType === 'week' &&
                                                    selAnaEvaluationFilters?.dateRange === 'Custom Date Range') &&
                                                    <Button variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${hedrIndx === 0 ? moment(selAnaEvaluationFilters?.fromDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'W').startOf('week').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')}&to_date=${(hedrIndx + 1) === score?.data[index]?.length ? moment(selAnaEvaluationFilters?.toDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'W').endOf('week').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow')) || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}>
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>
                                                }
                                                {(dateType === 'month' &&
                                                    selAnaEvaluationFilters?.dateRange === 'Custom Date Range') &&
                                                    <Button variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${hedrIndx === 0 ? moment(selAnaEvaluationFilters?.fromDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'M').startOf('month').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')}&to_date=${(hedrIndx + 1) === score?.data[index]?.length ? moment(selAnaEvaluationFilters?.toDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'M').endOf('month').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow')) || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}>
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>
                                                }
                                                {(dateType === 'quarter' &&
                                                    selAnaEvaluationFilters?.dateRange === 'Custom Date Range') &&
                                                    <Button variant={`${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                        sx={{
                                                            backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                            "&:hover": { backgroundColor: `${score?.data[index][hedrIndx]?.count}` < (selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow')) ? `#ffcb4e` : `transparent` }
                                                        }}
                                                        onClick={() => {
                                                            if (!customTableData) {
                                                                navigate(`/analytics/evaluation/reportResultDetails?from_date=${hedrIndx === 0 ? moment(selAnaEvaluationFilters?.fromDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'Q').startOf('quarter').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')}&to_date=${(hedrIndx + 1) === score?.data[index]?.length ? moment(selAnaEvaluationFilters?.toDate).format('YYYY-MM-DD') : moment(score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`], 'Q').endOf('quarter').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${dateType}`}&date_type_value=${score?.data[index][hedrIndx]?.[`${selAnaEvaluationFilters?.filterByRange}__${dateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${score?.data[index][hedrIndx]?.year}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.data[index][hedrIndx]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow') || ""}&module=${3}&sub_module=${2}`)
                                                            }
                                                        }}>
                                                        {score?.data[index][hedrIndx]?.count}
                                                    </Button>
                                                }

                                            </TableCell> : <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                                0
                                            </TableCell>)}
                                        <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                            <Button size='small'
                                                variant={`${score?.score_card[index]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? 'contained' : 'text'}
                                                sx={{
                                                    backgroundColor: `${score?.score_card[index]?.count}` < (selAnaEvaluationFilters?.highlightBelow || Number(searchParam.get('highlightBelow'))) ? `#ffcb4e` : `transparent`,
                                                    "&:hover": { backgroundColor: `${score?.score_card[index]?.count}` < (selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow')) ? `#ffcb4e` : `transparent` }
                                                }}
                                                onClick={() => {
                                                    if (!customTableData)
                                                        navigate(`/analytics/evaluation/reportResultDetails?from_date=${moment(selAnaEvaluationFilters?.fromDate).format('YYYY-MM-DD')}&to_date=${moment(selAnaEvaluationFilters?.toDate).format('YYYY-MM-DD')}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${selAnaEvaluationFilters?.scoreCard}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&dateRange=${selAnaEvaluationFilters?.dateRange || ''}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${score?.score_card[index]?.evaluator}&dateType=${dateType}&highlightBelow=${selAnaEvaluationFilters?.highlightBelow || searchParam.get('highlightBelow') || ""}&module=${3}&sub_module=${2}`)
                                                }}
                                            >
                                                {score?.score_card[index]?.count}
                                            </Button>


                                        </TableCell>
                                    </TableRow>)
                                    )}
                                    {score?.length === 0 && (
                                        <TableRow>

                                            <TableCell colSpan={12}>
                                                <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                                    No Data Found
                                                </Typography>
                                            </TableCell>

                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Scrollbar>
                </Box>

            </Box>

        </>
    )
}
