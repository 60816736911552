import React from 'react';
// MATERIAL IMPORTS
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Stack, TextField, Typography } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
// OTHER NPM IMPORTS
import { useFormik } from "formik";
import * as Yup from "yup";
// CONTEXT IMPORTS
import { StaffContext } from '../../contexts/staffContext';
import { SettingsContext } from '../../contexts/SettingsContext';
// API IMPORTS
import { getGdashboardGroupApi, getGdashboardDetailApi, getGdashboardTeamApi } from '../../_apis_/authApi.js/globalDashboardApi';

export default function FilterDialog({ openFilter, setOpenFilter }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT STATES
    const [grpdropdownList, setGroupDropdownList] = React.useState([]);
    const [teamdropdownList, setTeamDropdownList] = React.useState([]);
    /* const [selectedTeam, setSelectedTeam] = React.useState([]); */
    /* const [selectedGroup, setSelectedGroup] = React.useState([]); */
    const [openGrp, setOpenGrp] = React.useState(false);
    const [openTeam, setOpenTeam] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    // CONTEXT STATES
    const { selectedTeam, setSelectedTeam, selectedGroup, setSelectedGroup, setTeamListsContext, filterby, setFilterBy, setGpId, groupList, setGroupList, strtDate, endDate, setGlobalTeamId, dateObj } = React.useContext(StaffContext);
    const { setColor } = React.useContext(SettingsContext);

    // FORMIK STATES
    const formik = useFormik({
        initialValues: { groups: [], teams: [], filter_by: filterby },
        onSubmit: values => {
            setLoading(true);
            setFilterBy(values?.filter_by)
            if (selectedGroup?.length > 0) {
                setGlobalTeamId(selectedTeam);
            } else {
                setGlobalTeamId(null);
            }
            setSelectedGroup(values.groups);
            setSelectedTeam(values.teams);
            async function getList() {
                if (dateObj && loggedUserData?.userLevel?.name !== 'Team Admin') {
                    const { data } = await getGdashboardGroupApi(dateObj?.date1, dateObj?.date2, values.groups, values?.filter_by);
                    setLoading(false);
                    setGroupList(data?.data);
                    setGpId(values.groups);
                    const res = await getGdashboardTeamApi(dateObj?.date1, dateObj?.date2, values.groups, values?.filter_by)
                    setLoading(false);
                }
                if (dateObj && loggedUserData?.userLevel?.name === 'Team Admin') {
                    const groupIds = []
                    loggedUserData?.groups?.map(row => groupIds.push(row?.id));
                    const { data } = await getGdashboardTeamApi(dateObj?.date1, dateObj?.date2, groupIds, values?.filter_by);
                    const newTeamList = [];
                    if (values.teams?.length > 0) {
                        data?.data?.map(row => { if (values.teams?.includes(row?.teams)) { return newTeamList.push(row) } return row })
                        setTeamListsContext(newTeamList);
                        setLoading(false);
                    } else {
                        setTeamListsContext(data?.data);
                        setLoading(false);
                    }
                }

            }
            getList()
            setOpenFilter(false);

        }
    });


    // USE EFFECT METHODS
    React.useEffect(() => {
        formik.setFieldValue("groups", selectedGroup);
        formik.setFieldValue("teams", selectedTeam);
    }, [selectedGroup, selectedTeam, openFilter]);

    React.useEffect(() => {
        async function getList() {
            if (dateObj && loggedUserData?.userLevel?.name !== 'Team Admin') {
                const { data } = await getGdashboardGroupApi(dateObj?.date1, dateObj?.date2, [], formik.values.filter_by);

                setGroupDropdownList(data?.data)
                const newArray = [];

                data?.data?.map(obj => {
                    if (obj?.score_card__groups) {
                        return newArray.push(obj?.score_card__groups);
                    } return null
                })
                // setGroupDropdownList(groupList);
                const res = await getGdashboardTeamApi(dateObj?.date1, dateObj?.date2, [], formik.values.filter_by)
                setTeamDropdownList(res?.data?.data);

            }
            if (dateObj && loggedUserData?.userLevel?.name === 'Team Admin') {
                const groupIds = []
                loggedUserData?.groups?.map(row => groupIds.push(row?.id));
                const res = await getGdashboardTeamApi(dateObj?.date1, dateObj?.date2, groupIds, "created_at")
                setTeamDropdownList(res?.data?.data);

            }

        }
        if (openFilter) getList();

    }, [strtDate, openFilter])
    return (
        <>
            <Dialog open={openFilter} maxWidth="xs" fullWidth sx={{ borderRadius: 'none' }}>
                <DialogTitle sx={{ py: 1, borderBottom: { lg: '2px solid #DEE2E6', xl: '3px solid #DEE2E6' } }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant='h5'>Filter</Typography>
                        <IconButton onClick={() => { setOpenFilter(false); formik.resetForm(); formik.setFieldValue("filter_by", filterby); }}><Cancel /></IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ py: 1, px: 2 }}>
                    <form onSubmit={formik.handleSubmit}>
                        {loggedUserData?.userLevel?.name !== 'Team Admin' && <Box sx={{ width: "100%", my: 2 }}>
                            <TextField select
                                sx={{ width: "100%" }}
                                name="groups"
                                label="Select Group"
                                SelectProps={{
                                    multiple: true,
                                    value: formik.values.groups,
                                    open: openGrp,
                                    onOpen: () => { setOpenGrp(true) },
                                    onClose: () => { setOpenGrp(false) },
                                    onChange: async (e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        if (value?.length === 0) {
                                            formik.setFieldValue("teams", []);

                                        }

                                        const res = await getGdashboardTeamApi(dateObj?.date1, dateObj?.date2, value, formik.values.filter_by)
                                        setTeamDropdownList(res?.data?.data);
                                        setOpenGrp(false)
                                        formik.setFieldValue("groups", value);

                                    },

                                }}


                            >
                                {grpdropdownList?.map(obj => <MenuItem key={obj?.score_card__groups} value={obj?.score_card__groups}>{obj?.score_card__groups__name}</MenuItem>)}
                            </TextField>

                            {formik.errors.grp && formik.touched.grp ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.grp}</Typography> : null}

                        </Box>}
                        <Box sx={{ width: "100%", my: 2 }}>
                            {loggedUserData?.userLevel?.name !== 'Team Admin' && <TextField select
                                sx={{ width: "100%" }}
                                name="teams"
                                label="Select Team"
                                disabled={formik.values.groups?.length === 0}
                                helperText={<>
                                    {formik.values.groups?.length === 0 && <Typography variant='body' sx={{ color: theme => theme.palette.mode === 'light' ? setColor?.main : setColor?.dark }}>* Select A Group First</Typography>}</>}
                                SelectProps={{
                                    multiple: true,
                                    value: formik.values.groups?.length > 0 ? formik.values.teams : [],
                                    open: openTeam,
                                    onOpen: () => { setOpenTeam(true) },
                                    onClose: () => { setOpenTeam(false) },
                                    onChange: (e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        setOpenTeam(false)
                                        formik.setFieldValue("teams", value);
                                    },


                                }}

                            >
                                {teamdropdownList?.map(obj => <MenuItem key={obj.teams} value={obj.teams}>{obj?.teams__name}</MenuItem>)}
                            </TextField>}

                            {loggedUserData?.userLevel?.name === 'Team Admin' && <TextField select
                                sx={{ width: "100%" }}
                                name="teams"
                                label="Select Team"

                                SelectProps={{
                                    multiple: true,
                                    value: formik.values.teams?.length > 0 ? formik.values.teams : [],
                                    open: openTeam,
                                    onOpen: () => { setOpenTeam(true) },
                                    onClose: () => { setOpenTeam(false) },
                                    onChange: (e) => {
                                        const {
                                            target: { value },
                                        } = e;
                                        formik.setFieldValue("teams", value);
                                        setOpenTeam(false)
                                    },

                                }}

                            >
                                {teamdropdownList?.map(obj => <MenuItem key={obj.teams} value={obj.teams}>{obj?.teams__name}</MenuItem>)}
                            </TextField>}
                            {formik.errors.tm && formik.touched.tm ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.tm}</Typography> : null}

                        </Box>
                        <Box sx={{ width: "100%", my: 2 }}>
                            <TextField
                                id="filter_by"
                                multiple={false}
                                select
                                label="Filter By"
                                variant="outlined"
                                sx={{ mt: 1, width: "100%" }}
                                name="filter_by"
                                value={formik?.values?.filter_by}
                                {...formik.getFieldProps('filter_by')}
                                onChange={(e) => {
                                    formik.setFieldValue("filter_by", e.target.value);
                                    formik.setFieldValue("groups", []);
                                    formik.setFieldValue("teams", []);
                                }}
                            >
                                <MenuItem sx={{ color: 'text.primary' }} value="created_at">Score Date</MenuItem>
                                {/* <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("created_at") }} value="created_at">Score Date</MenuItem> */}
                                <MenuItem sx={{ color: 'text.primary' }} value="event_date">Event Date</MenuItem>
                                <MenuItem sx={{ color: 'text.primary' }} value="updated_at">Edited Date</MenuItem>
                            </TextField>
                        </Box>
                        <Box display="flex" sx={{ mt: 5, mb: 2 }} justifyContent="flex-end">
                            <Button onClick={() => { setOpenFilter(false); formik.resetForm(); formik.setFieldValue("filter_by", filterby); }} sx={{ backgroundColor: 'white', color: '#fb5d2e', stroke: 'white', strokeWidth: '1px', fontWeight: 'normal', fontSize: '12px', mx: 1, borderRadius: '5px', border: '1px solid #dddde7', boxShadow: 'none', '&:hover': { backgroundColor: 'white', color: '#fb5d2e' } }} variant='contained'>Cancel</Button>
                            <Button disabled={isLoading} sx={{ backgroundColor: 'white', color: '#fb5d2e', mx: 1, stroke: 'white', strokeWidth: '1px', fontWeight: 'normal', fontSize: '12px', borderRadius: '5px', border: '1px solid #dddde7', boxShadow: 'none', '&:hover': { backgroundColor: 'white', color: '#fb5d2e' } }} type="submit" variant='contained'>{isLoading && <CircularProgress size={14} />}&nbsp;Apply</Button>
                        </Box>
                    </form>

                </DialogContent>
            </Dialog>
        </>
    )
}