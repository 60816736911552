import React from "react";
import { Box, Pagination, TablePagination, Typography } from "@material-ui/core";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
    },
    paginationText: {
        color: '212668', // Change this to the color you want
    }
});
const PaginationTable = (props) => {
    const { pageRange, tablePage, count, page, handlePagination, style } = props

    const classes = useStyles();
    console.log('style', style)
    const handleDisplayRowText = ({ from, to, count }) => {
        return <Box sx={{ display: 'flex' }}>
            <Typography sx={{ color: style?.color, fontWeight: style?.fontWeight }}>{from} – {to}</Typography>
            <Typography>&nbsp;of&nbsp;</Typography>
            <Typography sx={{ color: style?.color, fontWeight: style?.fontWeight }}>{count !== -1 ? count : `more than ${to}`}</Typography>

        </Box>
    }
    return <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Showing
                </Typography>
                <TablePagination
                    classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={count || 0}
                    rowsPerPage={[100]}
                    page={tablePage}
                    labelDisplayedRows={(from, to, count) => handleDisplayRowText(from, to, count)}
                    sx={{ border: 'none' }}
                />
            </>
            <Pagination
                sx={{ ml: style?.ml, color:style?.color }}
                count={pageRange}
                page={page}
                onChange={(e, pageNumber) => handlePagination(e, pageNumber)}
                variant="outlined" shape="rounded" />
        </Box>
    </>
}
export default PaginationTable