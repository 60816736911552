import React, { useState } from 'react';
// REACT ROUTER IMPORTS
// import { useLocation, useSearchParams } from 'react-router-dom';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// MATERIAL IMPORTS
import {
    Box, Button,
    Checkbox,
    IconButton,
    Dialog, DialogContent, DialogTitle, Divider,
    FormControl, FormControlLabel,
    Grid,
    MenuItem,
    Stack,
    TextField, Typography,
} from '@material-ui/core';
import {
    ArrowDownward,
    Close,
    FilterAltOutlined, Flag,
    Group,
    Settings, Star,
} from '@material-ui/icons';
// import { useTheme } from '@material-ui/styles';
// NPM IMPORTS
import moment from 'moment';
import { useFormik } from 'formik';
import Chart from 'react-apexcharts';
import { useResizeDetector } from 'react-resize-detector';
// COMPONENT IMPORTS
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from "../../components/Page";
import { PATH_EMP } from '../../routes/paths';
import ScoreSummary from './dashboard/scorecard';
import CommentSummary from './dashboard/comments';
import ActivitySummary from './dashboard/activity';
import EmpGoalSummary from './dashboard/goals';
import EmpTaskSummary from "./dashboard/tasksummary";
import EmpDatePicker from "./datepicker";
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import { SettingsContext } from '../../contexts/SettingsContext';



export default function EmpDashboard() {
    // const theme = useTheme();
    // REACT ROUTER STATES
    // const [searchParams, setSearchParams] = useSearchParams();
    // const location = useLocation();
    // NPM STATES
    const { width, ref } = useResizeDetector();
    // REDUX STATES
    const summary = useSelector(state => state.empSummary);
    // CONTEXT STATES
    const { strtDate, endDate, filterby, setFilterBy, IdEmp } = React.useContext(EmpContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);
    // REACT STATES
    const [value, setValue] = React.useState([strtDate, endDate]);
    const [tscore, setTscore] = useState([]);
    const [myscore, setMyscore] = useState([]);
    const [xLabel, setXLabel] = useState([]);
    const [setting, setSeting] = useState(false);
    const [performance, setPerformance] = useState(true);
    const [comment, setComment] = useState(true);
    const [score, setScore] = useState(true);
    const [activity, setActivity] = useState(true);
    const [task, setTask] = useState(true);
    const [goal, setGoal] = useState(true);
    const [openfilter, setopenfilter] = useState(false)


    // FORMIK METHOD
    const formik = useFormik({
        initialValues: {
            performanceTab: performance, commentTab: comment, scoreTab: score, activityTab: activity,
            taskTab: task, goalTab: goal
        },
        onSubmit: values => {
            setPerformance(values.performanceTab);
            setComment(values.commentTab);
            setScore(values.scoreTab);
            setActivity(values.activityTab);
            setTask(values.taskTab);
            setGoal(values.goalTab);
            setSeting(false)
        }
    })
    React.useEffect(() => {
        async function getdata() {
            if (summary.staff_graph_data) {
                const arr1 = summary?.staff_graph_data.map(obj => ((obj.total_score / (obj.total_sum - obj.na_sum)) * 100)?.toFixed(2));
                const arr2 = summary?.team_graph_data.map(obj => ((obj.total_score / (obj.total_sum - obj.na_sum)) * 100)?.toFixed(2));
                setTscore([]); setMyscore([]);
                // jshint sub:true
                if (summary?.staff_graph_data?.length > 0 && summary?.staff_graph_data[0]?.created_at__month) {
                    const a = [...new Set(summary?.staff_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))];
                    const b = [...new Set(summary?.team_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]

                    if (a?.length > b?.length) {
                        setXLabel([...new Set(summary?.staff_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }
                    if (b?.length > a?.length) {
                        setXLabel([...new Set(summary?.team_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }
                    if (b?.length === a?.length) {
                        setXLabel([...new Set(summary?.staff_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }

                    setTscore(arr2); setMyscore(arr1);
                }

                if (summary?.staff_graph_data?.length > 0 && summary?.staff_graph_data[0]?.created_at__week) {
                    const a = [...new Set(summary?.staff_graph_data.map(el => `Week ${el?.created_at__week}`))];
                    const b = [...new Set(summary?.team_graph_data.map(el => `Week ${el?.created_at__week}`))];


                    if (a?.length > b?.length) {
                        setXLabel(a);
                    }
                    if (b?.length > a?.length) {
                        setXLabel(b);
                    }
                    if (b?.length === a?.length) {
                        setXLabel(a);
                    }


                    // setXLabel([...new Set(summary?.staff_graph_data.map(el => `Week ${el?.created_at__week}`))]);
                    setTscore(arr2); setMyscore(arr1);
                }


            }
        }
        if (summary) {
            getdata();
        }
    }, [summary])

    const option = {

        series: [{
            name: 'Team Overall Score',
            data: tscore?.length > 0 ? tscore : []
        }, {
            name: 'My Overall Score',
            data: myscore?.length > 0 ? myscore : []
        },],
        options: {
            colors: [themeMode === 'light' ? setColor?.main : setColor?.dark, '#bdc3c7'],
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: tscore?.length > 1 ? "45%" : '25%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: xLabel?.length > 0 ? xLabel : [0],
                labels: {
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 4,
                labels: {
                    formatter: (val) =>
                        val?.toFixed(1),
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },

                },
            },
            fill: {
                opacity: 1
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '18px',
                    fontWeight: "bold",
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                }
            },
            legend: {
                labels: {
                    colors: themeMode === 'light' ? ['#666666'] : ['#ffffff'],
                    useSeriesColors: false,
                }, showForSingleSeries: true, fontSize: '12px', position: "top", horizontalAlign: "right", height: 40
            },
            title: {
                text: 'Quality Score',
                align: 'middle',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                },
            },
        },
    };

    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));



    // console.log(moment(0, "HH").endOf('quarter').format('YYYY-MM-DD').toString());

    return (
        <>
            <Page title="Employee Dashboard | BeyondQA">
                <Stack direction="row" sx={{ mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0 }} justifyContent="space-between" alignContent="center" alignItems="center">
                    <Box>
                        <Typography variant='h5' sx={{ fontSize: { lg: '20px', xl: '28px' }, color: (theme) => theme.palette.mode === 'light' ? '#666666' : '#fff' }}>
                            Employee Dashboard
                        </Typography>
                        <HeaderBreadcrumbs
                            sx={{ pr: 3, mb: 1, color: '#666666', fontWeight: 600 }}
                            links={[{ name: 'Home/Dashboard', href: PATH_EMP.dashboard }]}
                        />
                    </Box>

                    <IconButton onClick={() => { setSeting(true) }}><Settings /></IconButton>
                </Stack>



                <Stack direction="row" justifyContent="flex-end" alignContent="center" alignItems="center" sx={{ borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}`, backgroundColor: theme => theme.palette.background.paper, px: 2, py: 1, borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    {/* <Typography variant="h4" sx={{ color: theme => theme.palette.text.primary, textTransform: 'capitalize' }}>
                        {!IdEmp && `Welcome ${searchParams.get("stff_emp_id") ? location.state?.stffName : loggedUserData?.firstName}`}
                        {IdEmp && `Welcome ${IdEmp?.user?.first_name}`}

                    </Typography> */}
                    <Stack direction="row" alignItems="center" >
                        <EmpDatePicker />
                        <Button startIcon={<FilterAltOutlined size='small' sx={{ color: '#636e72', stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px', '&:hover': { backgroundColor: (theme) => theme.palette.background.paper }, backgroundColor: (theme) => theme.palette.background.paper }} />} variant="contained" sx={{ mx: 1, backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }} onClick={() => {
                            setopenfilter(!openfilter);
                        }}  >
                            Filter
                        </Button>
                    </Stack>

                </Stack>
                <Grid container sx={{ height: "450px", display: performance ? "flex" : "none", backgroundColor: theme => theme.palette.background.paper, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', pt: 2 }}>
                    <Grid item xs={12} sm={4} sx={{ p: 2 }}>
                        <Stack sx={{ height: "40%", mb: 2 }} direction="row" justifyContent="Space-between">
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", border: '2px solid #E9EBF3', width: "45%", backgroundColor: "#EFF1F7", color: theme => theme.palette.action.selected, borderRadius: 1, p: 1, textAlign: "center" }}>
                                <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} >Team Overall Score</Typography><br />
                                <Group sx={{ fontSize: "35px" }} />
                                <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                    {/* {`${(Math?.round((100 * summary?.team_overall_score?.score_sum || 0) / summary?.team_overall_score?.score_total || 0))}%`} */}
                                    {`${(summary?.team_overall_score?.total_sum !== 0 && !Number.isNaN((summary?.team_overall_score?.total_score / (summary?.team_overall_score?.total_sum - summary?.team_overall_score?.na_sum)) * 100)) ? ((summary?.team_overall_score?.total_score / (summary?.team_overall_score?.total_sum - summary?.team_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                    {/* {`${((100 * summary?.team_overall_score?.score_sum || 0) / summary?.team_overall_score?.score_total || 0).toFixed(1)}%`} */}
                                </Typography><br />
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", color: (theme) => theme.palette.text.buttonText, width: "45%", border: '2px solid #FEEFE9', backgroundColor: "#FEF7F5", borderRadius: 1, p: 1, textAlign: "center" }}>
                                <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} >My Overall Score</Typography><br />
                                <ArrowDownward fontSize='large' sx={{ stroke: (theme) => theme.palette.text.buttonText, strokeWidth: 3 }} />
                                <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                    {/* {`${((100 * summary?.overall_score?.score_sum || 0) / summary?.overall_score?.score_total || 0).toFixed(1)}%`} */}
                                    {`${(summary?.overall_score?.total_sum !== 0 && !Number.isNaN((summary?.overall_score?.total_score / (summary?.overall_score?.total_sum - summary?.overall_score?.na_sum)) * 100)) ? ((summary?.overall_score?.total_score / (summary?.overall_score?.total_sum - summary?.overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                </Typography><br />
                            </Box>
                        </Stack>
                        <Stack sx={{ height: "40%" }} direction="row" justifyContent="Space-between">
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", color: '#29abe2', border: '2px solid #E5F5FD', backgroundColor: "#ecf9ff", width: "45%", borderRadius: 1, p: 1, textAlign: "center" }}>
                                <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} fontWeight="bold">Kudos</Typography><br />
                                <Star sx={{ fontSize: "35px" }} />
                                <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                    {summary?.kudos || 0}
                                </Typography><br />
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", border: '1px solid #F2F2F2', backgroundColor: "#f8f8f8", color: '#202020', width: "45%", borderRadius: 1, p: 1, textAlign: "center" }}>
                                <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} fontWeight="bold">Flags</Typography><br />
                                <Flag sx={{ fontSize: "35px" }} />
                                <Typography variant="h3" fontWeight="bold" sx={{ mt: 1 }}>
                                    {summary?.flag || 0}
                                </Typography><br />
                            </Box>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ p: 2, height: "auto", width: "100%" }}>
                        <Box display="flex" justifyContent="center" className='chart-wrapper' ref={ref} sx={{ height: "88%", width: "100%", border: "1px solid #dcdde1", pt: 3, px: 2 }}>
                            <Chart options={option.options} series={option.series} type="bar" width={width} height={320} />
                        </Box>
                    </Grid>
                </Grid>


                {/* ----------------------------------summary------------------------   */}

                <Box sx={{ my: 2, display: score ? "block" : "none" }}>
                    <ScoreSummary theme={themeMode} themeColor={setColor} />
                </Box>
                <Box sx={{ py: 2 }} display="flex" justifyContent="space-between">
                    <Box sx={{ width: comment && activity === false ? "100%" : "49%", display: comment ? "block" : "none" }}>
                        <CommentSummary theme={themeMode} themeColor={setColor} />
                    </Box>
                    <Box sx={{ width: activity && comment === false ? "100%" : "49%", display: activity ? "block" : "none" }}>
                        <ActivitySummary theme={themeMode} themeColor={setColor} />
                    </Box>
                </Box>

                <Box sx={{ py: 2 }} display="flex" justifyContent="space-between">
                    <Box sx={{ width: goal && task === false ? "100%" : "49%", display: goal ? "block" : "none" }}>
                        <EmpGoalSummary theme={themeMode} themeColor={setColor} />
                    </Box>
                    <Box sx={{ width: task && goal === false ? "100%" : "49%", display: task ? "block" : "none" }}>
                        <EmpTaskSummary theme={themeMode} themeColor={setColor} />
                    </Box>
                </Box>
                <Dialog open={setting} fullWidth maxWidth="xs">
                    <DialogTitle sx={{ borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 1, px: 2 }}>
                            <Typography variant='h5' sx={{ p: 0 }}>
                                Setting
                            </Typography>
                            <IconButton onClick={() => {
                                setSeting(false);
                                formik.setFieldValue('performanceTab', performance);
                                formik.setFieldValue('commentTab', comment);
                                formik.setFieldValue('scoreTab', score);
                                formik.setFieldValue('taskTab', task);
                                formik.setFieldValue('activityTab', activity);
                                formik.setFieldValue('goalTab', goal);
                            }}>
                                <Close fontSize='small' />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    {/* <Divider /> */}
                    <DialogContent sx={{ px: 2 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ height: "280px" }}>
                                <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{ px: 4, height: "120px" }}>

                                    <Box display="flex" justifyContent="flex-start" sx={{ width: "33.33%" }}>
                                        <FormControlLabel name='performanceTab' checked={formik.values.performanceTab} onChange={() => { formik.setFieldValue('performanceTab', !formik.values.performanceTab); }} label="Performance" control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="center" sx={{ width: "33.33%" }}>
                                        <FormControlLabel name='scoreTab' checked={formik.values.scoreTab} onChange={() => { formik.setFieldValue('scoreTab', !formik.values.scoreTab) }} label="Score" control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="flex-end" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.commentTab} onChange={() => { formik.setFieldValue('commentTab', !formik.values.commentTab) }} label="Comments" name='commentTab' control={<Checkbox size="small" />} />

                                    </Box>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 4, height: "120px" }}>

                                    <Box display="flex" justifyContent="flex-start" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.taskTab} onChange={() => { formik.setFieldValue("taskTab", !formik.values.taskTab) }} label="Task" name='taskTab' control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="center" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.activityTab} onChange={() => { formik.setFieldValue('activityTab', !formik.values.activityTab) }} label="Activity" name='activityTab' control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="flex-end" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.goalTab} onChange={() => { formik.setFieldValue('goalTab', !formik.values.goalTab) }} label="Goals" name='goalTab' control={<Checkbox size="small" />} />

                                    </Box>

                                </Stack>
                                <Stack direction="row" justifyContent="flex-end" sx={{ px: 3 }}>
                                    <Button size='small' sx={{ boxShadow: 'none', border: '1px solid #dddde7', fontSize: '12px', borderRadius: '7px', '&:hover': { color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper }, color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper }} variant="contained" type="submit">Confirm</Button>
                                </Stack>

                            </Box>
                        </form>

                    </DialogContent>
                </Dialog>
                <Dialog open={openfilter} fullWidth maxWidth="xs">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2, py: 1 }}>
                        <DialogTitle>
                            <Typography variant="h6">Filter</Typography>
                        </DialogTitle>
                        <IconButton onClick={() => { setopenfilter(false); }}><Close /></IconButton>
                    </Stack>
                    <Divider />
                    <DialogContent sx={{ p: 2, minHeight: "120px" }}>
                        <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth>

                                <TextField
                                    id="filter_by"
                                    multiple={false}
                                    select
                                    label="Filter By"
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                    name="filter_by"
                                    value={filterby}

                                >
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("created_at") }} value="created_at">Score Date</MenuItem>
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("event_date") }} value="event_date">Event Date</MenuItem>
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("updated_at") }} value="updated_at">Edited Date</MenuItem>
                                </TextField>

                            </FormControl>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Page >
        </>
    )
}