import { useState, useEffect, useContext } from 'react';
// import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import { useSearchParams, useLocation } from 'react-router-dom';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, Avatar, Skeleton } from '@material-ui/core';
// hooks
import useCollapseDrawer from '../hooks/useCollapseDrawer';
// hooks
import useAuth from '../hooks/useAuth';
import { SettingsContext } from "../contexts/SettingsContext"

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const pathnameIndexOne = ['score', 'reports', 'calibration', 'calls', 'events'];
  const pathnameIndexTwo = ['calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore', 'call_details', 'eventBuilder']
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // const theme = useTheme();
  const [imgUrl, setImgUrl] = useState();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const SECONDARY_MAIN = theme.palette.secondary.main;

  const { isCollapse,
    // collapseClick, collapseHover,
    // onToggleCollapse, onHoverEnter, onHoverLeave
  } = useCollapseDrawer();

  // context
  const {
    getCompanySettings,
    // updateCompanySettings,
    // updateProfilePicture, getProfilePicture, deleteProfilePicture, updateLogo,
    headerLogo, setHeaderLogo, favIconLogo, setFavIconLogo } = useAuth();
  // const [headerLogo, setHeaderLogo] = useState();

  const { showSidebar, setShowSidebar, dataCreatedFrom, setDataCreatedFrom } = useContext(SettingsContext);

  useEffect(() => {

    const fetchMyAPI = async () => {
      const response = await getCompanySettings();
      setHeaderLogo(response?.logo);
      setFavIconLogo(response?.favicon);
      const favIcon = document.getElementById("favicon");
      favIcon.href = response?.favicon;
      localStorage.setItem('dataCreatedOn', JSON.stringify(response?.createdOn));
      setDataCreatedFrom(response?.createdOn);
      if ((pathnameIndexOne?.includes(pathname?.split("/")[1]) && pathnameIndexTwo?.includes(pathname?.split("/")[2])) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 || searchParams.get('stff_sup_id')) {
        return setImgUrl(response?.logo)
      }

    }


    fetchMyAPI();
  }, []);

  useEffect(() => {
    getImgUrl()
  }, [pathname])

  const getImgUrl = () => {
    // if ((pathnameIndexOne?.includes(pathname?.split("/")[1]) && pathnameIndexTwo?.includes(pathname?.split("/")[2])) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 || searchParams.get('stff_sup_id')) {
    //   return setImgUrl(headerLogo)
    // }
    setImgUrl('https://bqa-logo.s3.ap-south-1.amazonaws.com/media/logo/2/BeyondQAwhite.svg')
  }

  return (
    <>

      {!pathnameIndexTwo?.includes(pathname?.split("/")[2]) && (!loggedUserData?.staff) && <Box sx={{ height: { lg: 30, xl: 40 }, ...sx }}>
        {isCollapse ? (<Avatar alt="logo" src={favIconLogo} />) : (<>{
          headerLogo ?
            <img alt="logo" src={imgUrl} style={{ height: '100%' }} />
            :
            <Skeleton variant="rectangular" height={30} width={170} />
        }</>)}
      </Box>}


      {((pathnameIndexOne?.includes(pathname?.split("/")[1]) &&
        pathnameIndexTwo?.includes(pathname?.split("/")[2])) && !loggedUserData?.staff) &&
        // searchParams.get('stff_sup_id') &&
        <Box sx={{ height: { lg: 30, xl: 40 }, ...sx }}>
          {
            isCollapse ? (<Avatar alt="logo" src={favIconLogo} />) :
              (<>
                {
                  headerLogo ?
                    <img alt="logo" src={headerLogo} style={{ height: '100%' }} /> :
                    <Skeleton variant="rectangular" height={30} width={170} />
                }</>)}
        </Box>}


      {loggedUserData?.staff &&
        // searchParams.get('stff_sup_id') &&
        <Box sx={{ height: { lg: 30, xl: 40 }, ...sx }}>
          {isCollapse ? (<Avatar alt="logo" src={favIconLogo} />) : (<>{
            headerLogo ?
              <img alt="logo" src={imgUrl} style={{ height: '100%' }} />
              :
              <Skeleton variant="rectangular" height={30} width={170} />
          }</>)}
        </Box>}
      {/* {loggedUserData?.staff?.id !== '10' && <Box sx={{ height: { lg: 40, xl: 40 }, ...sx }}>
        {isCollapse ? (<Avatar alt="logo" src={favIconLogo} />) : (<>{
          headerLogo ? <img alt="logo" src={imgUrl} style={{ height: '100%' }} /> : <Skeleton variant="rectangular" height={30} width={170} />
        }</>)}
      </Box>} */}
    </>
  );
}