import axios from 'axios';
// URL
import { URL } from "../../backend";

// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
const API = axios.create({ baseURL: URL })
API.interceptors.request.use(req => {
    // const { token } = JSON?.parse(localStorage?.getItem("user_info"));
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Token ${token}`;
    }
    return req
})

// dashbaord
export const empdashboardApi = (id, date1, date2, filterBy) => API.get(`/agent_dashboard/dashboard_score_graph?staff=${id}&from_date=${date1}&to_date=${date2}&filter=created_at`);



export const empScoreListApi = (id, date1, date2) => API.get(`/agent_dashboard/list_scorecard?staff=${id}&from_date=${date1}&to_date=${date2}`)
export const empScoreDetailsApi = (id, staffid, date1, date2) => API.get(`/agent_dashboard/agent_score?from_date=${date1}&to_date=${date2}&scorecard=${id}&staff=${staffid}`);
export const empCommentListApi = (id, date1, date2, isDashboard) => API.get(isDashboard ? `agent_dashboard/list_comment?from_date=${date1}&to_date=${date2}&staff=${id}&records_count=5` : `agent_dashboard/list_comment?from_date=${date1}&to_date=${date2}&staff=${id}`);
export const empCommentDetailApi = (id, staffId, date1, date2, pg) => API.get(`agent_dashboard/list_comment?from_date=${date1}&to_date=${date2}&scorecard=${id}&staff=${staffId}&page=${pg || 1}`);
export const empActivityApi = (date1, date2, staffId, pgNo, isDashboard) => API.get(isDashboard ? `/agent_dashboard/latest_activity?from_date=${date1}&to_date=${date2}&staff=${staffId}&page=${pgNo || 1}&records_count=5` : `/agent_dashboard/latest_activity?from_date=${date1}&to_date=${date2}&staff=${staffId}&page=${pgNo || 1}`);
export const prsnlGoalApi = (id) => API.get(`/goals?personal=true&user=${id}`);
export const empGoalApi = (staffId) => API.get(`/goals?staff=${staffId}`);
export const empTaskApi = (date1, date2, id) => API.get(`/tasks?from_date=${date1}&to_date=${date2}&staff=${id}`)
export const accptCommentAPI = (data) => API.post("/score/accept", data);
export const scoreAttachmentApi = (file) => API.post(`/score_attachments`, file, {
    headers: { 'Content-Type': 'multipart/form-data' }
});

export const checkDraftScorecardApi = (scorecardId, staffId) => API.get(`/score/get_score_draft?scorecard=${scorecardId}&staff=${staffId}`);
export const saveDraftApi = (obj) => API.patch(`/score/save_draft`, obj);


// supervisor dashboard
export const supDashboardApi = (sId, date1, date2, grp, tm, scrd, filterBy) => API.get(`/global_dashboard/supervisor_dashboard?supervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__groups__in=${grp}&teams__in=${tm}&score_card__in=${scrd}&filter_by=${filterBy}`);
// export const supDashboardApi = (sId, date1, date2, grp, tm, scrd) => API.get(`/global_dashboard/supervisor_dashboard?sypervisor_id=${sId}&from_date=${date1}&to_date=${date2}&group=${grp}&teams=${tm}&score_cards=${scrd}`);

// supervisor Monthly Performance
// export const supMonthlyApi = (sId, date1, date2, scrd, grp, tm, filterBy) => API.get(`/global_dashboard/monthly_performance_trend?sypervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__in=${scrd}&score_card__groups__in=${grp}&teams__in=${tm}&filter_by=${filterBy}`)
export const supMonthlyApi = (sId, date1, date2, scrd, grp, tm, filterBy) => API.get(`/global_dashboard/monthly_performance_trend?supervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__in=${scrd}&score_card__groups__in=${grp}&teams__in=${tm}&filter_by=${filterBy}`)

// accept review 

export const acceptReviewApi = (data) => API.post(`/score/review`, data);

// UNDO REVIEW

export const undoReviewApi = (data) => API.post(`/score/unreview`, data);

// aceept One 2One

export const acceptOne2OneApi = (data) => API.post(`/score/one_to_one_req`, data);

// Escalate One 2 One
export const escalateOne2OneApi = (type, data) => API.post(type === 1 ? `/score/escalate_reject?reject=${true}` : `/score/escalate_reject`, data)

// MY SCORE LIST
export const myscorelistApi = (date1, date2, range, datenumber, id, scorecard, pgNo) => API.get(pgNo ? `/score/staff_score?from_date=${date1}&to_date=${date2}&range=${range}&date_number=${datenumber}&staff=${id}&score_card=${scorecard}&page=${pgNo}` : `/score/staff_score?from_date=${date1}&to_date=${date2}&range=${range}&date_number=${datenumber}&staff=${id}&score_card=${scorecard}&page=1`);
export const myscorelistScorePageApi = (date1, date2, id, scorecard, pgNo, query) => API.get(`/score/staff_score?from_date=${date1}&to_date=${date2}&staff=${id}&score_card=${scorecard}&page=${pgNo || 1}&search=${query || ""}`);
export const myscoreSearchlistApi = (date1, date2, range, datenumber, id, scorecard, query, pgNo) => API.get(pgNo ? `/score/staff_score?from_date=${date1}&to_date=${date2}&range=${range}&date_number=${datenumber}&staff=${id}&score_card=${scorecard}&search=${query}&page=${pgNo}` : `/score/staff_score?from_date=${date1}&to_date=${date2}&range=${range}&date_number=${datenumber}&staff=${id}&score_card=${scorecard}&search=${query}&page=1`);


// score_draft_list
export const grpDraftListApi = (pgNo, drftType) => API.get(pgNo ? `/score/draft_list?page=${pgNo}&draft_type=${[drftType] || 20}` : `/score/draft_list?page=1&draft_type=${[drftType] || 20}`);
export const scoreDraftListApi = (id, pgNo, draftType) => API.get(pgNo ? `/score/draft_list?staff=${id}&page=${pgNo}&draft_type=${draftType}` : `/score/draft_list?staff=${id}&page=1&draft_type=${draftType}`);

export const scoreSearchDraftListApi = (id, query, pgNo) => API.get(pgNo ? `/score/draft_list?staff=${id}&search=${query}&page=${pgNo}` : `/score/draft_list?staff=${id}&search=${query}&page=1`);
export const AllSearchDraftListApi = (query, pgNo, drftType) => API.get(pgNo ? `/score/draft_list?search=${query}&page=${pgNo}&draft_type=${drftType || 20}` : `/score/draft_list?search=${query}&page=1&draft_type=${drftType || 20}`);

// delete_draft

export const deleteDraftApi = (id) => API.delete(`/score/${id}`);

// add details

export const addDraftDetailsApi = (data) => API.post(`/score/details_submit`, data);

// add values

export const addDraftValuesApi = (data) => API.post(`/score/value_submit`, data);

// add causes_comments

export const addcausesNcommentsApi = (data) => API.post(`/score/cause_comment_submit`, data);
