// ----------------------------------------------------------------------

export default function Badge(theme) {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 10,
          height: 10,
          borderRadius: '50%',
        },
        standard: {
          backgroundColor: theme.palette.action.selected,
        },
      }
    }
  };
}
