// NPM IMPORTS
import moment from 'moment';
import axios from 'axios';
//
import humps from 'humps';

// URL
import { URL } from "../../backend";



// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
const API = axios.create({ baseURL: URL })

API.interceptors.request.use(req => {
    // const { token } = JSON?.parse(localStorage?.getItem("user_info"));
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Token ${token}`;
    }
    return req
})




export const setForMarkCoachingApi = (callId) => API.post(`/calls/${callId}/enable_disable_coaching`)
export const createCoachingSessionAPI = (obj) => API.post(`/coaching/coaching_session`, obj);
export const editCoachingSessionAPI = (editId, obj) => API.patch(`/coaching/coaching_session/${editId}`, obj);
export const listCallAPI = (fDate, tDate, agentId, pg) => API.get(`/coaching/coaching_session/calls_listing?from_date=${fDate}&to_date=${tDate}&agent=${agentId}&page=${pg}`)
export const listScorecardAPI = (fd, td, agentId) => API.get(`/coaching/coaching_session/score_cards_listing?from_date=${fd}&to_date=${td}&staff=${agentId}`)
export const listScoreAPI = (fd, td, agentId, scorecardId, pg) => API.get(`/coaching/coaching_session/scores_listing?from_date=${fd}&to_date=${td}&staff=${agentId}&score_card=${scorecardId}&page=${pg}`)
export const viewCoachingSessionAPI = (fd, td, group, team, agents, coachs, coachingAreas, pg) => API.get(`/coaching/coaching_session?from_date=${fd}&to_date=${td}&group=${group}&team=${team}&agent__in=${agents}&coach__in=${coachs}&coaching_areas__in=${coachingAreas}&page=${pg || 1}`)
export const viewAgentReportAPI = (fd, td, group, team, agents, coachs, coachingAreas, orderType, pg) => API.get(`/coaching/agent_report/listing?from_date=${fd}&to_date=${td}&group=${group}&team=${team}&agent__in=${agents}&coach__in=${coachs}&coaching_areas__in=${coachingAreas}&ordering=${orderType}&page=${pg || 1}`)
export const feedbackFormDetails = (id) => API.get(`/coaching/coaching_session/${id}`)
export const coachingAreasDDAPI = () => API.get(`/coaching/coaching_session/coaching_areas`)
export const deleteDraftAPI = (id) => API.delete(`/coaching/coaching_session/${id}`)
export const addCoachingAreaAPI = (obj) => API.post(`/coaching/coaching_area`, obj);
export const addCoachingGroupsDropDownAPI = () => API.get(`/coaching/coaching_session/staffs_filter_groups`)
export const addCoachingTeamsDropDownAPI = (grpArray) => API.get(`/coaching/coaching_session/staffs_filter_teams?group=${grpArray}`)
export const coachingCategoryDropdownsAPI = () => API.get(`/coaching/coaching_area/category`);
export const editCoachingAreaAPI = (id, obj) => API.patch(`/coaching/coaching_area/${id}`, obj);
// COACHING DASHBOARD APIS
export const getCoachingDashboardSummaryAPI = (fDate, tDate, grp, tm) => API.get(`/coaching/dashboard/summary?from_date=${fDate}&to_date=${tDate}&group=${grp}&team=${tm}`);
export const getCoachingDashboardAgentListingTableAPI = (fDate, tDate, grp, tm) => API.get(`/coaching/dashboard/summary_agents?from_date=${fDate}&to_date=${tDate}&group=${grp}&team=${tm}&page=1`);
