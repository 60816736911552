import { useEffect, useState, useContext, useCallback } from 'react';
// REACT ROUTER IMPORTS
import { useNavigate } from 'react-router';
// NPM IMPORTS
import _, { capitalize } from "lodash";
import { Icon } from '@iconify/react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
// MATERIAL IMPORTS
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Table,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TablePagination,
    Typography,
    Tooltip,
} from '@material-ui/core';
import { Sort, Drafts, Mail } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import closeFill from '@iconify/icons-eva/close-fill';

// COMPONENT IMPORTS
import Scrollbar from '../../../../../components/Scrollbar';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
import { MIconButton } from '../../../../../components/@material-extend';
// API IMPORTS
import { getAllInboxMsgRead, getInbox, getMessageCount, getSearchInbox } from '../../../../../_apis_/authApi.js/globalDashboardApi';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../contexts/staffContext';
import { SettingsContext } from "../../../../../contexts/SettingsContext";

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function MessageHomeTable() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATES
    const navigate = useNavigate();
    // CONTEXT STATES
    const { listMessage, setListMessage, setSearchText, searchText } = useContext(StaffContext);
    const { setColor, notificationMsgCount, setNotificationMsgCount } = useContext(SettingsContext);
    // REACT STATES
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [showCheckBox, setShowCheckBox] = useState(false)
    const [checked, setChecked] = useState(false)
    const [checkedBox, setCheckedBox] = useState(false)
    const [selectedMsgId, setSelectedMsgId] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // get intial inbox list
    useEffect(() => {
        setIsLoading(true);
        if (searchText) {
            setFilterName(searchText);
            return searchInboxValue();
        }
        return getInboxValue();
    }, [])

    // chck error connection

    const chckTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        chckTimeOut();
    }, 60000);

    const getInboxValue = async (pg) => {
        setData([])
        const res = await getInbox(pg);
        const tempArray = res?.data
        setListMessage(tempArray);
        setCount(res?.data?.count);
        setIsLoading(false);
        setIsSearch(false);
        setFilterName("");
    }

    const searchInboxValue = async (query, pg) => {
        setData([])
        setIsLoading(true);
        const res = await getSearchInbox(query, pg);
        const tempArray = res?.data
        setListMessage(tempArray);
        setCount(res?.data?.count);
        setIsLoading(false);
        setSearchText();
        setIsSearch(true);

    }

    // updating DATA STATE

    useEffect(() => {

        if (listMessage?.results?.length > 0) {
            setDataFn();
        }
    }, [listMessage]);

    const setDataFn = () => {
        const tempArray = listMessage?.results
        setData(tempArray)
    }

    const handleSort = () => {
        setListMessage({ ...listMessage, results: listMessage?.results?.reverse() });
    }

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        setData([])
        setPage(newPage);
        searchInboxValue(filterName, newPage + 1)
        return setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const sendQuery = (query, pgNo) => {
        searchInboxValue(query, pgNo)
    }

    const delayedQuery = useCallback(_.debounce((FilterName, pgNo) => sendQuery(FilterName, pgNo), 800), []);

    const handleFilterByName = (event) => {
        setIsSearch(false);
        setFilterName(event.target.value)
        delayedQuery(event.target.value?.trim(), 1)
    };



    let homeLink = PATH_DASHBOARD.root;

    if (loggedUserData?.staff?.staffType === 10) {
        homeLink = '/employee/dashboard'
    }
    if (loggedUserData?.staff?.staffType === 20) {
        homeLink = "/supervisor/dashboard"
    }

    // commenting for some time
    // const handleMouseHover = (e, row, index) => {
    //     console.log('test', row)
    //     const { checked } = e.target
    //     console.log('checked', checked)
    //     setShowCheckBox(row?.id)
    // }
    // const handleHoverLeave = (e, row, index) => {
    //     console.log('leave', e)
    //     const { checked } = e.target
    //     setShowCheckBox(row?.id)
    //     console.log('checked leave', checked)
    // }
    const handleCheckedBox = (e, row, index) => {
        const { checked } = e.target
        setChecked(false)
        setCheckedBox(true)
        setSelectedMsgId(prev => {
            return checked
                ? [...prev, row?.id]                  // add if checked
                : prev.filter(val => val !== row?.id) // remove if not checked
        });

        setData(
            data.map((item, currentIndex) => {
                return currentIndex === index
                    ? { ...item, checked: !item.checked }
                    : item
            }

            )
        )
    }

    const handleMarkAllRead = async () => {
        setChecked(() => !checked)
        setCheckedBox(false)
        const filter = {
            ids: [...new Set(selectedMsgId)]?.toString() || '',
            isAllMsgRead: true
        }
        const res = await getAllInboxMsgRead(filter)
        setIsLoading(true);
        if (res.data.status === 200) {
            setTimeout(async () => {
                await getInboxValue()
                const { data } = await getMessageCount();
                setNotificationMsgCount(data?.data);
                setChecked(false)
                setIsLoading(false);
                setCheckedBox(false)


            }, 1000);
        } else {
            setChecked(false)
            setIsLoading(false);
            setCheckedBox(false)


        }
    }

    console.log('checked || checkedBox', checked, checkedBox)
    const isCoachingKeyWords = ['Coaching', 'follow-up', 'reminder']
    return (
        <>
            <ScoreWrapper title="Notification" link={[{ name: 'Home', href: homeLink }, { name: "Notification" }]}>
                <Stack sx={{ width: "100%", borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                    <Box sx={{ py: 2, px: 2 }}>
                        <Box >
                            <SearchStyle
                                value={filterName}
                                onChange={handleFilterByName}
                                placeholder="Search..."
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </Box>
                        {filterName && <Button onClick={() => { getInboxValue(1) }} variant="text" sx={{ mx: 1 }}>Clear search</Button>}
                    </Box>
                    <Stack direction="row" alignItems="center" alignContent="center">
                        {
                            data?.some((ele) => ele?.is_seen !== true) ? <Box>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleMarkAllRead}
                                        />}
                                        label={(checked || checkedBox) ?
                                            <>
                                                {
                                                    checkedBox ? 'Mark as read' : 'Mark all read'

                                                }

                                            </>
                                            :
                                            <>Mark all read</>} />
                                </FormGroup>
                            </Box> : ''
                        }

                        <Tooltip title="Sort">
                            <IconButton sx={{ mr: 1 }} onClick={handleSort}>
                                <Sort fontSize='small' />
                            </IconButton>
                        </Tooltip>

                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </Stack>
                {/* <Divider /> */}
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 1 }}>
                        <Table size='small'>

                            <TableBody>

                                {(data?.length > 0 && !isLoading) && data?.map((row, index) => {
                                    console.log('row', row)
                                    return <TableRow
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }}
                                    >
                                        <TableCell
                                            // onMouseEnter={(e) => handleMouseHover(e, row, index)}
                                            // onMouseLeave={(e) => handleHoverLeave(e, row, index)}
                                            key={index}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "300px" }}>
                                                {/* uncomment when need to show checkbox on hover */}
                                                {/* {
                                                    showCheckBox === row?.id ?
                                                        <Checkbox
                                                            checked={row?.checked || false}
                                                            onChange={(e) => handleCheckedBox(e, row, index)}
                                                        /> : ''
                                                } */}
                                                <Checkbox
                                                    checked={row?.checked || false}
                                                    onChange={(e) => handleCheckedBox(e, row, index)}
                                                    disabled={row?.is_seen}
                                                />
                                                {(row?.is_seen) ?
                                                    <Drafts sx={{ color: '#535c68' }} fontSize='large' /> :
                                                    <Mail sx={{ color: theme => theme.palette.action.selected }} fontSize='large' />
                                                }
                                                <Box sx={{ ml: 2 }}>
                                                    {/* {allUsers?.results?.length} */}
                                                    <Typography sx={{ textTransform: 'capitalize', fontWeight: "light", fontSize: { xs: '15px', xl: '17px' } }}>{row?.from_user} </Typography>
                                                    <Button
                                                        onClick={() => {
                                                            navigate(`/notification/inbox?id=${row?.id}`);
                                                            setListMessage({ ...listMessage, results: [] });
                                                        }}
                                                        disableRipple
                                                        variant='text'
                                                        sx={{
                                                            justifyContent: "flex-start",
                                                            color: (row?.is_seen) ? '#535c68' : setColor?.main,
                                                            // color: (row?.is_seen || checked) ? '#535c68' : setColor?.main,
                                                            p: 0, m: 0,
                                                            "&:hover": { bgcolor: "transparent" }, fontSize: "13px"
                                                        }}>
                                                        {!row?.message.match(/(?<=score_Id=)(\d+)/gm) ? row?.subject :
                                                            `${row?.subject} for Result Id ${row?.message.match(/(?<=score_Id=)(\d+)/gm)}`}


                                                    </Button>
                                                    <Typography style={{ fontWeight: "normal", marginRight: '5px', fontSize: "14px" }}>Hi, please click link to view the message.</Typography>
                                                </Box >
                                                {/* <Mail sx={{ color: theme => theme.palette.action.selected }} fontSize='large' /> */}
                                            </Box >
                                        </TableCell>
                                    </TableRow>
                                }

                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {data?.length === 0 && (
                        <Stack sx={{ width: '100%', minHeight: '220px' }} justifyContent='center' alignItems='center' flexDirection='center'  >

                            {isLoading ? <TableCell colSpan={12}>
                                <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                    <CircularProgress /> Loading Messages...
                                </Box>
                            </TableCell> : <TableCell colSpan={12}>
                                <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                    {(!isSearch || (filterName === '' || !filterName)) ? 'No Message Found' : `${data?.length} results found for keyword ${filterName}`}
                                </Typography>
                            </TableCell>}

                        </Stack>
                    )}
                </Scrollbar>

                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 2,
                        top: 0,
                    }}
                />

            </ScoreWrapper >
        </>
    );
}
