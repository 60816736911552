import { last } from 'lodash';
import PropTypes from 'prop-types';
// material
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Link, Breadcrumbs } from '@material-ui/core';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// ----------------------------------------------------------------------
const Separator = <Box component="span"
  sx={{
    width: 4, height: 4,
    // borderRadius: '50%',
    bgcolor: theme => theme.palette.mode === 'light' ? 'text.disabled' : '#fff'
  }}
>{'<'}</Box>;
LinkItem.propTypes = {
  link: PropTypes.object
};
function LinkItem({ link }) {
  const { href, name, icon } = link;
  return (
    <Link
      to={href}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: (theme) => theme.palette.mode === 'light' ? 'text.dark' : '#fff',
        '& > div': {
          display: 'inherit'
        },
        fontSize: '16px'
      }}
    >      {icon && (
      <Box
        sx={{
          mr: 1,
          '& svg': { width: 20, height: 20 }
        }}
      >  {icon}
      </Box>)}
      {name}
    </Link>);
}
MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool
};
export default function MBreadcrumbs({ links, activeLast = false, ...other }) {
  const currentLink = last(links)?.name;
  const listDefault = links?.map((link) => <LinkItem key={link?.name} link={link} />);
  const listActiveLast = links?.map((link) => (
    <div key={link?.name}>
      {(link?.name !== currentLink && link?.disableLink !== 'True') &&
        <LinkItem link={link} />}
      {(link?.name === currentLink && link?.disableLink !== 'True') &&
        <Typography
          variant="body2"
          sx={{
            maxWidth: 280,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: (theme) => theme.palette.mode === 'light' ? '#fb5d2e' : '#fff',
            textOverflow: 'ellipsis',
            fontWeight: "200",
            fontSize: '16px',

          }}
        >
          {currentLink}
        </Typography>}
      {(link?.name !== currentLink && link?.disableLink === 'True') && <Typography
        variant="body2"
        sx={{
          maxWidth: 280,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          color: (theme) => theme.palette.mode === 'light' ? '#A9A9A9' : '#fff',
          fontWeight: "bold",
          textOverflow: 'ellipsis',
          fontSize: '16px',
        }}
      >
        {link?.name}
      </Typography>}
    </div>));
  return (
    <Breadcrumbs
      // separator={Separator} 
      // separator={<ArrowBackIosIcon fontSize="small" />}
      separator='<'
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>);
}