import * as Yup from 'yup';
import { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack5';
import { useFormik, Form, FormikProvider } from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import moment from 'moment'
// material
import { Close } from '@material-ui/icons'
import { makeStyles, createStyles } from '@material-ui/styles';
import { useSearchParams } from 'react-router-dom';
import {
    Stack,
    Button,
    Dialog,
    TextField,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    IconButton,
    Divider,
    Box,
    Typography,
    OutlinedInput,
    CircularProgress,
    Autocomplete, Chip,
    FormControlLabel, Radio
} from '@material-ui/core';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../../../components/@material-extend';
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext'
import { AnalyticContext } from '../../../../contexts/AnalyticContext';
import { SettingsContext } from "../../../../contexts/SettingsContext"
import { filterByList, DateRangeList } from "../../../../utils/filterUtilities";
import { getReviewByEvaluatorApi, getReviewBySupervisorApi, getEvaluationPerScorecardApi, getEvaluationPerPeriodApi, getEvaluationPerScorecardByStaffApi } from "../../../../_apis_/authApi.js/analytic_api";

// ------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    dropdownListStyle:
    {
        padding: 5
    },
    select: {
        backgroundColor: "#FFFFFF"
    },
    selectDisabled: {
        backgroundColor: "#E9ECEF"
    },
    textField: {
        backgroundColor: "#FFFFFF"
    },
    textFieldLock: {
        backgroundColor: "#E9ECEF"
    },
    selectLock: {
        backgroundColor: "#E9ECEF"
    }
}));

// ----------------------------------------------------------------------

export default function AnalyticEvaluationFilterDialog({ addUserButton, isDialogOpened, selectedRow, setAddMode, closeAddUserDialog, fromSnapshot, fromAnalDashboard, scorecardId }) {

    // scorecard context-----
    // const { userLevels, groups, teams, getUserLevels, getGroups, getTeams, addUser, getAllUsers, editUser, resetPassword } = useContext(AddUserContext);
    const { getScoreLogGroups, reportsScoreLogGroups, getScoreLogScorecards, scorecardSection, getScorecardSection, reportsScoreLogScorecards, getScoreLogEventType, reportsScoreLogEventType, getScoreLogEventSubType, reportsScoreLogEventSubType, getScoreLogTeams, reportsScoreLogTeams, getScoreLogSupervisor, reportsScoreLogSupervisor, getScoreLogEmployee, reportsScoreLogEmployee, getScoreLogCategory, reportsScoreLogCategory, getScoreLogEvaluator, reportsScoreLogEvaluator, applyReportFilter, setselectedScoreFilters, setScoreFiltersEnabled, viewSnapShot } = useContext(ScoreCardsContext);

    // Analytic context----
    const { reviewByEvaluatorDateType, selectedEvaluationDataTags, setSelectedEvaluationDataTags, dataTagByScoreCard, getDataTagsByScoreCard, setReviewByEvaluatorData, setReviewByEvaluatorLoading, getScoringPatterns, selectedFlag, setReviewBySupervisorData, setReviewBySupervisorLoading, reviewBySupervisorDateType, selectedEvaluationHighlight, setSelectedEvaluationHighlight, setPerPeriodLoading, selectedEvaluationScoreCardMultiple, setSelectedEvaluationScoreCardMultiple, selectedEvaluationEventSubType, setSelectedEvaluationEventSubType, selectedEvaluationEventType, setSelectedEvaluationEventType, selectedEvaluationScoreCard, setSelectedEvaluationScoreCard, selectedEvaluationDateAnaRange, setSelectedEvaluationAnaDateRange, perScorecardDateType, setPerScorecardData, setPerScorecardByStaffData, setSelAnaEvaluationFilters, setSelectedEvaluationFilterBy, setSelectedEvaluationTeamFilter, setSwitchView, getEvaluationPerScoreCard, selScDurationInd, selectedEvaluationFromDate, selectedEvaluationToDate, setSelectedEvaluationToDate, setSelectedEvaluationFromDate, selectedEvaluationFilterBy, setSelectedFilterBy, setFilterNameAcceptanceByAgnt, agentData, setAgentData, isLoadingAgent, setLoadingAgent, countAgent, setCountAgent, selAnaEvaluationFilters, setSelAnaFilters, getStaffTrend, getStaffTrendDistribution, getStaffTrendAboBelow, selDuration, subModLabel, getQuestionTrend, selectedStaFilter, setselectedStaFilter, selectedEvaluationGroupFilter, setSelectedEvaluationGroupFilter, selectedEvaluationTeamFilter, setSelectedTeamFilter, selectedSupervisorFilter, setSelectedSupervisorFilter, getStaOverTrendByInd, selectedStaffFilter, setSelectedStaffFilter, setPerPeriodData, perPeriodDateType, selectedEvaluationfromWeek, setSelectedEvaluationfromWeek, selectedEvaluationtoWeek, setSelectedEvaluationtoWeek } = useContext(AnalyticContext);
    const { dataCreatedFrom } = useContext(SettingsContext);
    const [open, setOpen] = useState(false);

    // const [userLevel, setUserLevel] = useState(userLevels);
    const [userLev, setUserLev] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [readOnly, setReadOnly] = useState();
    // const [fromWeekNo, setWeekNo] = useState();
    const [fromWeekNo, setFromWeekNo] = useState();
    const [toWeekNo, setToWeekNo] = useState();
    const [advanceFilters, setAdvanceFilters] = useState();
    const [customFilters, setCustomFilters] = useState(false);
    const [circular, setCircular] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isAnyOfTag, setIsAnyOfTag] = useState(true)
    const [isAllOfTag, setIsAllOfTag] = useState(false)

    const isMountedRef = useIsMountedRef();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);

    useEffect(() => {
        if (open) {

            if (selectedEvaluationDateAnaRange) {
                setFieldValue("dateRange", selectedEvaluationDateAnaRange);
                setFieldValue("fromDate", selectedEvaluationFromDate);
                setFieldValue("toDate", selectedEvaluationToDate);

            } else {
                setFieldValue("dateRange", 'This Month');
                setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
            }


            if (selectedEvaluationDateAnaRange === 'Custom Date Range') {
                setCustomFilters(true);
                setFieldValue("fromWeek", selectedEvaluationfromWeek);
                setFieldValue("toWeek", selectedEvaluationtoWeek);
                setFieldValue("fromDate", selectedEvaluationFromDate);
                setFieldValue("toDate", selectedEvaluationToDate);
            }

            if (selectedEvaluationFilterBy) {
                setFieldValue("filterByRange", selectedEvaluationFilterBy);
            } else {
                setFieldValue("filterByRange", 'created_at');
            }
            if (selectedEvaluationGroupFilter) {
                setFieldValue("group", selectedEvaluationGroupFilter);
            }
            else {
                setFieldValue("group", '');
            }

            if (selectedEvaluationTeamFilter) {
                setFieldValue("teams", selectedEvaluationTeamFilter);
            }
            else {
                setFieldValue("teams", '');
            }

            if (selectedEvaluationEventType) {
                setFieldValue("eventType", selectedEvaluationEventType);
            }
            else {
                setFieldValue("eventType", '');
            }

            if (selectedEvaluationEventSubType) {
                setFieldValue("eventTypeEventSubType", selectedEvaluationEventSubType);
            }
            else {
                setFieldValue("eventTypeEventSubType", '');
            }

            if (selectedEvaluationScoreCard) {
                setFieldValue("scoreCard", selectedEvaluationScoreCard);
            } else {
                setFieldValue("scoreCard", '');
            }

            if (selectedEvaluationScoreCardMultiple) {
                setFieldValue("scoreCardMultiple", selectedEvaluationScoreCardMultiple);
            } else {
                setFieldValue("scoreCardMultiple", []);
            }

            if (selectedEvaluationHighlight) {
                setFieldValue("highlightBelow", selectedEvaluationHighlight);
            } else {
                setFieldValue("highlightBelow", 0);
            }

            if (selectedEvaluationDataTags?.length > 0) {
                setFieldValue('dataTag', selectedEvaluationDataTags);

            } else {
                setFieldValue('dataTag', []);
            }

            setFieldValue("scoreCardCategory", '');
            setFieldValue("evaluator", '');
            // setFieldValue("group", '')

        }
    }, [open]);

    const ReportSchema = Yup.object().shape({
        scoreCard: (subModLabel === 3.2 || subModLabel === 3.1) ? Yup.number().required('This Value is required').nullable() : '',
        scoreCardMultiple: (subModLabel === 3.7 || subModLabel === 3.6) ? Yup.number().transform((value) => Number.isNaN(value) ? null : value).required('This Value is required').nullable() : '',
        // staff: (subModLabel === 1.4 || subModLabel === 1.5) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable(),
        group: (subModLabel === 3.5 || subModLabel === 3.2 || subModLabel === 3.1 || subModLabel === 3.3 || subModLabel === 3.4 || subModLabel === 3.6 || subModLabel === 3.7) && Yup.number().required('This Value is required').nullable(),
    });

    const formik = useFormik({
        initialValues: {
            id: '',
            dateRange: '',
            filterByRange: '',
            fromDate: '',
            fromWeek: '',
            toDate: '',
            toWeek: '',
            filterBy: '',
            group: '',
            teams: '',
            scoreCard: '',
            scoreCardMultiple: [],
            scoreCardName: [],
            eventType: '',
            eventTypeEventSubType: '',
            supervisor: '',
            staff: '',
            scoreCardCategory: '',
            evaluator: '',
            highlightBelow: 0,
            reference: '',
            section: '',
            formula: '',
            dataTag: [],
            // dateRange: '',
        },
        validationSchema: ReportSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            setCircular(true);
            localStorage.setItem('scoreCardMutliple', JSON.stringify(values?.scoreCardMultiple))
            console.log('reviewBySupervisorDateType', reviewByEvaluatorDateType)

            if (subModLabel === 3.1) {
                setSelectedEvaluationHighlight(values.highlightBelow);
                setSelectedEvaluationScoreCard(values.scoreCard);
                setSelectedEvaluationScoreCardMultiple([values.scoreCard]);
                setSelectedEvaluationEventType(values.eventType);
                setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                setSelectedEvaluationToDate(values.toDate);
                setSelectedEvaluationFromDate(values.fromDate);
                setSelAnaEvaluationFilters({ ...values, filterBy: values.filterByRange });
                setSelectedEvaluationAnaDateRange(values.dateRange);
                setSelectedEvaluationGroupFilter(values.group);
                setSelectedEvaluationTeamFilter(values.teams);
                setSelectedEvaluationFilterBy(values.filterByRange);
                const filterObj = {
                    fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                    toDate: moment(values.toDate).format('YYYY-MM-DD'),
                    fromWeek: values.fromWeek,
                    toWeek: values.toWeek,
                    filterBy: values.filterByRange,
                    group: values.group,
                    teams: values.teams,
                    scoreCard: values.scoreCard,
                    scoreCardMultiple: [values.scoreCard],
                    scoreCardGroups: values?.group,
                    eventType: values.eventType,
                    eventTypeEventSubType: values.eventTypeEventSubType,
                    staff: values.staff,
                    scoreCardCategory: values.scoreCardCategory,
                    evaluator: values.evaluator,
                    page: '1',
                    filterName: '',
                    highlightBelow: values.highlightBelow,
                    flag: selectedFlag,
                    section: values.section,
                    supervisor: values.supervisor,
                    numericValues: values.numericValues,
                    dataTag: values.dataTag,
                    // numericValues: true,,
                    formula: values.formula || ''
                };
                getScoringPatterns(filterObj)
                setSearchParams(filterObj)
                setSelAnaEvaluationFilters({ ...values, filterBy: values.filterByRange });
                handleClose();

            }
            try {
                if (subModLabel === 3.2) {
                    setSelectedEvaluationScoreCard(values.scoreCard);
                    setSelectedEvaluationHighlight(values.highlightBelow);
                    setSelectedEvaluationScoreCardMultiple([values.scoreCard]);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(values.toDate);
                    setSelectedEvaluationFromDate(values.fromDate);
                    setSelAnaEvaluationFilters({
                        ...values, scoreCardMultiple: [values.scoreCard], fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'), filterBy: values.filterByRange
                    });
                    setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    setPerPeriodData([]);
                    setPerPeriodLoading(true);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        dateRange: values.dateRange,
                        fromWeek: values.fromWeek,
                        toWeek: values.toWeek,
                        filterBy: values.filterByRange,
                        group: values.group,
                        teams: values.teams,
                        scoreCard: values.scoreCard,
                        scoreCardMultiple: [values.scoreCard],
                        scoreCardGroups: values?.group,
                        eventType: values.eventType,
                        eventTypeEventSubType: values.eventTypeEventSubType,
                        staff: values.staff,
                        scoreCardCategory: values.scoreCardCategory,
                        evaluator: values.evaluator,
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow,
                        // flag: selScDurationInd,
                        section: values.section,
                        supervisor: values.supervisor,
                        numericValues: values.numericValues,
                        // numericValues: true,,
                        formula: values.formula || '',
                        dataTag: values.dataTag,
                    };
                    const { data } = await getEvaluationPerPeriodApi({ ...filterObj }, perPeriodDateType);
                    if (data?.score_card?.length > 0) {
                        setPerPeriodData(data);
                        setPerPeriodLoading(false);
                        setSearchParams({ ...filterObj, scoreCardMultiple: JSON.stringify([filterObj.scoreCard]) })
                    }
                    if (data?.score_card?.length === 0) {
                        setPerPeriodData([]);
                        setPerPeriodLoading(false);
                    }
                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);
                    }

                }

                if (subModLabel === 3.3) {
                    setSelectedEvaluationScoreCard(values.scoreCard);
                    setSelectedEvaluationHighlight(values.highlightBelow);
                    setSelectedEvaluationScoreCardMultiple(values.scoreCardMultiple);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(moment(values.toDate).format('YYYY-MM-DD'));
                    setSelectedEvaluationFromDate(moment(values.fromDate).format('YYYY-MM-DD'));
                    setSelAnaEvaluationFilters({
                        ...values, fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'), filterBy: values.filterByRange
                    });
                    setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        dateRange: values.dateRange,
                        fromWeek: values.fromWeek,
                        toWeek: values.toWeek,
                        filterBy: values.filterByRange,
                        group: values.group,
                        teams: values.teams,
                        scoreCard: values.scoreCard,
                        scoreCardMultiple: values.scoreCardMultiple,
                        scoreCardGroups: values?.group,
                        eventType: values.eventType,
                        eventTypeEventSubType: values.eventTypeEventSubType,
                        staff: values.staff,
                        scoreCardCategory: values.scoreCardCategory,
                        evaluator: values.evaluator,
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow,
                        // flag: selScDurationInd,
                        section: values.section,
                        supervisor: values.supervisor,
                        numericValues: values.numericValues,
                        dataTag: values.dataTag,
                        // numericValues: true,,
                        formula: values.formula || '',
                    };
                    const { data } = await getEvaluationPerScorecardApi({ ...filterObj }, perScorecardDateType);
                    if (data?.score_card?.length > 0) {
                        setPerScorecardData(data);
                        setSearchParams({ ...filterObj })

                    }
                    if (data?.score_card?.length === 0) {
                        setPerScorecardData([]);
                    }

                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);
                    }

                }
                if (subModLabel === 3.4) {
                    setSelectedEvaluationScoreCard(values.scoreCard);
                    setSelectedEvaluationHighlight(values.highlightBelow);
                    setSelectedEvaluationScoreCardMultiple(values.scoreCardMultiple);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(moment(values.toDate).format('YYYY-MM-DD'));
                    setSelectedEvaluationFromDate(moment(values.fromDate).format('YYYY-MM-DD'));
                    setSelAnaEvaluationFilters({
                        ...values, fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'), filterBy: values.filterByRange
                    });
                    setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        fromWeek: values.fromWeek,
                        toWeek: values.toWeek,
                        filterBy: values.filterByRange,
                        dateRange: values.dateRange,
                        group: values.group,
                        teams: values.teams,
                        scoreCard: values.scoreCard,
                        scoreCardMultiple: values.scoreCardMultiple,
                        scoreCardGroups: values?.group,
                        eventType: values.eventType,
                        eventTypeEventSubType: values.eventTypeEventSubType,
                        staff: values.staff,
                        scoreCardCategory: values.scoreCardCategory,
                        evaluator: values.evaluator,
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow,
                        // flag: selScDurationInd,
                        section: values.section,
                        supervisor: values.supervisor,
                        numericValues: values.numericValues,
                        dataTag: values.dataTag,
                        // numericValues: true,,
                        formula: values.formula || ''
                    };

                    const { data } = await getEvaluationPerScorecardByStaffApi({ ...filterObj });
                    if (data?.score_card?.length > 0) {
                        setPerScorecardByStaffData(data);
                        setSearchParams({ ...filterObj })

                    }
                    if (data?.score_card?.length === 0) {
                        setPerScorecardByStaffData([]);
                    }

                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);
                    }

                }

                if (subModLabel === 3.5) {
                    setSelectedEvaluationHighlight(values.highlightBelow);
                    setSelectedEvaluationScoreCard(values.scoreCard);
                    setSelectedEvaluationScoreCardMultiple(values.scoreCardMultiple);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(values.toDate);
                    setSelectedEvaluationFromDate(values.fromDate);
                    setSelAnaEvaluationFilters({ ...values, filterBy: values.filterByRange });
                    setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        fromWeek: values.fromWeek,
                        toWeek: values.toWeek,
                        dateRange: values.dateRange,
                        filterBy: values.filterByRange,
                        group: values.group,
                        teams: values.teams || '',
                        scoreCard: values.scoreCard,
                        scoreCardMultiple: values.scoreCardMultiple,
                        scoreCardGroups: values?.group,
                        eventType: values.eventType,
                        eventTypeEventSubType: values.eventTypeEventSubType,
                        staff: values.staff,
                        scoreCardCategory: values.scoreCardCategory,
                        evaluator: values.evaluator,
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow,
                        // flag: selScDurationInd,
                        section: values.section,
                        supervisor: values.supervisor,
                        numericValues: values.numericValues,
                        dataTag: values.dataTag,
                        // numericValues: true,,
                        formula: values.formula || ''
                    };
                    await getEvaluationPerScoreCard({
                        ...filterObj,
                        // scoreCard: values.scoreCardSingle,
                        // scoreCardSingle: values.scoreCardSingle
                    });


                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);
                    }

                }

                if (subModLabel === 3.6) {
                    setSelectedEvaluationScoreCard(values.scoreCardMultiple[0]);
                    setSelectedEvaluationScoreCardMultiple(values.scoreCardMultiple);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(values.toDate);
                    setSelectedEvaluationFromDate(values.fromDate);
                    setSelAnaEvaluationFilters({
                        ...values, fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'), filterBy: values.filterByRange
                    }); setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    setReviewBySupervisorLoading(true);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        fromWeek: values.fromWeek || '',
                        toWeek: values.toWeek || '',
                        dateRange: values.dateRange,
                        filterBy: values.filterByRange || "created_at",
                        group: values.group || '',
                        teams: values.teams || '',
                        scoreCard: values.scoreCardMultiple[0] || '',
                        scoreCardMultiple: values.scoreCardMultiple || '',
                        scoreCardGroups: values?.group || '',
                        eventType: values.eventType || '',
                        eventTypeEventSubType: values.eventTypeEventSubType || '',
                        staff: values.staff || '',
                        scoreCardCategory: values.scoreCardCategory || '',
                        evaluator: values.evaluator || '',
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow || '',
                        // flag: selScDurationInd,
                        section: values.section || '',
                        supervisor: values.supervisor || '',
                        numericValues: values.numericValues || '',
                        // numericValues: true,,
                        formula: values.formula || '',
                        dataTag: values.dataTag || '',
                    };

                    const { data } = await getReviewByEvaluatorApi({ ...filterObj }, reviewByEvaluatorDateType);
                    if (data?.evaluator?.length > 0) {
                        setReviewByEvaluatorData(data);
                        setReviewByEvaluatorLoading(false);
                        setSearchParams({
                            ...filterObj,
                            scoreCardMultiple: [filterObj?.scoreCardMultiple],
                            // scoreCardMultiple: JSON.stringify(filterObj?.scoreCardMultiple),
                            dateType: reviewByEvaluatorDateType
                        });
                    }
                    if (data?.evaluator?.length === 0 || data?.evaluator === undefined) {
                        setReviewByEvaluatorData([]);
                        setReviewByEvaluatorLoading(false);
                    }
                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);

                    }

                }

                if (subModLabel === 3.7) {
                    setSelectedEvaluationScoreCard(values.scoreCardMultiple[0]);
                    setSelectedEvaluationScoreCardMultiple(values.scoreCardMultiple);
                    setSelectedEvaluationEventType(values.eventType);
                    setSelectedEvaluationEventSubType(values.eventTypeEventSubType);
                    setSelectedEvaluationToDate(values.toDate);
                    setSelectedEvaluationFromDate(values.fromDate);
                    setSelAnaEvaluationFilters({
                        ...values, fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'), filterBy: values.filterByRange
                    }); setSelectedEvaluationAnaDateRange(values.dateRange);
                    setSelectedEvaluationGroupFilter(values.group);
                    setSelectedEvaluationTeamFilter(values.teams);
                    setSelectedEvaluationFilterBy(values.filterByRange);
                    setReviewBySupervisorLoading(true);
                    const filterObj = {
                        fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
                        toDate: moment(values.toDate).format('YYYY-MM-DD'),
                        fromWeek: values.fromWeek,
                        toWeek: values.toWeek,
                        filterBy: values.filterByRange,
                        dateRange: values.dateRange,
                        group: values.group,
                        teams: values.teams,
                        scoreCard: values.scoreCardMultiple[0],
                        scoreCardMultiple: values.scoreCardMultiple,
                        scoreCardGroups: values?.group,
                        eventType: values.eventType,
                        eventTypeEventSubType: values.eventTypeEventSubType,
                        staff: values.staff,
                        scoreCardCategory: values.scoreCardCategory,
                        evaluator: values.evaluator,
                        page: '1',
                        filterName: '',
                        highlightBelow: values.highlightBelow,
                        // flag: selScDurationInd,
                        section: values.section,
                        supervisor: values.supervisor,
                        numericValues: values.numericValues,
                        // numericValues: true,,
                        formula: values.formula || '',
                        dataTag: values.dataTag,
                    };

                    const { data } = await getReviewBySupervisorApi({ ...filterObj }, reviewBySupervisorDateType);
                    if (data?.supervisor?.length > 0) {
                        setReviewBySupervisorData(data);
                        setReviewBySupervisorLoading(false);
                        setSearchParams({
                            ...filterObj,
                            scoreCardMultiple: [filterObj?.scoreCardMultiple],

                            // scoreCardMultiple: JSON.stringify(filterObj?.scoreCardMultiple) 
                        })
                    }
                    if (data?.supervisor?.length === 0 || data?.supervisor === undefined) {
                        setReviewBySupervisorData([]);
                        setReviewBySupervisorLoading(false);
                    }
                    handleClose();

                    if (useIsMountedRef.current) {
                        setSubmitting(false);
                        setOpen(false);

                    }

                }
                setCircular(false)

            } catch (error) {

                setSelAnaEvaluationFilters({});
                setSelectedEvaluationAnaDateRange();
                setSelectedEvaluationGroupFilter();
                setSelectedEvaluationTeamFilter();
                setSelectedEvaluationFilterBy();
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({ afterSubmit: error.message });
                }
                if (subModLabel === 3.3) {
                    setPerScorecardData([])
                }
                if (subModLabel === 3.2) {
                    setPerPeriodData([])
                    setPerPeriodLoading(false);
                }
                if (subModLabel === 3.4) {
                    setPerScorecardByStaffData([]);
                }
                if (subModLabel === 3.7) {
                    setReviewBySupervisorData([]);
                    setReviewBySupervisorLoading(false);
                }

                if (subModLabel === 3.6) {
                    setReviewByEvaluatorData([]);
                    setReviewByEvaluatorLoading(false);
                }
            }
        },
        enableReinitialze: true,
    });

    const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;

    // Open user dialog modal
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Close user dialog modal
    const handleClose = (event, reason) => {

        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleGroupChange = (event, newValue) => {
        // Resetting remaining fields
        setFieldValue("teams", '');
        setFieldValue("scoreCard", '');
        setFieldValue("scoreCardMultiple", []);

        setFieldValue("eventType", '');
        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');
        // setSelectedSupervisorGroupFilter(newValue?.id);
        if (subModLabel === 3.7 || subModLabel === 3.6) {
            setFieldValue("scoreCardMultiple", []);
        }

        setFieldValue("group", newValue?.id ?? '');
        // setFieldValue("group", event.target.value);
        setSelectedEvaluationGroupFilter(newValue?.id ?? '');
        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": newValue?.id ?? '',
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "teams": '',
            "scoreCard": '',
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
        }
        if (subModLabel !== 3.5) {
            getScoreLogTeams(filterObj);
            getScoreLogEventType(filterObj);
            getScoreLogEventSubType(filterObj);
            getScoreLogScorecards(filterObj);
        }


    };

    const handleTeamChange = (event) => {
        // Resetting remaining fields
        setFieldValue("scoreCard", '');
        setFieldValue("scoreCardMultiple", []);

        setFieldValue("eventType", '');
        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');

        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "teams": event.target.value,
            "scoreCard": '',
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
        }

        setFieldValue("teams", event.target.value);
        setSelectedEvaluationTeamFilter(event.target.value);

        if (subModLabel !== 3.5) {
            getScoreLogScorecards(filterObj);
            getScoreLogEventType(filterObj);
            getScoreLogEventSubType(filterObj);
        }
    };

    const handleDataTags = (event, val) => {
        setFieldValue("dataTag", (event.target.value).flat(100));
        setSelectedEvaluationDataTags((event.target.value).flat(100))

    }

    const handleAnyOfTag = (event) => {
        setFieldValue('allOfTag', '')
        setFieldValue('anyOfTag', '10')
        setIsAnyOfTag(true)
        setIsAllOfTag(false)
    }

    const handleAllOfTag = () => {
        setFieldValue('anyOfTag', '')
        setFieldValue('allOfTag', '20')
        setIsAnyOfTag(false)
        setIsAllOfTag(true)
    }

    const handleScorecardChange = (event, newValue) => {

        setFieldValue("eventType", '');
        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');
        setFieldValue("dataTag", []);

        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "teams": values.teams,
            "scoreCard": newValue?.scoreCard ?? '',
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
        }

        if (subModLabel === 3.7 || subModLabel === 3.6) {
            const value = [];
            newValue?.map(option => value.push(option?.scoreCard));
            setFieldValue("scoreCardMultiple", value ?? '');
            setSelectedEvaluationScoreCard(value?.length > 0 ? value[0] : '');
            setSelectedEvaluationScoreCardMultiple(value || [])
            console.log('value data', typeof value)
            const filterScoreCardObj = {
                "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                "filterBy": values.filterByRange,
                'scoreCard': value ?? ''
            }
            getDataTagsByScoreCard(filterScoreCardObj);
            // filterScoreCardObj.scoreCard = value
            // setFieldValue("scoreCardMultiple", event.target.value);
        } else {
            setFieldValue("scoreCard", newValue?.scoreCard ?? '');
            // setselectedStaFilter(newValue?.scoreCard ?? '');
            const filterScoreCardObj = {
                "fromDate": values.fromDate,
                "toDate": values.toDate,
                "filterBy": values.filterByRange,
                'scoreCard': newValue?.scoreCard
            }
            getDataTagsByScoreCard(filterScoreCardObj);
            // filterScoreCardObj.scoreCard = newValue?.scoreCard
            setSelectedEvaluationScoreCard(newValue?.scoreCard ?? '')
            setSelectedEvaluationScoreCardMultiple([newValue?.scoreCard])

        }
        // setFieldValue("scoreCardMultiple", event.target.value);
        // setSelectedEvaluationScoreCard(event.target.value);
        getScoreLogEventType(filterObj);
        getScoreLogEventSubType(filterObj);


    };
    const handleEventTypeChange = (event) => {

        setFieldValue("eventType", '');
        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');

        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "teams": values.teams,
            "scoreCard": values.scoreCard,
            "eventType": event.target.value,
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
        }

        setFieldValue('eventType', event.target.value);
        setSelectedEvaluationEventType(event.target.value);
        if (subModLabel !== 3.5) {
            getScoreLogEventSubType(filterObj);
        }
    };

    console.log('error', errors, values)
    const handleDateRangeChange = (event) => {

        // Resetting remaining fields
        setFieldValue("group", '');
        setFieldValue("teams", '');
        setFieldValue("scoreCard", '');
        setFieldValue("eventType", '');
        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');
        // if (subModLabel === 3.7 || subModLabel === 3.6) {
        setFieldValue("scoreCardMultiple", []);
        // }


        const SelectedDateRange = event.target.value;
        setFieldValue("dateRange", SelectedDateRange);
        // setScorecardType(event.target.value);
        switch (SelectedDateRange) {

            case 'Today':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Today');
                break;
            case 'Yesterday':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Yesterday');
                break;
            case 'This Week':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('week').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('week').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().startOf('week').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().endOf('week').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('This Week');
                break;
            case 'Last Week':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Last Week');
                break;
            case 'This Month':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().startOf('month').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().endOf('month').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('This Month');
                break;
            case 'Last Month':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Last Month');
                break;
            case 'This Quarter':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('This Quarter');
                break;
            case 'Last Quarter':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Last Quarter');
                break;
            case 'This Year':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('year').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('year').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().startOf('year').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().endOf('year').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('This Year');
                break;
            case 'Last Year':
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
                setSelectedEvaluationFromDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'))
                setSelectedEvaluationToDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'))
                setSelectedEvaluationAnaDateRange('Last Year');
                break;
            case 'Custom Date Range':
                setCustomFilters(true);
                formik.setFieldValue("fromDate", moment());
                formik.setFieldValue("toDate", moment());
                setFieldValue("toWeek", toWeekNo[toWeekNo?.length - 1]);
                setFieldValue("fromWeek", fromWeekNo[fromWeekNo?.length - 1]);
                setSelectedEvaluationfromWeek(fromWeekNo[fromWeekNo?.length - 1]);
                setSelectedEvaluationtoWeek(toWeekNo[toWeekNo?.length - 1]);

                setSelectedEvaluationAnaDateRange('Custom Date Range');
                break;
            default:
                setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().format('YYYY-MM-DD'));
                break;
        }

    };

    useEffect(() => {
        // setSelectedEvaluationGroupFilter('')
        const arrFromWeek = [];
        const arrToWeek = [];
        if (formik.values.fromWeek > moment().week()) {
            for (let i = 1; i <= formik.values.fromWeek; i) {
                arrFromWeek.push(i);
                i += 1;
            }
            setFromWeekNo(arrFromWeek);
        } else {
            for (let i = 1; i <= moment().week(); i) {
                arrFromWeek.push(i);
                i += 1;
            }
            setFromWeekNo(arrFromWeek);
        }

        if (formik.values.toWeek > moment().week()) {
            for (let i = 1; i <= formik.values.toWeek; i) {
                arrToWeek.push(i);
                i += 1;
            }
            setToWeekNo(arrToWeek);
        } else {
            for (let i = 1; i <= moment().week(); i) {
                arrToWeek.push(i);
                i += 1;
            }
            setToWeekNo(arrToWeek);
        }

        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams || '',
            // "scoreCard": (subModLabel === 3.1 || subModLabel === 3.2) ? values.scoreCard : values?.scoreCardMultiple,
            "scoreCard": values.scoreCard,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "supervisor": values.supervisor,
            "staff": values.staff,
            "scoreCardCategory": '',
            "evaluator": values.evaluator,
            "highlightValue": values.highlightValue,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const groupFilterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            filterBy: values.filterByRange,
            group: '',
            teams: values.teams,
            scoreCard: (subModLabel === 3.1 || subModLabel === 3.2) ? values?.scoreCard : values?.scoreCardMultiple,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            supervisor: values.supervisor,
            staff: values.staff,
            scoreCardCategory: values.scoreCardCategory,
            evaluator: values.evaluator,
            highlightValue: values.highlightValue,
            sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        };

        const evntTypeFilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": (subModLabel === 3.1 || subModLabel === 3.2) ? values.scoreCard : values?.scoreCardMultiple,
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
            "highlightValue": '',
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const evntSubTypeFilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": (subModLabel === 3.1 || subModLabel === 3.2) ? values?.scoreCard : values?.scoreCardMultiple,
            "eventType": values.eventType,
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
            "highlightValue": '',
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const scoreCardfilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": '',
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
            "highlightValue": '',
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const teamFilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": '',
            "eventType": '',
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
            "highlightValue": '',
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const empFilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": (subModLabel === 3.1 || subModLabel === 3.2) ? values.scoreCard : values?.scoreCardMultiple,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "supervisor": values.supervisor,
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": values.evaluator,
            "highlightValue": values.highlightValue,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        const supFilterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": (subModLabel === 3.1 || subModLabel === 3.2) ? values.scoreCard : values?.scoreCardMultiple,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "supervisor": '',
            "staff": values.staff,
            "scoreCardCategory": '',
            "evaluator": values.evaluator,
            "highlightValue": values.highlightValue,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
        }

        // if (formik.values.fromDate && formik.values.toDate && formik.values.filterByRange) {
        //     getScoreLogGroups(groupFilterObj);
        // }

        if (formik.values.fromDate && formik.values.toDate && formik.values.filterByRange) {
            if (subModLabel === 3.5) {
                if (!selectedEvaluationGroupFilter) {
                    getScoreLogGroups(groupFilterObj);
                    getScoreLogEventType(evntTypeFilterObj);
                    getScoreLogTeams(teamFilterObj);
                    getScoreLogEventSubType(evntSubTypeFilterObj);
                } else if (!selectedEvaluationTeamFilter) {
                    getScoreLogEventType(evntTypeFilterObj);
                    getScoreLogTeams(teamFilterObj);
                    getScoreLogEventSubType(evntSubTypeFilterObj);
                } else if (!selectedEvaluationEventType) {
                    getScoreLogEventType(evntTypeFilterObj);
                    getScoreLogEventSubType(evntSubTypeFilterObj);
                } else {
                    getScoreLogEventSubType(evntSubTypeFilterObj);

                }
            } else {
                getScoreLogGroups(groupFilterObj);
                getScoreLogTeams(teamFilterObj);
                getScoreLogScorecards(scoreCardfilterObj);
                getScoreLogEventType(evntTypeFilterObj);
                getScoreLogEventSubType(evntSubTypeFilterObj);
                getScoreLogSupervisor(supFilterObj);
                getScoreLogEmployee(empFilterObj);
                getScoreLogEvaluator(filterObj);
            }

        }

    }, [formik?.values]);


    const handleFilterByChange = (event) => {
        const filterBy = event.target.value;
        switch (filterBy) {

            case 'created_at':
                setFieldValue("filterByRange", 'created_at');
                setSelectedEvaluationFilterBy(event.target.value);
                break;
            case 'event_date':
                setFieldValue("filterByRange", 'event_date');
                setSelectedEvaluationFilterBy(event.target.value);
                break;
            case 'updated_at':
                setFieldValue("filterByRange", 'updated_at');
                setSelectedEvaluationFilterBy(event.target.value);
                break;
            default:
                setFieldValue("filterByRange", 'created_at');
                setSelectedEvaluationFilterBy(event.target.value);
                break;
        }

    };

    const handleTypeChange = (event) => {

        setFieldValue("eventTypeEventSubType", '');
        setFieldValue("staff", '');
        setFieldValue("scoreCardCategory", '');
        setFieldValue("evaluator", '');
        setFieldValue("supervisor", '');
        setFieldValue("highlightBelow", 0);
        setFieldValue("section", '');
        setFieldValue("evaluator", '');

        const filterObj =
        {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterByRange,
            "group": values.group,
            "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "teams": values.teams,
            "scoreCard": values.scoreCard,
            "eventType": values.eventType,
            "eventTypeEventSubType": '',
            "supervisor": '',
            "staff": '',
            "scoreCardCategory": '',
            "evaluator": '',
        }

        setFieldValue('eventTypeEventSubType', event.target.value);
        getScoreLogEventSubType(filterObj);
        setSelectedEvaluationEventSubType(event.target.value);

    };

    const handleAdvancedFilters = (event) => {
        setAdvanceFilters((advanceFilters) => !advanceFilters);
    };
    const renderOptions = (props, option) => {
        return <li {...props} key={option?.scoreCard}>
            {option?.scoreCardName}
        </li>

    }
    return (
        <div>

            {addUserButton &&
                <Button variant="contained" onClick={handleClickOpen}>
                    Filter
                </Button>
            }

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle sx={{ pb: 2 }} > <h3>Analytics Report Filter {subModLabel} </h3>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 15,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent style={{ paddingTop: '20px' }}>
                            <Stack spacing={3}>

                                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 0 }}>
                                    <FormControl fullWidth sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                                        {(subModLabel !== 3.3 && subModLabel !== 3.4) && <Button startIcon={advanceFilters ? <VisibilityOffIcon /> : <VisibilityIcon />} sx={{ p: 0 }} onClick={handleAdvancedFilters}> {advanceFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'} </Button>}
                                    </FormControl>
                                </Stack>
                                <Divider />


                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="date-range-label">Date Range</InputLabel>
                                        <Select
                                            // defaultValue='This Month'
                                            labelId="date-range-label"
                                            id="demo-select-small"
                                            value={values.dateRange}
                                            label="Date Range"
                                            {...getFieldProps('dateRange')}
                                            onChange={(e) => { handleDateRangeChange(e) }}
                                        >
                                            {DateRangeList?.map((item) => (
                                                <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                                                    {item.dateRange}
                                                </MenuItem >
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="filter-by-label">Filter By</InputLabel>
                                        <Select
                                            // defaultValue='Score Date'
                                            labelId="filter-by-label"
                                            id="demo-select-small"
                                            value={values.filterByRange}
                                            label="Filter By"
                                            {...getFieldProps('filterByRange')}
                                            onChange={handleFilterByChange}
                                        >
                                            {filterByList?.map((item) => (
                                                <MenuItem key={item.id} id={item.id} value={item.id}>
                                                    {item.type}
                                                </MenuItem >
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>


                                {customFilters && <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <Box sx={{ width: "50%" }}>
                                            <DesktopDatePicker
                                                label="Date From"
                                                inputFormat="yyyy/MM/dd"
                                                name="fromDate"
                                                minDate={new Date(dataCreatedFrom)}
                                                disableFuture
                                                {...getFieldProps('fromDate')}
                                                onChange={(newValue) => {
                                                    setFieldValue("group", '');
                                                    if (moment(newValue).format('MM-DD') === '01-01') {
                                                        formik.setFieldValue("fromWeek", 1)
                                                        setSelectedEvaluationfromWeek(1)
                                                    } else {
                                                        formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                        setSelectedEvaluationfromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                    }
                                                    setFieldValue("fromDate", newValue);
                                                    setSelectedEvaluationFromDate(newValue);
                                                    if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                                                        formik.setFieldValue("toDate", moment(newValue).format('YYYY-MM-DD'))
                                                        setSelectedEvaluationToDate(moment(newValue).format('YYYY-MM-DD'));
                                                        if (moment(newValue).format('MM-DD') === '01-01') {
                                                            formik.setFieldValue("toWeek", 1)
                                                            setSelectedEvaluationtoWeek(1)

                                                        } else {
                                                            formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                            setSelectedEvaluationtoWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                                                        }

                                                    }
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                            {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                                        </Box>

                                        <Box sx={{ width: "50%" }}>
                                            <DesktopDatePicker
                                                label="Date To"
                                                inputFormat="yyyy/MM/dd"
                                                name="toDate"
                                                minDate={new Date(dataCreatedFrom)}
                                                disableFuture
                                                {...getFieldProps('toDate')}
                                                onChange={(newValue) => {
                                                    setFieldValue('group', '');
                                                    if (moment(newValue).format('MM-DD') === '01-01') {
                                                        formik.setFieldValue('toWeek', 1);
                                                        setSelectedEvaluationtoWeek(1);
                                                    } else {
                                                        formik.setFieldValue(
                                                            'toWeek',
                                                            moment(newValue).year(moment(newValue).format('YYYY')).isoWeek()
                                                        );
                                                        setSelectedEvaluationtoWeek(
                                                            moment(newValue).year(moment(newValue).format('YYYY')).isoWeek()
                                                        );
                                                    }
                                                    setFieldValue('toDate', newValue);
                                                    setSelectedEvaluationToDate(newValue);

                                                    // Extract year from full date
                                                    const fullDate = moment(newValue);
                                                    const extractyear = fullDate.year();

                                                    if (moment(newValue, 'YYYY-MM-DD').isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {
                                                        formik.setFieldValue('fromDate', newValue);
                                                        setSelectedEvaluationFromDate(newValue);

                                                        if (moment(newValue).format('MM-DD') === '01-01') {
                                                            formik.setFieldValue('fromWeek', 1);
                                                            setSelectedEvaluationfromWeek(1);
                                                        } else {
                                                            formik.setFieldValue(
                                                                'fromWeek',
                                                                moment(newValue).year(moment(newValue).format('YYYY')).isoWeek()
                                                            );
                                                            setSelectedEvaluationfromWeek(
                                                                moment(newValue).year(moment(newValue).format('YYYY')).isoWeek()
                                                            );
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                            {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                                        </Box>
                                    </Stack>
                                </LocalizationProvider>}


                                {customFilters && <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                                    <Box sx={{ width: "50%" }}>
                                        <TextField
                                            fullWidth
                                            required
                                            value={formik.values.fromWeek}
                                            multiple={false}
                                            select
                                            name="fromWeek"
                                            onChange={(e) => {
                                                moment.updateLocale('en', {
                                                    week: {
                                                        dow: 1
                                                    }
                                                });
                                                formik.setFieldValue("fromWeek", e.target.value);
                                                setSelectedEvaluationfromWeek(e.target.value);
                                                formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                                                const newDate = moment().week(e.target.value).startOf('week').format('YYYY-MM-DD')
                                                if (moment(newDate, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                                                    formik.setFieldValue("toDate", moment(newDate).format('YYYY-MM-DD'))
                                                    setSelectedEvaluationToDate(moment(newDate).format('YYYY-MM-DD'));
                                                }
                                            }}

                                        >
                                            {fromWeekNo?.map((item) => (
                                                <MenuItem key={item} id={item} value={item}>
                                                    Week&nbsp;{item}
                                                </MenuItem >
                                            ))}

                                        </TextField>
                                        {/* {formik.errors.fname && formik.touched.fname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography> : null} */}
                                    </Box>
                                    <Box sx={{ width: "50%" }}>
                                        <TextField
                                            fullWidth
                                            multiple={false}
                                            value={formik.values.toWeek}
                                            required
                                            select
                                            name="toWeek"
                                            onChange={(e) => {
                                                moment.updateLocale('en', {
                                                    week: {
                                                        dow: 1
                                                    }
                                                });
                                                formik.setFieldValue("toWeek", e.target.value);
                                                setSelectedEvaluationtoWeek(e.target.value);
                                                formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                                                const newDate = moment().week(e.target.value).endOf('week').format('YYYY-MM-DD')
                                                if (moment(newDate, "YYYY-MM-DD").isBefore(formik.values.fromDate)) {
                                                    formik.setFieldValue("fromDate", moment(newDate).format('YYYY-MM-DD'))
                                                    setSelectedEvaluationFromDate(moment(newDate).format('YYYY-MM-DD'));
                                                }
                                            }}

                                        >
                                            {toWeekNo?.map((item) => (
                                                <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                                                    Week&nbsp;{item}
                                                </MenuItem >
                                            ))}
                                        </TextField>
                                        {/* {formik.errors.lname && formik.touched.lname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography> : null} */}
                                    </Box>

                                </Stack>}

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            value={reportsScoreLogGroups?.find((option) => option?.id === values?.group)}
                                            fullWidth
                                            options={reportsScoreLogGroups}
                                            getOptionLabel={(option) => option?.name || ""}
                                            onChange={(event, newValue) => {
                                                handleGroupChange(event, newValue);
                                            }}
                                            id="controllable-states-demo"
                                            // sx={{
                                            //     border: `${(touched.group && errors.group) ? '1px solid red' : '0px'}`,
                                            //     borderRadius: `${(touched.group && errors.group) ? '9px' : '0px'}`,
                                            // }}
                                            renderInput={(params) => <TextField {...params} label="Group"
                                                popoverProps={{
                                                    style: {
                                                        bottom: 0,
                                                        overflowY: 'auto'
                                                    }
                                                }}
                                                variant="outlined"
                                                error={Boolean(touched.group && errors.group)}
                                                helperText={touched.group && errors.group}
                                                required
                                            />}
                                        />
                                        {/* {errors.group && touched.group ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null} */}
                                    </FormControl>

                                    {
                                        (dataTagByScoreCard?.data?.length > 0 && (subModLabel !== 3.3 && subModLabel !== 3.4 && subModLabel !== 3.5)) && <Box sx={{ width: '100%' }}> <FormControl fullWidth sx={{ width: '100%' }}>
                                            <InputLabel shrink id="scorecard-label">
                                                Data Tags
                                            </InputLabel>
                                            <Select
                                                labelId="scorecard-label"
                                                id="demo-select-small"
                                                fullWidth
                                                multiple
                                                displayEmpty
                                                value={values.dataTag}
                                                input={<OutlinedInput notched label="Data Tags" />}
                                                {...getFieldProps('dataTag')}
                                                onChange={handleDataTags}
                                            >
                                                {dataTagByScoreCard?.data?.map((item, i) => (
                                                    <MenuItem key={i} id={item.dataTags} value={item.dataTags}>
                                                        {item.dataTagsName}
                                                    </MenuItem >
                                                ))}
                                            </Select>
                                        </FormControl>


                                            <Stack direction={{ sm: 'row' }} spacing={0} alignItems="flex-end" justifyContent="flex-end">
                                                <FormControlLabel
                                                    name="anyOfTag"
                                                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                                                    // value="10"
                                                    control={<Radio />}
                                                    label="Any of the Tags"
                                                    checked={isAnyOfTag}
                                                    onChange={handleAnyOfTag}
                                                />
                                                {/* {...getFieldProps('anyOfTag')} */}

                                                <FormControlLabel
                                                    name="allOfTag"
                                                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                                                    // value="20"
                                                    control={<Radio />}
                                                    label="All of the Tags"
                                                    onChange={handleAllOfTag}
                                                    checked={isAllOfTag}


                                                />
                                                {/* {...getFieldProps('allOfTag')} */}

                                            </Stack>



                                        </Box>}

                                    {(subModLabel === 3.5 || subModLabel === 3.3 || subModLabel === 3.4) && <FormControl fullWidth>
                                        <InputLabel shrink id="teams-label">Teams</InputLabel>
                                        <Select
                                            // defaultValue='All'
                                            required
                                            labelId="teams-label"
                                            id="demo-select-small"
                                            displayEmpty
                                            value={values.teams}
                                            {...getFieldProps('teams')}
                                            // label="Teams"
                                            input={<OutlinedInput notched label="Teams" />}
                                            onChange={handleTeamChange}
                                        >
                                            <MenuItem value=""> All </MenuItem >
                                            {reportsScoreLogTeams?.map((item) => (
                                                <MenuItem key={item.id} id={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem >
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                </Stack>

                                {(subModLabel === 3.1 || subModLabel === 3.2) &&
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            // value={formValues.location === '' ? {label: ''} : {label: formValues.location}}
                                            value={reportsScoreLogScorecards?.find((option) => option?.scoreCard === values?.scoreCard)}
                                            fullWidth
                                            options={reportsScoreLogScorecards}
                                            getOptionLabel={(option) => option?.scoreCardName || ""}
                                            renderOption={renderOptions}
                                            onChange={(event, newValue) => {
                                                handleScorecardChange(event, newValue);
                                                // setFieldValue('scoreCardSingle', newValue?.scoreCard)
                                            }}
                                            id="controllable-states-demo"
                                            renderInput={(params) => <TextField {...params} label="Scorecard"
                                                popoverProps={{
                                                    style: {
                                                        bottom: 0,
                                                        overflowY: 'auto'
                                                    }
                                                }}
                                                variant="outlined"
                                                error={Boolean(touched.scoreCard && errors.scoreCard)}
                                                helperText={touched.scoreCard && errors.scoreCard}
                                                required
                                            />}
                                        />
                                        {/* {errors.scoreCard && touched.scoreCard ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null} */}
                                    </FormControl>}
                                {(subModLabel === 3.7 || subModLabel === 3.6) &&
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            value={reportsScoreLogScorecards?.filter((option) => values?.scoreCardMultiple?.includes(option?.scoreCard))}
                                            // inputValue={inputValue}
                                            fullWidth
                                            options={reportsScoreLogScorecards}
                                            getOptionLabel={(option) => option?.scoreCardName || ""}
                                            renderOption={renderOptions}
                                            onChange={(event, newValue) => {
                                                handleScorecardChange(event, newValue);
                                            }}
                                            id="controllable-states-demo"
                                            isOptionEqualToValue={(option, value) => option?.scoreCard === value?.scoreCard}
                                            renderTags={(value, getTagProps) => {
                                                return value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={typeof option === "string" ? option : option?.scoreCardName}
                                                        {...getTagProps({ index })}
                                                    />
                                                ));
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Scorecard"
                                                popoverProps={{
                                                    style: {
                                                        bottom: 0,
                                                        overflowY: 'auto',
                                                    }
                                                }}
                                                variant="outlined"
                                                required
                                                error={Boolean(touched.scoreCardMultiple && errors.scoreCardMultiple)}
                                            // helperText={(touched.scoreCardMultiple && errors.scoreCardMultiple)}
                                            />}

                                        />
                                        {errors.scoreCardMultiple && touched.scoreCardMultiple ?
                                            <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This value is required</Typography>
                                            : null}
                                    </FormControl>}

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="group-label">Event Type</InputLabel>
                                        <Select
                                            // defaultValue='All'
                                            labelId="group-label"
                                            id="demo-select-small"
                                            displayEmpty
                                            // value={values.type}
                                            // label="Event Type"
                                            input={<OutlinedInput notched label="Event Type" />}
                                            {...getFieldProps('eventType')}
                                            onChange={handleEventTypeChange}
                                        >
                                            <MenuItem value=""> All </MenuItem >
                                            {reportsScoreLogEventType?.map((item) => (
                                                <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                                                    <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                                </MenuItem >
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel shrink id="teams-label">Event Sub Type</InputLabel>
                                        <Select
                                            labelId="teams-label"
                                            id="demo-select-small"
                                            displayEmpty
                                            input={<OutlinedInput notched label="Event Sub Type" />}
                                            onChange={handleTypeChange}
                                            {...getFieldProps('eventTypeEventSubType')}
                                        >
                                            <MenuItem value=""> All </MenuItem >
                                            {reportsScoreLogEventSubType?.map((item) =>
                                                <MenuItem key={item.eventSubType} id={item.eventSubType} value={item.eventSubType}>
                                                    <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventSubType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {
                                    advanceFilters && <><Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <FormControl sx={{ width: "50%" }}>

                                            <TextField
                                                fullWidth
                                                type='number'
                                                value={values.highlightBelow}
                                                label="Highlight Below"
                                                {...getFieldProps('highlightBelow')}
                                                error={Boolean(touched.highlightBelow && errors.highlightBelow)}
                                                helperText={touched.highlightBelow && errors.highlightBelow}
                                            />
                                        </FormControl>
                                    </Stack>
                                    </>
                                }

                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { handleClose() }} color="inherit">
                                Cancel
                            </Button>
                            <Button disabled={circular} type="submit" variant="contained" loading={isSubmitting}>
                                Apply
                                {circular && <CircularProgress size={14} sx={{ ml: 1 }} />}
                            </Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
} 