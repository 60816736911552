import React, { useState } from 'react';
import { Outlet, useSearchParams, useLocation } from 'react-router-dom';
// material
import { Box } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// import context 
import { SettingsContext } from "../../contexts/SettingsContext"
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import MainFooter from '../main/MainFooter';
import SupervisorDashboardNavbar from './supervisor_dashboard/sup_navbar';
import EmployeeDashboardNavbar from "./employee_dashboard/emp_navbar";
import EmployeeDashboardSidebar from './employee_dashboard/emp_sidenavbar';
import HelpSupportPopUpForm from "../../pages/dashboard/help_support/help_support_pop_up_form";

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
const APP_BAR_MOBILE = 34;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  // minHeight: '100%',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',


}));

const MainStyle = styled('div')(({ theme, userdata }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,

  paddingBottom: '0px',
  // paddingBottom: userdata?.staff ? 15 : theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + Number(`${userdata?.staff ? 7 : 24}`),
    paddingLeft: userdata?.staff ? 0 : theme.spacing(1),
    paddingRight: userdata?.staff ? 0 : theme.spacing(1),
    paddingBottom: 0
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  const { showHelpSupportPopUp, setShowHelpSupportPopUp, showSidebar } = React.useContext(SettingsContext)
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { collapseClick, collapseHover } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation()

  const getBgValue = () => {
    if (!loggedUserData?.staff) {
      return '#f5f6fa'
    }
    return 'transparent'
  }

  const pathnameIndexTwo = [
    'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore', 'call_details', 'eventBuilder'
  ]


  console.log(collapseClick);
  console.log(collapseHover);

  return (
    <>
      <RootStyle  >
        {(loggedUserData?.staff?.staffType === 20 || (loggedUserData?.staff?.staffType === 30)) && <SupervisorDashboardNavbar />}
        {/* {(loggedUserData?.staff?.staffType === 30 || (loggedUserData?.staff?.staffType !== 10 && searchParams.get("review"))) && <SupervisorDashboardNavbar />} */}
        {(loggedUserData?.staff?.staffType !== 10 && searchParams.get("review")) && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
        {loggedUserData?.staff?.staffType === 10 && <EmployeeDashboardNavbar type="score" />}
        {(!loggedUserData?.staff && !searchParams.get("review")) && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
        {/* {(!loggedUserData?.staff && !searchParams.get("review") && !searchParams.get("stff_emp_id")) && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />} */}
        {(!loggedUserData?.staff && !searchParams.get("review") && !searchParams.get("stff_emp_id") && !pathnameIndexTwo?.includes(pathname?.split('/')[2])) && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}

        {(loggedUserData?.staff?.staffType === 10 || searchParams.get("stff_emp_id")) && <EmployeeDashboardSidebar />}
        <MainStyle
          userdata={loggedUserData}
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: !showSidebar ? '1px' : '60px'
            }),
            // backgroundColor: (theme) => loggedUserData?.staff ? 'transparent' : '#f5f6fa', // changes for bg
            backgroundColor: (theme) => theme.palette.mode === 'light' ? getBgValue() : '#636e72', // changes for bg
          }}
        >
          <Outlet />

        </MainStyle>
        <HelpSupportPopUpForm />

      </RootStyle>

      <MainFooter />
    </>
  );
}