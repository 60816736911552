import React from 'react';
import { ConfirmProvider } from "material-ui-confirm";
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
// routes
import Router from './routes/index';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { NetworkHook } from './hooks/networkHook';
// import './assets/christmasCss.css';
// import Login from './pages/authentication/Login';
// ----------------------------------------------------------------------

// function getFaviconEl() {
//   return document.getElementById("favicon");
// }
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, logout } = useAuth();
  // const { pathname, search } = useLocation();
  // const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

  // context
  // const { getCompanySettings, favIconLogo, setHeaderLogo, headerLogo } = useAuth();

  const onIdle = () => {
    // const obj = { pathLink: `${pathname}${search}`, email: loggedUserData?.email }
    // localStorage.setItem('lastPath', JSON.stringify(obj));
    logout();
  }

  const idleTimer = useIdleTimer({
    onIdle, timeout: 1000 * 1800, crossTab: true, events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
  })

  React.useEffect(() => {
    localStorage.removeItem('lastPath');
    idleTimer.start();
  }, [])


  // get fav icon image from api
  // React.useEffect(() => {

  //   if (favIconLogo || headerLogo) {
  //     alert("triggered from app");
  //     const fetchMyAPI = async () => {
  //       const response = await getCompanySettings();
  //       setHeaderLogo(response?.logo);
  //       const favIcon = document.getElementById("favicon");
  //       favIcon.href = response?.favicon;
  //     }

  //     fetchMyAPI();
  //   }

  // }, [favIconLogo, headerLogo]);

  return (
    <>
      <ThemeConfig>
        <ThemePrimaryColor>
          <ConfirmProvider>
            <ThemeLocalization>
              <RtlLayout>
                <NotistackProvider>
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <GoogleAnalytics />
                  {isInitialized ? <Router /> : <LoadingScreen />}
                </NotistackProvider>
              </RtlLayout>
            </ThemeLocalization>
          </ConfirmProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </>
  );
}
