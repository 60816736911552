import React from 'react';
// MATERIAL IMPORT
import { Box, Button, Menu, MenuItem, Tooltip, TextField } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import {/* Group, Settings, KeyboardArrowDown, Close, */ CalendarTodayOutlined } from '@material-ui/icons';
// OTHER NPM IMPORTS
import moment from 'moment';
// OTHER COMPONENT IMPORTS
import DatePicker from "react-multi-date-picker";
// CONTEXT STATES
import { StaffContext } from '../../contexts/staffContext';
// CSS IMPORTS
import "../../assets/datepicker.css";

import CalenderIcon from '../../assets/Dashboard/calenderIcon.svg'

export default function GlobalDatePicker() {

    // REACT STATES
    const [anchorEl, setAnchorEl] = React.useState(null);
    /* const [selectedItem, setSelectedItem] = React.useState(3); */
    const open = Boolean(anchorEl);

    // REACT REF STATES
    const datePickerRefs = React.useRef();

    // CONTEXT STATES
    const { isCustomDate, strtDate, endDate, setStrtDate, setEndDate, tester, dateItem, setDateItem, dateObj, setDateObj } = React.useContext(StaffContext);
    const [value, setValue] = React.useState([strtDate, endDate]);

    // METHODS/ FUNCTIONS
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (value) => {
        setValue(value)
    }

    const handleDate = (val) => {
        if (val === "today") {
            setStrtDate(moment(0, "HH").format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            handleClose();
        }
        if (val === "l7days") {
            setStrtDate(moment(0, "HH").subtract(1, 'week').format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").subtract(1, 'week').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })

            return handleClose();
        }
        if (val === "l30days") {
            setStrtDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            return handleClose();

        }
        if (val === "thismonth") {
            setStrtDate(moment(0, "HH").startOf('month').format('YYYY-MM-DD'));
            setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").startOf('month').format('YYYY-MM-DD'), date2: moment().endOf('month').format('YYYY-MM-DD') })
            return handleClose();
        }
        if (val === "lastmonth") {
            setStrtDate(moment(0, "HH").startOf('month').subtract(1, 'month').format('YYYY-MM-DD'));
            setEndDate(moment(24, "HH").subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), date2: moment(24, "HH").subtract(1, 'month').endOf('month').format('YYYY-MM-DD') })
            return handleClose();
        }
        if (val === "quarter") {
            setStrtDate(moment(0, "HH").startOf('quarter').format('YYYY-MM-DD'));
            setEndDate(moment(24, "HH").endOf('quarter').format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").startOf('quarter').format('YYYY-MM-DD'), date2: moment(24, "HH").endOf('quarter').format('YYYY-MM-DD') })
            return handleClose();

        }
        if (val === "year") {
            setStrtDate(moment(0, "HH").startOf("year").format('YYYY-MM-DD'));
            // setEndDate(moment().format('YYYY-MM-DD'));
            setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
            setDateObj({ date1: moment(0, "HH").startOf("year").format('YYYY-MM-DD'), date2: moment().endOf('year').format('YYYY-MM-DD') })

            return handleClose();

        }
        if (val === "custom") {
            const fromDate = moment(value[0]?.toString()).format("YYYY-MM-DD");
            const toDate = moment(value[1]?.toString()).format("YYYY-MM-DD");

            setStrtDate(fromDate); setEndDate(toDate);
            setDateObj({ date1: fromDate, date2: toDate });
            datePickerRefs.current.closeCalendar();
        }
    }

    // USE EFFECT STATES
    React.useEffect(() => {
        if (isCustomDate) {
            setDateObj({ date1: strtDate, date2: endDate });
            setDateItem(7)
        }
    }, [isCustomDate])

    return (
        <>
            <Box display="flex" sx={{ position: "relative" }}>

                <Box>
                    <Tooltip title="Pick a date">
                        {/* <TextField
                            label="Select Date"
                            id="outlined-start-adornment"
                            placeholder='Select Date'
                            sx={{ m: 1, width: '28ch' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <CalendarTodayOutlined
                                        sx={{
                                            color: '#fb5d2e',
                                            stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px'
                                        }}
                                    />
                                </InputAdornment>,
                            }}
                            value={`${dateObj?.date1}-${dateObj?.date2}`}
                            onClick={handleClick}

                        /> */}


                        <Button
                            //     startIcon={
                            //         <CalendarTodayOutlined
                            //         sx={{
                            //             color: '#fb5d2e',
                            //             stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px'
                            //         }}
                            //     />
                            // }
                            // startIcon={<CalenderIcon />}
                            startIcon={<img src={CalenderIcon} alt='calender-icon' />}
                            variant="contained"
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.paper,
                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.background.paper,
                                    color: '#fb5d2e'
                                },
                                color: '#fb5d2e',
                                boxShadow: 'none',
                                fontWeight: 'normal',
                                borderRadius: '5px',
                                fontSize: '14px',
                                border: '1px solid #fb5d2e'
                            }}
                            onClick={handleClick}
                        >

                            {dateObj?.date1}&nbsp;-&nbsp;{dateObj?.date2}


                        </Button>
                    </Tooltip>
                    {/* <Button variant="contained" sx={{ mx: 1 }} onClick={handleClick}>
                        Filter
                    </Button> */}
                    <Box position="absolute" sx={{ top: "0" }}>
                        <DatePicker
                            numberOfMonths={1}
                            className="green"
                            ref={datePickerRefs}
                            calendarPosition="bottom-end"
                            onChange={handleChange}
                            render={(value, openCalendar) => {
                                return (
                                    <Box onClick={openCalendar} sx={{ visibility: "hidden" }}>one</Box>
                                )
                            }}
                            maxDate={new Date()}
                            range

                        >
                            <Button sx={{ my: 2 }} onClick={() => { handleDate("custom") }} >Confirm</Button>
                            <Button sx={{ my: 2 }} onClick={() => { datePickerRefs.current.closeCalendar(); }} >Cancel</Button>
                        </DatePicker>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >

                        <MenuItem selected={dateItem === 0}
                            sx={{ fontSize: "12px", /* color: dateItem === 0 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(0); handleDate("today") }}>Today </MenuItem>
                        <MenuItem selected={dateItem === 1}
                            sx={{ fontSize: "12px", /* color: dateItem === 1 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(1); handleDate("l7days") }}>Last 7 days</MenuItem>
                        <MenuItem selected={dateItem === 2}
                            sx={{ fontSize: "12px", /* color: dateItem === 2 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(2); handleDate("l30days") }}>Last 30 days</MenuItem>
                        <MenuItem selected={dateItem === 3}
                            sx={{ fontSize: "12px", /* color: dateItem === 3 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(3); handleDate("thismonth") }}>This Month</MenuItem>
                        <MenuItem selected={dateItem === 4}
                            sx={{ fontSize: "12px", /* color: dateItem === 4 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(4); handleDate("lastmonth") }}>Last Month</MenuItem>
                        <MenuItem selected={dateItem === 5}
                            sx={{ fontSize: "12px", /* color: dateItem === 5 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(5); handleDate("quarter") }}>This Quarter</MenuItem>
                        <MenuItem selected={dateItem === 6}
                            sx={{ fontSize: "12px", /* color: dateItem === 6 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(6); handleDate("year") }}>This Year</MenuItem>
                        <MenuItem selected={dateItem === 7}
                            sx={{ fontSize: "12px", /* color: dateItem === 7 ? "white" : "black", */ "&:hover": { color: "black" } }}
                            onClick={() => { setDateItem(7); handleClose(); datePickerRefs.current.openCalendar(); }}>Custom Range</MenuItem>



                    </Menu>

                </Box>
            </Box>
        </>
    )
}