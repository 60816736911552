import React from "react";
import ReactApexChart from 'react-apexcharts';
import { styled, useTheme } from '@material-ui/core/styles';


export const ReactBarChart = (props) => {
    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;
    const {
        refrence, id,
        chartEvents,
        color, xAxisLabelFormatter,
        series, datalableEnabled = false,
        options, plotOptionsHorizontal,
        width, xAxisLabelShow = true,
        height, tickAmount,
        title, xCategories,
        xTitle, yTitle, legendShow,
        plotOptionsDistributed,
        handleTooltipCustomFun

    } = props
    console.log('series', series)
    const barChartOption = {
        // colors: ['#3CB8E1', '#FFCB4E', '#FF935A'],
        colors: GRAPH_COLOR,
        // series: series,
        chart: {
            id: `${id}`,
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                dataPointSelection: (event, chartContext, config) => { chartEvents(event, chartContext, config) }
                // dataPointSelection: (event, chartContext, config) => {

                //     const numberPattern = /\d+/g;
                //     const noSpecialChars = percentaegRange[config.dataPointIndex].match(numberPattern);
                //     console.log(".......===>", distStaffIds, noSpecialChars, config);
                //     if (noSpecialChars?.length > 1) {
                //         navigate(`/analytics/StaffTrendReportResultDetails?staff_Id=${distStaffIds[config.dataPointIndex] || ''}&score_card=${selAnaFilters?.scoreCard}&group_id=${selAnaFilters?.group}&team_id=${selAnaFilters?.teams}&from_percentage=${noSpecialChars[0]}&to_percentage=${noSpecialChars[1]}&is_distribution_graph=${'True'}&from_date=${selAnaFilters?.fromDate}&to_date=${selAnaFilters?.toDate}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&event_type=${selAnaFilters?.eventType}&event_sub_type=${selAnaFilters?.eventTypeEventSubType}&supervisor_id=${selAnaFilters?.supervisor}&scorecard_category=${selAnaFilters?.scoreCardCategory}&evaluator_id=${selAnaFilters?.evaluator}&highlight_value=${selAnaFilters?.highlightValue}&filter_by=${selAnaFilters?.filterBy}&module="1"&sub_module="1.1"&filter_by_range=${selAnaFilters?.filterByRange}&from_na=${'False'}&from_table=${'False'}&data_tag=${selAnaFilters?.dataTag || ""}&data_tags_operator=${selAnaFilters?.dataTagsOperator || ""}`);
                //     } else {
                //         navigate(`/analytics/StaffTrendReportResultDetails?staff_Id=${distStaffIds[config.dataPointIndex] || ''}&score_card=${selAnaFilters?.scoreCard}&group_id=${selAnaFilters?.group}&team_id=${selAnaFilters?.teams}&from_percentage=${0}&to_percentage=${5}&is_distribution_graph=${'True'}&from_date=${selAnaFilters?.fromDate}&to_date=${selAnaFilters?.toDate}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&event_type=${selAnaFilters?.eventType}&event_sub_type=${selAnaFilters?.eventTypeEventSubType}&supervisor_id=${selAnaFilters?.supervisor}&scorecard_category=${selAnaFilters?.scoreCardCategory}&evaluator_id=${selAnaFilters?.evaluator}&highlight_value=${selAnaFilters?.highlightValue}&filter_by=${selAnaFilters?.filterBy}&module="1"&sub_module="1.1"&filter_by_range=${selAnaFilters?.filterByRange}&from_na=${'True'}&from_table=${'False'}&data_tag=${selAnaFilters?.dataTag || ""}&data_tags_operator=${selAnaFilters?.dataTagsOperator || ""}`);
                //     }
                // },
                // legendClick: (chartContext, seriesIndex, config) => {
                //     console.log(chartContext, seriesIndex, config)
                //     if (customGraphData && module === '4.7') {
                //         const seriesArr = config?.globals?.series[0];
                //         if (seriesArr[seriesIndex] !== null) {
                //             const tempArr = seriesArr?.map((row, index) => { if (index === seriesIndex) { return null } return row })
                //             return setSeriesData([{ name: 'Count', data: tempArr }])
                //         }
                //         if (seriesArr[seriesIndex] === null) {
                //             const tempArr = seriesArr;
                //             tempArr[seriesIndex] = customGraphData[0]?.data[seriesIndex];
                //             console.log(tempArr);
                //             setSeriesData([{ name: 'Count', data: tempArr }])
                //         }

                //     }

                // }
            }
        },
        // colors,
        plotOptions: {
            bar: {
                columnWidth: '35%',
                distributed: plotOptionsDistributed,
                horizontal: plotOptionsHorizontal,
                // borderRadius: 4,
                // horizontal: true,
                // dataLabels: {
                //     position: 'top',
                // },
                // borderRadius: 10,
                // borderRadiusApplication: 'end', // 'around', 'end'
                // borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    position: 'top',
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: datalableEnabled,
            // formatter: (val, opts) => {
            //     return `${val} %`
            // },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        legend: {
            show: legendShow,
            position: 'top',
            horizontalAlign: 'right',
            offsetY: -25,
            labels: {
                colors: undefined
                // colors: GRAPH_COLOR
            },
            onItemClick: {
                toggleDataSeries: true
            },
            showForSingleSeries: true,
            offsetX: 40,
        },
        xaxis: {
            categories: xCategories,
            labels: {
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: false,
                trim: true,
                // minHeight: undefined,
                // maxHeight: 260,
                style: {
                    // colors,
                    fontSize: '12px'
                },
                show: xAxisLabelShow,
                formatter: (val) => xAxisLabelFormatter(val)
            },
            title: {
                text: xTitle,
                offsetY: 80,

            },
            tickPlacement: 'off',

        },
        yaxis: {
            title: {
                text: yTitle,
                offsetX: -2,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            floating: false,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: "#000",
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 10,
                offsetY: 10,
                rotate: 0,
            },
            min: 0,
            // max: 100,
            // tickAmount: 5,
            // stepSize: 20
            // tickAmount: tickAmount 
        },
        tooltip: {
            // x: {
            //     formatter: (val) => tooltipXFormatter(val)
            // },
            // y: {
            //     formatter: (val) => tooltipYFormatter(val)
            // },
            shared: false,
            intersect: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => handleTooltipCustomFun(series, seriesIndex, dataPointIndex, w)
            // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            //     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            //     return `<ul style="font-size: 12px;padding:5px;text-align: left; "> 
            //   <li><span>${timeBtnGroup === "quarter" ? "Quarter" : timeBtnGroup} ${w.globals.labels[dataPointIndex]}:</span> <span>${data}%</span>  </li>
            //   </ul>`;
            // }
        },
        fill: {
            opacity: 1
        },
        title: {
            text: title,
            align: 'middle',
            margin: 30,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black'
            },
        },
        markers: {
            size: 0,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        grid: {
            show: true,
            borderColor: '#f1f1f1',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5
            },
            column: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        // theme: {
        //     mode: 'light',
        //     palette: theme.ApexCharts.theme.palette
        // },
        // theme: {
        //     mode: 'light',
        //     palette: 'palette3',
        //     monochrome: {
        //         enabled: false,
        //         color: '#255aee',
        //         shadeTo: 'light',
        //         shadeIntensity: 0.65
        //     },
        // }
        noData: {
            text: 'No Data Found',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    noData: {
                        text: undefined,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    noData: {
                        text: undefined,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    },
                },
            }
        ]

    }
    console.log('refrence', refrence)
    return <>
        <ReactApexChart
            ref={refrence}
            type="bar"
            series={series}
            options={barChartOption}
            width={width}
            height={height}
        />
    </>
}

// export const ReactPieDounoughtChart = () => {
//     const optionPie = {

//         series: seriesData?.length > 0 ? seriesData : [],
//         options: {
//             chart: {
//                 type: 'pie',
//             },
//             labels: categoryData?.length > 0 ? categoryData : [],
//             tooltip: {
//                 enabled: true,
//                 y: {
//                     formatter: (val) =>
//                         `Group: ${val}`

//                 },
//             },
//             legend: {

//                 position: 'bottom'
//             }
//             //   responsive: [{
//             //     breakpoint: 480,
//             //     options: {
//             //       chart: {
//             //         width: 200
//             //       },
//             //       legend: {
//             //         position: 'bottom'
//             //       }
//             //     }
//             //   }]
//         },


//     };
//     return <>
//     </>
// }

// export const ReactLineChart = () => {
//     const lineChartOptions = {
//         // colors: [BAR_GRAPH_COLOR,BAR_GRAPH_COLOR_LG,BAR_GRAPH_COLOR_LR,BAR_GRAPH_COLOR_V],
//         chart: {
//             height: 350,
//             type: 'line',
//             events: {
//                 dataPointSelection: (event, chartContext, config) => {
//                     handleRedirectFromChart(timeRangeRedirect[config?.dataPointIndex], yearRange[config?.dataPointIndex])
//                 },
//                 markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
//                     return navigate(`/analytics/scorecards/score_card_result_details?from_date=${moment(searchParams?.get('fromDate')).format('YYYY-MM-DD')}&to_date=${moment(searchParams?.get('toDate')).format('YYYY-MM-DD')}&filterBy=${searchParams?.get('filterBy')}&score_Card_Id=${searchParams?.get('scoreCard')}&event_type=${searchParams.get('eventType') || ''}&event_type_id=${eventTypeTrendApiRes[seriesIndex]?.id}&sub_type=${searchParams?.get('eventTypeEventSubType')}&quarter=${handleQuarter(seriesIndex, dataPointIndex)}&month=${handleMonth(seriesIndex, dataPointIndex)}&week=${handleWeek(seriesIndex, dataPointIndex)}&year=${eventTypeTrendApiRes[seriesIndex]?.groupBySeason[dataPointIndex]?.year}&group=${searchParams?.get('group')}&teams=${searchParams?.get('teams')}&module=${4}&sub_module=${4.2}&type=${'eventType'}&filterByRange=${searchParams?.get('filterByRange')}&dataTag=${searchParams?.get('dataTag') || selAnaScoreCardFilters?.dataTag || ''}&dataTagsOperator=${searchParams?.get('dataTagsOperator') || selAnaScoreCardFilters?.dataTagsOperator || ''}&sbVal=4.2`)
//                 }
//             },
//             toolbar: {
//                 show: false
//             },
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: '45%',
//                 distributed: true,
//             }
//         },
//         dataLabels: {
//             enabled: true
//         },
//         legend: {
//             show: false
//         },
//         xaxis: {
//             categories: timeRangeLabel,
//             labels: {
//                 style: {
//                     fontSize: '12px'
//                 }
//             },
//             title: {
//                 text: `${timeBtnGroup[0]?.toUpperCase() + timeBtnGroup?.slice(1).toLowerCase()}`,
//                 offsetY: 80,
//                 style: {
//                     fontSize: '12px',
//                     fontWeight: '400',
//                 },
//             }
//         },
//         yaxis: {
//             title: {
//                 text: 'Percentage (%)'
//             },
//             max: 100
//         },
//         fill: {
//             opacity: 1
//         },
//         stroke: {
//             show: true,
//             width: 2
//         },
//         tooltip: {
//             custom: ({ series, seriesIndex, dataPointIndex, w }) => {
//                 var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
//                 return `<ul style="font-size: 12px;padding:5px;text-align: left; ">
//               <li><span>${timeBtnGroup === "quarter" ? "Quarter" : timeBtnGroup} ${w.globals.categoryLabels[dataPointIndex]}:</span> <span>${data}%</span>  </li>
//               </ul>`;
//             },
//             shared: false,
//             intersect: true,
//         },
//         markers: {
//             size: 5
//         }
//     }
//     return <>
//         <ReactApexChart
//             type="line"
//             refrence={componentRef21}
//             series={dataLineChart}
//             options={lineChartOptions}
//             height={364}
//         />
//     </>
// }

// export const ReactStackedChart = () => {
//     const optionStacked = {

//         series: seriesData?.length > 0 ? seriesData : [],
//         options: {
//             colors: ['#eb4d4b', '#ffcb4e'],
//             chart: {

//                 stacked: true,
//                 type: 'bar',

//                 toolbar: {
//                     show: false
//                 },
//             },
//             plotOptions: {
//                 bar: {
//                     horizontal: false,
//                     columnWidth: "80%",
//                 },
//             },
//             dataLabels: {
//                 enabled: false,

//             },
//             stroke: {
//                 show: true,
//                 width: 2,
//                 colors: ['transparent']
//             },
//             xaxis: {
//                 show: true,
//                 categories: categoryData?.length > 0 ? categoryData : [],
//                 title: {
//                     text: 'Groups',
//                     offsetY: 88,
//                     style: {
//                         fontSize: "12px"
//                     }
//                 },
//                 axisBorder: {
//                     show: true,
//                     color: "#535c68",
//                 },
//             },
//             yaxis: {
//                 // max:100,
//                 // tickAmount: 4,
//                 show: true,
//                 title: {
//                     text: "Count",
//                     offsetX: 0,
//                     offsetY: 0,
//                     style: {
//                         color: undefined,
//                         fontSize: '14px',
//                         fontFamily: 'Helvetica, Arial, sans-serif',
//                         fontWeight: 600,
//                         cssClass: 'apexcharts-yaxis-title',
//                     },

//                 },
//                 axisBorder: {
//                     show: true,
//                     color: "#535c68"
//                 },
//                 labels: {
//                     formatter: (val) =>
//                         Math.round(val)

//                 },
//             },
//             fill: {
//                 opacity: 1
//             },
//             noData: {
//                 text: "No data found for the data range selected.",
//                 align: 'center',
//                 verticalAlign: 'middle',
//                 offsetX: 0,
//                 offsetY: 0,
//                 style: {
//                     fontSize: '18px',
//                     fontWeight: "bold"
//                 }
//             },
//             legend: { showForSingleSeries: true, fontSize: '12px', position: "top", horizontalAlign: "right", height: 60 },

//             // tooltip: {
//             //     custom: ({ series, seriesIndex, dataPointIndex, w }) =>
//             //     (

//             //     )
//             // },
//             title: {
//                 text: '',
//                 align: 'middle',
//                 margin: 0,
//                 offsetX: 0,
//                 offsetY: 0,
//                 floating: false,
//                 style: {
//                     fontSize: '16px',
//                     fontWeight: 'bold',
//                     color: 'black'
//                 },
//             },
//         },
//     };
//     return <>
//     </>
// }