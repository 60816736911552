// MATERIAL IMPORTS
import {
    Alert, AlertTitle,
    Button, Box,
    Paper, Typography
} from '@material-ui/core';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// HOOK IMPORTS
// COMPONENT IMPORTS
import Page from '../../components/Page';
import { ScoreBlock } from './scoreblock';
import { MainBlockV2 } from '../components-overview/MainBlockV2';
import { URLFront } from "../../backend";
// import ScoreGroupTable from "../components-overview/material-ui/table/score/scretble";
// import { getAllGroups } from "../../redux/action/staffAction";
// import { PATH_DASHBOARD } from "../../routes/paths";
// API IMPORTS
import { deleteDraftApi } from "../../_apis_/authApi.js/empapi";
// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

ScoreWrapper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    link: PropTypes.array,
    draftData: PropTypes.array,
    fnDraft: PropTypes.func
}


export default function ScoreWrapper({ children, title, link, draftData, fnDraft }) {
    // // const dispatch = useDispatch();
    // // useEffect(() => {
    // //     dispatch(getAllGroups(1))
    // // }, [])
    // const { themeStretch } = useSettings();
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATE
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <Page title={`${title} | BeyondQA`}>

            {/* <HeaderBreadcrumbs
                sx={{ pl: 4, ml: 3 }}
                links={link}

            /> */}

            <Box
                component={Paper}
                elevation={5}
                sx={{ backgroundColor: (theme) => theme.palette.background.paper, width: '100%', borderRadius: '5px', my: 3 }}
            >

                <MainBlockV2 title={title} MBLinks={link} buttonType={searchParams.get("draftType") === "10" ? <Button sx={{ py: ' 12px', px: 3, display: { sm: 'inline', xs: 'none' } }} onClick={() => { navigate("/score/selectDraft?draftType=10,20") }} variant="contained">View Draft</Button> : draftData?.length > 0 && <Button size='small' sx={{ py: '12px', px: 3, display: { sm: 'inline', xs: 'none' } }} onClick={() => { navigate("/score/selectDraft?draftType=20") }} variant="contained">View Draft</Button>} buttonValue={searchParams.get("draftType") === "10" ? <Button size='small' sx={{ fontSize: '10px', display: { xs: 'inline', sm: 'none' } }} onClick={() => { navigate("/score/selectDraft?draftType=10,20") }} variant="contained">View Draft</Button> : draftData?.length > 0 && <Button sx={{ fontSize: '10px', display: { xs: 'inline', sm: 'none' } }} onClick={() => { navigate("/score/selectDraft?draftType=20") }} variant="contained">View Draft</Button>}>
                    {(draftData?.length > 0 && (!loggedUserData?.staff || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30)) && <Alert sx={{ mb: 1, mt: { sm: 0, xs: 1 } }} severity="warning">
                        <AlertTitle sx={{ fontSize: { xs: '12px', sm: '15px' } }} >Recent Draft Available!</AlertTitle>

                        <Typography sx={{ fontSize: { xs: '10px', sm: '13px' } }} >
                            You have already started a score for {draftData[0]?.staff?.name}, on {draftData[0]?.score_card?.name} Scorecard. Would you prefer to pick up where you left off?<Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: "capitalize", p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={() => { window.location.href = `${URLFront}/score/reviewPerformance?gpId=${draftData[0]?.groups?.id}&staff_type=${draftData[0]?.staff?.staff_type}&empId=${draftData[0]?.staff?.id}&score_card_Id=${draftData[0]?.score_card?.id}&draftId=${draftData[0]?.id}&toScore=True`; }}>&nbsp;Resume</Button> your old score, or <Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: 'lowercase', p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={async () => { await deleteDraftApi(draftData[0]?.id); fnDraft() }}>delete</Button> the draft.
                        </Typography>

                    </Alert>}

                    {(draftData?.length > 0 && loggedUserData?.staff?.staffType === 10) && <Alert sx={{ mb: 1, mt: { sm: 0, xs: 1 } }} severity="warning">
                        <AlertTitle sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Recent Draft Available!</AlertTitle>
                        <Typography sx={{ fontSize: { xs: '10px', sm: '13px' } }} >
                            You have already started a score for {draftData[0]?.score_card?.name} Scorecard. Would you prefer to pick up where you left off?<Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: "capitalize", p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={() => { window.location.href = `${URLFront}/score/reviewPerformance?gpId=${draftData[0]?.groups?.id}&staff_type=${draftData[0]?.staff?.staff_type}&empId=${draftData[0]?.staff?.id}&score_card_Id=${draftData[0]?.score_card?.id}&draftId=${draftData[0]?.id}&toScore=True`; }}>&nbsp;Resume</Button> your old score, or <Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: 'lowercase', p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={async () => { await deleteDraftApi(draftData[0]?.id); fnDraft() }}>delete</Button> the draft.
                        </Typography>
                    </Alert>}
                    {/* <Box component={Paper} elevation={5} sx={{ borderRadius: '5px', backgroundColor: theme => theme.palette.background.paper, }}> */}
                    {children}
                    {/* </Box> */}
                </MainBlockV2>
            </Box>
        </Page>
    )
}