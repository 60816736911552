import React from 'react';

// REACT ROUTER IMPORTS
import { useNavigate, useSearchParams } from "react-router-dom";

// MATERIAL UI IMPORT
import {
    Button, Box, CircularProgress, Divider, InputAdornment, Stack,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TablePagination,
    Typography, OutlinedInput
} from "@material-ui/core";

// MATERIAL STYLED
import { styled } from '@material-ui/core/styles';

// OTHER NPM IMPORTS
import moment from "moment";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useSnackbar } from 'notistack5';


// OTHER COMPONENT IMPORTS
import Scrollbar from '../../../../../../components/Scrollbar';
import { MIconButton } from '../../../../../../components/@material-extend';
import AnalyticsWrapper from "../../../../../dashboard/analyticswrapper";
import { PATH_DASHBOARD } from '../../../../../../routes/paths';

// Context IMPORTS
import ScoreCardsContext from "../../../../../../contexts/ScoreCardsContext";
import { AnalyticContext } from "../../../../../../contexts/AnalyticContext";

// API IMPORTS
import { getAcceptanceByGroupApi, getAcceptanceByGrpDownloadXLS } from "../../../../../../_apis_/authApi.js/analytic_api";


// STYLED COMPONENT
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 45,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function AcceptanceGroupTable() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // CONTEXT STATES
    const { getScoreLogGroups, getScoreLogScorecards } = React.useContext(ScoreCardsContext);
    const { setSelectedGroup, setSelectedTeam, setSelectedGroupFromWeek, setSelectedGroupToWeek, setSelectedHighLightBelow, selAnaGroupFilters, setSelAnaGroupFilters, pageAccpGrp, setSubModLabel, setPageAccpGrp, filterNameAcceptanceByGrp, setFilterNameAcceptanceByGrp, isLoading, setIsLoading, count, setCount, acceptanceByGrpData, setacceptanceByGrpData, selectedDateRange, setSelectedDateRange, selectedFilter, setSelectedFilter, selectedScoreCard, setSelectedScoreCard, selectedEventType, setSelectedEventType, selectedEventSubType, setSelectedEventSubType, selectedEvaluator, setSelectedEvaluator, selectedDate, setSelectedDate, selectedWeek, setSelectedWeek } = React.useContext(AnalyticContext);

    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // STATES
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [supGrp, setSupGrp] = React.useState(loggedUserData?.groups?.map(obj => obj.id));

    // USE-EFFECT METHODS
    React.useEffect(() => {
        setacceptanceByGrpData([]);
        setIsLoading(true);
        setSubModLabel(6.7)
        if (!searchParams.get('fromDate')) {
            fetchAcceptanceByGrpData(1);
        }
        if (searchParams.get('fromDate')) {
            // setSelectedGroup(searchParams.get('group') === '' ? '' : Number(searchParams.get('group')));
            setSelectedGroup('');
            setSelectedTeam(searchParams.get('teams'));
            setSelectedDateRange(Number(searchParams.get('dateRange')));
            setSelectedDate({ date1: moment(searchParams.get('fromDate')).format('YYYY-MM-DD'), date2: moment(searchParams.get('toDate')).format('YYYY-MM-DD') });
            setSelectedFilter(searchParams.get('filter_By'));
            setSelectedScoreCard(searchParams.get('score_card') === '' ? '' : Number(searchParams.get('score_card')));
            setSelectedEventType(searchParams.get('event_Type'));
            setSelectedEventSubType(searchParams.get('sub_type'));
            setSelectedEvaluator(searchParams.get('evaluator'));
            setSelectedGroupFromWeek(searchParams.get('fromWeek'));
            setSelectedGroupToWeek(searchParams.get('toWeek'));


            const filterObj = {
                // group: searchParams.get('group') === '' ? '' : Number(searchParams.get('group')),
                group: '',
                teams: searchParams.get('teams'),
                dateRange: searchParams.get('dateRange'),
                fromWeek: searchParams.get('fromWeek'),
                toWeek: searchParams.get('toWeek'),
                fromDate: moment(searchParams.get('fromDate')).format('YYYY-MM-DD'),
                toDate: moment(searchParams.get('toDate')).format('YYYY-MM-DD'),
                score_card: searchParams.get('score_card') === '' ? '' : Number(searchParams.get('score_card')),
                filter_By: searchParams.get('filter_By'),
                event_Type: searchParams.get('event_Type'),
                sub_type: searchParams.get('sub_type'),
                evaluator: searchParams.get('evaluator'),
                highlightBelow: searchParams.get('highlightBelow'),
                dataTag: searchParams?.get('dataTag') || '',
                dataTagsOperatr: searchParams.get('dataTagsOperator') || ''

            }

            setSelAnaGroupFilters(filterObj);
            fetchCustomAcceptanceByGrpData(filterObj, 1)
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.group, filterBy: filterObj?.filter_By })
            getScoreLogScorecards({ ...filterObj, group: filterObj?.group, staff: '', sup_grp: '', filterBy: filterObj?.filter_By, eventType: filterObj?.event_Type, eventTypeEventSubType: filterObj?.sub_type, scoreCard: filterObj?.score_card, scoreCardCategory: '' })

        }
    }, []);

    const fetchCustomAcceptanceByGrpData = async (obj, pgNo, query) => {
        setacceptanceByGrpData([]);
        setIsLoading(true);
        try {

            const { data } = await getAcceptanceByGroupApi(obj?.fromDate, obj?.toDate, obj?.filter_By, obj?.score_card === 'all' ? '' : obj?.score_card, obj?.event_Type === 'all' ? '' : obj?.event_Type, obj?.sub_type === 'all' ? '' : obj?.sub_type, (obj?.evaluator === 'all' || obj?.evaluator === undefined) ? '' : obj?.evaluator, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), pgNo, query || "", obj?.dataTag, obj?.dataTagsOperator);
            setacceptanceByGrpData(data?.results);
            setCount(data?.count);
            setIsLoading(false);
        } catch (err) {
            setacceptanceByGrpData([]);
            setCount(0);
            setIsLoading(false);
        }
    }

    const fetchAcceptanceByGrpData = async (pgNo, query) => {
        setacceptanceByGrpData([]);
        setIsLoading(true);
        try {

            // const { data } = await getAcceptanceByGroupApi(selAnaGroupFilters?.fromDate, selAnaGroupFilters?.toDate, selAnaGroupFilters?.filter_By, selAnaGroupFilters?.score_card === 'all' ? '' : selAnaGroupFilters?.score_card, selAnaGroupFilters?.event_Type === 'all' ? '' : selAnaGroupFilters?.event_Type, selAnaGroupFilters?.sub_type === 'all' ? '' : selAnaGroupFilters?.sub_type, (selAnaGroupFilters?.evaluator === 'all' || selAnaGroupFilters?.evaluator === undefined) ? '' : selAnaGroupFilters?.evaluator, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), pgNo, query || "");
            const { data } = await getAcceptanceByGroupApi(moment(selectedDate?.date1).format('YYYY-MM-DD'), moment(selectedDate?.date2).format('YYYY-MM-DD'), selectedFilter, selectedScoreCard === 'all' ? '' : selectedScoreCard, selectedEventType === 'all' ? '' : selectedEventType, selectedEventSubType === 'all' ? '' : selectedEventSubType, selectedEvaluator === 'all' ? '' : selectedEvaluator, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), pgNo, query || "", searchParams?.get('dataTag') || '', searchParams?.get('dataTagsOperator') || '');
            setacceptanceByGrpData(data?.results);
            setCount(data?.count);
            setIsLoading(false);
            if (data?.results?.length > 0) {
                setSearchParams({
                    ...selAnaGroupFilters,
                    'dateRange': selectedDateRange,
                    "evaluator": selectedEvaluator
                });
            }
        } catch (err) {
            setacceptanceByGrpData([]);
            setCount(0);
            setIsLoading(false);
        }
    }

    // METHODS
    const handleChangePage = (event, newPage) => {
        fetchAcceptanceByGrpData(newPage + 1, filterNameAcceptanceByGrp);
        setPageAccpGrp(newPage);
    }

    const handleFilterByName = (event) => {
        setPageAccpGrp(0);
        setFilterNameAcceptanceByGrp(event.target.value);
        setIsLoading(true);
        setacceptanceByGrpData([]);
        return fetchAcceptanceByGrpData(1, event.target.value);
    }

    const handleDownloadAcceptanceByGroup = async () => {
        try {
            const res = await getAcceptanceByGrpDownloadXLS(moment(selectedDate?.date1).format('YYYY-MM-DD'), moment(selectedDate?.date2).format('YYYY-MM-DD'), selectedFilter, selectedScoreCard === 'all' ? '' : selectedScoreCard, selectedEventType === 'all' ? '' : selectedEventType, selectedEventSubType === 'all' ? '' : selectedEventSubType, selectedEvaluator === 'all' ? '' : selectedEvaluator, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : "",searchParams?.get('dataTag') || '', searchParams?.get('dataTagsOperator') || ''));
            enqueueSnackbar('Downloading File...', {
                variant: 'info',
                autoHideDuration: 1800,
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            const outputFilename = `Result_Acceptance_by_group_.xlsx`;
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            console.log(err.message);
        }
    }




    const mbLink = [{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: 'Analytics', href: "/analytics" }, { name: "Group", href: "", disableLink: 'True' }, { name: "6.7 Result Acceptance By Group" }]
    return (
        <>
            <AnalyticsWrapper val="6" sbVal="6.7" subModule="6.7 Result Acceptance By Group" LabelName="Group" brdCrmbLink={mbLink} dwnldFn={handleDownloadAcceptanceByGroup}>
                <Box>
                    <Stack sx={{ width: "100%", px: 2 }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                        <SearchStyle
                            value={filterNameAcceptanceByGrp}
                            onChange={handleFilterByName}
                            placeholder="Search..."
                            startAdornment={
                                <>
                                    <InputAdornment position="start">
                                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                </>
                            }
                        />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                            <TablePagination
                                sx={{ border: "1px solid transparent" }}
                                rowsPerPageOptions={100}
                                component="div"
                                count={count || 0}
                                rowsPerPage={rowsPerPage}
                                page={pageAccpGrp}
                                onPageChange={handleChangePage}

                            />
                        </Box>
                    </Stack>
                    <Divider />
                    <Box>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                                <Table size='small' sx={{
                                    "& .MuiTableCell-root": {
                                        border: (theme) => `1px solid ${theme.palette.text.primaryBorder}`,

                                    },

                                    "& .MuiTableCell-root:first-of-type": {
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        boxShadow: 'none',
                                    },
                                    "& .MuiTableCell-root:last-of-type": {
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        boxShadow: 'none',
                                    },
                                }}>

                                    <TableHead>
                                        <TableRow sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                py: 2,
                                            },
                                        }}>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                                Group
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                                Viewed
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                Accepted
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                Request 1:1
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                Reviewed
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                No Action
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                Total Results
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {acceptanceByGrpData?.length > 0 && (acceptanceByGrpData?.map((row, index) => <TableRow key={index}>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                                <Button onClick={() => { navigate(`/analytics/acceptance_by_team?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&link=Groups`) }} size="small" disableRipple>{row?.score_card__groups__name}</Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center" padding='normal'>
                                                <Button
                                                    onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&viewed=1&link=Groups`) }} size="small" disableRipple>
                                                    {`${((row?.viewed / row?.count) * 100)?.toFixed(2) || 0}%`}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Button
                                                    onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&accepted=1&link=Groups`) }} size="small" disableRipple>
                                                    {`${((row?.accepted / row?.count) * 100)?.toFixed(2) || 0}%`}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Button
                                                    onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&one2one=1&link=Groups`) }} size="small" disableRipple>
                                                    {`${((row?.onetoone / row?.count) * 100)?.toFixed(2) || 0}%`}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Button
                                                    onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&reviewed=1&link=Groups`) }} size="small" disableRipple>
                                                    {`${((row?.reviewed / row?.count) * 100)?.toFixed(2) || 0}%`}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Button
                                                    onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&noaction=${true}&link=Groups`) }} size="small" disableRipple>
                                                    {`${((row?.no_action / row?.count) * 100)?.toFixed(2) || 0}%`}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Button onClick={() => { navigate(`/analytics/group/result_acceptance_by_group?date1=${selectedDate?.date1}&date2=${selectedDate?.date2}&dateRange=${selectedDateRange}&filter=${selectedFilter}&scorcard=${selectedScoreCard}&envnt=${selectedEventType}&subEvnt=${selectedEventSubType}&isTeamResult=False&eval=${selectedEvaluator}&scorecard_group=${row?.score_card__groups}&dataTag=${searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || ''}&link=Groups&highlightBelow=${searchParams.get('highlightBelow')}&fromWeek=${searchParams.get('fromWeek')}&toWeek=${searchParams.get('toWeek')}`) }} size="small" disableRipple>
                                                    {row?.count}
                                                </Button>

                                            </TableCell>
                                        </TableRow>)
                                        )}
                                        {acceptanceByGrpData?.length === 0 && (
                                            <TableRow>

                                                {isLoading ? <TableCell colSpan={12}>
                                                    <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                        <CircularProgress /> Loading Data...
                                                    </Box>
                                                </TableCell> : <TableCell colSpan={12}>
                                                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                                        No Data Found
                                                    </Typography>
                                                </TableCell>}

                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Box>
                </Box>
            </AnalyticsWrapper>

        </>
    )
}