import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import adminReducer from './slices/ai-slices/admin';
import eventsReducer from './slices/ai-slices/events';

import { authReduceR } from './reducer/authReducer';
import { scoreReduceR } from "./reducer/scoreReducer";
import { empSummaryReduceR, empActivityReduceR } from './reducer/empReducer';
import { AllStaffReduceR, listTeamReduceR, EmpReduceR, SupReduceR, GroupReduceR, TeamReduceR, TaskReduceR, GoalReduceR, restoreReduceR, listGrpReduceR, listSupReduceR } from './reducer/staffReducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  info: authReduceR,
  staff: AllStaffReduceR,
  groups: GroupReduceR,
  teams: TeamReduceR,
  tasks: TaskReduceR,
  goals: GoalReduceR,
  restoreItem: restoreReduceR,
  emps: EmpReduceR,
  sups: SupReduceR,
  scoreList: scoreReduceR,
  empSummary: empSummaryReduceR,
  empActivity: empActivityReduceR,
  stateSupList: listSupReduceR,
  stateGrpList: listGrpReduceR,
  stateTeamList: listTeamReduceR,
  admin: adminReducer,
  events: eventsReducer,
});

export { rootPersistConfig, rootReducer };
