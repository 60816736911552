// Staff Types
export const GetSaff = "All_Staff";
export const AddStaff = "Add_Staff";
export const EditStaff = "Edit_Staff";
export const DelStaff = "Del_Staff";
export const AddSup = "ADD_Supervisor";
export const EditSup = "Edit_Supervisor";
export const DelSup = "Del_Supervisor";
export const RestoreStaff = "Res_Emp";


// Emp
export const GetEmps = "GETEMPs";
export const AddEmps = "AddEmps";
export const EditEmps = "EditEmps";
export const DelEmps = "DelEmps";
export const AccessEmp = "AccessEmps";

// supervisor
export const GetSups = "GETSups";
export const AddSups = "AddSups";
export const EditSups = "EditSups";
export const DelSups = "DelSups";
export const AccessSup = "AccessSups";




// Task Types
export const GetTasks = "All_Tasks";
export const NewTask = "New_Task";
export const EditTask = "Edit_Task";

// teams types
export const GetTeams = "All_Teams";
export const AddTeams = "New_Team";
export const EditTeams = "Edit_Team";
export const DelTeams = "Del_Team";

// Group TYPES
export const GetGroups = "All_Groups";
export const AddGroup = "New_Group";
export const EditGroup = "Edit_Group";
export const DelGroup = "Del_Group";

// GOAL TYPES

export const getGoals = "All_Goals";
export const AddGoal = "Add_Goal";
export const EditGoal = "Edit_Goal";
export const DelGoal = "Del_Goal";


// ALL LIST DATAS

export const listAllTypeSup = "ALL_SUPERVISOR_LIST";
export const listAllTypeGrp = "ALL_Group_LIST";
export const listAllTypeTeam = "ALL_Team_LIST";