/* eslint-disable react-hooks/rules-of-hooks */
import * as Yup from 'yup';
import { useState, useContext, useEffect, useMemo } from 'react';
// 
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import moment from 'moment'
// material
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles';
import {
  Stack,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
  Divider,
  Box, Radio,
  Typography,
  OutlinedInput,
  Autocomplete,
  Chip
} from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSearchParams } from 'react-router-dom';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext'
import { AnalyticContext } from '../../../../contexts/AnalyticContext';
import { SettingsContext } from "../../../../contexts/SettingsContext"
import { getSectionByResultApi, getAcceptanceByAgentApi, getSectionTrendByIndividualApi, getCausesByStaffApi, getSectionByEventTypeApi } from "../../../../_apis_/authApi.js/analytic_api";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle:
  {
    padding: 5
  },
  select: {
    backgroundColor: "#FFFFFF"
  },
  selectDisabled: {
    backgroundColor: "#E9ECEF"
  },
  textField: {
    backgroundColor: "#FFFFFF"
  },
  textFieldLock: {
    backgroundColor: "#E9ECEF"
  },
  selectLock: {
    backgroundColor: "#E9ECEF"
  }
}));

// ----------------------------------------------------------------------

export default function AnalyticsFilterDialog({ addUserButton, selectedRow, setAddMode, closeAddUserDialog, fromSnapshot, fromAnalDashboard, scorecardId }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateRange = [
    {
      "id": 1,
      "dateRange": "Today"
    },
    {
      "id": 2,
      "dateRange": "Yesterday"
    },
    {
      "id": 3,
      "dateRange": "This Week"
    },
    {
      "id": 4,
      "dateRange": "Last Week"
    },
    {
      "id": 5,
      "dateRange": "This Month"
    },
    {
      "id": 6,
      "dateRange": "Last Month"
    },
    {
      "id": 7,
      "dateRange": "This Quarter"
    },
    {
      "id": 8,
      "dateRange": "Last Quarter"
    },
    {
      "id": 9,
      "dateRange": "This Year"
    },
    {
      "id": 10,
      "dateRange": "Last Year"
    },
    {
      "id": 11,
      "dateRange": "Custom Date Range"
    },
  ]

  const filterBy = [
    {
      "id": 1,
      "type": "Score Date"
    },
    {
      "id": 2,
      "type": "Event Date"
    },
    {
      "id": 3,
      "type": "Last Edit Date"
    }
  ]

  const formulaType = [
    {
      id: 10,
      type: 'True'
    },
    {
      id: 20,
      type: 'Potential'
    }
  ]

  // const { userLevels, groups, teams, getUserLevels, getGroups, getTeams, addUser, getAllUsers, editUser, resetPassword } = useContext(AddUserContext);
  const { getScoreLogGroups, reportsScoreLogGroups, getScoreLogScorecards, scorecardSection, getScorecardSection, reportsScoreLogScorecards, getScoreLogEventType, reportsScoreLogEventType, getScoreLogEventSubType, reportsScoreLogEventSubType, getScoreLogTeams, reportsScoreLogTeams, getScoreLogSupervisor, reportsScoreLogSupervisor, getScoreLogEmployee, reportsScoreLogEmployee, getScoreLogCategory, reportsScoreLogCategory, getScoreLogEvaluator, reportsScoreLogEvaluator, applyReportFilter, setselectedScoreFilters, setScoreFiltersEnabled, viewSnapShot, getSectionAnswerByStaff, sectionAnswerByStaff, questionsAnswerByStaff, getQuestionsAnswerByStaff, getQuestionByStaffFormula, getAnswerByStaffFormula, formulaQuestByStaff, formulaAnsByStaff } = useContext(ScoreCardsContext);
  const { setSectionByResultLoading, getKudosByStaff,
    setSectionByResultData, setSectionByEventTypeLoading,
    setSectionByEventTypeData, setIsLoadingTeams,
    setIsSectionLoading, setCausesByStaffData, setCausesByStaffLoading,
    dateType, setSwitchView, getQuestionTrendIndividual, selScDuration,
    setSectionTrndData, setPageAccpAgnt, dataTagByScoreCard,
    selectedDateAnaRange, setSelectedAnaDateRange,
    selectedFromDate, selectedToDate, setSelectedToDate, setSelectedFromDate,
    selectedFilterBy, setSelectedFilterBy, setFilterNameAcceptanceByAgnt,
    agentData, setAgentData, setLoadingAgent,
    setCountAgent, selAnaFilters, setSelAnaFilters, getStaffTrend, selectedFilterEventType,
    getStaffTrendDistribution, getStaffTrendAboBelow, setSelectedFilterEventType,
    subModLabel, selectedStaFilter, dateTypeFlag, setSelectedFilterSubType,
    setselectedStaFilter, selectedGroupFilter, setSelectedGroupFilter, selectedFilterSubType,
    selectedTeamFilter, setSelectedTeamFilter, selectedSupervisorFilter,
    setSelectedSupervisorFilter, getStaOverTrendByInd, selectedStaffFilter,
    setSelectedStaffFilter, getQuestionByStaff,
    getAnswerByStaff, getAnswerByStaffDistribution, getDataTagsByScoreCard, getKudosByStaffWithDataTags,
    dateTypeSecTrend, setSelectedStaffFromDate,
    selectedScoreCardSingle, setSelectedScoreCardSingle,
    setSelectedStaffFromWeek, setSelectedStaffToDate,
    selectedStaffToWeek, selectedStaffFromWeek,
    setSelectedStaffToWeek, selScDurationInd, selectedDataTag, setselectedDataTag,
    selectedFilterByValue, setSelectedFilterByValue,
    selectedFilterEventTypeStaffTrend,
    selectedFilterSubTypeStaffTrend,
    selectedEventTypeFilter, setSelectedEventTypeFilter,
    selectedEventSubTypeFilter, setSelectedEventSubTypeFilter,
    // filter state for section field
    selectedSectionFilter,
    setSelectedSelectionFilter,
    // filter state for question field
    selectedQuestionFilter, setSelectedQuestionFilter
  } = useContext(AnalyticContext);

  const { showSidebar, setShowSidebar, dataCreatedFrom, setDataCreatedFrom } = useContext(SettingsContext);

  const [open, setOpen] = useState(false);

  const [userLev, setUserLev] = useState([]);

  const [advanceFilters, setAdvanceFilters] = useState();
  const [customFilters, setCustomFilters] = useState(false);

  const [isAnyOfTag, setIsAnyOfTag] = useState(true)
  const [isAllOfTag, setIsAllOfTag] = useState(false)
  const [fromWeekNo, setFromWeekNo] = useState([]);
  const [toWeekNo, setToWeekNo] = useState([]);
  const classes = useStyles();

  const isMountedRef = useIsMountedRef();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const grpArr = loggedUserData?.groups?.map(obj => obj.id);

  useEffect(() => {
    const groupFilterObj = {
      fromDate: selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedFilterByValue || "created_at",
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };
    const scoreCardfilterObj = {
      fromDate: selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedFilterByValue || "created_at",
      group: selectedGroupFilter || "",
      teams: subModLabel === 1.4 ? "" : selectedTeamFilter || "",
      scoreCard: selectedScoreCardSingle || '',
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: values.supervisor,
      staff: values.staff,
      scoreCardCategory: values.scoreCardCategory,
      evaluator: values.evaluator,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };
    // if (subModLabel !== 1.6 && subModLabel !== 1.9) {
    getScoreLogGroups(groupFilterObj);
    getScoreLogScorecards(scoreCardfilterObj);
    // }
  }, []);

  useEffect(() => {

    if (open) {
      if (selectedDateAnaRange) {
        if (subModLabel === 1.2) {
          if (selectedDateAnaRange === 'Last Year' ||
            selectedDateAnaRange === 'This Year') {
            setFieldValue("dateRange", 'This Month');
            setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
            setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
            setSelectedFromDate(moment().startOf('month').format('YYYY-MM-DD'))
            setSelectedToDate(moment().endOf('month').format('YYYY-MM-DD'))
            setSelectedAnaDateRange('This Month');
            // re-setting group & scorecard ---
            // setFieldValue('scoreCardSingle', '')
            // setFieldValue("scoreCard", []);
            // setFieldValue("dataTag", []);
            // setselectedDataTag()
            // setselectedStaFilter([]);
            // setSelectedScoreCardSingle()

          } else if (selectedDateAnaRange === 'Custom Date Range') {
            setFieldValue("dateRange", selectedDateAnaRange);
            setFieldValue("fromDate", selectedFromDate);
            setFieldValue("toDate", selectedToDate);
          } else {
            setFieldValue("dateRange", selectedDateAnaRange);
            setFieldValue("fromDate", selectedFromDate);
            setFieldValue("toDate", selectedToDate);
          }
        } else {
          setFieldValue("dateRange", selectedDateAnaRange);
          setFieldValue("fromDate", selectedFromDate);
          setFieldValue("toDate", selectedToDate);
        }


      } else {
        setFieldValue("dateRange", 'This Month');
        setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setSelectedToDate(moment().endOf('month').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('This Month');
      }

      if (selectedDateAnaRange === 'Custom Date Range') {
        if (subModLabel === 1.2) {
          setFieldValue("dateRange", 'Custom Date Range');

          setCustomFilters(true);
          formik.setFieldValue("fromDate", selectedFromDate);
          formik.setFieldValue("toDate", selectedToDate);

          formik.setFieldValue("fromWeek", selectedStaffFromWeek);
          formik.setFieldValue("toWeek", selectedStaffToWeek);


        } else {
          setCustomFilters(true);
          formik.setFieldValue("fromDate", selectedFromDate);
          formik.setFieldValue("toDate", selectedToDate);
          formik.setFieldValue("fromWeek", selectedStaffFromWeek);
          formik.setFieldValue("toWeek", selectedStaffToWeek);
        }

      }

      if (selectedFilterBy) {
        setFieldValue("filterByRange", selectedFilterBy);
        setFieldValue("filterBy", selectedFilterByValue);
        switch (selectedFilterBy) {
          case 'Score Date':
            setFieldValue("filterBy", 'created_at');
            break;
          case 'Event Date':
            setFieldValue("filterBy", 'event_date');
            break;
          case 'Last Edit Date':
            setFieldValue("filterBy", 'updated_at');
            break;
          default:
            setFieldValue("filterBy", 'created_at');
            break;
        }

      } else {
        setFieldValue("filterByRange", 'Score Date');
        setFieldValue("filterBy", 'created_at');
      }

      if (selectedGroupFilter || searchParams.get('group')) {
        setFieldValue("group", (selectedGroupFilter || searchParams.get('group')));
      }
      else {
        setFieldValue("group", '');
      }

      if (selectedTeamFilter) {
        setFieldValue("teams", selectedTeamFilter);
      }
      else {
        setFieldValue("teams", '');
      }

      if (selectedSupervisorFilter) {
        setFieldValue("supervisor", selectedSupervisorFilter);
      }
      else {
        setFieldValue("supervisor", '');
      }

      if (selectedStaffFilter) {
        // setFieldValue("staff", reportsScoreLogEmployee?.includes(selectedStaffFilter) ? selectedStaffFilter : '');
        setFieldValue("staff", selectedStaffFilter);
      }
      else {
        setFieldValue("staff", '');
      }
      if (selectedStaFilter) {
        setFieldValue("scoreCard", selectedStaFilter);
        const filterScoreCardObj = {
          "fromDate": searchParams.get('fromDate') || moment(values.fromDate).format('YYYY-MM-DD'),
          "toDate": searchParams.get('toDate') || moment(values.toDate).format('YYYY-MM-DD'),
          "filterBy": values.filterBy || searchParams.get('filterBy'),
          "scoreCard": selectedStaFilter ?? '',
        }
        getDataTagsByScoreCard(filterScoreCardObj);
      } else {
        setFieldValue("scoreCard", '');
      }

      if ((subModLabel === 1.5 || subModLabel === 1.11 || subModLabel === 1.8 || subModLabel === 1.7 ||
        subModLabel === 1.6 || subModLabel === 1.2 || subModLabel === 1.3 ||
        subModLabel === 1.31 || subModLabel === '1.10') && selectedScoreCardSingle) {
        setFieldValue("scoreCardSingle", selectedScoreCardSingle || searchParams.get('scoreCard'));
        const filterScoreCardObj = {
          "fromDate": searchParams.get('fromDate') || moment(values.fromDate).format('YYYY-MM-DD'),
          "toDate": searchParams.get('toDate') || moment(values.toDate).format('YYYY-MM-DD'),
          "filterBy": values.filterBy || searchParams.get('filterBy'),
          "scoreCard": selectedScoreCardSingle ?? '',
        }
        getDataTagsByScoreCard(filterScoreCardObj);
      } else {
        setFieldValue("scoreCardSingle", '');
      }

      if (selAnaFilters?.scoreCardSingle === undefined && subModLabel === 1.11) {
        setFieldValue("scoreCardSingle", '');
      }


      // need to save data tag in local storage to show data tag on open
      if (selectedDataTag) {
        setFieldValue("dataTag", selectedDataTag);
      }
      if (searchParams?.get('dataTagsOperator') === '20') {
        setFieldValue('allOfTag', '20')
        setIsAllOfTag(true)
        setFieldValue('anyOfTag', '')
        setIsAnyOfTag(false)

      } else if (searchParams?.get('dataTagsOperator') === '10') {
        setFieldValue('anyOfTag', '10')
        setIsAnyOfTag(true)
        setIsAllOfTag(false)
        setFieldValue('allOfTag', '')

      } else {
        setFieldValue('anyOfTag', '10')
        setIsAnyOfTag(true)
        setIsAllOfTag(false)
      }

      if (searchParams.get('highlightValue') || (selAnaFilters && selAnaFilters?.highlightValue !== undefined && selAnaFilters?.highlightValue !== null && selAnaFilters?.highlightValue !== '')) {
        setFieldValue("highlightValue", searchParams.get('highlightValue') || selAnaFilters?.highlightValue)
      } else {
        setFieldValue("highlightValue", "");
      }

      if (selectedFilterEventType) {
        setFieldValue("eventType", selectedFilterEventType);
      } else {
        setFieldValue("eventType", '');
      }

      if (selectedFilterSubType) {
        setFieldValue("eventTypeEventSubType", selectedFilterSubType);
      } else {
        setFieldValue("eventTypeEventSubType", '');
      }

      // for 1.8 event filter 
      if (selectedEventTypeFilter) {
        setFieldValue("eventType", selectedEventTypeFilter);
      }
      // else {
      //   setFieldValue("eventType", '');
      // }

      if (selectedEventSubTypeFilter) {
        setFieldValue("eventTypeEventSubType", selectedEventSubTypeFilter);
      }
      // else {
      //   setFieldValue("eventTypeEventSubType", '');
      // }
      // 1.8 module end

      // set value for section field
      if (selectedSectionFilter) {
        setFieldValue('section', selectedSectionFilter)
      } else {
        setFieldValue('section', '')

      }
      // set value for question field
      if (selectedQuestionFilter) {
        setFieldValue('question', selectedQuestionFilter)
      } else {
        setFieldValue('question', '')
      }

      if (searchParams.get('formula')) {
        setFieldValue('formula', searchParams.get('formula'))
      } else {
        setFieldValue('formula', '')
      }

      if (searchParams.get('highlightValue')) {
        setFieldValue('highlightValue', searchParams.get('highlightValue'))
      } else {
        setFieldValue('highlightValue', '')
      }
      setFieldValue("scoreCardCategory", '');
      setFieldValue("evaluator", '');
    }
  }, [open]);


  const ReportSchema = Yup.object().shape({
    scoreCard: (subModLabel === 1.4 || subModLabel === 1.1 || subModLabel === 1.9) ? Yup.number().required('This Value is required').nullable() : '',
    scoreCardSingle: (subModLabel === 1.6 || subModLabel === 1.8 || subModLabel === 1.7 || subModLabel === '1.10' || subModLabel === 1.2 || subModLabel === 1.3 || subModLabel === 1.31) ? Yup.number().required('This Value is required').nullable() : '',
    staff: (subModLabel === 1.4 || subModLabel === 1.5 || subModLabel === 1.8 || subModLabel === 1.7 || subModLabel === 1.6) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable(),
    group: (subModLabel === 1.4 || subModLabel === 1.6 || subModLabel === 1.1 || subModLabel === 1.8 || subModLabel === 1.7 || subModLabel === '1.10' || subModLabel === 1.5 || subModLabel === 1.2 || subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === 1.9) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      dateRange: '',
      filterByRange: '',
      fromDate: '',
      toDate: '',
      fromweekValue: '',
      toweekValue: '',
      filterBy: '',
      group: '',
      teams: '',
      scoreCard: [],
      scoreCardSingle: '',
      scoreCardSingle2: '',
      scoreCardName: [],
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: '',
      staff: '',
      scoreCardCategory: '',
      evaluator: '',
      highlightValue: '',
      reference: '',
      section: '',
      question: '',
      formula: 10,
      teamsId: searchParams.get('teams__id') || '',
      allOfTag: '',
      anyOfTag: '',
      dataTag: [],
      eventTypeEventType: '',
      eventSubTypeEventSubType: '',
      // dateRange: '',
    },
    validationSchema: ReportSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {

      try {

        const staffFilterObj =
        {
          "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
          "toDate": moment(values.toDate).format('YYYY-MM-DD'),
          "filterBy": values.filterBy,
          "group": values.group,
          "teams": values.teams,
          "scoreCard": (subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === 5.11 || subModLabel === 5.12) ? values.scoreCardSingle : values.scoreCard,
          "scoreCardGroups": values?.group,
          "eventType": values.eventType,
          "eventTypeEventSubType": values.eventTypeEventSubType,
          "staff": values.staff,
          "scoreCardCategory": values.scoreCardCategory,
          "evaluator": selAnaFilters?.evaluator || "",
          "page": "1",
          "filterName": "",
          "highlightValue": values.highlightValue,
          "flag": dateTypeSecTrend,
          "formula": values.formula,
          "reference": values.reference,
          "section": values.section,
          "supervisor": values.supervisor,
          "question": values.question,
          "teamsId": values.teamsId,
          "filterByRange": values.filterByRange,
          "eventTypeEventType": values.eventTypeEventType,
          "eventSubTypeEventSubType": values.eventSubTypeEventSubType,
        }

        setSelectedFilterEventType(values.eventType);
        setSelectedFilterSubType(values.eventTypeEventSubType);

        // setSelectedFilterEventTypeStaffTrend(values.eventTypeEventType);
        // setSelectedFilterSubTypeStaffTrend(values.eventSubTypeEventSubType);

        // persist filtered values on refresh
        localStorage.setItem("selectedAnaDateRange", JSON.stringify(selectedDateAnaRange || 'This Month'));
        localStorage.setItem("selectedFilterBy", JSON.stringify(selectedFilterBy || 'Score Date'));
        localStorage.setItem("selectedFilterByValue", JSON.stringify(selectedFilterByValue || "created_at"));
        localStorage.setItem("selectedFromDate", JSON.stringify(selectedFromDate));
        localStorage.setItem("selectedToDate", JSON.stringify(selectedToDate));
        localStorage.setItem("selectedGroupFilter", JSON.stringify(selectedGroupFilter));
        localStorage.setItem("selectedTeamFilter", JSON.stringify(selectedTeamFilter));
        localStorage.setItem("selectedStaFilter", JSON.stringify(selectedStaFilter));
        localStorage.setItem("selectedStaffFilter", JSON.stringify(selectedStaffFilter));
        localStorage.setItem("selectedSupervisorFilter", JSON.stringify(selectedSupervisorFilter));
        localStorage.setItem("selectedFilterEventType", JSON.stringify(selectedFilterEventType || selectedEventTypeFilter));
        localStorage.setItem("selectedFilterSubType", JSON.stringify(selectedFilterSubType || selectedEventSubTypeFilter));
        localStorage.setItem("selectedFilterEventTypeStaffTrend", JSON.stringify(selectedFilterEventTypeStaffTrend));
        localStorage.setItem("selectedFilterSubTypeStaffTrend", JSON.stringify(selectedFilterSubTypeStaffTrend));
        localStorage.setItem('selectedSelectionFilter', JSON.stringify(selectedSectionFilter))
        localStorage.setItem('selectedQuestionFilter', JSON.stringify(selectedQuestionFilter))
        localStorage.setItem('selectedScoreCardSingle', JSON.stringify(selectedScoreCardSingle ?? ''))
        localStorage.setItem('fromWeek', JSON.stringify(selectedStaffFromWeek))
        localStorage.setItem('toWeek', JSON.stringify(selectedStaffToWeek))

        if (subModLabel === 1.1) {

          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.length > 0) {
            staffFilterObj.dataTag = values.dataTag || '';
            staffFilterObj.dataTagsOperator = values.anyOfTag || values.allOfTag || '10';
          } else {
            staffFilterObj.dataTag = '';
            staffFilterObj.dataTagsOperator = '';
          }

          await getStaffTrend(staffFilterObj);
          await getStaffTrendDistribution(staffFilterObj);
          await getStaffTrendAboBelow(staffFilterObj);
          setSelectedStaffFilter('')

          handleClose();
          const arr = values?.scoreCard?.sort((a, b) => b - a)
          setSelAnaFilters({ ...staffFilterObj, scoreCardSingle: arr[0], scoreCardSingle2: arr[0] });
          localStorage.setItem("selAnaFilters", JSON.stringify({ ...staffFilterObj, scoreCardSingle: arr[0], scoreCardSingle2: arr[0] }));
        } else if (subModLabel === 1.2) {
          await getQuestionByStaff({ ...staffFilterObj, scoreCard: [values.scoreCardSingle], scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
          setSearchParams({ ...staffFilterObj, scoreCard: selectedScoreCardSingle || values.scoreCardSingle, highlightValue: values?.highlightValue })
          setSelAnaFilters({ ...staffFilterObj, scoreCard: [values.scoreCardSingle], scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
          localStorage.setItem("selAnaFilters", JSON.stringify({ ...staffFilterObj, scoreCard: [values.scoreCardSingle], scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle }));
          setSelectedStaffFilter('')

          handleClose();
        }
        else if (subModLabel === 1.3) {
          setSelAnaFilters({ ...staffFilterObj, scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
          await getAnswerByStaff(staffFilterObj);
          setSelectedStaffFilter(values?.staff)
          handleClose();
          localStorage.setItem("selAnaFilters", JSON.stringify({ ...values, scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle }));
        }
        else if (subModLabel === 1.31) {
          setSelAnaFilters({ ...values, scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });

          await getAnswerByStaffDistribution(staffFilterObj);
          setSelectedStaffFilter(values?.staff)

          handleClose();
        }
        else if (subModLabel === 1.4) {
          await getStaOverTrendByInd({
            ...staffFilterObj,
            dataTags: values?.dataTag || '',
            dataTagsOperator: values?.anyOfTag || values?.allOfTag || '10'
          });
          const arr = values?.scoreCard?.sort((a, b) => b - a)

          setSelAnaFilters({
            ...staffFilterObj,
            scoreCardSingle: arr[0],
            scoreCardSingle2: '',
            dataTags: values?.dataTag || '',
            dataTagsOperator: values?.anyOfTag || values?.allOfTag || '10'
          });
          localStorage.setItem("selAnaFilters", JSON.stringify({
            ...values,
            scoreCardSingle: arr[0],
            scoreCardSingle2: '',
            dataTags: values?.dataTag || '',
            dataTagsOperator: values?.anyOfTag || values?.allOfTag || '10'
          }));
          setSelectedStaffFilter(values?.staff)

          handleClose();
        }
        if (subModLabel === 1.5) {
          setIsSectionLoading(true);
          setSectionTrndData([]);

          const { data } = await getSectionTrendByIndividualApi({ ...staffFilterObj, scoreCardSingle: values.scoreCardSingle }, dateType)
          try {
            handleClose();
            setSelAnaFilters();
            if (data?.score?.length > 0 && data?.section?.length > 0) {
              setSectionTrndData(data);
              setIsSectionLoading(false);
            }
            if (data?.score?.length === 0 || data?.section?.length === 0) {
              setSectionTrndData([]);
              setIsSectionLoading(false);
            }
            setSwitchView(false);
            setSelAnaFilters({
              ...values,
              scoreCardSingle: values.scoreCardSingle,
            });
            setselectedStaFilter([values.scoreCardSingle])
            setSelectedStaffFilter(values?.staff)

          } catch (err) {
            setSectionTrndData([]);
            setIsSectionLoading(false);
          }

        }

        if (subModLabel === 1.6) {
          const filterObj = {
            'fromDate': moment(values.fromDate).format('YYYY-MM-DD'),
            'toDate': moment(values.toDate).format('YYYY-MM-DD'),
            'filterBy': values.filterBy,
            'filterByRange': values.filterByRange,
            'group': values.group,
            'teams': values.teams,
            'scoreCard': values.scoreCardSingle !== "" ? values.scoreCardSingle : values.scoreCard,
            'scoreCardGroups': values?.group,
            'eventType': values.eventType,
            'eventTypeEventSubType': values.eventTypeEventSubType,
            'staff': values.staff,
            'scoreCardCategory': values.scoreCardCategory,
            'evaluator': values.evaluator,
            'page': '1',
            'filterName': '',
            'highlightValue': values.highlightValue,
            'flag': selScDurationInd,
            'section': values.section,
            'question': values.question,
            'supervisor': values.supervisor,
            'numericValues': values.numericValues || '',
            'formula': values.formula || '',
            'dataTags': values?.dataTag || '',
            'dataTagsOperator': values.anyOfTag || values.allOfTag || ''
          };
          setSelAnaFilters({
            ...filterObj,
            scoreCard: [values.scoreCardSingle],
            scoreCardSingle: values.scoreCardSingle,
            scoreCardSingle2: values.scoreCardSingle,
            flag: selScDuration,
            dataTags: values?.dataTag || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag
          });
          localStorage.setItem("selAnaFilters", JSON.stringify({
            ...values,
            scoreCard: [values.scoreCardSingle],
            scoreCardSingle: values.scoreCardSingle,
            scoreCardSingle2: values.scoreCardSingle,
            dataTags: values?.dataTag || '',
            dataTagsOperator: values?.anyOfTag || values?.allOfTag || '10'
          }));
          setSearchParams({
            ...filterObj,
            scoreCard: [values.scoreCardSingle],
            scoreCardSingle: values.scoreCardSingle,
            scoreCardSingle2: values.scoreCardSingle,
            flag: selScDuration,
            dataTags: values?.dataTag || '',
            dataTagsOperator: values.anyOfTag || values.allOfTag
          })
          await getQuestionTrendIndividual({
            ...filterObj,
            scoreCard: values.scoreCardSingle,
            scoreCardSingle: values.scoreCardSingle
          });

          // if (values.scoreCardSingle !== '') {
          setselectedStaFilter([values.scoreCardSingle]);
          setSelectedStaffFilter(values?.staff)

          // }
          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false);
          }
        }

        if (subModLabel === 1.7) {
          setSectionByResultLoading(true);
          setSectionByResultData([]);

          const { data } = await getSectionByResultApi({ ...staffFilterObj, scoreCardSingle: values.scoreCardSingle })
          try {
            handleClose();
            setSelAnaFilters({});
            if (data?.section_details?.length > 0) {
              setSectionByResultData(data);
              setSearchParams({ ...staffFilterObj, scoreCard: JSON.stringify([staffFilterObj.scoreCard]), scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle })
              setSectionByResultLoading(false);
            }
            if (data?.section_details?.length === 0) {
              setSectionByResultData([]);
              setSectionByResultLoading(false);
            }
            setSelAnaFilters({ ...values, scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
            setselectedStaFilter([values.scoreCardSingle])
            setSelectedStaffFilter(values?.staff)

          } catch (err) {
            setSectionByResultData([]);
            setSectionByResultLoading(false);
            setSelAnaFilters({ ...values, scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
          }
        }

        if (subModLabel === 1.8) {
          setSectionByEventTypeLoading(true);
          setSectionByEventTypeData([]);
          const filterObj =
          {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterBy,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": values.scoreCardSingle,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "staff": values.staff,
            "page": "1",
            "highlightValue": values.highlightValue,
            "formula": values.formula,
            "section": values.section,
          }
          const { data } = await getSectionByEventTypeApi({ ...filterObj, scoreCard: values.scoreCardSingle })
          try {
            handleClose();
            setSelAnaFilters({});
            if (data?.section_details?.length > 0) {
              setSectionByEventTypeData(data);
              setSectionByEventTypeLoading(false);
            }
            if (data?.section_details?.length === 0 || !data?.section_details) {
              setSectionByEventTypeData([]);
              setSectionByEventTypeLoading(false);
            }
            setSelAnaFilters({ ...values, scoreCard: values.scoreCardSingle });
            setselectedStaFilter([values.scoreCardSingle])
            setSelectedStaffFilter(values?.staff)
            setSearchParams({ ...filterObj, scoreCard: values.scoreCardSingle })

          } catch (err) {
            setSectionByEventTypeData([]);
            setSectionByEventTypeLoading(false);
            setSelAnaFilters({ ...values, scoreCard: values.scoreCardSingle });
          }

        }


        if (subModLabel === 1.9) {
          const staffFilterObj =
          {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterBy,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": values.scoreCard,
            "scoreCardGroups": values?.group,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "staff": values.staff,
            "scoreCardCategory": values.scoreCardCategory,
            "evaluator": selAnaFilters?.evaluator || "",
            "page": "1",
            "filterName": "",
            "highlightValue": values.highlightValue,
            "flag": dateTypeFlag,
            "formula": values.formula,
            "reference": values.reference,
            "section": values.section,
            "supervisor": values.supervisor,
            "question": values.question,
            "teamsId": values.teamsId,
            "filterByRange": values.filterByRange,
            // "dataTag": values.dataTag || '',
            // "dataTagsOperator": values.anyOfTag || values.allOfTag,
          }
          if (values?.anyOfTag || values?.allOfTag || values?.dataTag?.length > 0 || dataTagByScoreCard?.length > 0) {
            setSelAnaFilters({ ...staffFilterObj, dataTag: values.dataTag || '', dataTagsOperator: values.anyOfTag || values.allOfTag || '10' })

            getKudosByStaffWithDataTags({ ...staffFilterObj, dataTag: values.dataTag || '', dataTagsOperator: values.anyOfTag || values.allOfTag || '10' })
          } else {
            setSelAnaFilters(staffFilterObj)
            getKudosByStaff(staffFilterObj)
          }
          setSelectedStaffFilter('')

        }

        if (subModLabel === '1.10') {
          const filterObj =
          {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterBy,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": values.scoreCardSingle !== "" ? values.scoreCardSingle : values.scoreCard,
            "scoreCardGroups": values?.group,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "staff": values.staff,
            "scoreCardCategory": values.scoreCardCategory,
            "evaluator": values.evaluator,
            "section": values.section,
            "question": values.question,
            "supervisor": values.supervisor,
            "highlightValue": values.highlightValue,
            "dataTag": [values.dataTag],
            "dataTagsOperator": values.anyOfTag || values.allOfTag || '10'
          }
          setCausesByStaffLoading(true);
          setCausesByStaffData([]);
          setSearchParams(filterObj)
          localStorage.setItem("selAnaFilters", JSON.stringify({
            ...filterObj,
            scoreCard: [values.scoreCardSingle],
            scoreCardSingle: values.scoreCardSingle,
            scoreCardSingle2: values.scoreCardSingle,
          }));
          const { data } = await getCausesByStaffApi({ ...filterObj, scoreCard: values.scoreCardSingle });
          setSelAnaFilters({ ...filterObj, scoreCard: [values.scoreCardSingle], scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });
          // setScoreFiltersEnabled(true);
          // if (values.scoreCardSingle !== '') {
          setselectedStaFilter([values.scoreCardSingle]);
          // }

          if (data?.data?.length > 0) {
            setCausesByStaffData(data);
            setCausesByStaffLoading(false);
          } else {
            setCausesByStaffData([]);
            setCausesByStaffLoading(false);
          }
          setSelectedStaffFilter(values?.staff)

          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false)
          }
        }

        if (subModLabel === 1.11) {
          const filterObj =
          {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterBy,
            "group": values.group,
            "teams": values.teams,
            "scoreCard": values.scoreCardSingle !== "" ? values.scoreCardSingle : values.scoreCard,
            "scoreCardGroups": values?.group,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "staff": values.staff,
            "scoreCardCategory": values.scoreCardCategory,
            "evaluator": values.evaluator,
            "section": values.section,
            "question": values.question,
            "supervisor": values.supervisor,
            "highlightValue": values.highlightValue,
            "dataTag": [values.dataTag],
            "dataTagsOperator": values.anyOfTag || values.allOfTag || '10'
          }
          setLoadingAgent(true);
          setAgentData([]);
          setPageAccpAgnt(0);
          setFilterNameAcceptanceByAgnt("")
          setSearchParams({ ...filterObj })
          const response = await getAcceptanceByAgentApi({ ...filterObj, scoreCardSingle: values.scoreCardSingle }, 1, "");
          setSelAnaFilters({ ...filterObj, scoreCard: [values.scoreCardSingle], scoreCardSingle: values.scoreCardSingle, scoreCardSingle2: values.scoreCardSingle });

          // if (values.scoreCardSingle !== '') {
          //   setselectedStaFilter([values.scoreCardSingle]);
          // }
          setSelectedStaffFilter('')

          setAgentData(response?.data?.results);
          setCountAgent(response?.data?.count);
          setLoadingAgent(false);
          handleClose();

          if (useIsMountedRef.current) {
            setSubmitting(false);
            setOpen(false)
          }
        }

        handleClose();

      } catch (error) {
        setIsLoadingTeams(false);
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }

        if (subModLabel === 1.11) {
          setAgentData([]);
          setCountAgent(0);
          setLoadingAgent(false);
        }
      }
    },
    enableReinitialze: true,
  });

  const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;
  useEffect(() => {
    if (open) {
      const arrFromWeek = [];
      const arrToWeek = [];
      if (formik.values.fromWeek > moment().week()) {
        for (let i = 1; i <= formik.values.fromWeek; i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      }

      if (formik.values.toWeek > moment().week()) {
        for (let i = 1; i <= formik.values.toWeek; i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      }
    }

    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": subModLabel === 1.5 ? values.scoreCardSingle2 : values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": values.supervisor,
      "staff": values.staff,
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const evntTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values?.scoreCardSingle || values.scoreCard,
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const evntSubTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values?.scoreCardSingle || values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const scoreCardfilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const groupFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": '',
      "teams": '',
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const teamFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const supFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": values.scoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": '',
      "staff": values.staff,
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }


    const empFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": (subModLabel === 1.4 || subModLabel === 1.1 || subModLabel === 1.9) ? values.scoreCard : values.scoreCardSingle,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": values.supervisor,
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": values.evaluator,
      "highlightValue": values.highlightValue,
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    if (formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {

      if (subModLabel !== 1.6) {
        getScoreLogSupervisor(supFilterObj);
      }


      if (subModLabel !== 1.9) {
        getScoreLogEmployee(empFilterObj);
      }

      if (subModLabel !== 1.4 && subModLabel !== 1.1 && subModLabel !== 1.6 && values?.scoreCardSingle) {
        getScorecardSection(values?.scoreCardSingle);
        getScoreLogCategory(filterObj);
        getScoreLogEvaluator(filterObj);
      }

      if (values.section !== '' && values.scoreCardSingle !== '') {
        getQuestionsAnswerByStaff(values.scoreCardSingle, values.section);
      }

      if ((values.scoreCard !== undefined && values.scoreCard !== null) && (subModLabel === 1.4 && subModLabel === 1.1)) {
        getScorecardSection(values.scoreCard);
      }
      if (values.scoreCardSingle !== undefined && values.scoreCardSingle !== null && subModLabel === 1.3) {
        getSectionAnswerByStaff(values.scoreCardSingle);
        getQuestionsAnswerByStaff(values.scoreCardSingle, values.section);
      }
      // if (values.scoreCard !== undefined && values.scoreCard !== null && subModLabel === 1.2) {
      //   getQuestionByStaffFormula(values);
      // }
      if (values.scoreCardSingle && values.scoreCardSingle !== undefined && values.scoreCardSingle !== null && subModLabel === 1.3) {
        getAnswerByStaffFormula(values);
      }

      getScoreLogGroups(groupFilterObj);
      getScoreLogScorecards(scoreCardfilterObj);
      getScoreLogEventSubType(evntSubTypeFilterObj);
      getScoreLogEventType(evntTypeFilterObj);
      getScoreLogTeams(teamFilterObj);

    }
  }, [formik?.values]);

  // function for select section field
  const handleSectionField = (event) => {
    setFieldValue('section', event.target.value)
    setSelectedSelectionFilter(event.target.value)

  }
  const handleChange = (event) => {
    setUserLev(event.target.value);
  };

  // function for question field
  const handleQuestionField = (event) => {
    setFieldValue('question', event.target.value)
    setSelectedQuestionFilter(event.target.value)

  }

  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close user dialog modal
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {

      setOpen(false)

      if (!setAddMode) {
        closeAddUserDialog(false);
      }
    }
  };

  const handleDateRangeChange = (event) => {

    // Resetting remaining fields
    setFieldValue("group", '');
    setFieldValue("teams", '');
    setFieldValue("scoreCard", []);
    setFieldValue("scoreCardSingle", "");
    setFieldValue("scoreCardSingle2", "");
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("question", '');
    setFieldValue("evaluator", '');


    const dateRange = event.target.value;
    setFieldValue("dateRange", dateRange);
    // setScorecardType(event.target.value);
    // localStorage.setItem("selectedAnaDateRange", JSON.stringify( dateRange ));
    if (subModLabel === 1.11) {
      setSelectedGroupFilter('')
      setSelectedScoreCardSingle('')
      setselectedStaFilter('')
    }
    switch (dateRange) {

      case 'Today':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().format('YYYY-MM-DD'));
        setSelectedFromDate(moment().format('YYYY-MM-DD'))
        setSelectedToDate(moment().format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Today');
        break;
      case 'Yesterday':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        setSelectedToDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Yesterday');
        break;
      case 'This Week':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('week').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().startOf('week').format('YYYY-MM-DD'))
        setSelectedToDate(moment().endOf('week').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('This Week');
        break;
      case 'Last Week':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'))
        setSelectedToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Last Week');
        break;
      case 'This Month':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setSelectedToDate(moment().endOf('month').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('This Month');
        break;
      case 'Last Month':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
        setSelectedToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Last Month');
        break;
      case 'This Quarter':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'))
        setSelectedToDate(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('This Quarter');
        break;
      case 'Last Quarter':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'))
        setSelectedToDate(moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Last Quarter');
        break;
      case 'This Year':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().startOf('year').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().startOf('year').format('YYYY-MM-DD'))
        setSelectedToDate(moment().endOf('year').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('This Year');
        break;
      case 'Last Year':
        setCustomFilters(false);
        setFieldValue("fromDate", moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedFromDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'))
        setSelectedToDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'))
        setSelectedAnaDateRange('Last Year');
        break;
      case 'Custom Date Range':
        setCustomFilters(true);
        setFieldValue("fromDate", moment());
        setFieldValue("toDate", moment());
        setSelectedFromDate(moment());
        setSelectedToDate(moment());

        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("fromWeek", 1)
          setSelectedStaffFromWeek(1);
        }
        else {
          setFieldValue("fromWeek", moment().year(moment().format('YYYY')).week());
          setSelectedStaffFromWeek(moment().year(moment().format('YYYY')).week());
        }
        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("toWeek", 1)
          setSelectedStaffToWeek(1)
        }
        else {
          setFieldValue("toWeek", moment().year(moment().format('YYYY')).week());
          setSelectedStaffToWeek(moment().year(moment().format('YYYY')).week());
        }
        setSelectedAnaDateRange('Custom Date Range');
        break;
      default:
        setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
        setFieldValue("toDate", moment().format('YYYY-MM-DD'));
        break;
    }

  };

  const handleGroupChange = (event, newVal) => {

    // Resetting remaining fields
    setFieldValue("teams", '');
    setFieldValue("scoreCard", []);
    setFieldValue("scoreCardSingle", "");
    setFieldValue("scoreCardSingle2", "");
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("question", '');
    setFieldValue("evaluator", '');
    setFieldValue('group', newVal?.id ?? '');
    setSelectedGroupFilter(newVal?.id ?? '');
    // setFieldValue("group", event.target.value);
    // setSelectedGroupFilter(event.target.value);
    const filterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": newVal?.id ?? '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    getScoreLogTeams(filterObj);
  };

  const handleDataTags = (event, val) => {
    setFieldValue("dataTag", (event.target.value).flat(100));
    setselectedDataTag((event.target.value).flat(100))
  }

  const handleAnyOfTag = (event) => {
    setFieldValue('allOfTag', '')
    setFieldValue('anyOfTag', '10')
    setIsAnyOfTag(true)
    setIsAllOfTag(false)
  }

  const handleAllOfTag = () => {
    setFieldValue('anyOfTag', '')
    setFieldValue('allOfTag', '20')
    setIsAnyOfTag(false)
    setIsAllOfTag(true)
  }
  const handleTeamChange = (event) => {
    // Resetting remaining fields
    setFieldValue("scoreCard", []);
    setFieldValue("scoreCardSingle", "");
    setFieldValue("scoreCardSingle2", "");
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("question", '');
    setFieldValue("evaluator", '');

    setFieldValue("teams", event.target.value);
    setSelectedTeamFilter(event.target.value);
  };

  const handleSupervisorChange = (event) => {
    setFieldValue("supervisor", event.target.value);
    setSelectedSupervisorFilter(event.target.value);
  };
  const handleScorecardChange = (event, newValue) => {
    setFieldValue("eventType", '');
    setFieldValue("eventTypeEventSubType", '');
    setFieldValue("staff", '');
    setFieldValue("scoreCardCategory", '');
    setFieldValue("evaluator", '');
    setFieldValue("supervisor", '');
    setFieldValue("highlightValue", '');
    setFieldValue("section", '');
    setFieldValue("question", '');
    setFieldValue("evaluator", '');
    setFieldValue("dataTag", []);
    const value = [];
    newValue?.map(option => value.push(option?.scoreCard));
    setFieldValue("scoreCard", value ?? []);
    setFieldValue("scoreCardSingle", value?.length > 0 ? value[0] : '');
    setselectedStaFilter(value ?? []);
    setSelectedScoreCardSingle(value?.length > 0 ? value[0] : '')

    // if (subModLabel === 1.9 || subModLabel === 1.1) {
    const filterScoreCardObj = {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "scoreCard": value ?? '',
    }
    getDataTagsByScoreCard(filterScoreCardObj)
    setselectedDataTag()
    // }
  };

  const handleStaffChange = (event) => {
    setFieldValue("highlightValue", '');
    setFieldValue("staff", event.target.value);
    setSelectedStaffFilter(event.target.value);
  };



  const handleFilterByChange = (event) => {
    const filterBy = event.target.value;
    // setFieldValue("filterByRange", filterBy);
    // setScorecardType(event.target.value);
    switch (filterBy) {

      case 'Score Date':
        setFieldValue("filterByRange", 'Score Date');
        setFieldValue("filterBy", 'created_at');
        setSelectedFilterBy('Score Date');
        setSelectedFilterByValue('created_at');
        break;
      case 'Event Date':
        setFieldValue("filterByRange", 'Event Date');
        setFieldValue("filterBy", 'event_date');
        setSelectedFilterBy('Event Date');
        setSelectedFilterByValue('event_date');
        break;
      case 'Last Edit Date':
        setFieldValue("filterByRange", 'Last Edit Date');
        setFieldValue("filterBy", 'updated_at');
        setSelectedFilterBy('Last Edit Date');
        setSelectedFilterByValue('updated_at');
        break;
      default:
        setFieldValue("filterByRange", 'Last Edit Date');
        setFieldValue("filterBy", 'updated_at');
        setSelectedFilterBy('updated_at');
        setSelectedFilterByValue('updated_at');
        break;
    }

  };

  const handleEvalChange = (event) => {
    setFieldValue("evaluator", event.target.value)
  }

  const handleAdvancedFilters = (event) => {
    setAdvanceFilters((advanceFilters) => !advanceFilters);
  };

  const handleTypeChange = (event, value) => {
    setFieldValue("eventType", event.target.value)
    setSelectedFilterEventType(event.target.value)
    // for 1.8 module
    setSelectedEventTypeFilter(event.target.value)
    // setting name for 1.1 module
    setFieldValue("eventTypeEventType", value.props.children)
  };

  const handleSubTypeChange = (event, value) => {
    setSelectedFilterSubType(event.target.value)
    setFieldValue("eventTypeEventSubType", event.target.value)
    // for 1.8 module --
    setSelectedEventSubTypeFilter(event.target.value)
    // setting name for 1.1 module
    // setFieldValue("eventSubTypeEventSubType", value.props.children)
  };

  const renderOptions = (props, option) => {
    return <li {...props} key={option?.scoreCard} style={{ color: option?.scoreCardIsArchive ? 'red' : '' }}>
      {option?.scoreCardName}
    </li>
  }

  const renderGroupOptions = (props, option) => {
    return <li {...props} key={option?.id}
    // style={{ color: option?.scoreCardIsArchive ? 'red' : '' }}
    >
      {option?.name}
    </li>
  }
  return (
    <div>

      {addUserButton &&
        <Button variant="contained" onClick={handleClickOpen}>
          Filter
        </Button>
      }

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle sx={{ pb: 2 }} > <h3>Analytics Report Filter {subModLabel === 1.31 ? '1.3' : subModLabel} </h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 15,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: '20px' }}>
              <Stack spacing={3}>

                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 0, justifyContent: 'end' }}>

                  {/* <FormControl fullWidth > */}
                  <Button startIcon={advanceFilters ? <VisibilityOffIcon /> : <VisibilityIcon />} sx={{ p: 0 }} onClick={handleAdvancedFilters}> {advanceFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'} </Button>
                  {/* </FormControl> */}
                </Stack>
                <Divider />


                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                      // defaultValue='This Month'
                      labelId="date-range-label"
                      id="demo-select-small"
                      value={values.dateRange}
                      label="Date Range"
                      {...getFieldProps('dateRange')}
                      onChange={(e) => { handleDateRangeChange(e) }}
                    >
                      {dateRange?.map((item) => (
                        (subModLabel === 1.2) ?
                          (item?.dateRange !== 'This Year' && item?.dateRange !== 'Last Year') &&
                          <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                            {item.dateRange}
                          </MenuItem >

                          :
                          <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                            {item.dateRange}
                          </MenuItem >

                      )
                      )}


                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="filter-by-label">Filter By</InputLabel>
                    <Select
                      // defaultValue='Score Date'
                      name='filterByRange'
                      labelId="filter-by-label"
                      id="demo-select-small"
                      value={values.filterByRange}
                      label="Filter By"
                      {...getFieldProps('filterByRange')}
                      onChange={handleFilterByChange}
                    >
                      {filterBy?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.type}>
                          {item.type}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                {customFilters &&
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Box sx={{ width: "50%" }}>
                        <DesktopDatePicker
                          label="Date From"
                          inputFormat="yyyy/MM/dd"
                          name="fromDate"
                          minDate={new Date(dataCreatedFrom)}
                          disableFuture
                          {...getFieldProps('fromDate')}
                          onChange={(newValue) => {
                            setFieldValue("group", '');
                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("fromWeek", 1)
                              setSelectedStaffFromWeek(1)
                            } else {
                              formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedStaffFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            }

                            setFieldValue("fromDate", newValue);
                            setSelectedStaffFromDate(newValue);
                            setSelectedFromDate(newValue);

                            if (subModLabel === 1.2 && moment(newValue, "YYYY-MM-DD").isBefore(formik.values.fromDate)) {
                              setSelectedFromDate(newValue)

                              setFieldValue("toDate", moment(newValue).add(3, 'month'));
                              setSelectedToDate(moment(newValue).add(3, 'month'))
                              setSelectedStaffToDate(newValue);

                              const newToValue = moment(newValue).add(3, 'month')

                              formik.setFieldValue("toWeek", moment(newToValue).year(moment(newToValue).format('YYYY')).isoWeek());
                              setSelectedStaffToWeek(moment(newToValue).year(moment(newToValue).format('YYYY')).isoWeek())
                            }
                            if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {

                              formik.setFieldValue("toDate", newValue)
                              setSelectedStaffToDate(newValue);
                              if (moment(newValue).format('MM-DD') === '01-01') {
                                formik.setFieldValue("toWeek", 1)
                                setSelectedStaffToWeek(1)

                              } else {
                                formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                setSelectedStaffToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                              }



                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <DesktopDatePicker
                          label="Date To"
                          inputFormat="yyyy/MM/dd"
                          name="toDate"
                          minDate={new Date(dataCreatedFrom)}
                          disableFuture
                          {...getFieldProps('toDate')}
                          onChange={(newValue) => {

                            setFieldValue("group", '')

                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("toWeek", 1)
                              setSelectedStaffToWeek(1)
                            } else {
                              formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedStaffToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                            setFieldValue("toDate", newValue);
                            setSelectedToDate(newValue);


                            if (subModLabel === 1.2 && moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                              setSelectedToDate(moment(newValue).format('YYYY-MM-DD'))

                              setFieldValue("fromDate", moment(newValue).subtract(3, 'month'));
                              setSelectedStaffFromDate(moment(newValue).subtract(3, 'month'))
                              // setSelectedStaffFromDate(newValue);

                              setSelectedFromDate(moment(newValue).subtract(3, 'month'))
                            }

                            // Extract year from full date
                            const fullDate = moment(newValue);
                            const extractyear = fullDate.year();

                            // Check the year has 4 digit
                            if (moment(newValue).isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {

                              formik.setFieldValue("fromDate", newValue)
                              setSelectedStaffFromDate(newValue);
                              setSelectedFromDate(newValue)
                              setSelectedToDate(newValue);
                              if (moment(newValue).format('MM-DD') === '01-01') {
                                formik.setFieldValue("fromWeek", 1)
                                setSelectedStaffToWeek(1)

                              } else {
                                formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                setSelectedStaffToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                              }

                            }
                          }}

                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}
                      </Box>
                    </Stack>
                  </LocalizationProvider>
                }
                {customFilters && <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      required
                      value={formik.values.fromWeek}
                      multiple={false}
                      select
                      name="fromWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("fromWeek", e.target.value);
                        setSelectedStaffFromWeek(e.target.value);
                        setSelectedFromDate(moment().week(e.target.value).startOf('week'));
                        formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                      }}

                    >
                      {fromWeekNo?.map((item) => (
                        <MenuItem key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}

                    </TextField>
                    {/* {formik.errors.fname && formik.touched.fname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography> : null} */}
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      multiple={false}
                      value={formik.values.toWeek}
                      required
                      select
                      name="toWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("toWeek", e.target.value);
                        setSelectedStaffToWeek(e.target.value);
                        setSelectedToDate(moment().week(e.target.value).endOf('week'));
                        formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                      }}

                    >
                      {toWeekNo?.map((item) => (
                        <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}
                    </TextField>
                    {/* {formik.errors.lname && formik.touched.lname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography> : null} */}
                  </Box>

                </Stack>}
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        ((subModLabel === 1.11)
                          ? ([{
                            id: '', name: 'All'
                          }].concat(reportsScoreLogGroups)) : reportsScoreLogGroups)?.find((option) => {
                            return option?.id === (selectedGroupFilter || values?.group)
                          })}
                      fullWidth
                      options={
                        (
                          // subModLabel === 1.31 ||
                          // subModLabel === 1.3 ||
                          subModLabel === 1.11
                        ) ? [{
                          id: '', name: 'All'
                        }].concat(reportsScoreLogGroups) : reportsScoreLogGroups || ''}
                      getOptionLabel={(option) => option?.name || ""}
                      renderOption={renderGroupOptions}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id
                      }}
                      onChange={(event, newValue) => {
                        handleGroupChange(event, newValue);
                      }}
                      id="controllable-states-demo"
                      name="group"
                      renderInput={(params) => <TextField {...params} label="Group"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        variant="outlined"
                        error={Boolean(touched.group && errors.group)}
                        helperText={touched.group && errors.group}
                        required
                      />}
                    />
                  </FormControl>


                  {(subModLabel !== 1.4 && subModLabel !== 1.5 && subModLabel !== 1.8 && subModLabel !== 1.7 && subModLabel !== 1.6) && <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">Teams</InputLabel>
                    <Select
                      // defaultValue='All'
                      required
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.teams}
                      {...getFieldProps('teams')}
                      // label="Teams"
                      input={<OutlinedInput notched label="Teams" />}
                      onChange={handleTeamChange}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogTeams?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                  }
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {(subModLabel === 1.4 || subModLabel === 1.1 || subModLabel === 1.9) &&
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        freeSolo
                        value={reportsScoreLogScorecards?.filter((option) => values?.scoreCard?.includes(option?.scoreCard))}
                        // inputValue={inputValue}
                        fullWidth
                        options={reportsScoreLogScorecards}
                        getOptionLabel={(option) => option?.scoreCardName || ""}
                        renderOption={renderOptions}
                        onChange={(event, newValue) => {
                          handleScorecardChange(event, newValue);
                        }}

                        id="controllable-states-demo"
                        isOptionEqualToValue={(option, value) => option?.scoreCard === value?.scoreCard}
                        renderTags={(value, getTagProps) => {
                          return value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={typeof option === "string" ? option : option?.scoreCardName}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                        renderInput={(params) => <TextField {...params} label="Scorecard"
                          popoverProps={{
                            style: {
                              bottom: 0,
                              overflowY: 'auto',
                            }
                          }}
                          variant="outlined"
                          error={Boolean(touched.scoreCard && errors.scoreCard)}
                          required
                        />}

                      />
                      {(errors.scoreCard && touched.scoreCard) ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This value is required</Typography> : null}
                    </FormControl>}

                  {(subModLabel === 1.5 || subModLabel === 1.11 || subModLabel === 1.6
                    || subModLabel === 1.2 || subModLabel === 1.3 || subModLabel === 1.31
                    || subModLabel === '1.10' || subModLabel === 1.8
                    || subModLabel === 1.7) && <FormControl fullWidth>
                      <Autocomplete
                        value={(subModLabel === 1.11 ? [{
                          scoreCardName: 'All', scoreCard: ''
                        }].concat(reportsScoreLogScorecards) : reportsScoreLogScorecards)?.find((option) => option?.scoreCard === (selectedScoreCardSingle || values?.scoreCardSingle))}

                        fullWidth
                        options={subModLabel === 1.11 ? [{
                          scoreCardName: 'All', scoreCard: ''
                        }].concat(reportsScoreLogScorecards) : reportsScoreLogScorecards}
                        getOptionLabel={(option) => option?.scoreCardName || ""}
                        renderOption={renderOptions}
                        onChange={(event, newValue) => {
                          setFieldValue("eventType", '');
                          setFieldValue("eventTypeEventSubType", '');
                          setFieldValue("staff", '');
                          setFieldValue("highlightValue", '');
                          setFieldValue("section", '');

                          setFieldValue('scoreCardSingle', newValue?.scoreCard ?? '')
                          setFieldValue("scoreCard", [newValue?.scoreCard] ?? []);
                          const filterScoreCardObj = {
                            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                            "filterBy": values.filterBy,
                            "scoreCard": newValue?.scoreCard !== '' ? newValue?.scoreCard : '',
                          }
                          setFieldValue("dataTag", []);
                          setselectedDataTag()
                          setselectedStaFilter([newValue?.scoreCard] || []);

                          setSelectedScoreCardSingle(newValue?.scoreCard ?? '')
                          getDataTagsByScoreCard(filterScoreCardObj)

                        }}
                        id="controllable-states-demo"
                        renderInput={(params) => <TextField {...params} label="Scorecard"
                          popoverProps={{
                            style: {
                              bottom: 0,
                              overflowY: 'auto'
                            }
                          }}
                          variant="outlined"
                          error={Boolean((touched.scoreCardSingle && errors.scoreCardSingle))}
                          helperText={(touched.scoreCardSingle && errors.scoreCardSingle)}
                          required
                        />}
                      />
                    </FormControl>

                  }
                  {
                    (dataTagByScoreCard?.length > 0 && (subModLabel === 1.4 || subModLabel === 1.9 || subModLabel === 1.6 || subModLabel === '1.10' || subModLabel === 1.1 || subModLabel === 1.11)) && <> <FormControl fullWidth>
                      <InputLabel id="demo-multiple-name-label">Data Tag(s)</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={values.dataTag}
                        {...getFieldProps('dataTag')}
                        input={<OutlinedInput notched label="Data Tag(s)" />}
                        onChange={handleDataTags}
                      >
                        {/* <MenuItem value="">Select Value</MenuItem > */}
                        {dataTagByScoreCard?.map((item, i) => (
                          <MenuItem key={i} id={item.dataTags} value={item.dataTags}>
                            {item.dataTagsName}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    </>
                  }

                </Stack>
                {(dataTagByScoreCard?.length > 0 && (subModLabel === 1.4 || subModLabel === 1.9 || subModLabel === '1.10' || subModLabel === 1.1 || subModLabel === 1.11)) && <Stack direction={{ sm: 'row' }} spacing={0} alignItems="flex-end" justifyContent="flex-end">
                  <FormControlLabel
                    name="anyOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="10"
                    control={<Radio />}
                    label="Any of the Tags"
                    checked={isAnyOfTag}
                    onChange={handleAnyOfTag}
                  />
                  {/* {...getFieldProps('anyOfTag')} */}

                  <FormControlLabel
                    name="allOfTag"
                    sx={{ marginLeft: '0px', marginRight: '0px' }}
                    // value="20"
                    control={<Radio />}
                    label="All of the Tags"
                    onChange={handleAllOfTag}
                    checked={isAllOfTag}


                  />
                </Stack>
                }

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="group-label">Event Type</InputLabel>
                    <Select
                      // defaultValue='All'
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      // label="Event Type"
                      value={values.eventType}
                      {...getFieldProps('eventType')}
                      input={<OutlinedInput notched label="Event Type" />}
                      onChange={(e, val) => { handleTypeChange(e, val) }}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogEventType?.map((item) => (

                        <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                          <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                        </MenuItem>

                      ))}
                      {/* {reportsScoreLogEventType?.map((item) => (
                        <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                          {item.eventTypeEventType}
                        </MenuItem >
                      ))} */}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">Event Sub Type</InputLabel>
                    <Select
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.eventTypeEventSubType}
                      {...getFieldProps('eventTypeEventSubType')}
                      input={<OutlinedInput notched label="Event Sub Type" />}
                      onChange={(e, val) => { handleSubTypeChange(e, val) }}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogEventSubType?.map(
                        (item, index) =>


                          <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                            <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventSubType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                          </MenuItem>


                      )}
                      {/* {reportsScoreLogEventSubType?.map((item) =>
                        <MenuItem key={item.eventSubType} id={item.eventSubType} value={item.eventSubType}>
                          {item.subType}
                        </MenuItem>
                      )} */}
                    </Select>
                  </FormControl>
                </Stack>
                {(subModLabel === 1.5 || subModLabel === 1.4 || subModLabel === 1.6 || subModLabel === 1.7 || subModLabel === 1.8) &&
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel shrink id="scorecard-label" required>Employee</InputLabel>
                    <Select
                      labelId="scorecard-label"
                      id="demo-select-small"
                      // displayEmpty
                      required
                      input={<OutlinedInput notched label="employee" />}
                      error={Boolean(touched.staff && errors.staff)}
                      helperText={touched.staff && errors.staff}
                      // value={reportsScoreLogEmployee?.find((option) => {
                      //   return (option?.includes(values?.staff) ? values?.staff : '')
                      // })}
                      // value={reportsScoreLogEmployee?.includes(values.staff) ? values?.staff : ''}
                      value={values?.staff}
                      {...getFieldProps('staff')}
                      onChange={handleStaffChange}
                    >
                      {reportsScoreLogEmployee?.map((item) => (
                        <MenuItem key={item.staff} id={item.staff} value={item.staff}>
                          {`${item.staffUserFirstName} ${item.staffUserLastName}`}
                        </MenuItem >
                      ))}
                    </Select>
                    {formik.errors.staff && formik.touched.staff ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>{formik.errors.staff}</Typography> : null}
                  </FormControl>
                }
                {
                  <FormControl fullWidth>
                    {advanceFilters && (subModLabel === 1.6) && <FormControl>
                      <InputLabel shrink id="scorecard-label">Sections</InputLabel>
                      <Select
                        defaultValue='All'
                        labelId="scorecard-label"
                        id="demo-select-small"
                        displayEmpty
                        input={<OutlinedInput notched label="Sections" />}
                        onChange={handleChange}
                        // error={Boolean(touched.userLev && errors.userLev)}
                        // helperText={touched.userLev && errors.userLev}
                        {...getFieldProps("section")}
                      >
                        <MenuItem value=""> All </MenuItem >
                        {scorecardSection?.map((item) => (
                          <MenuItem key={item.id} id={item.id} value={item.id}>
                            {item.name}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    }
                  </FormControl>
                }
                {
                  (advanceFilters && (subModLabel !== 1.11)) && <><Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    {((subModLabel !== 1.5) && (subModLabel !== 1.8) && (subModLabel !== 1.7) && (subModLabel !== 5.11) && (subModLabel !== 5.12) && (subModLabel !== 1.4) && (subModLabel !== 1.6)) &&
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="group-label">Supervisor</InputLabel>
                          <Select
                            // defaultValue='All'
                            labelId="group-label"
                            id="demo-select-small"
                            displayEmpty
                            value={values.supervisor}
                            // label="Supervisor"
                            input={<OutlinedInput notched label="Supervisor" />}
                            {...getFieldProps('supervisor')}
                            onChange={handleSupervisorChange}
                          >
                            <MenuItem value=""> All </MenuItem >
                            {reportsScoreLogSupervisor?.map((item) => (
                              <MenuItem key={item.supervisor} id={item.supervisor} value={item.supervisor}>
                                {`${item.supervisorUserFirstName} ${item.supervisorUserLastName}`}
                              </MenuItem >
                            ))}
                          </Select>
                        </FormControl>
                        {(advanceFilters && (subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === '1.10')) &&
                          <FormControl fullWidth>
                            <InputLabel shrink id="scorecard-label">Employee</InputLabel>
                            <Select
                              labelId="scorecard-label"
                              id="demo-select-small"
                              displayEmpty
                              required
                              input={<OutlinedInput notched label="Employee" />}
                              error={Boolean(touched.staff && errors.staff)}
                              helperText={touched.staff && errors.staff}
                              value={values.staff}
                              {...getFieldProps('staff')}
                              onChange={handleStaffChange}
                            >
                              {(subModLabel === '1.10' || subModLabel === 1.3) && <MenuItem value=""> All </MenuItem >}
                              {reportsScoreLogEmployee?.map((item) => (
                                <MenuItem key={item.staff} id={item.staff} value={item.staff}>
                                  {`${item.staffUserFirstName} ${item.staffUserLastName}`}
                                </MenuItem >
                              ))}
                            </Select>
                          </FormControl>
                        }
                      </Stack>}

                    {advanceFilters && (subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === '1.10') &&
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="scorecard-label">Sections</InputLabel>
                          <Select
                            defaultValue='All'
                            labelId="scorecard-label"
                            id="demo-select-small"
                            displayEmpty
                            input={<OutlinedInput notched label="Sections" />}
                            value={values?.section}
                            {...getFieldProps("section")}
                            onChange={handleSectionField}
                          >
                            <MenuItem value=""> All </MenuItem >
                            {scorecardSection?.map((item) => (
                              <MenuItem key={item.id} id={item.id} value={item.id}>
                                {item.name}
                              </MenuItem >
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel shrink id="scorecard-label">Questions</InputLabel>
                          <Select
                            defaultValue='All'
                            labelId="scorecard-label"
                            id="demo-select-small"
                            displayEmpty
                            input={<OutlinedInput notched label="Questions" />}
                            // error={Boolean(touched.userLev && errors.userLev)}
                            // helperText={touched.userLev && errors.userLev}
                            value={values?.question}
                            {...getFieldProps("question")}
                            onChange={handleQuestionField}
                          >
                            <MenuItem value=""> All </MenuItem >
                            {questionsAnswerByStaff && questionsAnswerByStaff?.map((item) => (
                              <MenuItem key={item.id} id={item.id} value={item.id}>
                                {item.question}
                              </MenuItem >
                            ))}
                          </Select>
                        </FormControl>
                      </ Stack>
                    }
                    {subModLabel !== '1.10' && <FormControl fullWidth>

                      <TextField
                        fullWidth
                        type='number'
                        label="Highlight Below"
                        value={values.highlightBelow}
                        {...getFieldProps('highlightValue')}
                        error={Boolean(touched.highlightValue && errors.highlightValue)}
                        helperText={touched.highlightValue && errors.highlightValue}
                      />
                    </FormControl>}

                    {((advanceFilters && (subModLabel === 1.2 || subModLabel === 1.5 || subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === 1.7 || subModLabel === 1.8 || subModLabel === 1.6))) &&

                      <FormControl fullWidth>
                        <InputLabel shrink id="group-label">Formula</InputLabel>
                        <Select
                          labelId="group-label"
                          id="demo-select-small"
                          displayEmpty
                          value={values.formula}
                          input={<OutlinedInput notched label="Formula" />}
                          {...getFieldProps('formula')}
                        >
                          {(subModLabel === 1.2 || subModLabel === 1.3 || subModLabel === 1.31 || subModLabel === 1.6 || subModLabel === 1.7 || subModLabel === 1.8 || subModLabel === 1.5) && formulaType?.map((item) => (
                            <MenuItem key={item.id} id={item.id} value={item?.id}>
                              {item.type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>}
                  </Stack>

                    {subModLabel === 4.4 && <FormControl>
                      <InputLabel shrink id="scorecard-label">Sections</InputLabel>
                      <Select
                        defaultValue='All'
                        labelId="scorecard-label"
                        id="demo-select-small"
                        displayEmpty
                        input={<OutlinedInput notched label="Sections" />}
                        onChange={handleChange}
                        // error={Boolean(touched.userLev && errors.userLev)}
                        // helperText={touched.userLev && errors.userLev}
                        {...getFieldProps("section")}
                      >
                        <MenuItem value=""> All </MenuItem >
                        {scorecardSection?.map((item) => (
                          <MenuItem key={item.id} id={item.id} value={item.id}>
                            {item.name}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    }

                  </>
                }
                {advanceFilters && subModLabel === 1.6 && (
                  <FormControlLabel
                    label="Numeric Values"
                    control={
                      <Checkbox checked={values.numericValues} {...getFieldProps('numericValues')} size="small" />
                    }
                  />
                )}
                {(advanceFilters && subModLabel === 1.11) && <FormControl sx={{ width: "50%" }}>
                  <InputLabel shrink id="scorecard-label">Evaluators</InputLabel>
                  <Select
                    defaultValue='All'
                    labelId="scorecard-label"
                    id="demo-select-small"
                    displayEmpty
                    input={<OutlinedInput notched label="evaluator" />}
                    onChange={handleEvalChange}
                    // error={Boolean(touched.userLev && errors.userLev)}
                    // helperText={touched.userLev && errors.userLev}
                    {...getFieldProps('evaluator')}
                  >
                    <MenuItem value=""> All </MenuItem >
                    {reportsScoreLogEvaluator?.map(obj => <MenuItem key={obj?.evaluator} value={obj?.evaluator}>{`${obj?.evaluatorFirstName} ${obj?.evaluatorLastName}`}</MenuItem>)}
                  </Select>
                </FormControl>
                }
                {
                  (advanceFilters && subModLabel === 1.2) && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        name='Reference'
                        id="outlined-error"
                        label="Reference"
                        variant="outlined"
                        {...getFieldProps('reference')}
                      // error={Boolean(touched.reference && errors.reference)}
                      // helperText={touched.reference && errors.reference}
                      />
                    </FormControl>

                  </Stack>
                }


              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => { handleClose() }} color="inherit">
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit" variant="contained" loading={isSubmitting}>
                {isSubmitting && <><CircularProgress size={14} />&nbsp;</>}Apply
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}