import { useState } from 'react';
import { Navigate, useLocation, useSearchParams, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  // changes
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestedLocation, setRequestedLocation] = useState(null);


  const { token, email } = useParams();

  if (loggedUserData?.mustChangePassword === true && isAuthenticated) {
    // navigate(`${PATH_AUTH.mustChangePassword}`)
    return <Navigate to={`${PATH_AUTH.mustChangePassword}`} />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    if (searchParams.get('ticket_id')) {
      return <Navigate to={`/auth/login?isTicket=True&ticket_id=${searchParams.get('ticket_id')}`} />
    }
    return <Navigate to="/auth/login" />;
  }


  if (pathname.includes("test") && isAuthenticated && !loggedUserData?.staff) {
    return <Login />
  }

  if (!pathname.includes("employee") && !pathname.includes("calibration") && !pathname.includes("userProfile") && !pathname.includes("companySettings") && !pathname.includes("coaching") && !pathname.includes("auto_qa_rules") && !pathname.includes("analyze") && !pathname.includes("calls") && !pathname.includes("events") && pathname.split("/")[1] !== "notification" && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "reviewPerformance")) && (pathname.split("/")[1] !== "reports" || (pathname.split("/")[1] === "reports" && pathname.split("/")[2] !== "reviewPerformance")) && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "select_scorecard")) && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "selectDraft")) && isAuthenticated && loggedUserData?.staff?.staffType === 10) {
    return <Login />
  }


  if (!pathname.includes("employee") && !pathname.includes("analytics") && !pathname.includes("support") && !pathname.includes("calibration") && !pathname.includes("scorecards") && !pathname.includes("userProfile") && !pathname.includes("companySettings") && !pathname.includes("coaching") && !pathname.includes("auto_qa_rules") && !pathname.includes("analyze") && !pathname.includes("calls") && !pathname.includes("events") && pathname.split("/")[1] !== "notification" && !pathname.includes("supervisor") && !pathname.includes("reports") && (pathname.split("/")[1] !== "reports" || (pathname.split("/")[1] === "reports" && pathname.split("/")[2] !== "reviewPerformance")) && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "reviewPerformance")) && (pathname.split("/")[1] !== "reports" || (pathname.split("/")[1] === "reports" && pathname.split("/")[2] !== "editScore")) && !pathname.includes("staff") && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "select_scorecard")) && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "select_emp")) && (pathname.split("/")[1] !== "score" || (pathname.split("/")[1] === "score" && pathname.split("/")[2] !== "selectDraft")) && isAuthenticated && (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30)) {
    return <Login />
  }



  // if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   console.log("yes")
  //   return <Navigate to={requestedLocation} />;
  // }

  return <>{children}</>;
}
