import {
    ScoreList
} from '../types/typeScore';
import { getScorecardListApi, getScorecardSearchApi } from '../../_apis_/authApi.js/staffApi';

export const getScoreList = (id, pgNo) =>
    async (dispatch) => {
        const { data } = await getScorecardListApi(id, pgNo);
        dispatch(setAllScoreList(data))
    }
export const getScoreSearch = (id, query, pgNo) =>
    async (dispatch) => {
        const { data } = await getScorecardSearchApi(id, query, pgNo);
        dispatch(setAllScoreList(data))
    }

const setAllScoreList = (data = null) => {
    if (data) {
        return {
            type: ScoreList,
            payload: data
        };
    }
    return {
        type: ScoreList,
        payload: data
    }
}