import React from 'react';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Dialog, DialogContent, DialogTitle, Divider,
    IconButton,
    Stack,
    TextField, Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
// NPM IMPORTS
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { MIconButton } from '../../components/@material-extend';
// API IMPORTS
import { addGoalApi, editGoalApi } from '../../_apis_/authApi.js/staffApi';

DialogGoal.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    desc: PropTypes.object,
    goalfn: PropTypes.func,
    userid: PropTypes.number,
    id: PropTypes.object,
}


export default function DialogGoal({ isOpen, setOpen, desc, goalfn, userid, id }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [disBtn, setDisBtn] = React.useState(false);
    const formik = useFormik({
        initialValues: { description: "" },
        validationSchema: Yup.object({
            description: Yup.string().required("* Required Field").nullable()
        }),
        onSubmit: values => {
            setDisBtn(true)
            async function editGoal() {
                const newObj = {
                    "description": values.description,
                    "user": userid,
                    "staff": null,
                    "supervisor": null,
                }
                const { data } = await editGoalApi(newObj, id?.gId);
                setDisBtn(false)
                enqueueSnackbar('Updated Personal Goal Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                desc.setData(""); id?.setGId(null); goalfn(); formik.resetForm(); setOpen(false);
            }

            async function newGoal() {
                setDisBtn(true)
                const newObj = {
                    "description": values.description,
                    "user": userid,
                    "staff": null,
                    "supervisor": null,
                }
                const { data } = await addGoalApi(newObj);
                setDisBtn(false)
                enqueueSnackbar('Added Personal Goal Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                desc.setData(""); id?.setGId(null); goalfn(); formik.resetForm(); setOpen(false);
            }



            if (id?.gId) {
                return editGoal()
            }
            newGoal();

        }


    });

    React.useEffect(() => {
        if (desc?.data !== "") {
            formik.setFieldValue('description', desc?.data)

        }
        console.log(typeof userid, typeof id)
    }, [desc])
    return (
        <>
            <Dialog open={isOpen} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2 }}>
                        <Typography variant="h4">
                            {id?.gId ? "Edit Personal Goal" : "Add Personal Goal"}
                        </Typography>
                        <IconButton onClick={() => { desc.setData(""); id?.setGId(null); setOpen(false); formik.resetForm(); }}><Close /></IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ minHeight: "250px", py: 2, px: 4 }}>
                    <Typography variant="h6" sx={{ my: 2 }}>{id?.gId ? "Edit Goal" : "Add New Goal"}</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ width: "100%", my: 2 }}>
                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={5}
                                name="description"
                                {...formik.getFieldProps('description')}
                            />
                            {formik.errors.description && formik.touched.description ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.description}</Typography> : null}
                            <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                                <Button disabled={disBtn} type="variant" variant="contained">{id?.gId ? "Edit Goal" : "Add Goal"} {disBtn && <CircularProgress sx={{ ml: 1 }} size={14} />}</Button>
                            </Stack>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}