import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from "../../api";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  usersList: {
    results: [],
    count: 0
  },
  activityLogData: {
    results: [],
    count: 0
  },
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // action: function
    apiRequested: (state) => {
      state.isLoading = true;
    },
    apiRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addBqaUser: (state, action) => {
      state.isLoading = false;
      state.usersList = { results: [action.payload, ...state.usersList.results], count: state.usersList.count + 1 }
    },
    getAllBqaUsers: (state, action) => {
      state.isLoading = false;
      const { results, count } = action.payload;
      state.usersList = { results, count }
    },
    removeUser: (state, action) => {
      console.log(action.payload)
      // const newArr = state?.results.filter(obj => obj.id !== action.payload)

      // state = { results: newArr, count: state?.count - 1 }
      // return state;
    },
    getActivityLog: (state, action) => {
      state.isLoading = false;
      const { results, count } = action.payload;
      state.activityLogData = { results, count }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { apiRequested, apiRequestFailed, addBqaUser, getAllBqaUsers, removeUser, getActivityLog } = slice.actions;

// Action Creators
const url = "/users";

export const getAllUsersList = (pageNo) =>
  apiCallBegan({
    url: `${url}?page=${pageNo}`,
    onStart: apiRequested.type,
    onSuccess: getAllBqaUsers.type,
    onError: apiRequestFailed.type,
  });

export const addNewUser = (user) =>
  apiCallBegan({
    url,
    method: "POST",
    data: user,
    onSuccess: addBqaUser.type,
  });

export const searchUser = (query) =>
  apiCallBegan({
    url: `${url}/search?value=${query}`,
    onStart: apiRequested.type,
    onSuccess: getAllBqaUsers.type,
    onError: apiRequestFailed.type,
  });

export const getDeactivatedUsersList = () =>
  apiCallBegan({
    url: `${url}?is_deleted=True`,
    onStart: apiRequested.type,
    onSuccess: getAllBqaUsers.type,
    onError: apiRequestFailed.type,
  });

export const deactivateUser = (userId) =>
  apiCallBegan({
    url: `${url}/${userId}`,
    method: 'DELETE',
    onStart: apiRequested.type,
    onSuccess: removeUser.type,
    onError: apiRequestFailed.type,
  });

export const getUsersActivityLog = (obj) =>
  apiCallBegan({
    url: `activity_log/filters?start_date=${obj.fromDate}&end_date=${obj.toDate}&name=${obj.userName}&action=${obj.action}&action_type=${obj.actionType}&page=${obj.page}&value=${obj.filterList}`,
    onStart: apiRequested.type,
    onSuccess: getActivityLog.type,
    onError: apiRequestFailed.type,
  });
