import React from 'react';
// REACT ROUTER IMPORTS
import { useDispatch, useSelector } from 'react-redux';
// MATERIAL IMPORTS
import {
    Box,
    Dialog, DialogTitle, DialogContent, Divider,
    IconButton,
    Stack,
    Tab, Tabs, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
// COMPONENTS IMPORTS
import { getAllGoals } from '../../../../../../redux/action/staffAction';
import GoalPanel from '../../../../../staffComponents/staff/goalPanel';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../../contexts/staffContext';
// API IMPORTS
import { getGoalApi, getTeamGoalApi, getGroupGoalApi } from '../../../../../../_apis_/authApi.js/staffApi';
// import { TabContext, TabList, TabPanel } from '@material-ui/lab';


export default function GoalsDialog({ type }) {
    // REDUX STATES
    const dispatch = useDispatch();
    const goals = useSelector(state => state.goals)
    // REACT STATES
    const [value, setValue] = React.useState(0)
    const [isEdit, setIsEdit] = React.useState(false);
    const [goalData, setGoalData] = React.useState();
    const { grpId, setGrpId, setSupervisrID, supervisrID, employeeID, setEmployeeID, isGoalOpen, setGoalOpen, setNewGoal, newGoal, goalPanel, setGoalPanel, teamId, setTeamId } = React.useContext(StaffContext);

    // METHOD / FUNCTION
    async function getGroupGoals() {
        const { data } = await getGroupGoalApi(grpId);
        dispatch(getAllGoals(data?.results));
    }
    async function getTeamGoals() {
        const { data } = await getTeamGoalApi(teamId);
        dispatch(getAllGoals(data?.results));
    }

    async function getStaffGoals() {
        const { data } = await getGoalApi();
        dispatch(getAllGoals(data.results.filter(obj => obj.staff === (supervisrID || employeeID))));
    }


    // USEEFFECT STATES

    React.useEffect(() => {
        if ((type === "Supervisors" || type === "Employees") && (supervisrID || employeeID)) {
            return getStaffGoals()
        }

        if (type === "Teams" && teamId) {

            return getTeamGoals();
        }
        if (type === "Groups" && grpId) {

            return getGroupGoals()
        }
    }, [isGoalOpen])
    // }, [goalPanel])

    const handleChange = (event, newValue) => {
        setGoalPanel(newValue);
        if (newValue !== 0) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    };



    return (
        <Dialog open={isGoalOpen} fullWidth maxWidth="sm">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2 }}>
                    <Typography variant="h4">{isEdit ? "Edit Goals" : "Add Goals"}</Typography>
                    <IconButton onClick={() => { setGoalOpen(false); setGoalPanel(0); setNewGoal(false); setSupervisrID(null); setEmployeeID(null); setTeamId(null); setGrpId(null); setIsEdit(false); }}><Close /></IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minHeight: "390px", px: 0 }}>
                <Box sx={{ pb: 1, px: 3 }}>
                    <Tabs value={goalPanel} onChange={handleChange}>
                        <Tab label="Add" />
                        {newGoal ? <Tab label="Edit Existing Goals" /> : null}
                    </Tabs>
                </Box>
                <Divider />
                <Box sx={{ px: 3, py: 1 }}>
                    <GoalPanel isEdit={isEdit} data={value} type={type} />
                </Box>

            </DialogContent>
        </Dialog>
    )
}