import PropTypes from 'prop-types';
import React, { useState } from 'react';
// material
import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, Stack, TableRow, IconButton, TableCell, TableHead, TableSortLabel, Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import Icon from 'react-multi-date-picker/components/icon';

// ----------------------------------------------------------------------


export default function SortingSelectingMyScore({
    tableOrder,
    handleTableOrder,
    headLabel

}) {

    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT STATES
    const [showIcon, setShowIcon] = useState(false);

    return (
        <TableHead>
            <TableRow>
                {headLabel.map((headCell) => (
                    <TableCell

                        key={headCell.id}
                        align={headCell.id === "name" || headCell.id === "description" ? "left" : "center"}
                        sx={{ px: 1, margin: "0 auto", minWidth: "140px", py: 2 }}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {(!handleTableOrder || !headCell.id === 'id') && <>{headCell.label}</>}
                        {(handleTableOrder && headCell.id === 'id') && <Stack onMouseLeave={() => setShowIcon(false)} onMouseEnter={() => setShowIcon(true)} flexDirection='row' alignItems='center'>
                            {headCell.label}&nbsp; {showIcon && <IconButton onClick={handleTableOrder}>
                                {!tableOrder ? <ArrowUpward fontSize='small' /> : <ArrowDownward fontSize='small' />}
                            </IconButton>}
                        </Stack>}

                    </TableCell>
                    // <TableCell

                    //   key={headCell.id}
                    //   align="center"
                    //   sx={{ width: "100%", border: "1px solid red", margin: "0 auto" }}
                    //   padding={headCell.disablePadding ? 'none' : 'normal'}
                    //   sortDirection={orderBy === headCell.id ? order : false}
                    // >
                    //   <TableSortLabel
                    //     active={orderBy === headCell.id}
                    //     direction={orderBy === headCell.id ? order : 'asc'}
                    //     onClick={createSortHandler(headCell.id)}
                    //   >
                    //     {headCell.label}
                    //     {orderBy === headCell.id ? (
                    //       <Box component="span" sx={{ ...visuallyHidden }}>
                    //         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    //       </Box>
                    //     ) : null}
                    //   </TableSortLabel>
                    // </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

}