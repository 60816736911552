import React from 'react';

// REACT ROUTER IMPORTS
import { useNavigate, useSearchParams } from "react-router-dom";

// MATERIAL UI IMPORT
import {
    Table, TableHead, TableContainer, TableRow, TableCell, TableBody, TablePagination,
    Box, Stack, Button, Typography, OutlinedInput, Divider, InputAdornment, CircularProgress
} from "@material-ui/core";

// MATERIAL STYLED
import { styled } from '@material-ui/core/styles';

// DND
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// OTHER IMPORTS
import moment from "moment";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import Scrollbar from '../../../../../components/Scrollbar';
import AnalyticsWrapper from "../../../../dashboard/analyticswrapper";
import AddCustomReportDialog from "../../dialog/custom_analytics_report_filter"
import AddedCustomReports from "./custom/added_cutom_reports";



// Context IMPORTS
import { AnalyticContext } from "../../../../../contexts/AnalyticContext";

// API IMPORTS
import { getAcceptanceByTeamApi, getAllCustomAnalyticReportApi } from "../../../../../_apis_/authApi.js/analytic_api";
import { PATH_DASHBOARD } from '../../../../../routes/paths';






export default function CustomDashboardTest() {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // CONTEXT STATES
    const { isLoading, setIsLoading, acceptanceByTeamData, setacceptanceByTeamData, acceptanceByGrpData, setacceptanceByGrpData, selectedDateRange, setSelectedDateRange, selectedFilter, setSelectedFilter, selectedScoreCard, setSelectedScoreCard, selectedEventType, setSelectedEventType, selectedEventSubType, setSelectedEventSubType, selectedEvaluator, setSelectedEvaluator, selectedDate, setSelectedDate, selectedWeek, setSelectedWeek, customData, setCustomData } = React.useContext(AnalyticContext);


    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    // STATES
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);

    // USE-EFFECT METHODS
    React.useEffect(() => {
        fetchAllCustomReports();
    }, []);


    const fetchAllCustomReports = async () => {
        const { data } = await getAllCustomAnalyticReportApi();
        setCustomData(data?.map(item => ({ ...item, isLoading: 'True' })));
    }


    const fetchAcceptanceByTeamData = async (pgNo) => {
        setacceptanceByTeamData([]);
        setIsLoading(true);
        const { data } = await getAcceptanceByTeamApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("filter"), searchParams.get("scorcard") === 'all' ? '' : searchParams.get("scorcard"), searchParams.get("envnt") === 'all' ? '' : searchParams.get("envnt"), searchParams.get("subEvnt") === 'all' ? '' : searchParams.get("subEvnt"), searchParams.get("eval") === 'all' ? '' : searchParams.get("eval"), searchParams.get("scorecard_group"), pgNo);
        setacceptanceByTeamData(data?.results);
        setIsLoading(false);
    }

    // METHODS
    const handleChangePage = (newPage) => {
        fetchAcceptanceByTeamData(newPage + 1);
        setPage(newPage);
    }


    const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
        width: 240,
        height: 45,
        transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`
        }
    }));

    const mbLink = [
        { name: 'Home', href: PATH_DASHBOARD.general.dashboard },
        { name: 'Analytics', href: "/analytics" },
        { name: "Custom Dashboard" }]
    return (
        <>
            <AnalyticsWrapper val="" sbVal="" subModule="Custom Dashboard" LabelName="Custom" brdCrmbLink={mbLink}>
                {customData?.length === 0 && <Box sx={{ py: 1, width: "100%", minHeight: "350px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h4" sx={{ my: 1 }}>This is your custom dashboard area</Typography>
                    <AddCustomReportDialog addUserButton isEmptyCustom text="Add Your First Report" filterValue={undefined} />
                </Box>}

                {customData?.length > 0 &&
                    <DndProvider backend={HTML5Backend}>
                        <AddedCustomReports data={customData} />
                    </DndProvider>
                }

            </AnalyticsWrapper>


        </>
    )
}