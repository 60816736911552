import PropTypes from 'prop-types';

import { useMemo } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
// hooks
import ApexCharts from 'apexcharts';
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const { themeMode, themeDirection, setColor } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, action: { selected: setColor?.main, hover: "#e3e4e9e8" }, mode: 'light' } : { ...palette.dark, action: { selected: setColor?.dark, hover: "#e3e4e9e8" }, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      ApexCharts: {
        theme: {
          palette: 'palette1'
        }
      }
    }), [isLight, themeDirection, setColor]
  );
  // Apex.theme.palette: isLight
  // const chartTheme = createTheme({
  //   ApexCharts.theme.palette:'palette10'

  // })

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
