// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {

          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            // backgroundColor: theme.palette.grey[400]
            backgroundColor: 'red'

          }
        },
        // containedPrimary: {
        //   color: theme.palette.text.buttonText,
        //   stroke: 'white',
        //   strokeWidth: '1px',
        //   fontWeight: 'lighter',
        //   fontSize: '12px',
        //   borderRadius: '5px',
        //   backgroundColor: 'white',
        //   border:theme.palette.text.primaryBorder,
        //   '&:hover': { backgroundColor: 'white' },
        //   boxShadow: 'none'
        // },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          backgroundColor: theme.palette.action.selected,
          color: '#fff',


          '&:hover': {
            backgroundColor: theme.palette.action.selected,
            color: '#fff'
          }
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        // outlined
        outlinedPrimary: {
          color: theme.palette.action.selected,
          border: `1px solid ${theme.palette.action.selected}`,

          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            border: `1px solid ${theme.palette.action.selected}`,
            color: theme.palette.action.selected,
          }
        },
        outlinedInherit: {
          color: theme.palette.mode === "light" ? '#666666' : theme.palette.action.selected,
          border: theme.palette.mode === "light" ? `1px solid  ${theme.palette.grey[500_32]}` : `1px solid ${theme.palette.text.primary}`,

          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            border: theme.palette.mode === "light" ? `1px solid  ${theme.palette.grey[500_32]}` : `1px solid ${theme.palette.text.primary}`,
            color: theme.palette.mode === "light" ? '#666666' : theme.palette.action.selected,
          }
        },
        textPrimary: {
          color: theme.palette.action.selected,
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
