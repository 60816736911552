import {
    GetSaff, GetGroups, GetTeams,
    AddStaff, EditStaff, DelStaff, AddSup, RestoreStaff,
    GetTasks, NewTask, EditTask,
    AddTeams, EditTeams, DelTeams,
    AddGroup, EditGroup, DelGroup,
    getGoals, AddGoal, EditGoal, DelGoal,
    GetEmps, AddEmps, EditEmps, DelEmps, AccessEmp,
    GetSups, AddSups, EditSups, DelSups, AccessSup,
    listAllTypeSup, listAllTypeGrp, listAllTypeTeam
}
    from '../types/typeStaff';

const initialData = {};
const initialEmp = {};
const initialSup = {};
const initialDataGroups = {};
const initialDataTeams = {};
const intialDataTasks = [];
const initialGoals = [];
const initialSupList = [];
const initialGrpList = [];
const intialTeamList = [];


// ------------*STAFF REDUCER*-----------------

/* eslint-disable */

export const AllStaffReduceR = (state = initialData, action) => {
    switch (action.type) {
        case GetSaff: {
            const { results, count } = action.payload;
            state = { results, count }
            // action.payload?.map((val) => { if ((val.staff_type === 20 || val.staff_type === 30) && !val.user.is_deleted) { supervisors.push(val) } return false })
            // const employee = [];
            // action.payload?.map((val) => { if (val.staff_type === 10 && !val.user.is_deleted) { employee.push(val) } return false })
            // state = { all: action.payload, emp: employee, sup: supervisors }
            return state;
        }


        case AddStaff: {
            state = { all: [action.payload, ...state.all], emp: [action.payload, ...state.emp], sup: state?.sup }
            return state
        }

        case AddSup: {
            state = { all: [action.payload, ...state.all], emp: state?.emp, sup: [action.payload, ...state.sup] }
            return state;
        }

        case EditStaff: {
            const newArr = state?.all.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj });
            const supervisors = []
            newArr.map((val) => { if ((val.staff_type === 20 || val.staff_type === 30) && !val.user.is_deleted) { supervisors.push(val) } return false });
            const employee = [];
            newArr?.map((val) => { if (val.staff_type === 10 && !val.user.is_deleted) { employee.push(val) } return false })
            state = { all: newArr, emp: employee, sup: supervisors }
            return state
        }

        case DelStaff: {
            // console.log(action.payload)
            const newArr = state?.all.filter(obj => obj.id !== action.payload)
            // console.log(newArr)
            const supervisors = []
            newArr.map((val) => { if ((val.staff_type === 20 || val.staff_type === 30) && !val.user.is_deleted) { supervisors.push(val) } return false });
            const employee = [];
            newArr?.map((val) => { if (val.staff_type === 10 && !val.user.is_deleted) { employee.push(val) } return false })
            state = { all: newArr, emp: employee, sup: supervisors }
            return state;
        }



        default: return state
    }

}


// ----------------------------------EMPLOYEE------------------------------------------------

/* eslint-disable */

export const EmpReduceR = (state = initialEmp, action) => {
    console.log('reducer', state, action)
    switch (action.type) {
        case GetEmps: {
            const { results, count, page_range } = action.payload;
            // const newArr = []
            // action.payload.results?.map(obj => { if (obj?.user?.is_deleted === false) { return newArr.push(obj) } return obj });
            // console.log(newArr)
            state = { results, count, page_range }
            // console.log(state?.results)
            return state;
        }

        case AddEmps: {
            state = { results: [action.payload, ...state.results], count: state?.count + 1 }
            return state
        }


        case EditEmps: {
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj });
            state = { results: newArr, count: state?.count }
            return state
        }

        case AccessEmp: {
            console.log(action.payload)
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return { ...obj, is_agent_access: action.payload.accessValue } } return obj });

            state = { results: newArr, count: state?.count }
            return state
        }

        case DelEmps: {
            // console.log(action.payload)
            const newArr = state?.results.filter(obj => obj.id !== action.payload)
            // console.log(newArr)
            state = { results: newArr, count: state?.count - 1 }
            return state;
        }

        default: return state
    }

}


// ----------------------------------SUPERVISOR------------------------------------------------

/* eslint-disable */

export const SupReduceR = (state = initialSup, action) => {
    switch (action.type) {
        case GetSups: {
            const { results, count, page_range } = action.payload;
            const newArr = []
            results?.map(obj => { if (!obj?.user?.is_deleted) { return newArr.push(obj) } return obj });
            // console.log(newArr)
            state = { results: newArr, count, page_range }
            // console.log(action.payload)
            return state;
        }


        case AddSups: {
            state = { results: [action.payload, ...state.results], count: state?.count + 1 }
            return state
        }

        case EditSups: {
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj });
            state = { results: newArr, count: state?.count }
            return state
        }

        case AccessSup: {
            console.log(action.payload)
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return { ...obj, is_agent_access: action.payload.accessValue } } return obj });

            state = { results: newArr, count: state?.count }
            return state
        }

        case DelSups: {
            // console.log(action.payload)
            const newArr = state?.results.filter(obj => obj.id !== action.payload)
            // console.log(newArr)
            state = { results: newArr, count: state?.count - 1 }
            return state;
        }

        default: return state
    }

}



// --------------*GROUP REDUCER*------------------

/* eslint-disable */

export const GroupReduceR = (state = initialDataGroups, action) => {
    switch (action.type) {

        case GetGroups: {
            const { results, count,page_range } = action.payload;
            state = { results, count,page_range }
            return state;
        }
        case AddGroup: {
            state = { results: [action.payload, ...state.results], count: state?.count }
            return state
        }
        case EditGroup: {
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj });
            state = { results: newArr, count: state?.count }
            return state
        }
        case DelGroup: {
            const newArr = state?.results.filter(obj => obj.id !== action.payload)
            // console.log(newArr)
            state = { results: newArr, count: state?.count }
            return state;
        }
        default: return state
    }

}


// --------------*TEAM REDUCER*-----------------
/* eslint-disable */


export const TeamReduceR = (state = initialDataTeams, action) => {
    switch (action.type) {
        case GetTeams: {
            const { results, count, page_range } = action.payload;
            state = { results, count, page_range }
            return state;
        }
        case AddTeams: {
            state = { results: [action.payload, ...state.results], count: state?.count }
            return state
        }
        case EditTeams: {
            const newArr = state?.results?.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj });
            state = { results: newArr, count: state?.count }
            return state
        }
        case DelTeams: {
            const newArr = state?.results.filter(obj => obj.id !== action.payload)
            // console.log(newArr)
            state = { results: newArr, count: state?.count }
            return state;
        }
        default: return state
    }

}







// --------------*TASK REDUCER*--------------------
/* eslint-disable */


export const TaskReduceR = (state = intialDataTasks, action) => {
    switch (action.type) {
        case GetTasks: {
            state = action.payload
            return state;
        }
        case NewTask: {
            state = { ...state, results: [action.payload, ...state.results] }
            return state;
        }
        case EditTask: {
            const newArr = state.results.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj })
            state = { ...state, results: newArr }
            return state;
        }
        default: return state
    }

}

// ---------------*Goals Reduducers*----------------------
/* eslint-disable */

export const GoalReduceR = (state = initialGoals, action) => {
    switch (action.type) {
        case getGoals: {

            state = action.payload
            return state;
        }
        case AddGoal: {
            console.log(action.payload)
            state = [action.payload, ...state]
            return state;
        }
        case EditGoal: {
            const newArr = state.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj })
            state = [...newArr]
            return state;
        }
        case DelGoal: {
            console.log(action.payload)
            state = state?.filter(obj => obj.id !== action.payload)
            console.log(state)
            return state;
        }
        default: return state
    }

}

// -------------*restore*----------------------------
/* eslint-disable */

export const restoreReduceR = (state = initialGoals, action) => {
    switch (action.type) {
        case RestoreStaff: {

            const { results, count, page_range } = action.payload;
            state = { results, count, page_range }
            return state;
        }
        // case AddGoal: {
        //     console.log(action.payload)
        //     state = [...state, action.payload]
        //     return state;
        // }
        // case EditGoal: {
        //     const newArr = state.map(obj => { if (obj.id === action.payload.id) { return action.payload } return obj })
        //     state = [...newArr]
        //     return state;
        // }
        // case DelGoal: {
        //     console.log(action.payload)
        //     state = state?.filter(obj => obj.id !== action.payload)
        //     console.log(state)
        //     return state;
        // }
        default: return state
    }

}


// ------------------------LIST-ALL-SUP-----------------------------------------

export const listSupReduceR = (state = initialSupList, action) => {
    switch (action.type) {
        case listAllTypeSup: {
            state = action.payload
            return state;
        }

        default: return state
    }

}


export const listGrpReduceR = (state = initialGrpList, action) => {
    switch (action.type) {
        case listAllTypeGrp: {
            state = action.payload
            return state;
        }

        default: return state
    }

}

export const listTeamReduceR = (state = intialTeamList, action) => {
    switch (action.type) {
        case listAllTypeTeam: {
            state = action.payload
            return state;
        }

        default: return state
    }

}