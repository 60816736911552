import React, { useRef } from 'react'
// MATERIAL IMPORTS
import { CircularProgress, Stack, Typography, IconButton, Box, Button, Tooltip } from "@material-ui/core";
import { Cancel, BarChartOutlined, SettingsOutlined } from "@material-ui/icons";
import { useDrag, useDrop } from 'react-dnd'
import { Resizable } from "re-resizable";
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { Icon } from '@iconify/react';
import ItemTypes from './ItemTypes';
import { MIconButton } from '../../../../../../components/@material-extend';
import CustomDashboardTableView from "./table_view_section";
import AddCustomReportDialog from "../../../dialog/custom_analytics_report_filter";
import QuestionByStaffGraph from '../../../../../dashboard/analytics/staff/1.2 Question By Staff/QuestionByStaffGraph';
import EvaluationPerPeriodTable from "../evaluation/evaluation_per_period_table";
import EvaluationPerPeriodGraph from "../../../../../analytic component/evaluation/per_period_graph";
import { URLFront } from "../../../../../../backend";


// Context IMPORTS
import {
    AnalyticContext
} from "../../../../../../contexts/AnalyticContext";
import {
    StaffContext
} from "../../../../../../contexts/staffContext";
import ScoreCardsContext from "../../../../../../contexts/ScoreCardsContext";
import { SettingsContext } from "../../../../../../contexts/SettingsContext";

// API IMPORTS
import { getQuestionByStaffApi, delCustomAnalyticReportApi, getAllCustomAnalyticReportApi, getEvaluationPerPeriodApi, getCauseByScorecardGraphApi, getOverAllTeamTrendApi, getGroupSummaryApi, getPointsOfFailureApi } from "../../../../../../_apis_/authApi.js/analytic_api";
import { getGdashboardDetailApi } from "../../../../../../_apis_/authApi.js/globalDashboardApi";

const Card = ({ id, text, index, moveCard, width1, apiData }) => {
    const { open, setOpen, titleText, setTitleText, customData, setCustomData,
        reportFieldValue, setReportFieldValue, setReportSettingObject,
        radioBtnValue, setRadioBtnValue,
        setPerPeriodData, setPerPeriodLoading, setPerPeriodDateType, setPerPeriodGraphCategory, setPerPeriodGraphData,
        /* for 1.2 */
        setSelAnaFilters, setSelectedFromDate, setSelectedToDate, setSelectedAnaDateRange, setSelectedFilterBy, setSelectedGroupFilter, setSelectedTeamFilter, setselectedStaFilter, setSelectedFilterEventType, setSelectedFilterSubType,
        /* for 3.2 */
        setReviewByEvaluatorData, setReviewByEvaluatorLoading, getScoringPatterns, selectedFlag, setReviewBySupervisorData, setReviewBySupervisorLoading, reviewBySupervisorDateType, selectedEvaluationHighlight, setSelectedEvaluationHighlight, selectedEvaluationScoreCardMultiple, setSelectedEvaluationScoreCardMultiple, selectedEvaluationEventSubType, setSelectedEvaluationEventSubType, selectedEvaluationEventType, setSelectedEvaluationEventType, selectedEvaluationScoreCard, setSelectedEvaluationScoreCard, selectedEvaluationDateAnaRange, setSelectedEvaluationAnaDateRange, perScorecardDateType, setPerScorecardData, setPerScorecardByStaffData, setSelAnaEvaluationFilters, setSelectedEvaluationFilterBy, setSelectedEvaluationTeamFilter, setSwitchView, getEvaluationPerScoreCard, selScDurationInd, selectedEvaluationFromDate, selectedEvaluationToDate, setSelectedEvaluationToDate, setSelectedEvaluationFromDate, selectedEvaluationFilterBy, setFilterNameAcceptanceByAgnt, agentData, setAgentData, isLoadingAgent, setLoadingAgent, countAgent, setCountAgent, selAnaEvaluationFilters, getStaffTrend, getStaffTrendDistribution, getStaffTrendAboBelow, selDuration, subModLabel, getQuestionTrend, selectedStaFilter, selectedEvaluationGroupFilter, setSelectedEvaluationGroupFilter, selectedEvaluationTeamFilter, setSelectedSupervisorFilter, getStaOverTrendByInd, selectedStaffFilter, setSelectedStaffFilter, perPeriodDateType, selectedEvaluationfromWeek, setSelectedEvaluationfromWeek, selectedEvaluationtoWeek, setSelectedEvaluationtoWeek,
        /* for 6.1 */
        setGroupFilterApplied, setSelAnaGroupFilters, setSelectedGroup, setSelectedTeam, setSelectedDate, setSelectedDateRange, setSelectedFilter, setSelectedScoreCard, setSelectedEventType, setSelectedEventSubType, setSelectedEvaluator, setSelectedGroupFromWeek, setSelectedGroupToWeek,
        /* 5.1 */
        setSelectedTeamsFromWeek, setSelectedTeamsToWeek, setSelAnaTeamsFilters, setSelectedTeamsGroupFilter, setSelectedDateAnaTeamsRange, setSelectedTeamsFromDate, setSelectedTeamsToDate, setSelectedTeamsFilterByValue, setSelectedTeamsFilterBy, setSelectedTeamsEventFilter, setSelectedTeamsEventSubTypeFilter,
        /* 4.7 */
        setSelectedScFilterByValue, setSelectedScFromWeek, setSelectedScToWeek, setSelectedScFromDate, setSelectedScToDate, setSelectedDateAnaScRange, setSelectedScFilterBy, setSelectedScGroupFilter, setSelectedScTeamFilter, setselectedScFilter, setSelectedScEventType, setSelectedScSubType, setSelAnaScoreCardFilters,
        // Custom Dashboard
        dialogVisible, updateCustomId, setUpdateCustomId
    } = React.useContext(AnalyticContext)
    const {
        setGroupList, setGlobalTeamId, setStrtDate, setEndDate, setGpId, setCustomDate,
    } = React.useContext(StaffContext)
    const { getScoreLogGroups, getScoreLogScorecards } = React.useContext(ScoreCardsContext);

    const { themeMode } = React.useContext(SettingsContext);


    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ref = useRef(null)
    const [w, setW] = React.useState({ width: 200, height: 450 })
    const [boxWidth, setBoxWidth] = React.useState();
    const [cardData, setCardData] = React.useState([]);
    const [showOptions, setOptions] = React.useState(0);
    const [filterValue, setFilterValue] = React.useState();
    const [percentageQuestionArray, setPercentageQuestionArray] = React.useState([]);
    const [graphQuestionArray, setGraphQuestionArray] = React.useState([]);
    const [graphQuestionIdArray, setGraphQuestionIdArray] = React.useState([]);
    const [customTableData, setCustomTableData] = React.useState();
    const [customDateType, setCustomDateType] = React.useState();
    const [customGraphCategoryData, setCustomGraphCategoryData] = React.useState([]);
    const [customGraphData, setCustomGraphData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        setFilterValue({ ...apiData?.report_params, type: apiData?.report_type, module: apiData?.report_module, id: apiData?.id });
    }, [apiData])

    React.useEffect(() => {
        // console.log(ref.current.clientWidth)
        setW({ width: (width1 / 2), height: 450 })
        setBoxWidth((width1 / 2))
        // console.log(width1)
    }, [ref?.current, width1])


    React.useEffect(async () => {
        setCustomGraphCategoryData([]);
        setCustomGraphData([]);
        const filterObj = {
            fromDate: apiData?.report_params?.from_date,
            // fromDate: "2023-02-19",
            toDate: apiData?.report_params?.to_date,
            // toDate: "2023-04-19",
            filterBy: apiData?.report_params?.filter_by,
            group: apiData?.report_params?.score_card__groups,
            // group: "18",
            scorecard: apiData?.report_params?.score_card,
            // scorecard: "119",
            teams: apiData?.report_params?.teams,
            // teams:'19',
            eventType: apiData?.report_params?.event_type, /* 64 */
            eventTypeEventSubType: apiData?.report_params?.event_sub_type, /* 65 */
            dataLimit: apiData?.report_params?.data_limit,
            supervisor: '',
            highlightValue: '',
            formula: apiData?.report_params?.formula || "",
            reference: '',
        }
        if ((apiData?.report_module === '1.2' && !updateCustomId) || updateCustomId === apiData?.id) {
            // console.log(updateCustomId === apiData?.id, updateCustomId, apiData?.id)
            setIsLoading(true);
            try {
                const { data } = await getQuestionByStaffApi(filterObj);
                console.log('data', data)
                setCardData({ ...data, report_type: apiData?.report_type, id: apiData?.id })
                setIsLoading(false);
                setIsError(false);
                setUpdateCustomId(null)
                if (apiData?.report_type === '20') {
                    const percentageFinder = (total, maxTotal, naTotal = 0, parse = false) => {
                        let totalVal = '';
                        if ((maxTotal - naTotal) !== 0) {
                            if (parse) {
                                totalVal = `${((total / (maxTotal - naTotal)) * 100).toFixed(2)}`;
                            } else {
                                totalVal = `${((total / (maxTotal - naTotal)) * 100).toFixed(2)}%`;
                            }
                        } else {
                            return "0 %"
                        }
                        return totalVal;
                    };
                    const tempArray = [];
                    const tempGraphQuestionArray = [];
                    let k = 0;
                    const tempQuestionIdArray = [];

                    data?.data?.map((daTa) =>
                        daTa?.questions?.map((qtotal, index) => {
                            if (qtotal.total.na_total !== null) {
                                tempArray.push(percentageFinder(qtotal.total.total, qtotal.total.max_total, qtotal.total.na_total, true));
                            } else {
                                tempArray.push(percentageFinder(qtotal.total.total, qtotal.total.max_total, 0, true));
                            }
                            tempQuestionIdArray.push(qtotal.question_id);
                            tempGraphQuestionArray.push(k);
                            k += 1;
                            return '';
                        })
                    );

                    setGraphQuestionIdArray(tempQuestionIdArray);
                    setPercentageQuestionArray(tempArray);
                    setGraphQuestionArray(tempGraphQuestionArray.map((v) => v + 1));
                }
            } catch (err) {
                setIsError(true);
                setIsLoading(false);
            }

        }

        if ((apiData?.report_module === '3.2' && !updateCustomId) || updateCustomId === apiData?.id) {
            setIsLoading(true);
            setCustomTableData([]);

            try {
                const { data } = await getEvaluationPerPeriodApi({ ...filterObj, scoreCard: filterObj?.scorecard }, getDateType(apiData?.report_params?.date_type));
                setCardData({ ...data, report_type: apiData?.report_type, id: apiData?.id })
                setIsLoading(false);
                setIsError(false);
                setUpdateCustomId(null)
                if (apiData?.report_type === '10') {
                    setCustomTableData(data);
                    setPerPeriodLoading(false);
                    setCustomDateType(getDateType(apiData?.report_params?.date_type));

                }
                if (apiData?.report_type === '30') {
                    if (getDateType(apiData?.report_params?.date_type) === "week") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `W${val?.date_num} (${val?.year})` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));

                        setCustomGraphCategoryData(categoryName);

                    }
                    if (getDateType(apiData?.report_params?.date_type) === "month") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `${moment(val?.date_num, 'MM').format('MMMM')?.slice(0, 3)}` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));
                        setCustomGraphCategoryData(categoryName);

                    }
                    if (getDateType(apiData?.report_params?.date_type) === "quarter") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `Q${val?.date_num} (${val?.year})` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));
                        setCustomGraphCategoryData(categoryName);
                    }

                    const arr = [];
                    for (let index = 0; index < data?.score_card?.length; index += 1) {
                        const arr2 = [];
                        const i = index;
                        data?.data[i]?.map(row => arr2.push(row?.count ? row?.count : null));
                        arr.push({ name: `${data?.score_card[i]?.evaluator__first_name} ${data?.score_card[i]?.evaluator__last_name}`, data: arr2 });
                    }
                    setCustomGraphData(arr);
                }
            } catch (err) {
                setIsLoading(false);
                setIsError(false);
            }
        }

        if ((apiData?.report_module === '4.7' && !updateCustomId) || updateCustomId === apiData?.id) {
            setIsLoading(true);
            try {
                const { data } = await getCauseByScorecardGraphApi(filterObj);
                setCardData({ ...data, report_type: apiData?.report_type, id: apiData?.id })
                setIsLoading(false);
                setUpdateCustomId(null)
                setCustomGraphCategoryData(data?.results?.map(item => item?.causes));
                setCustomGraphData([{ name: 'count', data: data?.results?.map(item => item?.results) }]);
            } catch (err) {
                // console.log('error 4.7')
                setIsLoading(false);
                setIsError(false);
                setUpdateCustomId(null)
                setCustomGraphCategoryData([]);
                setCustomGraphData([]);
            }


        }
        if ((apiData?.report_module === '5.1' && !updateCustomId) || updateCustomId === apiData?.id) {

            const getDateType = (id) => {
                let typeOfDate = 'week'
                switch (id) {
                    case '10':
                        typeOfDate = 'week'
                        break;
                    case '20':
                        typeOfDate = 'month'
                        break;
                    case '30':
                        typeOfDate = 'year'
                        break;

                    default:
                        typeOfDate = 'week'
                        break;
                }
                return typeOfDate
            };
            try {
                setIsLoading(true);
                const { data } = await getOverAllTeamTrendApi(filterObj, getDateType(apiData?.report_params?.date_type));
                setCardData({ data, dateType: apiData?.report_params?.date_type, report_type: apiData?.report_type, id: apiData?.id })
                setIsLoading(false);
                setUpdateCustomId(null);
                setIsError(false);
                console.log('5.1 data', data);

                if (apiData?.report_type === '30') {
                    const handleDuration = (data) => {
                        let result;
                        if (getDateType(apiData?.report_params?.date_type) === 'week') {
                            if (data?.created_at__week) {
                                result = data?.created_at__week && `W ${data?.created_at__week}`;
                            } else if (data?.updated_at__week) {
                                result = data?.updated_at__week && `W ${data?.updated_at__week}`;
                            } else if (data?.event_date__week) {
                                result = data?.event_date__week && `W ${data?.event_date__week}`;
                            }
                            // result = data?.created_at__week && `W ${data?.created_at__week}`;
                        } else if (getDateType(apiData?.report_params?.date_type) === 'month') {
                            if (data?.created_at__month) {
                                result = data?.created_at__month && data?.created_at__month <= 12 && moment(data?.created_at__month, 'M').format('MMMM');
                            } else if (data?.updated_at__month) {
                                result = data?.updated_at__month && data?.updated_at__month <= 12 && moment(data?.updated_at__month, 'M').format('MMMM');
                            } else if (data?.event_date__month) {
                                result = data?.event_date__month && data?.event_date__month <= 12 && moment(data?.event_date__month, 'M').format('MMMM');
                            }
                            // result = data && data <= 12 && moment(data, 'M').format('MMMM');
                        } else if (getDateType(apiData?.report_params?.date_type) === 'year') {
                            if (data?.created_at__year) {
                                result = data?.created_at__year && `Q [${data?.created_at__year}]`;
                            } else if (data?.updated_at__year) {
                                result = data?.updated_at__year && `Q [${data?.updated_at__year}]`;
                            } else if (data?.event_date__year) {
                                result = data?.event_date__year && `Q [${data?.event_date__year}]`;
                            }
                            // result = data && `Q ${data}`;
                        }
                        return result;
                    };
                    const handlePercentageYAxis = (dateName) => {
                        let result;
                        if (dateName === 'week') {
                            result = 'weeklyAvg';
                        } else if (dateName === 'month') {
                            result = 'monthlyAvg';
                        } else if (dateName === 'year') {
                            result = 'yearlyAvg';
                        }
                        // console.log(result);
                        return result;
                    };

                    const handlePercentage = (item) => {
                        let result = 0;
                        if ((item?.total_score || item?.na_total) !== 0) {
                            result = ((item?.total_score / (item?.max_val - item?.na_total)) * 100)
                        } else {
                            result = 0;
                        }
                        return result;
                    }

                    const tempArry = [];
                    const tempName = [];
                    data?.table_data?.forEach((dateData) => {
                        tempName.push(dateData?.name);
                        const tempValArray = [];
                        dateData?.records?.forEach((scor) => {

                            if (handlePercentageYAxis(getDateType(Number(apiData?.report_params?.date_type)))) {
                                tempValArray.push(handlePercentage(scor).toFixed('2'))
                                // tempValArray.push(scor[handlePercentageYAxis(dateTypeSecTrend)]?.toFixed(2));

                            }


                        });
                        const newVal = { name: dateData.name, data: tempValArray };
                        tempArry.push(newVal);
                    });
                    setCustomGraphData(tempArry);
                    const tempTimeList = [];
                    data?.headers?.forEach((headData) => {
                        // data1.header_data.forEach((headData) => {
                        tempTimeList.push(handleDuration(headData));
                        // });
                        setCustomGraphCategoryData(tempTimeList);
                    });
                    // console.log(tempArry, tempTimeList);
                }
            } catch (err) {
                setIsLoading(false);
                setIsError(true);
            }

            // if (apiData?.report_type === '10') {
            //     setCustomTableData(data?.results)
            // }

        }

        if ((apiData?.report_module === '6.1' && !updateCustomId) || updateCustomId === apiData?.id) {
            try {
                setIsLoading(true);
                const { data } = await getGroupSummaryApi({ ...filterObj, event_Type: filterObj?.eventType, sub_type: filterObj?.eventTypeEventSubType, filter_By: filterObj?.filterBy, score_card: filterObj?.scorecard }, getDateType(apiData?.report_params?.date_type))
                setCardData({ data, dateType: apiData?.report_params?.date_type, report_type: apiData?.report_type, id: apiData?.id })
                setIsError(false);
                // console.log(data)
                setIsLoading(false);
                setUpdateCustomId(null)

                if (apiData?.report_type === '30') {
                    if (getDateType(apiData?.report_params?.date_type) === "week") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `W${val?.date_num}` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));
                        setCustomGraphCategoryData(categoryName);

                    }
                    if (getDateType(apiData?.report_params?.date_type) === "month") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `${moment(val?.date_num, 'MM').format('MMMM')?.slice(0, 3)}` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));
                        setCustomGraphCategoryData(categoryName);

                    }
                    if (getDateType(apiData?.report_params?.date_type) === "quarter") {
                        const arr = [];
                        data?.header?.map(val => arr.push({ name: `Q${val?.date_num}` }))
                        const categoryName = []
                        arr?.map(val => categoryName.push(val?.name));
                        setCustomGraphCategoryData(categoryName);
                    }

                    const arr = [];
                    for (let index = 0; index < data?.score_card?.length; index += 1) {
                        const arr2 = [];
                        const i = index;
                        data?.data[i]?.map(row => { if (row?.max_total !== undefined && (row?.max_total - row?.na_sum !== 0)) { return arr2.push(row?.max_total !== 0 ? ((row?.total / (row?.max_total - row?.na_sum)) * 100)?.toFixed(2) : 0) } return arr2.push(null) });
                        arr.push({ name: data?.score_card[i]?.score_card__groups__name, data: arr2 });
                    }
                    setCustomGraphData(arr);
                }
            } catch (err) {
                setIsError(true);
                setIsLoading(false);
            }
        }

        if ((apiData?.report_module === '6.3' && !updateCustomId) || updateCustomId === apiData?.id) {
            try {
                setIsLoading(true);
                const { data } = await getPointsOfFailureApi({ ...filterObj, event_Type: filterObj?.eventType, sub_type: filterObj?.eventTypeEventSubType, filter_By: filterObj?.filterBy, score_card: filterObj.scorecard }, getDateType(apiData?.report_params?.date_type))
                setCardData({ data: data?.data, report_type: apiData?.report_type, id: apiData?.id })
                setIsLoading(false);
                setIsError(false);
                setUpdateCustomId(null)
                if (apiData?.report_type === '20') {
                    const arr = [];
                    for (let index = 0; index < 1; index += 1) {
                        const arr2 = [];
                        const i = index;
                        data?.data?.map(row => arr2.push(row?.fail_all));

                        if (arr2.every(row => row === null)) {
                            arr.push({ name: "Fail All", data: [] });
                        } else {
                            arr.push({ name: "Fail All", data: arr2 });
                        }

                        // arr.push({ name: "Fail All", data: arr2 });


                    }
                    for (let index = 0; index < 1; index += 1) {
                        const arr2 = [];
                        const i = index;
                        data?.data?.map(row => arr2.push(row?.fail_section));

                        if (arr2.every(row => row === null)) {
                            arr.push({ name: "Fail Section", data: [] });
                        } else {
                            arr.push({ name: "Fail Section", data: arr2 });
                        }

                        // arr.push({ name: "Fail Section", data: arr2 });

                    }

                    const categoryName = []
                    data?.data?.map(row => categoryName?.push(row?.group_name));
                    setCustomGraphCategoryData(categoryName)
                    if (arr[0]?.data?.length === 0 && arr[1]?.data?.length === 0) {
                        setCustomGraphData([]);

                    } else {
                        setCustomGraphData(arr);
                    }
                }
            } catch (err) {
                setIsLoading(false);
                setIsError(true);
            }

        }

        if ((apiData?.report_module === '7.1' && !updateCustomId) || updateCustomId === apiData?.id) {
            try {
                setIsLoading(true);
                const { data } = await getGdashboardDetailApi(filterObj?.fromDate, filterObj?.toDate, filterObj?.group, undefined, 'created_at');
                setIsLoading(false);
                setIsError(false);
                setUpdateCustomId(null)
                /* eslint-disable camelcase */
                const accepted = data?.data?.supervisor?.reduce((item, { accepted }) => item + accepted, 0);
                const reviewed = data?.data?.supervisor?.reduce((item, { reviewed }) => item + reviewed, 0);
                const onetoone = data?.data?.supervisor?.reduce((item, { onetoone_req }) => item + onetoone_req, 0);
                const viewed = data?.data?.supervisor?.reduce((item, { score_viewed }) => item + score_viewed, 0);
                const total = data?.data?.supervisor?.reduce((item, { total_result }) => item + total_result, 0);
                const created = (total - (accepted + reviewed + onetoone + viewed));
                // console.log(accepted, reviewed, onetoone, viewed, total);
                setCustomGraphData([viewed, accepted, onetoone, reviewed, created])
                setCustomGraphCategoryData(["Viewed", "Accepted", "1:1 Requested", "Reviewed", "Created"])
            } catch (err) {
                setIsLoading(false);
                setIsError(true);
            }

        }





    }, [apiData])


    const getDateType = (id) => {
        let typeOfDate = 'week'
        switch (id) {
            case '10':
                typeOfDate = 'week'
                break;
            case '20':
                typeOfDate = 'month'
                break;
            case '30':
                typeOfDate = 'quarter'
                break;

            default:
                typeOfDate = 'week'
                break;
        }
        return typeOfDate
    };

    const getDataLimit = (id) => {
        let limit = 'Last 50 Results';
        switch (id) {
            case '10':
                limit = `Last 50 Results`;
                break;
            case '20':
                limit = `Last 100 Results`;
                break;

            default:
                limit = `Last 100 Results`;
                break;
        }
        return limit
    }

    const getFilterByRange = (val) => {
        let value = 'Score Date';
        switch (val) {
            case 'created_at':
                value = 'Score Date'
                break;
            case 'event_date':
                value = 'Event Date'
                break;
            case 'updated_at':
                value = 'Last Edit Date'
                break;

            default:
                value = 'Score Date'
                break;
        }
        return value
    }

    const style = {
        border: "solid 1px #ddd",
        background: themeMode === 'light' ? "white" : '#636e72',
        padding: '0.5rem 1rem',
        cursor: 'move',


    }
    const style2 = {
        border: "solid 1px #ddd",
        background: themeMode === 'light' ? "#f0f0f0" : '#636e72',
        cursor: 'move',
        marginLeft: '10px',
        marginTop: '0px',
        marginBottom: '10px',
    }

    const [, drop] = useDrop({
        accept: 'card',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
                // console.log("lower", hoverClientY, hoverMiddleY)
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                // console.log("higher")
                return
            }
            // Time to actually perform the action
            // console.log(dragIndex, hoverIndex)
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    const getWeeks = (val) => {
        let weekNo = 0;
        if (moment(val).format('MM-DD') === '01-01') {
            weekNo = 1
            // setSelectedEvaluationfromWeek(1)
        } else {
            weekNo = moment(val).year(moment(val).format('YYYY')).week();
            // setSelectedEvaluationfromWeek(moment(newValue).year(moment(newValue).format('YYYY')).week());
        }
        return weekNo;
    }

    const handleRedirect = () => {
        if (apiData?.report_module === '7.1') {
            // alert(apiData?.report_params?.score_card__groups)
            setGpId([apiData?.report_params?.score_card__groups]);
            setCustomDate(true);
            setGroupList([apiData?.report_params?.score_card__groups]); setGlobalTeamId([apiData?.report_params?.teams]); setStrtDate(apiData?.report_params?.from_date); setEndDate(apiData?.report_params?.to_date);
            window.open(`${URLFront}/dashboard?custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_group=${apiData?.report_params?.score_card__groups}&custom_teams=${apiData?.report_params?.teams}`, "_blank")
        }
        if (apiData?.report_module === '1.2') {
            setSelectedFromDate(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))
            setSelectedToDate(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))
            setSelectedAnaDateRange('Custom Date Range');
            setSelectedFilterBy(getFilterByRange(apiData?.report_params?.filter_by))
            setSelectedGroupFilter(Number(apiData?.report_params?.score_card__groups));
            setSelectedTeamFilter(apiData?.report_params?.teams)
            setselectedStaFilter([Number(apiData?.report_params?.score_card)])
            setSelectedFilterEventType(apiData?.report_params?.event_type)
            setSelectedFilterSubType(apiData?.report_params?.event_sub_type)

            const filterObj =
            {
                "fromDate": moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'),
                "toDate": moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'),
                "filterBy": apiData?.report_params?.filter_by,
                "group": Number(apiData?.report_params?.score_card__groups),
                "teams": apiData?.report_params?.teams,
                "scoreCard": apiData?.report_params?.score_card,
                "scoreCardGroups": Number(apiData?.report_params?.score_card__groups),
                "eventType": apiData?.report_params?.event_type,
                "eventTypeEventSubType": apiData?.report_params?.event_sub_type,
                "staff": "",
                "scoreCardCategory": "",
                "evaluator": "",
                "page": "1",
                "filterName": "",
                "highlightValue": "",
                "flag": "",
                "formula": apiData?.report_params?.formula || "",
                "reference": "",
                "section": "",
                "supervisor": "",
                "question": "",
                "teamsId": "",
                "filterByRange": getFilterByRange(apiData?.report_params?.filter_by)
            }
            setSelAnaFilters({ ...filterObj, scoreCard: [Number(apiData?.report_params?.score_card)], scoreCardSingle: Number(apiData?.report_params?.score_card), scoreCardSingle2: Number(apiData?.report_params?.score_card) })
            window.open(`${URLFront}/analytics/staff/QuestionByStaff?custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_scorecard=${apiData?.report_params?.score_card}&custom_group=${apiData?.report_params?.score_card__groups}&custom_teams=${apiData?.report_params?.teams}&custom_filter_range=${getFilterByRange(apiData?.report_params?.filter_by)}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}&formula=${apiData?.report_params?.formula}`, "_blank")
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.scoreCardGroups })
            getScoreLogScorecards({ ...filterObj, group: filterObj?.scoreCardGroups, staff: '', sup_grp: '' })
        }
        if (apiData?.report_module === '3.2') {

            setSelectedEvaluationScoreCard(Number(apiData?.report_params?.score_card));
            setSelectedEvaluationScoreCardMultiple([Number(apiData?.report_params?.score_card)]);
            setSelectedEvaluationEventType(apiData?.report_params?.event_type);
            setSelectedEvaluationEventSubType(apiData?.report_params?.event_sub_type);
            setSelectedEvaluationToDate(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'));
            setSelectedEvaluationFromDate(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'));

            setSelectedEvaluationAnaDateRange('Custom Date Range');
            setSelectedEvaluationGroupFilter(Number(apiData?.report_params?.score_card__groups));
            setSelectedEvaluationTeamFilter(apiData?.report_params?.teams);
            setSelectedEvaluationFilterBy(apiData?.report_params?.filter_by);
            setSelectedEvaluationfromWeek(getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD')));
            setSelectedEvaluationtoWeek(getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD')));


            const filterObj = {
                fromDate: moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'),
                toDate: moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'),
                fromWeek: getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD')),
                toWeek: getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD')),
                filterByRange: apiData?.report_params?.filter_by,
                group: Number(apiData?.report_params?.score_card__groups),
                teams: apiData?.report_params?.teams,
                scoreCard: Number(apiData?.report_params?.score_card),
                scoreCardMultiple: [Number(apiData?.report_params?.score_card)],
                scoreCardGroups: Number(apiData?.report_params?.score_card__groups),
                eventType: apiData?.report_params?.event_type,
                eventTypeEventSubType: apiData?.report_params?.event_sub_type,
                staff: "",
                scoreCardCategory: "",
                evaluator: "",
                page: '1',
                filterName: '',
                highlightBelow: "",
                // flag: selScDurationInd,
                section: "",
                supervisor: "",
                numericValues: "",
                // numericValues: true,,
                formula: ''
            };
            setSelAnaEvaluationFilters({
                ...filterObj, filterBy: apiData?.report_params?.filter_by
            })
            window.open(`${URLFront}/analytics/evaluation/evaluation_per_period?custom_from_week=${getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))}&custom_to_week=${getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))}&custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_scorecard=${apiData?.report_params?.score_card}&custom_group=${apiData?.report_params?.score_card__groups}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}`, "_blank")
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.group, filterBy: apiData?.report_params?.filter_by })
            getScoreLogScorecards({ ...filterObj, group: filterObj?.group, staff: '', sup_grp: '', filterBy: apiData?.report_params?.filter_by })
        }
        if (apiData?.report_module === '6.1' || apiData?.report_module === '6.3') {

            setSelectedGroup(Number(apiData?.report_params?.score_card__groups) === 0 ? '' : Number(apiData?.report_params?.score_card__groups));
            setSelectedTeam(apiData?.report_params?.teams);
            setSelectedDate({ date1: moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'), date2: moment(apiData?.report_params?.to_date).format('YYYY-MM-DD') });
            setSelectedDateRange(Number(11));
            setSelectedFilter(apiData?.report_params?.filter_by);
            setSelectedScoreCard(apiData?.report_params?.score_card === '' ? '' : Number(apiData?.report_params?.score_card));
            setSelectedEventType(apiData?.report_params?.event_type);
            setSelectedEventSubType(apiData?.report_params?.event_sub_type);
            setSelectedGroupFromWeek(getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD')));
            setSelectedGroupToWeek(getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD')));
            const filterObj = {
                group: Number(apiData?.report_params?.score_card__groups) === 0 ? '' : Number(apiData?.report_params?.score_card__groups),
                teams: apiData?.report_params?.teams,
                dateRange: Number(11),
                fromWeek: getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD')),
                toWeek: getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD')),
                fromDate: moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'),
                toDate: moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'),
                score_card: apiData?.report_params?.score_card === '' ? '' : Number(apiData?.report_params?.score_card),
                filter_By: apiData?.report_params?.filter_by,
                event_Type: apiData?.report_params?.event_type,
                sub_type: apiData?.report_params?.event_sub_type,
                evaluator: "",
                highlightBelow: "",
            }
            setSelAnaGroupFilters(filterObj)
            setGroupFilterApplied(true);
            if (apiData?.report_module === '6.3') {
                window.open(`${URLFront}/analytics/group/points_of_failure?custom_from_week=${getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))}&custom_to_week=${getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))}&custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_scorecard=${apiData?.report_params?.score_card}&custom_group=${apiData?.report_params?.score_card__groups}&custom_teams=${apiData?.report_params?.teams}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}`, "_blank")
            }
            if (apiData?.report_module === '6.1') {
                window.open(`${URLFront}/analytics/group/summary?custom_from_week=${getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))}&custom_to_week=${getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))}&custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_scorecard=${apiData?.report_params?.score_card}&custom_group=${apiData?.report_params?.score_card__groups}&custom_teams=${apiData?.report_params?.teams}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}`, "_blank")
            }
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.group, filterBy: apiData?.report_params?.filter_by })
            if (apiData?.report_module === '6.1') getScoreLogScorecards({ ...filterObj, group: filterObj?.group, staff: '', sup_grp: '', filterBy: apiData?.report_params?.filter_by, eventType: filterObj?.event_Type, eventTypeEventSubType: filterObj?.sub_type, scoreCard: filterObj?.score_card, scoreCardCategory: '' })
        }
        if (apiData?.report_module === '5.1') {
            setSelectedTeamsFromWeek(getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD')))
            setSelectedTeamsToWeek(getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD')))
            setSelectedTeamsGroupFilter(Number(apiData?.report_params?.score_card__groups));
            setSelectedDateAnaTeamsRange('Custom Date Range');
            setSelectedTeamsFromDate(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'));
            setSelectedTeamsToDate(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'));
            setSelectedTeamsFilterBy(getFilterByRange(apiData?.report_params?.filter_by));
            setSelectedTeamsFilterByValue(apiData?.report_params?.filter_by);
            setSelectedTeamsEventFilter(apiData?.report_params?.event_type);
            setSelectedTeamsEventSubTypeFilter(apiData?.report_params?.event_sub_type);
            const getIntervalType = () => {
                if (apiData?.report_params?.date_type === '10') {
                    return 'week'
                }
                if (apiData?.report_params?.date_type === '20') {
                    return 'month'
                }
                return 'year'
            }


            const filterObj = {
                id: '',
                dateRange: 'Custom Date Range',
                filterByRange: getFilterByRange(apiData?.report_params?.filter_by),
                fromDate: moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'),
                toDate: moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'),
                filterBy: apiData?.report_params?.filter_by,
                group: Number(apiData?.report_params?.score_card__groups),
                scoreCard: '',
                scoreCardGroups: Number(apiData?.report_params?.score_card__groups),
                eventType: apiData?.report_params?.event_type,
                eventTypeEventSubType: apiData?.report_params?.event_sub_type,
                supervisor: '',
                highlightValue: '',
                scoreCardCategory: '',
                page: '1',
                filterName: '',
                flag: getIntervalType(),
            }
            setSelAnaTeamsFilters(filterObj)
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.group, filterBy: apiData?.report_params?.filter_by })
            window.open(`${URLFront}/analytics/team/overAllTeamsTrends?custom_from_week=${getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))}&custom_to_week=${getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))}&custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_group=${apiData?.report_params?.score_card__groups}&custom_filter_range=${getFilterByRange(apiData?.report_params?.filter_by)}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}&interval=${getIntervalType()}`, "_blank")
        }

        if (apiData?.report_module === '4.7') {
            setSelectedScFromWeek(getWeeks(moment(apiData?.report_params?.from_date)?.format('YYYY-MM-DD')));
            setSelectedScToWeek(getWeeks(moment(apiData?.report_params?.to_date)?.format('YYYY-MM-DD')));
            setSelectedScFromDate(moment(apiData?.report_params?.from_date)?.format('YYYY-MM-DD'))
            setSelectedScToDate(moment(apiData?.report_params?.to_date)?.format('YYYY-MM-DD'))
            setSelectedDateAnaScRange('Custom Date Range');
            setSelectedScFilterBy(getFilterByRange(apiData?.report_params?.filter_by));
            setSelectedScGroupFilter(Number(apiData?.report_params?.score_card__groups));
            setSelectedScTeamFilter(apiData?.report_params?.teams);
            setselectedScFilter(Number(apiData?.report_params?.score_card));
            setSelectedScEventType(apiData?.report_params?.event_type);
            setSelectedScSubType(apiData?.report_params?.event_sub_type);
            setSelectedScFilterByValue(apiData?.report_params?.filter_by);
            const filterObj =
            {
                "fromDate": moment(apiData?.report_params?.from_date)?.format('YYYY-MM-DD'),
                "toDate": moment(apiData?.report_params?.to_date)?.format('YYYY-MM-DD'),
                "filterBy": apiData?.report_params?.filter_by,
                "filterByRange": getFilterByRange(apiData?.report_params?.filter_by),
                "group": Number(apiData?.report_params?.score_card__groups),
                "teams": apiData?.report_params?.teams,
                "scoreCard": Number(apiData?.report_params?.score_card),
                "scoreCardGroups": Number(apiData?.report_params?.score_card__groups),
                "eventType": apiData?.report_params?.event_type,
                "eventTypeEventSubType": apiData?.report_params?.event_sub_type,
                "supervisor": "",
                "staff": "",
                "scoreCardCategory": "",
                "evaluator": "",
                "highlightValue": "",
                "page": "1",
                "filterName": "",
                "sup_grp": '',
                "flag": 'week'
            }
            setSelAnaScoreCardFilters(filterObj)
            window.open(`${URLFront}/analytics/scorecards/CausesByScorecard?custom_from_week=${getWeeks(moment(apiData?.report_params?.from_date).format('YYYY-MM-DD'))}&custom_to_week=${getWeeks(moment(apiData?.report_params?.to_date).format('YYYY-MM-DD'))}&custom_from_date=${apiData?.report_params?.from_date}&custom_to_date=${apiData?.report_params?.to_date}&custom_teams=${apiData?.report_params?.teams}&custom_scorecard=${apiData?.report_params?.score_card}&custom_group=${apiData?.report_params?.score_card__groups}&custom_filter_range=${getFilterByRange(apiData?.report_params?.filter_by)}&custom_filter_by=${apiData?.report_params?.filter_by}&custom_event_type=${apiData?.report_params?.event_type}&custom_event_sub_type=${apiData?.report_params?.event_sub_type}`, "_blank")
            getScoreLogGroups({ ...filterObj, sup_grp: filterObj?.group, filterBy: apiData?.report_params?.filter_by })
            getScoreLogScorecards({ ...filterObj, group: filterObj?.group, staff: '', sup_grp: '', filterBy: apiData?.report_params?.filter_by })
        }




    }

    console.log('boxWidth', boxWidth, 'width1', width1)
    console.log('customGraphData', customGraphData, 'customGraphCategoryData', customGraphCategoryData, 'percentageQuestionArray', percentageQuestionArray)
    return (



        <>

            <Resizable
                style={{ ...style2, border: 'none', borderRadius: '10px' }}
                maxHeight={450}
                onResize={
                    (e, direction, ref, d) => {

                        if ((w.width + d.width) > boxWidth) {
                            setW({
                                width: boxWidth,
                                height: (w.height + d.height)
                            })
                        }
                        if ((w.width + d.width) < boxWidth) {
                            setW({
                                width: (w.width + d.width),
                                height: (w.height + d.height)
                            })
                        }

                    }
                }
                onResizeStop={(e, direction, ref, d) => {
                    if ((w.width + d.width) > boxWidth / 2) {
                        setW({
                            width: ((boxWidth * 2) + 5),
                            height: 450
                        })
                    }
                    if ((w.width + d.width) <= boxWidth / 2) {
                        setW({
                            width: boxWidth,
                            height: 450
                        })
                    }

                }
                }
                size={{ width: w.width, height: w.height }}
            >

                <div
                    ref={ref}
                    onMouseLeave={() => setOptions(0)}
                    onMouseEnter={() => setOptions(id)}
                    style={{ ...style, width: w.width, height: w.height, opacity, zIndex: 5, borderRadius: '10px' }}>
                    {!isLoading && <>
                        <Stack direction="row" sx={{ my: 1, width: "100%" }} alignItems="center" justifyContent="space-between" >
                            <Typography variant="body2" sx={{ fontSize: "15px" }}>
                                {apiData?.report_module} {apiData?.report_module_name}
                            </Typography>
                            <Tooltip title="Remove Card">
                                <IconButton onClick={async () => {
                                    await confirm({ title: '', description: `Remove report ${apiData?.report_module} ${apiData?.report_module_name} ${apiData?.report_type_name}, are you sure you want to remove this report?`, confirmationText: 'Yes, Remove Report', cancellationText: 'No' });
                                    try {
                                        const res = await delCustomAnalyticReportApi(apiData?.id);
                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                        setCustomData(customData?.filter(item => item?.id !== apiData?.id))
                                        enqueueSnackbar('Report Tile Removed Successfully', {
                                            variant: 'success',
                                            action: (key) => (

                                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                                    <Icon icon={closeFill} />
                                                </MIconButton>
                                            )
                                        });
                                        const { data } = await getAllCustomAnalyticReportApi();
                                        setCustomData(data);

                                    } catch (err) {
                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                        enqueueSnackbar('Report Tile Not Removed', {
                                            variant: 'error',
                                            action: (key) => (

                                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                                    <Icon icon={closeFill} />
                                                </MIconButton>
                                            )
                                        });
                                    }

                                }}><Cancel fontSize='small' /></IconButton>
                            </Tooltip>

                        </Stack>
                        <Stack
                            // direction="row" 
                            sx={{
                                my: 1,
                                width: "100%",
                                flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: "column" }

                            }}
                            alignItems="center" justifyContent="space-between" >
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    paddingLeft: '5px',
                                    // width: "50%",
                                    width: { lg: '50%', md: '50%', sm: '100%', xs: "100%" }

                                }}>
                                <Typography variant="body2" sx={{ fontSize: "13px" }}>
                                    {apiData?.report_module === '4.7' ? 'Data Limit:' : 'Date Range:'}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: "13px", fontWeight: "bold" }}>
                                    &nbsp;{apiData?.report_module === '4.7' ? getDataLimit(apiData?.report_params?.data_limit) : apiData?.report_params?.date_range_type}
                                </Typography>
                            </Stack>
                            <Box sx={{
                                display: { lg: 'flex', md: "flex", sm: 'contents', xs: 'contents' },
                            }}>

                                <Stack
                                    sx={{
                                        width: "100%",
                                        // flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: "column" }
                                    }}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    {/* <Button size='small' sx={{ fontSize: "12px" }} startIcon={<SettingsOutlined fontSize='small' />}
                                        onClick={() => { setReportFieldValue(1.2); setRadioBtnValue(20); setReportSettingObject({ ...apiData?.report_params, type: apiData?.report_type_name }); setTitleText("Report Setting") }}
                                    >
                                        Report Settings</Button> */}
                                    <Box
                                        sx={{ height: '30px', display: { lg: 'flex', md: "flex", sm: 'contents', xs: 'contents' }, width: '100%' }}
                                    >
                                        {
                                            (showOptions === apiData?.id || dialogVisible) &&
                                            <>
                                                <AddCustomReportDialog addUserButton isReportSetting text="Report Setting" filterValue={filterValue} />
                                                <Button onClick={handleRedirect} size='small' sx={{ fontSize: "12px" }} startIcon={<BarChartOutlined fontSize='small' />}>See Full Report</Button>
                                            </>
                                        }
                                    </Box>
                                </Stack>

                            </Box>
                        </Stack>
                        {(isError) && <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ py: 2, height: "100%", width: "100%" }}>
                            No Data Found
                        </Stack>}
                        {(!isError && (apiData?.report_module === '1.2' || apiData?.report_module === '5.1' || apiData?.report_module === '6.1' || apiData?.report_module === '6.3') && apiData?.report_type === '10') && <CustomDashboardTableView cardData={cardData} module={apiData?.report_module} />}
                        {(!isError && apiData?.report_module === '3.2' && apiData?.report_type === '10') && <EvaluationPerPeriodTable wdth={(w.width - Number(60))} hght={w.height - Number(50)} customDateType={customDateType} customTableData={customTableData} />}
                        {(!isError && apiData?.report_module === '1.2' && apiData?.report_type === '20') &&
                            <QuestionByStaffGraph perGraphData={[
                                {
                                    name: 'percentage',
                                    data: percentageQuestionArray
                                }
                            ]}
                                graphQuestionArray={graphQuestionArray}
                                graphQuestionIdArray={graphQuestionIdArray}
                                wdth={(w.width - Number(60))}
                                hght={(w.height - Number(200))}

                            />}
                        {(!isError && (apiData?.report_module === '3.2' || apiData?.report_module === '4.7' || apiData?.report_module === '6.1' || apiData?.report_module === '5.1' || apiData?.report_module === '6.3' || apiData?.report_module === '7.1') && (apiData?.report_type === '30' || apiData?.report_type === '20' || apiData?.report_type === '40' || apiData?.report_type === '50')) &&
                            <EvaluationPerPeriodGraph
                                customGraphCategoryData={customGraphCategoryData}
                                customGraphData={customGraphData}
                                wdth={(w.width - Number(60))}
                                hght={(w.height - Number(150))}
                                module={`${apiData?.report_module}`}

                            />}
                    </>}

                    {isLoading && <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ py: 2, height: "100%", width: "100%" }}>
                        <CircularProgress />&nbsp;Loading Data...
                    </Stack>}




                </div>

            </Resizable>


        </>)
}
export default Card
