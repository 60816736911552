import PropTypes from 'prop-types';
// material
import { Card, CardHeader, Box, Typography, Divider, Stack } from '@material-ui/core';
//
import HeaderBreadcrumbsV2 from '../../components/HeaderBreadcrumbsV2';
import EmpDialog from './material-ui/dialog/admin/staff/EmpDialog';
import SupDialog from './material-ui/dialog/admin/staff/SupervisorDialog';
import TeamDialog from "./material-ui/dialog/admin/staff/teamDialog";
import GroupDialog from "./material-ui/dialog/admin/staff/GroupDialog";
import GoalsDialog from "./material-ui/dialog/admin/staff/GoalsDialog";

// ----------------------------------------------------------------------

Label.propTypes = {
  title: PropTypes.string
};

export function Label({ title }) {
  return (
    <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
      {title}
    </Typography>
  );
}

MainBlockV2.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object
};



export function MainBlockV2({ title, sx, children, buttonValue, tabType, MBLinks, component, buttonType, widthType }) {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  return (
    <Card elevation={0} sx={{ overflow: 'unset', boxShadow: 'none', position: 'unset', width: '100%', backgroundColor: 'transparent', pt: 1, px: 2, borderRadius: 1, }}>

      {component}
      <CardHeader
        sx={{
          mt: loggedUserData?.staff ? 3 : 0,
          // pt: 0, pl: 1,
          padding:{lg:'0px 0px 0px 8px',md:'0px 0px 0px 8px',sm:'0px 0px 0px 8px',xs:'0px'},
          flexDirection: { lg: 'row', md: 'row', sm: "row", xs: 'row' },
          color: (theme) => theme.palette.mode === 'light' ? '#666666' : '#ffffff'
        }} title={<>
          <HeaderBreadcrumbsV2
            sx={{ m: 0, color: '#666666', fontWeight: 600 }}
            links={MBLinks} />
          <Stack direction='row'
            alignItems='center'
            justifyContent='space-between' >
            <Box><Typography sx={{fontWeight: '400', color: '#000000'}} variant="h4">{title}</Typography></Box>
            <Box>{buttonValue}</Box>
          </Stack></>}
        titleTypographyProps={{ sx: { fontSize: { lg: '10px', xl: '18px' } } }}
        action={<Box sx={{ mt: 3 }}>{buttonType}</Box>} />
      {/* <Divider /> */}
      <Box
        sx={{

          minHeight: 180,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Card>
  );
}
