import React from 'react';
// REDUX STATE
import { useSelector, useDispatch } from 'react-redux';

// MATERIAL IMPORTS
import {
    Button, Box,
    CircularProgress, Chip,
    IconButton,
    Paper,
    Stack,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    tableCellClasses, Typography, Tooltip,
} from '@material-ui/core';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
import { List, ArrowUpward, ArrowDownward } from '@material-ui/icons';
// NPM IMPORTS
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import moment from "moment";
// COMPONENTS IMPORTS
import { MIconButton } from '../../components/@material-extend';
import { editTasks } from "../../redux/action/staffAction";
import EmpDatePicker from "./datepicker";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../routes/paths';
import { getTaskStatus, setTaskPriority } from "../../utils/filterUtilities";
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
// API IMPORTS
import { editTaskApi } from "../../_apis_/authApi.js/staffApi";



export default function EmpTasks() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const [searchParams, setSearchParams] = useSearchParams();

    // REDUX STATES
    const dispatch = useDispatch();
    const taskData = useSelector(state => state.tasks);

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // CONTEXT STATES
    const { datesData } = React.useContext(EmpContext);

    // REACT STATES
    const [task, setTask] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [tableOrder, setTableOrder] = React.useState(false);
    const [showIcon, setShowIcon] = React.useState(false);

    // METHODS / FUNCTIONS

    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    const handleTableOrder = () => {
        setTableOrder(!tableOrder);
        setTask(task.reverse())

    }

    // console.log(taskData)

    const editTaskFn = async (index, id) => {
        const newObj = {
            name: task[index]?.name,
            description: task[index]?.description,
            due_date: task[index]?.due_date,
            task_status: id || task[index]?.task_status,
            task_priority: task[index]?.task_priority,
            is_archive: task[index]?.is_archive,
            staff: task[index]?.staff
        }
        const { data } = await editTaskApi(task[index]?.id, newObj)
        dispatch(editTasks(data));

        enqueueSnackbar('Updated Task Successfully', {
            variant: 'success',
            action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    }


    // USEFFECT METHODS
    React.useEffect(() => {
        setIsDataLoading(true);
        loadingTimeOut();
        setTask([]);

    }, [datesData])

    React.useEffect(() => {
        setIsDataLoading(true);
        loadingTimeOut();
        if (taskData?.results !== undefined) {
            setTask(taskData?.results);
            setIsDataLoading(false);
        }
    }, [taskData])



    return (
        <Box sx={{ px: 1 }}>
            <Stack sx={{ p: 0, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0 }} direction='row' alignContent='center' alignItems='center' justifyContent='space-between'>

                <Box>

                    <Typography variant='h5' sx={{ p: 0, m: 0, fontSize: { lg: '20px', xl: '28px' }, color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }}>
                        Tasks
                    </Typography>
                    <HeaderBreadcrumbs
                        sx={{ pr: 3, mb: 1, color: '#666666', fontWeight: 600 }}
                        links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Tasks', href: PATH_EMP.tasks }]}
                    />

                </Box>
                <EmpDatePicker />
            </Stack>
            {/* <HeaderBreadcrumbs
                sx={{ pl: 2, ml: 3, pt: 1, pr: 3 }}
                links={[{ name: 'Home', href: PATH_EMP.dashboard }, { name: 'Tasks', href: PATH_EMP.tasks }]}
            /> */}
            <Paper sx={{ width: "100%", pt: 1, pb: 2, borderRadius: '5px' }} elevation={5}>

                {/* <Box sx={{ px: 5, py: 2 }} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Tasks</Typography>
                    <EmpDatePicker />
                </Box> */}
                {/* <Divider /> */}
                <Box>
                    <TableContainer component={Paper} elevation={0} sx={{ px: 1 }}>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: "14px", color: "black" }}>
                                                Task
                                            </Typography>&nbsp;
                                            {showIcon && <Tooltip title='Sort'>
                                                <IconButton onClick={() => handleTableOrder()}>
                                                    {tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                                </IconButton>
                                            </Tooltip>}
                                        </Box>

                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: "13px", color: "black" }}>
                                            Due Date
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography sx={{ fontSize: "13px", color: "black" }}>
                                            Priority
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography sx={{ fontSize: "13px", color: "black" }}>
                                            Status
                                        </Typography>
                                    </TableCell>
                                    {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <TableCell align='center'>
                                        <Typography sx={{ fontSize: "13px", color: "black" }}>
                                            Action
                                        </Typography>
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {task?.length > 0 && task?.map((row, index) => <TableRow
                                    key={index}
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: (theme) => `1px solid ${(index !== task?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                        },
                                    }}
                                >

                                    <TableCell align="left" component="th" scope="row" >
                                        <Box display="flex">
                                            <Typography sx={{ fontSize: "14px" }}>
                                                {row?.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left" component="th" scope="row">
                                        <Typography variant='body2'>
                                            {moment(row?.due_date).format("LL")}
                                        </Typography>

                                    </TableCell>
                                    <TableCell size='small' align="center" component="th" scope="row">
                                        <Chip size='small' label={setTaskPriority(row?.task_priority)?.priorityName} sx={{ color: 'white', backgroundColor: setTaskPriority(row?.task_priority)?.bgColor }} />
                                    </TableCell>
                                    <TableCell size='small' align="center" component="th" scope="row">
                                        <Chip size='small' label={getTaskStatus(row?.task_status)?.statusName} sx={{ color: 'white', backgroundColor: getTaskStatus(row?.task_status)?.bgColor }} />

                                    </TableCell>
                                    {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <TableCell align="center" component="th" scope="row">
                                        {row?.task_status === 10 && <Button sx={{ mx: 1, fontSize: '12px', backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', border: '1px solid #dddde7' }} size='small' disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant='contained' onClick={() => { editTaskFn(index, 20) }}>
                                            Start Task
                                        </Button>}
                                        {row?.task_status === 20 && <Button sx={{ mx: 1, fontSize: '12px', backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', border: '1px solid #dddde7' }} size='small' disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant='contained' onClick={() => { editTaskFn(index, 30) }}>
                                            Complete Task
                                        </Button>}
                                        {row?.task_status === 30 && <Button sx={{ mx: 1, fontSize: '12px', backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', border: '1px solid #dddde7' }} size='small' disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant='contained' onClick={() => { editTaskFn(index, 10) }}>
                                            Reopen Task
                                        </Button>}
                                    </TableCell>}
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {task?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                        <List sx={{ fontSize: "100px" }} /><br />
                        {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                            <CircularProgress />&nbsp;
                            <Typography variant="body" sx={{ my: 1 }}>

                                Loading Data...
                            </Typography>
                        </Stack> : <Typography variant="body" sx={{ my: 1 }}>
                            No Tasks found for the data range selected.
                        </Typography>}
                    </Box>}
                </Box>
            </Paper>
        </Box>
    )
}