import React from 'react';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress, Checkbox,
    Dialog, DialogContent, DialogTitle, Divider,
    FormControlLabel,
    IconButton,
    Stack, Typography, TextField
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
// NPM IMPORTS
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
// API IMPORTS
import { acceptOne2OneApi } from "../../_apis_/authApi.js/empapi";
// COMPONENT IMPORTS
import { MIconButton } from '../../components/@material-extend';

AcceptOne2One.propTypes = {
    ID: PropTypes.number,
    setID: PropTypes.func,
    fn: PropTypes.func,
    supervisorName: PropTypes.string,
    evaluatorName: PropTypes.string
};


export default function AcceptOne2One({ ID, setID, fn, supervisorName, evaluatorName }) {
    // LOCAL STORAGE
    const companyData = JSON.parse(window.localStorage.getItem('companyData'));

    // CONTEXT STATE
    const { acceptOne2One, setAcceptOne2One } = React.useContext(EmpContext);

    // REACT STATE
    const [disBtn, setDisBtn] = React.useState(false);
    const [isEvaluator, setIsEvaluator] = React.useState(false);
    const [IsSupervisor, setIsSupervisor] = React.useState(false);

    // SNAKBAR STATE
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getSupervisorName = () => {
        return supervisorName?.userFirstName ? `${supervisorName?.userFirstName} ${supervisorName?.userLastName}` : supervisorName
    }


    // USE EFFECT 
    // React.useEffect(() => {
    //     console.log(isEvaluator)
    // }, [isEvaluator])

    const formik = useFormik({
        initialValues: { comment: "" },
        onSubmit: values => {
            const handleOne2One = async () => {
                setDisBtn(true)

                const obj = {
                    score: ID,
                    comments: values.comment,
                    evaluator: companyData?.requestFlow === 30 ? true : isEvaluator,
                    supervisor: (companyData?.requestFlow === 20 || companyData?.requestFlow === 40) ? true : IsSupervisor
                }
                await acceptOne2OneApi(obj);
                setDisBtn(false);
                enqueueSnackbar('Accepted Commented Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )

                });
                if (fn) {
                    fn();
                }
                setAcceptOne2One(false); formik.resetForm(); if (setID) {
                    setID();
                }


            }

            handleOne2One()
        }
    });




    return (
        <>
            <Dialog open={acceptOne2One} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack sx={{ px: 2, py: 1 }} direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">
                            Request One to One
                        </Typography>
                        <IconButton><Close onClick={() => { setAcceptOne2One(false); formik.resetForm(); if (setID) { setID(); } }} /></IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ py: 1, px: 2 }}>
                        {(companyData?.requestFlow === 20 || companyData?.requestFlow === 40) && <Typography sx={{ my: 1 }} variant="body">Are you sure you want to send a for 1:1 to your Supervisor ({getSupervisorName() || ''}) ? </Typography>}
                        {companyData?.requestFlow === 30 && <Typography sx={{ my: 1 }} variant="body">Are you sure you want to send a for 1:1 to your Evaluator ? {`(${evaluatorName})`}</Typography>}
                        <form onSubmit={formik.handleSubmit}>
                            {companyData?.requestFlow === 10 && <><FormControlLabel
                                sx={{ my: 1 }}
                                value={IsSupervisor}
                                checked={IsSupervisor}
                                control={<Checkbox />}
                                onChange={() => setIsSupervisor(!IsSupervisor)}
                                label={`Send Notification to ${getSupervisorName() || ''} (Supervisor)`}
                                labelPlacement="end"
                            />
                                <FormControlLabel
                                    sx={{ my: 1 }}
                                    value={isEvaluator}
                                    checked={isEvaluator}
                                    onChange={() => setIsEvaluator(!isEvaluator)}
                                    control={<Checkbox />}
                                    label={`Send Notification to ${evaluatorName} (Evaluator)`}
                                    labelPlacement="end"
                                /></>}
                            <Box sx={{ my: 2 }}>
                                <TextField name="comment" {...formik.getFieldProps('comment')} fullWidth label="Add a comment concering this score" sx={{ my: 1, width: "100%" }} multiline rows={5} />
                                {formik.errors.comment && formik.touched.comment && <Typography variant="body2" sx={{ color: "red" }}>{formik.errors.comment}</Typography>}
                            </Box>
                            <Stack direction="row" justifyContent="flex-end" sx={{ pr: 1, mt: 3 }}>
                                <Button onClick={() => { setAcceptOne2One(false); formik.resetForm(); setID(); }} variant="contained" sx={{ mr: 2 }}>Cancel</Button>
                                <Button variant="contained" disabled={disBtn} type='submit'>Yes,&nbsp;Request {disBtn && <CircularProgress sx={{ mx: 1 }} size={14} />}</Button>
                            </Stack>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}