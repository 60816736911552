import { summary, empActivity } from "../types/typeEmp";

const initalData = {};
const initialActivity = {};


export const empSummaryReduceR = (state = initalData, action) => {
    switch (action.type) {
        case summary: {
            state = action.payload
            return state;
        }
        default: return state
    }

}

export const empActivityReduceR = (state = initialActivity, action) => {
    switch (action.type) {
        case empActivity: {
            // console.log("activity", action?.payload?.results);
            state = { counts: action?.payload?.count, result: action?.payload?.results?.reverse() }
            return state;
        }
        default: return state
    }

}