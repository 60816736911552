import { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from "moment";
// import { getTaskApi } from '../_apis_/authApi.js/staffApi';
import { getTaskApi } from '../_apis_/authApi.js/staffApi';
import { getGdashboardGroupApi, getGdashboardTeamApi } from '../_apis_/authApi.js/globalDashboardApi';
import { setTasks, getListAllGrp, getListAllSup, getListAllTeam } from "../redux/action/staffAction";

export const StaffContext = createContext();

export default function StaffProviders({ children }) {

    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));


    // DATE STATE

    const [fromDate, setFromDate] = useState();
    const [isCustomDate, setCustomDate] = useState(false);
    const [gDashboardOpen, setGdashboardOpen] = useState(true);
    const [toDate, setToDate] = useState();
    const [dateData, setDateData] = useState();
    const [test, setTest] = useState(0);
    const [filter, setfilter] = useState("created_at");
    const [selectedItem, setselecteItem] = useState(2);
    const [gpId, setGpId] = useState([]);
    const [grpList, setGrpList] = useState([]);
    const [teamListsContext, setTeamListsContext] = useState([]);
    const [gdTeamId, setGdTeamId] = useState(null);
    const [dashboardDetail, setDashboardId] = useState();
    const [test11, setTest11] = useState([]);

    const [messageData, setMessageData] = useState([]);
    const [query, setQuery] = useState();



    // SCORE STATES
    const [scoreForDraft, setScoreForDraft] = useState();




    // EMP STATE
    const [empDialog, setEmpdialog] = useState(false);
    const [empId, setEmpId] = useState();
    const [empData, setempData] = useState();

    // SUPERVISOR STATE
    const [supDialog, setSupDialog] = useState(false);
    const [supId, setSupId] = useState();
    const [supData, setSupData] = useState();

    // SUP DASHBOARD STATES
    const [selectedSupGrp, setSelectedSupGrp] = useState([]);
    const [selectedSupTm, setSelectedSupTm] = useState([]);
    const [selectedSupScrds, setSelectedSupScrds] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);


    // TASK STATES
    const [TaskName, SetTaskName] = useState();
    const [taskDialog, setTaskDialog] = useState();
    const [TaskId, SetTaskId] = useState("");
    const [TaskOpen, SetTaskOpen] = useState(false);
    const [isTask, SetIsTask] = useState(false);

    // TEAM  STATES
    const [IsTeamOpen, SetTeamOpen] = useState(false);
    const [TeamId, SetTeamId] = useState("");



    // GROUP STATES
    const [IsGrpOpen, SetGrpOpen] = useState(false);
    const [GrpId, SetGrpId] = useState(false);

    //  GOALS STATES
    const [IsGoalOpen, SetGoalOpen] = useState(false)
    const [GoaldId, SetGoalId] = useState("");
    const [NewGoal, SetNewGoal] = useState(false);
    const [GoalPanel, SetGoalPanel] = useState(0);

    // PIE CHART SUPERVISOR

    const [pieChartData, setPieChartData] = useState([]);
    // REDUX STATES
    const dispatch = useDispatch();

    // SUPERVISOR DASHBOARD GRAPH LOADERS
    const [supDashboardGraphLoading, setSupDashboardLoading] = useState(false);



    useEffect(() => {

        async function getTask() {
            if ((supId || empId) && isTask === true) {
                const { data } = await getTaskApi(supId || empId)
                dispatch(setTasks(data))
            }
        }
        getTask()

    }, [isTask])








    useEffect(() => {
        dispatch(getListAllGrp()); dispatch(getListAllSup()); dispatch(getListAllTeam());
        setFromDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
        setToDate(moment().format('YYYY-MM-DD'));
        setDateData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })

    }, [])



    useEffect(() => {
        const groupIds = []
        if (loggedUserData?.userLevel?.name === 'Team Admin') {
            loggedUserData?.groups?.map(row => groupIds.push(row?.id));
        }

        if (pathname?.split("/")[1] !== "dashboard") {
            setGrpList([]);
        }

        async function getList() {
            if (fromDate && toDate && pathname?.split("/")[1] === "dashboard" && !searchParams.get('custom_group')) {
                const { data } = await getGdashboardGroupApi(fromDate, toDate, gpId?.length === 0 ? groupIds : gpId, filter);

                setGrpList(data?.data);

                if (selectedSupGrp?.length === 0) { setSelectedSupGrp([]) }
                if (selectedSupTm?.length === 0) { setSelectedSupTm([]) }
                if (selectedSupScrds?.length === 0) { setSelectedSupScrds([]) }
            }


            if (pathname?.split("/")[1] === "dashboard" && searchParams.get('custom_group')) {
                console.log("perfecto")
                const { data } = await getGdashboardGroupApi(searchParams.get('custom_from_date'), searchParams.get('custom_to_date'), [searchParams.get('custom_group')], 'created_at');

                setGrpList(data?.data);
                setGpId([searchParams.get('custom_group')])

                if (searchParams.get('custom_teams') !== '') {
                    setGdTeamId([searchParams.get('custom_teams')])
                }

                setFromDate(moment(searchParams.get('custom_from_date')).format('YYYY-MM-DD'))
                setToDate(moment(searchParams.get('custom_to_date')).format('YYYY-MM-DD'))
                setCustomDate(true);
                if (selectedSupGrp?.length === 0) { setSelectedSupGrp([]) }
                if (selectedSupTm?.length === 0) { setSelectedSupTm([]) }
                if (selectedSupScrds?.length === 0) { setSelectedSupScrds([]) }
            }
            if (loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.length === 0) {
                const res = await getGdashboardTeamApi(fromDate, toDate, groupIds, filter)

                setTeamListsContext(res?.data?.data);

                console.log(res?.data?.data);
            }

        }
        getList()


    }, [dateData, pathname])





    const Groups = useSelector(state => state.groups);
    const Teams = useSelector(state => state.teams);
    const Staffs = useSelector(state => state.staff);
    const emp = useSelector(state => state.emps);
    const sup = useSelector(state => state.sups);
    const supAllList = useSelector(state => state.stateSupList);
    const grpAllList = useSelector(state => state.stateGrpList);
    const teamAllList = useSelector(state => state.stateTeamList)




    return (
        <>
            <StaffContext.Provider value={{
                searchText: query, setSearchText: setQuery,
                dateObj: dateData, setDateObj: setDateData,
                listMessage: messageData, setListMessage: setMessageData,
                dateItem: selectedItem, setDateItem: setselecteItem,
                globalTeamId: gdTeamId, setGlobalTeamId: setGdTeamId,
                strtDate: fromDate, setStrtDate: setFromDate,
                groupList: grpList, setGroupList: setGrpList,
                tester: test,
                filterby: filter, setFilterBy: setfilter,
                endDate: toDate, setEndDate: setToDate,
                getEmployee: emp.results, getSupervisor: sup.results,
                getGroups: Groups.results, getTeams: Teams.results,
                getStaffs: Staffs, employeeID: empId,
                setEmployeeID: setEmpId, emp: empData,
                setEmp: setempData, empForm: empDialog, setEmpForm: setEmpdialog,
                supervisrID: supId, setSupervisrID: setSupId,
                supForm: supDialog, setSupForm: setSupDialog, sup: supData, setSup: setSupData,
                taskName: TaskName, setTaskName: SetTaskName, isTaskDialogOpen: taskDialog, setTaskDialogOpen: setTaskDialog, taskId: TaskId, setTaskId: SetTaskId,
                taskEditorOpen: TaskOpen, setEditorTaskOpen: SetTaskOpen,
                isTeamOpen: IsTeamOpen, setTeamOpen: SetTeamOpen,
                teamId: TeamId, setTeamId: SetTeamId,
                grpId: GrpId, setGrpId: SetGrpId,
                isGrpOpen: IsGrpOpen, setGrpOpen: SetGrpOpen,
                isGoalOpen: IsGoalOpen, setGoalOpen: SetGoalOpen, goalId: GoaldId, setGoalId: SetGoalId,
                newGoal: NewGoal, setNewGoal: SetNewGoal, goalPanel: GoalPanel, setGoalPanel: SetGoalPanel,
                allGroup: grpAllList, allSup: supAllList, allTeam: teamAllList,
                t11: test11, setT11: setTest11,
                teamListsContext, setTeamListsContext,
                isTask, SetIsTask,
                selectedSupGrp, setSelectedSupGrp,
                selectedSupTm, setSelectedSupTm,
                selectedSupScrds, setSelectedSupScrds,
                gpId, setGpId,
                selectedTeam, setSelectedTeam,
                selectedGroup, setSelectedGroup,
                pieChartData, setPieChartData,
                isCustomDate, setCustomDate,
                scoreForDraft, setScoreForDraft,
                supDashboardGraphLoading, setSupDashboardLoading

            }}>
                {children}
            </StaffContext.Provider>
        </>
    )
}