import { LoginInfo } from "../types/typeUser";

const initalData = [];


export const authReduceR = (state = initalData, action) => {
    switch (action.type) {
        case LoginInfo: {
            state = action.payload
            return state;
        }
        default: return state
    }

}