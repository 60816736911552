import PropTypes from 'prop-types';
// material
import { Card, CardHeader, Box, Typography, Button, Divider } from '@material-ui/core';
//
import AddUserDialog from './material-ui/dialog/AddUserDialog';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

Label.propTypes = {
    title: PropTypes.string
};

export function Label({ title }) {
    return (
        <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
            {title}
        </Typography>
    );
}

BlockNewUI.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sx: PropTypes.object,
    MbLinks: PropTypes.array,
};

export function BlockNewUI({ title, sx, children, buttonType, component, MbLinks, widthType }) {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    return (
        <Card
            elevation={0}
            sx={{
                overflow: 'unset',
                // boxShadow: '1px solid grey',
                position: 'unset',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: 1,
                border: '1px solid transparent',
                widthType
            }}>

            {component}
            <HeaderBreadcrumbs
                sx={{ margin: '20px 0 0 20px', color: '#666666', fontWeight: 600 }}
                links={MbLinks}
            />
            <CardHeader
                sx={{
                    mt: loggedUserData?.staff ? 3 : 0,
                    padding: '0 0 0 20px',
                    m: 0,
                    color: (theme) => theme.palette.mode === 'light' ? '#666666' : '#fff'
                }}
                title={<>
                    <Typography sx={{
                        fontWeight: '400',
                        color: '#000000',
                        fontSize: { lg: "1.5rem", md: '1.5rem', sm: "1.5rem", xs: "1rem" }

                    }} variant="h4">{title}</Typography>

                    {/* <Box>{title}</Box> */}
                </>}
                subheader={component && 'Use this section to build custom reports, please keep large date ranges to a minimum.'}
                titleTypographyProps={{ sx: { fontSize: { lg: '20px', xl: '28px' } } }}
                action={<Box sx={{ margin: '0 20px 0 0' }}>{buttonType}</Box>}
            />
            <Box
                sx={{
                    // px: 3,
                    minHeight: 180,
                    ...sx
                }}
            >
                {children}
            </Box>
        </Card>
    );
}
