import axios from 'axios';
import moment from 'moment';
// URL
import { URL } from "../../backend";

// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
const API = axios.create({ baseURL: URL })
API.interceptors.request.use(req => {
    // const { token } = JSON?.parse(localStorage?.getItem("user_info"));

    const token = window.localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Token ${token}`;
    }
    return req
})
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));


// GET ALL TICKETS

export const getAllTicketsApi = (query, pg, ticketType, userType) => API.get(ticketType ? `/support_ticket?search=${encodeURIComponent(query) || ""}&page=${pg}&ticket_status=${ticketType}&ticket_raised_by=${userType}` : `/support_ticket?search=${encodeURIComponent(query) || ""}&page=${pg}&ticket_raised_by=${userType}`);


// GET SINGLE TICKET
export const getTicketApi = (id) => API.get(`/support_ticket/${id}`);

// CREATE NEW TICKET
export const createTicketApi = (obj) => API.post('/support_ticket', obj);

// DELETE TICKET
export const removeTicketApi = (id) => API.delete(`/support_ticket/${id}`);

// EDIT TICKET
export const updateTicketApi = (id, obj) => API.put(`/support_ticket/${id}`, obj);

// ADD ATTACHMENT
export const addTicketAttachmentApi = (obj) => API.post(`/support_ticket/add_attachment`, obj);

// GET TICKET HISTORY
export const getTicketHistory = (id) => API.get(`/support_ticket/status_history/${id}`);

// GET TRAINING APPOINTMENT OPTION
export const getAppointmentOptions = () => API.get(`/support_ticket/training_appointment_options`)
export const getAppointmentTimeOptions = () => API.get(`/support_ticket/training_appointment_time_options`)


// ---------------------------------------------------------REPLY TICKET APIS----------------------------------------------------------------------------------------

// GET ALL REPLY
export const getAllTicketsReplyApi = (ticketId) => API.get(`/support_ticket/all_replies/${ticketId}`);

// CREATE TICKET REPLY
export const createTicketReplyApi = (obj) => API.post(`/support_ticket/create_reply`, obj);

// UPDATE TICKET REPLY
export const updateTicketReplyApi = (ticketId, replyTicketId, obj) => API.put(`/support_ticket/update_reply/${ticketId}/${replyTicketId}`, obj);

// GET ALL SUPPORT RPLY TICKET ATTACHMENT
export const getAllReplyTicketAttachmentApi = (ticketId) => API.get(`/support_ticket/reply/attachments/${ticketId}`)

// ADD SUPPORT REPLY ATTACHEMENTS
export const addReplyTicketAttachementApi = (obj) => API.post(`/support_ticket/reply/add_attachment`, obj);

// DELETE SUPPORT ATTACHMENTS
export const removeTicketAttachmentApi = (attachmentId) => API.delete(`/support_ticket/remove_attachment/${attachmentId}`)
