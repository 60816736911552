const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900, // OLD 960
    lg: 1200, // OLD 1280
    newLg: 1536,
    xl: 1800,// OLD 1920
    qhd: 2560, // QHD 2560
  }
};

export default breakpoints;
