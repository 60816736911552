import axios from 'axios';
// URL
import { URL } from "../../backend";

// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
const API = axios.create({ baseURL: URL })

API.interceptors.request.use(req => {
    // const { token } = JSON?.parse(localStorage?.getItem("user_info"));
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Token ${token}`;
    }
    return req
})

// group list (filter changes to be added)
export const getGdashboardGroupApi = (date1, date2, grpId, filterBy) => {
    if (grpId?.length > 0) {
        return API.get(`/global_dashboard/groups?from_date=${date1}&to_date=${date2}&groups=${grpId}&filter=${filterBy || 'created_at'}`);

    }
    return API.get(`/global_dashboard/groups?from_date=${date1}&to_date=${date2}&filter=${filterBy || 'created_at'}`);


}


// team list (filter changes to be added)
export const getGdashboardTeamApi = (date1, date2, grpId, filterBy) => API.get(`/global_dashboard/teams?from_date=${date1}&to_date=${date2}&groups=${grpId}&filter=${filterBy}`);

// GLOBAL DASHBOARD DETAILs
// export const getEmployeeDetails = (date1, date2, grpId, teamId, filterBy, supId) => API.get(teamId ? `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId}&filter=${filterBy}&supervisor_id=${supId}` : `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId || []}&filter=${filterBy}&supervisor_id=${supId}`)
export const getEmployeeDetails = (date1, date2, grpId, teamId, filterBy, supId) => API.get(`/staff/supervisor_employee_list?supervisor=${supId}`)
export const getGdashboardDetailApi = (date1, date2, grpId, teamId, filterBy) => API.get(teamId ? `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId}&filter=${filterBy}` : `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId || []}&filter=${filterBy}`);
export const downloadGdashboardDetailApi = (date1, date2, grpId, teamId, filterBy) => API.get(teamId ? `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId}&filter=${filterBy}&download=true` : `global_dashboard/supervisor?from_date=${date1}&to_date=${date2}&groups=${grpId}&teams=${teamId || []}&filter=${filterBy}&download=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

// Inboxes
export const getInbox = (pgNo) => API.get(pgNo ? `/inbox?page=${pgNo}` : "/inbox");

// single inbox
export const getSingleInbox = (id) => API.get(`/inbox/${id}`);

// Message Search

export const getSearchInbox = (query, pgNo) => API.get(pgNo ? `/inbox?value=${query}&page=${pgNo}` : `/inbox?value=${query}`);

// Message Count

export const getMessageCount = () => API.get(`/inbox/msg_count`);

// mark all read api---

export const getAllInboxMsgRead = (obj) => API.get(`/inbox/mark_as_all_read?${obj?.ids ? 'ids' : 'all'}=${obj.ids ? obj?.ids : obj.isAllMsgRead}`)

